import React from 'react';
import { Form, Input, DatePicker, SubmitButton, FormItem, Select } from 'formik-antd';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Col, Row, Tooltip } from 'antd';
import { RiInformationLine } from 'react-icons/ri';
import { UUID_REGEXP } from '../../../../const/system';
import Yup from '../../../../vendor/yup';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';



const { Option } = Select;


const validationSchema  = Yup.object().shape({
  request_id: Yup.string().matches(UUID_REGEXP, 'Should be UUID value').nullable(),
  action: Yup.string().nullable(),
  status: Yup.string().nullable(),
  created_from: Yup.string().nullable(),
  created_to: Yup.string().nullable(),
});

const FormProjectRequestsFilter = ({ onSubmit, initialValues, isSubmitting, formErrors }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(getNotEmptyObjectValues(values));
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setFieldValue, setValues } = props;

        return (
          <Form layout="vertical">
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="request_id"
                label="Request ID:"
              >
                <Input name='request_id' placeholder='Specify request id (UUID)' />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="action"
                label="Action:"
              >
                <Select
                  allowClear
                  name="action"
                  value={values.action}
                  placeholder="Select action"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  <Option value="delete">Delete</Option>
                  <Option value="optout">Optout</Option>
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="status"
                label="Status:"
              >
                <Select
                  allowClear
                  name="status"
                  value={values.status}
                  placeholder="Select status"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  <Option value="created">Created</Option>
                  <Option value="in_progress">In progress</Option>
                  <Option value="executed">Executed</Option>
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='created_from'
                label={
                  <div className='content-right'>
                    Created from:
                    <Tooltip placement="top" title="Time zone - UTC +0">
                      <RiInformationLine size={18} />
                    </Tooltip>
                  </div>
                }
              >
                <DatePicker
                  showTime
                  format='YYYY-MM-DD HH:mm:ss'
                  placeholder='Select date'
                  name='created_from'
                  disabledDate={(date) => !date || date.isAfter(moment(new Date()).utc(false)) || date.isAfter(moment(values.created_to).utc(false))}
                  onChange={(value) => {
                    if (!isEmpty(value)) {
                      setFieldValue('created_from', moment(value).format('YYYY-MM-DD HH:mm:ss'));
                    }
                  }}
                />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='created_to'
                label={
                  <div className='content-right'>
                    Created to:
                    <Tooltip placement="top" title="Time zone - UTC +0">
                      <RiInformationLine size={18} />
                    </Tooltip>
                  </div>
                }
              >
                <DatePicker
                  showTime
                  format='YYYY-MM-DD HH:mm:ss'
                  placeholder='Select date'
                  name='created_to'
                  disabledDate={(date) => !date || date.isAfter(moment(new Date()).utc(false)) || (!isEmpty(values?.created_from) ? date.isBefore(moment(values.created_from).utc(false)) : false)}
                  onChange={(value) => {
                    if (!isEmpty(value)) {
                      setFieldValue('created_to', moment(value).format('YYYY-MM-DD HH:mm:ss'));
                    }
                  }}
                />
              </FormItem>
            </Col>

            <Row style={{ justifyContent: 'center', padding: '10px 0' }} gutter={[ 16, 16 ]}>
              <Col>
                <Button
                  type='secondary'
                  disabled={isEmpty(values)}
                  onClick={() => {
                    setValues({});
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || (isEqual(getObjectValuesByUrlParams(), values))}
                >
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormProjectRequestsFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    action: PropTypes.string,
    status: PropTypes.string,
    created_from: PropTypes.string,
    created_to: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool.isRequired,
};

FormProjectRequestsFilter.defaultProps = {
  formErrors: {},
};

export default FormProjectRequestsFilter;
