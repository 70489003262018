import { setRequestParams } from '../../../lib/setRequestParams';



export default class HintsMonitoringService {
  constructor (Api) {
    this.api = Api;
  }

  getHintsMonitoring (params) {
    return this.api.get(`monitoring/hints${setRequestParams(params)}`);
  }
}
