import React from 'react';
import moment from 'moment';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const GROUPS_TABLE_COLUMNS = [
  {
    title: 'Group',
    dataIndex: 'group',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Unique users',
    dataIndex: 'uniq_users',
    sorter: (_a, _b) => Number(_a.uniq_users) - Number(_b.uniq_users),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'User percentage',
    dataIndex: 'user_percentage',
    sorter: (_a, _b) => Number(_a.user_percentage) - Number(_b.user_percentage),
    render: (value) => <div>{value ? `${value}%` : EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];

export const USERS_BY_DATE_TABLE_COLUMNS = [
  {
    title: 'Group',
    dataIndex: 'group',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    sorter: (_a, _b) => (moment(_a.date) >= moment(_b.date) ? 1 : -1),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Unique users',
    dataIndex: 'uniq_users',
    sorter: (_a, _b) => Number(_a.uniq_users) - Number(_b.uniq_users),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Total',
    dataIndex: 'total',
    sorter: (_a, _b) => Number(_a.total) - Number(_b.total),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];


export const prepareDateStatisticsData = (data) => {
  const arrObject = {};

  data.forEach((item) => {
    const { group, date, uniq_users, total } = item;

    if (!arrObject[date]) {
      arrObject[date] = { date };
    }

    arrObject[date][`${group}_unique_users`] = uniq_users;
    arrObject[date][`${group}_total`] = (arrObject[date][`${group}_total`] || 0) + total;
  });

  return Object.values(arrObject);
};

export const getUniqueDateStatisticsFields = (data) => {
  const uniqueFields = [];

  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'date' && !uniqueFields.includes(key)) {
        uniqueFields.push(key);
      }
    });
  });

  return uniqueFields;
};

export const getIndexByGroup = (arr) => {
  const uniqueValues = [];

  arr.forEach((item) => {
    const splitItem = item.split('_');

    if (splitItem.length >= 2 && !uniqueValues.includes(splitItem[0])) {
      uniqueValues.push(splitItem[0]);
    }
  });

  return uniqueValues;
};
