import { Badge, Card, Descriptions, Tag } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { Link } from 'react-router-dom';
import { CONFIGURATIONS_STATUS_COLOR } from '../../pages/PageConfigurations/PageConfigurations.const';
import { hasRights, Permissions } from '../../../../const/permissions';
import urlPageConfigMixinVisualize from '../../../../urls/urlPageConfigMixinVisualize';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const ConfigurationDetailsCard = ({ data, isLoading, isMixin, hasMixins }) => {
  return (
    <div className='card-table'>
      <Card
        title='Main details'
        loading={isLoading}
        style={{ marginBottom: '10px' }}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Id:">
            {data?.id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          {isMixin && (
            <Descriptions.Item label="Config id:">
              {data?.config_id || EMPTY_VALUE_PLACEHOLDER}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Name:">
            {data?.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Status:">
            {!isEmpty(data?.status) ? (
              <Badge color={CONFIGURATIONS_STATUS_COLOR(data.status)} text={capitalize(data.status)} />
            ) : EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Priority:">
            {data?.priority || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Created at:">
            <Tag color="blue">
              {renderDateField(data?.created_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Updated at:">
            <Tag color="geekblue">
              {renderDateField(data?.updated_at)}
            </Tag>
          </Descriptions.Item>
          {hasMixins && hasRights([ Permissions.CONFIGS.CONFIGURATIONS.TARGETING.CREATE ]) && (
            <Descriptions.Item label="Mixin visualize:">
              <Link to={urlPageConfigMixinVisualize({ configurationId: data.id })} target='_blank'>
                Open
              </Link>
            </Descriptions.Item>
          )}
        </Descriptions>
      </Card>
    </div>
  );
};

ConfigurationDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMixin: PropTypes.bool,
  hasMixins: PropTypes.bool,
};

ConfigurationDetailsCard.defaultProps = {
  isMixin: false,
  hasMixins: false,
};

export default ConfigurationDetailsCard;
