import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Layout, Modal, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import { getRolesTableColumns } from './PageRoles.const';
import { useApp } from '../../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { hasRights, Permissions } from '../../../../../const/permissions';
import FormCreateEditRole from '../PageEditRole/FormCreateEditRole';
import {
  createRole,
  resetCreateEditRoleErrors,
  selectFormEditRoleErrors,
  selectFormEditRoleLoading,
} from '../../feature/roleDetailsSlice';
import { mkQueryParams } from '../../../../../lib/mkQueryParams';
import { getQueryParams } from '../../../../../lib/getQueryParams';
import { useCurrentSettingsScope } from '../../../../../app/hooks';
import { isFulfilledRequestStatus } from '../../../../../lib/isRequestSuccess';
import {
  deleteRole,
  getRolesListData,
  resetRolesListData,
  selectRolesList,
  selectRolesLoading,
  selectRolesPagination,
} from '../../feature/rolesSlice';
import { getCurrentSpace } from '../../../../../lib/getCurrentSpace';
import { GLOBAL_SETTINGS_PREFIX } from '../../../../../const/system';



const { Content } = Layout;

const PageRoles = () => {
  const { pathname, search } = useLocation();
  const { projectSettings, userSpaces } = useApp();
  const currentSpace = getCurrentSpace(projectSettings?.spaceKey, userSpaces);
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ isActiveRoleModal, setToggleCreateRoleModal ] = useState(false);

  const dispatch = useDispatch();
  const rolesList = useSelector(selectRolesList);
  const isLoading = useSelector(selectRolesLoading);
  const formCreateRoleErrors = useSelector(selectFormEditRoleErrors);
  const isFormCreateRoleLoading = useSelector(selectFormEditRoleLoading);
  const pagination = useSelector(selectRolesPagination);
  const settingsScope = useCurrentSettingsScope();


  useEffect(() => {
    dispatch(getRolesListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));

    return () => {
      dispatch(resetRolesListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting ]);


  const handleDeleteRole = async (roleId) => {
    await dispatch(deleteRole(roleId));
    dispatch(getRolesListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
  };

  const showCreateRoleModal = () => {
    setToggleCreateRoleModal(true);
  };

  const hideCreateRoleModal = () => {
    dispatch(resetCreateEditRoleErrors());
    setToggleCreateRoleModal(false);
  };

  const handleSubmitCreateRoleForm = async (values) => {
    await dispatch(createRole(values))
      .then((response) => {
        if (isFulfilledRequestStatus(response)) {
          hideCreateRoleModal();
          dispatch(getRolesListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
        }
      });
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <>
      <Modal
        title="Create role"
        width={530}
        centered
        destroyOnClose
        visible={isActiveRoleModal}
        onCancel={hideCreateRoleModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <FormCreateEditRole
          initialValues={{ level: 50 }}
          formErrors={formCreateRoleErrors}
          onSubmit={handleSubmitCreateRoleForm}
          isSubmitting={isFormCreateRoleLoading}
          isGlobalScope={settingsScope === GLOBAL_SETTINGS_PREFIX}
        />
      </Modal>

      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Roles list</Breadcrumb.Item>
          </Breadcrumb>

          {hasRights([ Permissions.ADMINISTRATION.ROLES.ROLES_LIST.CREATE_ROLE ]) && (
            <Button
              style={{ margin: '10px 0' }}
              size='middle'
              type="primary"
              onClick={showCreateRoleModal}
            >
              Create role
            </Button>
          )}
        </Row>
      </Col>

      <Content className='layout-content card-has-table'>
        <Card className='da-mb-24'>
          <Table
            sticky
            onChange={handleChangeTableParams}
            loading={isLoading}
            rowKey="id"
            columns={getRolesTableColumns(handleDeleteRole, settingsScope === GLOBAL_SETTINGS_PREFIX, currentSpace)}
            dataSource={rolesList}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 250 }}
          />
        </Card>
      </Content>
    </>
  );
};

export default PageRoles;
