import { Badge, Button, Card, Descriptions, Popconfirm, Tag, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import urlPageEditConfigTargeting from '../../../../urls/urlPageEditConfigTargeting';
import { CONFIGURATIONS_STATUS_COLOR } from '../../pages/PageConfigurations/PageConfigurations.const';
import { hasRights, Permissions } from '../../../../const/permissions';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const ConfigurationTargetDetailsCard = ({
  data,
  isLoading,
  configurationName,
  handleDeleteConfigTargeting,
}) => {
  return (
    <div className='card-table'>
      <Card
        title='Target details'
        loading={isLoading}
        style={{ marginBottom: '10px' }}
        extra={[
          <div key='1'>
            {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.TARGETING.DELETE ]) && (
              <Popconfirm
                title="Sure to delete configuration target?"
                onConfirm={() => handleDeleteConfigTargeting({ configurationId: data.config_id, targetingId: data.id })}
              >
                <Tooltip placement="bottom" title='Delete configuration target'>
                  <Button
                    disabled={isLoading}
                    type='primary'
                    size='small'
                    danger
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </div>,
          <div key='2'>
            {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.TARGETING.UPDATE ]) && (
              <Tooltip
                placement="bottom"
                title='Edit configuration target'
              >
                <Button
                  size="small"
                  type='primary'
                  className='btn-with-side-margin warning-btn'
                  disabled={isLoading}
                >
                  <Link
                    to={urlPageEditConfigTargeting({
                      configurationId: data.config_id,
                      configurationName,
                      targetingId: data.id,
                    })}
                  >
                    <EditOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </div>,
        ]}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Id:">
            {data?.id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Target name:">
            {data?.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Author name:">
            {data?.author.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Status:">
            {!isEmpty(data?.status) ? (
              <Badge color={CONFIGURATIONS_STATUS_COLOR(data.status)} text={capitalize(data.status)} />
            ) : EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Created at:">
            <Tag color="blue">
              {renderDateField(data?.created_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Updated at:">
            <Tag color="geekblue">
              {renderDateField(data?.updated_at)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};

ConfigurationTargetDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  configurationName: PropTypes.string,
  handleDeleteConfigTargeting: PropTypes.func.isRequired,
};


ConfigurationTargetDetailsCard.defaultProps = {
  configurationName: '',
};

export default ConfigurationTargetDetailsCard;
