import React from 'react';
import styled from 'styled-components';
import welcomeIcon from '../../../../../assets/images/welcome.svg';
import { useCheckMobileScreen } from '../../../../../app/hooks';



const DashboardPlaceholderContainer = styled.div`
  position: relative;
  height: calc(100vh - 200px);
  text-align: center;
`;

const DashboardPlaceholderContent = styled.div`
  position: relative;
  z-index: 100;
`;

const DashboardPlaceholderHeader = styled.h3`
  padding: 40px 0 10px 0;
`;

const DashboardPlaceholderSubheader = styled.h4`
  color: #636e72;
`;

const DashboardPlaceholder = () => {
  const isMobile = useCheckMobileScreen();

  return (
    <DashboardPlaceholderContainer>
      <DashboardPlaceholderContent>
        <DashboardPlaceholderHeader>
          Welcome to dashboard!
        </DashboardPlaceholderHeader>
        <DashboardPlaceholderSubheader>
          There is no available widgets for current project.
        </DashboardPlaceholderSubheader>
      </DashboardPlaceholderContent>
      <img
        src={welcomeIcon}
        alt='placeholder_image'
        style={{
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          maxWidth: '100%',
          height: 'auto',
          position: 'absolute',
          right: '-20px',
          bottom: `${isMobile ? '-20px' : '-10px'}`,
          opacity: '0.4',
        }}
      />
    </DashboardPlaceholderContainer>
  );
};

export default DashboardPlaceholder;
