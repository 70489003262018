// eslint-disable-next-line lodash/import-scope
import { isEmpty, isNumber, isBoolean, isObject, isArray } from 'lodash';



const deleteEmptyValues = (value) => {
  if (isArray(value)) {
    return value.filter((item) => !isEmpty(item) || isNumber(item) || isBoolean(item));
  }
  if (isObject(value)) {
    const newObj = {};

    Object.keys(value).forEach((key) => {
      const newValue = deleteEmptyValues(value[key]);

      if (!isEmpty(newValue) || isNumber(newValue) || isBoolean(newValue)) {
        newObj[key] = newValue;
      }
    });
    return newObj;
  }
  return value;
};

export const getObjectDeepClean = (values) => {
  return deleteEmptyValues(values);
};
