import { Breadcrumb, Card } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Permissions } from '../../../../const/permissions';
import HasRights from '../../../../components/HasRights';
import { useApp } from '../../../../app/context/AppContext';
import urlPageMixinsExperiments from '../../../../urls/urlPageMixinsExperiments';
import urlPageMixinsExperimentDetails from '../../../../urls/urlPageMixinsExperimentDetails';
import FormCreateEditExperiment from '../../forms/FormCreateEditExperiment';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  getAllConfigurationsListData,
  selectAllConfigurationsList,
  selectConfigurationsLoading,
} from '../../../Configurations/feature/configurationsSlice';
import {
  createExperiment,
  selectCreateExperimentLoading,
  selectCreateExperimentErrors,
} from '../../feature/mixinsExperimentsSlice';
import {
  getExperimentDetails,
  updateExperimentDetails,
  selectExperimentDetailsData,
  selectExperimentDetailsLoading,
  resetExperimentDetailsData,
  selectUpdateExperimentDetailsLoading,
  selectUpdateExperimentDetailsErrors,
} from '../../feature/mixinsExperimentDetailsSlice';



const PageCreateEditMixinsExperiment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = location;
  const { projectSettings } = useApp();
  const { testId } = useParams();

  const isExperimentCreate = pathname.includes('create');
  const configsList = useSelector(selectAllConfigurationsList);
  const isLoadingConfigsList = useSelector(selectConfigurationsLoading);
  const isCreateLoading = useSelector(selectCreateExperimentLoading);
  const isUpdateLoading = useSelector(selectUpdateExperimentDetailsLoading);
  const createErrors = useSelector(selectCreateExperimentErrors);
  const updateErrors = useSelector(selectUpdateExperimentDetailsErrors);
  const experimentDetails = useSelector(selectExperimentDetailsData);
  const experimentDetailsLoading = useSelector(selectExperimentDetailsLoading);

  const configsOptionsList = !isEmpty(configsList) ? configsList.filter((item) => item.status === 'enabled') : [];


  useEffect(() => {
    dispatch(getAllConfigurationsListData({ limit: -1 }));

    if (!isExperimentCreate) {
      dispatch(getExperimentDetails(testId));
    }
    return () => {
      dispatch(resetExperimentDetailsData());
    };
  }, []);


  const handleCancel = () => {
    navigate(-1);
  };

  const handleRedirectToTestDetails = () => {
    navigate(urlPageMixinsExperimentDetails({ testId, testName: experimentDetails.name }));
  };

  const handleCreateTest = async (values) => {
    if (isExperimentCreate) {
      await dispatch(createExperiment(values)).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          navigate(-1);
        }
      });
    } else {
      const data = { description: values.description };

      await dispatch(updateExperimentDetails({ testId: experimentDetails.id, data })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          handleRedirectToTestDetails();
        }
      });
    }
  };


  return (
    <HasRights allowedPermissions={[ Permissions.MIXINS_EXPERIMENTS.CREATE, Permissions.MIXINS_EXPERIMENTS.UPDATE ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageMixinsExperiments()}>
            Mixins experiments
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {isExperimentCreate ? 'Create' : 'Edit'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <FormCreateEditExperiment
            initialValues={!isEmpty(experimentDetails) ? experimentDetails : {
              name: '',
              config_id: null,
              description: null,
            }}
            isLoading={experimentDetailsLoading || isLoadingConfigsList || isCreateLoading || isUpdateLoading}
            isEditTest={!isExperimentCreate}
            configurationsOptionsList={configsOptionsList}
            formErrors={createErrors || updateErrors}
            onCancel={isExperimentCreate ? handleCancel : handleRedirectToTestDetails}
            onSubmit={handleCreateTest}
          />
        </Card>
      </div>
    </HasRights>
  );
};

export default PageCreateEditMixinsExperiment;
