import React from 'react';
import PropTypes from 'prop-types';
import Error403 from '../Error403';
import { useApp } from '../../app/context/AppContext';



export const checkPermissions = (userPermissions, allowedPermissions, isMultipleAllowance) => {
  if (allowedPermissions.length === 0) {
    return false;
  }

  return isMultipleAllowance ?
    allowedPermissions.every((permission) => userPermissions.includes(permission) === true) :
    userPermissions.some((permission) => allowedPermissions.includes(permission));
};

// Used for wrapping on routes or whole pages
const HasRights = ({ allowedPermissions, children, isMultipleAllowance }) => {
  const { userPermissions } = useApp();
  const isAllowed = checkPermissions(userPermissions, allowedPermissions, isMultipleAllowance);

  return isAllowed ? children : (
    <Error403 />
  );
};

HasRights.propTypes = {
  allowedPermissions: PropTypes.array,
  isMultipleAllowance: PropTypes.bool,
  children: PropTypes.any,
};

HasRights.defaultProps = {
  allowedPermissions: [],
  children: null,
  isMultipleAllowance: false,
};

export default HasRights;
