import React from 'react';
import { Card, Col, Drawer, Timeline } from 'antd';
import PropTypes from 'prop-types';
import { RiCloseFill } from 'react-icons/ri';
import DescriptionValueRow from '../../../../components/DescriptionValueRow';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const DeviceAbTestsDrawer = ({ handleShowDrawer, handleOnClose, visible, deviceName, deviceHistory }) => {
  const listResultStyle = { minWidth: 'max-content' };

  return (
    <Drawer
      title={`${deviceName} | Ab-tests history`}
      placement='right'
      closable
      showDrawer={handleShowDrawer}
      onClose={handleOnClose}
      visible={visible}
      closeIcon={
        <RiCloseFill
          className="remix-icon da-text-color-black-80"
          size={24}
        />
      }
      key='right'
    >
      <Timeline>
        {deviceHistory.map((item) => (
          <Timeline.Item key={item.id}>
            <div className='da-mb-8'>
              {item.name}
            </div>
            <Card style={{ margin: 'unset' }} className='da-profile-drawer-content'>
              <Col
                xs={24} sm={24} lg={12} md={12} xl={8}
                className="da-profile-content-list da-pb-sm-0"
              >
                <ul>
                  <DescriptionValueRow
                    firstRow
                    additionalStyle={listResultStyle}
                    title='Group'
                    value={item.group}
                  />

                  <DescriptionValueRow
                    marginStyle='da-mt-12'
                    additionalStyle={listResultStyle}
                    title='Start date'
                    value={renderDateField(item?.startedAt)}
                  />
                  <DescriptionValueRow
                    marginStyle='da-mt-12'
                    additionalStyle={listResultStyle}
                    title='Finish date'
                    value={renderDateField(item?.finishedAt)}
                  />
                </ul>
              </Col>
            </Card>
          </Timeline.Item>
        ))}
      </Timeline>
    </Drawer>
  );
};


DeviceAbTestsDrawer.propTypes = {
  handleShowDrawer: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  deviceName: PropTypes.string,
  deviceHistory: PropTypes.array,
};

DeviceAbTestsDrawer.defaultProps = {
  visible: false,
  deviceName: EMPTY_VALUE_PLACEHOLDER,
  deviceHistory: [],
};


export default DeviceAbTestsDrawer;
