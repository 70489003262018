import React from 'react';
import { Drawer } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import MenuLogo from '../logo';
import MenuItem from '../item';
import MenuFooter from '../footer';



const MenuMobile = ({ onClose, visible }) => {
  return (
    <Drawer
      title={<MenuLogo onClose={onClose} />}
      className="da-mobile-sidebar"
      placement="left"
      closable
      onClose={onClose}
      visible={visible}
      closeIcon={
        <RiCloseFill
          className="remix-icon da-text-color-black-80"
          size={24}
        />
      }
    >
      <MenuItem onClose={onClose} />
      <MenuFooter
        onClose={onClose}
        collapsed={false}
      />
    </Drawer>
  );
};

MenuMobile.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default MenuMobile;
