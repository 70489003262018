import React from 'react';
import capitalize from 'lodash/capitalize';
import { Badge, Tag } from 'antd';
import { CREATED_AT, getColumnSorting, UPDATED_AT } from '../../../../lib/getColumnSorting';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



export const REQUESTS_STATUS_MAP = {
  CREATED: 'created',
  IN_PROGRESS: 'in_progress',
  EXECUTED: 'executed',
};

export const REQUESTS_STATUS_TRANSLATIONS = (status) => {
  switch (status) {
    case REQUESTS_STATUS_MAP.CREATED: {
      return 'Created';
    }
    case REQUESTS_STATUS_MAP.IN_PROGRESS: {
      return 'In progress';
    }
    case REQUESTS_STATUS_MAP.EXECUTED: {
      return 'Executed';
    }
    default: {
      return EMPTY_VALUE_PLACEHOLDER;
    }
  }
};

export const REQUEST_STATUS_COLOR = (status) => {
  switch (status) {
    case REQUESTS_STATUS_MAP.IN_PROGRESS: {
      return 'geekblue';
    }
    case REQUESTS_STATUS_MAP.EXECUTED: {
      return 'green';
    }
    default: {
      return 'yellow';
    }
  }
};

export const REQUEST_ACTION_COLOR = (action) => {
  switch (action) {
    case 'delete': {
      return 'volcano';
    }
    case 'optout': {
      return 'purple';
    }
    case 'download': {
      return 'geekblue';
    }
    default: {
      return '';
    }
  }
};

export const GDPR_USER_REQUESTS_TABLE_COLUMNS = [
  {
    title: 'Request ID',
    dataIndex: 'request_id',
    align: 'center',
    width: '260px',
    fixed: 'left',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    width: '150px',
    align: 'center',
    render: (value) => <Tag color={REQUEST_ACTION_COLOR(value)}>{capitalize(value) || EMPTY_VALUE_PLACEHOLDER}</Tag>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '150px',
    align: 'center',
    render: (value) => <Badge color={REQUEST_STATUS_COLOR(value)} text={REQUESTS_STATUS_TRANSLATIONS(value)} />,
  },
  {
    title: 'Core created at',
    dataIndex: 'original_created_at',
    width: '200px',
    align: 'center',
    sorter: (_a, _b) => getColumnSorting(_a, _b, 'original_created_at'),
    render: (value) => <div>{renderDateField(value, 'DD.MM.YYYY HH:mm:ss')}</div>,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    width: '200px',
    align: 'center',
    sorter: (_a, _b) => getColumnSorting(_a, _b, CREATED_AT),
    render: (value) => <div>{renderDateField(value, 'DD.MM.YYYY HH:mm:ss')}</div>,
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    width: '200px',
    align: 'center',
    sorter: (_a, _b) => getColumnSorting(_a, _b, UPDATED_AT),
    render: (value) => <div>{renderDateField(value, 'DD.MM.YYYY HH:mm:ss')}</div>,
  },
  {
    title: 'Executed at',
    dataIndex: 'executed_at',
    width: '200px',
    align: 'center',
    sorter: (_a, _b) => getColumnSorting(_a, _b, 'executed_at'),
    render: (value) => <div>{renderDateField(value, 'DD.MM.YYYY HH:mm:ss')}</div>,
  },
];


export const GDPR_USER_HISTORY_TABLE_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    align: 'center',
    width: '260px',
    fixed: 'left',
    sorter: (_a, _b) => getColumnSorting(_a, _b, CREATED_AT),
    render: (value) => <div>{renderDateField(value, 'DD.MM.YYYY HH:mm:ss')}</div>,
  },
  {
    title: 'IP',
    width: '160px',
    align: 'center',
    render: (item) => <Tag color='blue'>{item.tracking.ip || EMPTY_VALUE_PLACEHOLDER}</Tag>,
  },
  {
    title: 'Country',
    width: '160px',
    align: 'center',
    render: (item) => <div>{capitalize(item.tracking.geo.country) || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Region',
    width: '160px',
    align: 'center',
    render: (item) => <div>{item.tracking.geo.region || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'App version',
    width: '160px',
    align: 'center',
    render: (item) => <Tag color='geekblue'>{item.app.version || EMPTY_VALUE_PLACEHOLDER}</Tag>,
  },
  {
    title: 'OS name',
    width: '160px',
    align: 'center',
    render: (item) => <div>{capitalize(item.app.os.name) || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'OS version',
    width: '160px',
    align: 'center',
    render: (item) => <Tag color='purple'>{item.app.os.version || EMPTY_VALUE_PLACEHOLDER}</Tag>,
  },
  {
    title: 'Source',
    width: '160px',
    align: 'center',
    render: (item) => <div>{item.legal.source}</div>,
  },
  {
    title: 'Consent version',
    width: '160px',
    align: 'center',
    render: (item) => <div>{item.legal.consentVersion || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'GDPR',
    width: '160px',
    align: 'center',
    render: (item) => <div>{capitalize(item.legal.gdpr) || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];
