import url from './url';



export default url(({
  testId = ':testId',
  testName = ':testName',
  configId = ':configId',
  configName = ':configName',
  targetingId = ':targetingId',
}) => `/mixins-experiments/${testId}/${testName}/config/${configId}/${configName}/targeting/${targetingId}/edit`);
