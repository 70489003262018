import React from 'react';
import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import urlPageUserDetails from '../../../../../urls/urlPageUserDetails';
import urlPageLogDetails from '../../../../../urls/urlPageLogDetails';
import urlPageSettingsUserDetails from '../../../../../urls/urlPageSettingsUserDetails';
import urlPageSettingsLogDetails from '../../../../../urls/urlPageSettingsLogDetails';
import { hasRights, Permissions } from '../../../../../const/permissions';
import { getDefaultTableSorting } from '../../../../../lib/getDefaultTableSorting';
import { EMPTY_VALUE_PLACEHOLDER, GLOBAL_SETTINGS_PREFIX, renderDateField } from '../../../../../const/system';



const { Paragraph } = Typography;


export const LOGS_ACTION_COLOR = (action) => {
  switch (action) {
    case 'create': {
      return 'green';
    }
    case 'update': {
      return 'purple';
    }
    case 'view': {
      return 'geekblue';
    }
    default: {
      return 'red';
    }
  }
};


export const getLogsTableColumns = (settingsScope) => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '7%',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
    render: (value) => <div>{value}</div>,
  },
  {
    title: 'User',
    align: 'center',
    width: '20%',
    render: (item) => {
      return (
        <>
          {hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]) && !isEmpty(item.user.name) ? (
            <Link
              to={settingsScope === GLOBAL_SETTINGS_PREFIX
                ? urlPageUserDetails({ userId: item.user.id })
                : urlPageSettingsUserDetails({ userId: item.user.id })}
            >
              {item?.user?.name}
            </Link>
          ) : (<div>{item?.user?.name || EMPTY_VALUE_PLACEHOLDER}</div>)}
        </>
      );
    },
  },
  {
    title: 'Category',
    dataIndex: 'category',
    align: 'center',
    width: '10%',
    render: (value) => capitalize(value) || EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'center',
    width: '10%',
    render: (value) => <Tag color={LOGS_ACTION_COLOR(value)}>{capitalize(value) || EMPTY_VALUE_PLACEHOLDER}</Tag>,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    align: 'center',
    width: '10%',
    render: (value) => <div>{capitalize(value) || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Message',
    dataIndex: 'message',
    align: 'center',
    width: '30%',
    render: (value) => {
      return (
        <Tooltip placement="top" title={value || EMPTY_VALUE_PLACEHOLDER}>
          <Paragraph
            className={value ? 'paragraph' : ''}
            ellipsis={{ rows: 2, symbol: '...' }}
          >
            {value || EMPTY_VALUE_PLACEHOLDER}
          </Paragraph>
        </Tooltip>
      );
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    width: '15%',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  ...(hasRights([ Permissions.ADMINISTRATION.LOGS.VIEW_DETAILS ]) ?
    [ {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            <Tooltip placement="top" title='Log details'>
              <Button size="small" type='primary' className='btn-with-side-margin'>
                <Link
                  to={settingsScope === GLOBAL_SETTINGS_PREFIX
                    ? urlPageLogDetails({ logId: item.id })
                    : urlPageSettingsLogDetails({ logId: item.id })}
                >
                  <EyeOutlined />
                </Link>
              </Button>
            </Tooltip>
          </Row>
        );
      },
    } ] : []
  ),
];
