import { Button, Card, Descriptions, Tag, Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { hasRights, Permissions } from '../../../../const/permissions';
import { getNotEmptyDescriptionItem } from '../../../../lib/getNotEmptyDescriptionItem';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import urlPageEditMixinsExperimentTargeting from '../../../../urls/urlPageEditMixinsExperimentTargeting';



const MixinsExperimentTargetDetailsCard = ({
  data,
  testDetails,
  isLoading,
  canEdit,
}) => {
  return (
    <div className='card-table'>
      <Card
        title='Target details'
        loading={isLoading}
        style={{ marginBottom: '10px' }}
        extra={[
          <div key='1'>
            {canEdit && hasRights([ Permissions.MIXINS_EXPERIMENTS.UPDATE ]) && (
              <Tooltip
                placement="bottom"
                title='Edit target details'
              >
                <Button
                  size="small"
                  type='primary'
                  className='btn-with-side-margin warning-btn'
                  disabled={isLoading}
                >
                  <Link
                    to={urlPageEditMixinsExperimentTargeting({
                      testId: testDetails?.id,
                      testName: testDetails?.name,
                      configId: testDetails?.config?.data?.id,
                      configName: testDetails?.config?.data?.name,
                      targetingId: data?.id,
                    })}
                  >
                    <EditOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </div>,
        ]}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Id:">
            {data?.id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          {getNotEmptyDescriptionItem(data?.name, 'Target name:')}
          {getNotEmptyDescriptionItem(data?.author.name, 'Author name:')}
          {!isEmpty(data?.created_at) && (
            <Descriptions.Item label="Created at:">
              <Tag color="blue">
                {renderDateField(data.created_at)}
              </Tag>
            </Descriptions.Item>
          )}
          {!isEmpty(data?.updated_at) && (
            <Descriptions.Item label="Updated at:">
              <Tag color="geekblue">
                {renderDateField(data.updated_at)}
              </Tag>
            </Descriptions.Item>
          )}
        </Descriptions>
      </Card>
    </div>
  );
};

MixinsExperimentTargetDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  testDetails: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool,
};

MixinsExperimentTargetDetailsCard.defaultProps = {
  canEdit: false,
};

export default MixinsExperimentTargetDetailsCard;
