export const customizeYamlStructure = (obj) => {
  const result = obj;

  // eslint-disable-next-line fp/no-loops,no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      customizeYamlStructure(obj[key]);
    } else {
      if (obj[key] === 'boolean') {
        // eslint-disable-next-line fp/no-delete
        delete obj[key];
        Object.assign(obj, {
          anyOf: [
            { type: 'string', title: 'Empty', nullable: true },
            { type: 'boolean', title: 'False', enum: [ false ], default: false },
            { type: 'boolean', title: 'True', enum: [ true ], default: true },
          ],
          ...obj.hasOwnProperty('default') ? { default: obj.default } : {},
        });
      }
    }
  }
  return result;
};
