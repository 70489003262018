const mkRegexFromDomainsArray = (domains) => {
  if (domains.length === 0) {
    return '';
  }
  const specializedDomainsArr = domains.map((domain) => domain.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

  return new RegExp(`(?:${specializedDomainsArr.join('|')})$`);
};


export default mkRegexFromDomainsArray;
