import React, { useEffect } from 'react';
import { Breadcrumb, Card, Col, Descriptions, Layout, Row, Tag } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import YAML from 'js-yaml';
import isEmpty from 'lodash/isEmpty';
import ReactJson from 'react-json-view';
import { Permissions } from '../../../../../const/permissions';
import urlPageStructures from '../../../../../urls/urlPageStructures';
import HasRights from '../../../../../components/HasRights';
import { useApp } from '../../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../../const/system';
import {
  getStructureDetails,
  selectStructureDetails,
  selectStructureDetailsLoading,
} from '../../../feature/structureDetailsSlice';



const { Content } = Layout;

const PageStructureDetails = () => {
  const dispatch = useDispatch();
  const structureDetails = useSelector(selectStructureDetails);
  const isLoading = useSelector(selectStructureDetailsLoading);
  const { structureId } = useParams();
  const { projectSettings } = useApp();


  useEffect(() => {
    dispatch(getStructureDetails(structureId));
  }, []);

  const structureData = !isEmpty(structureDetails?.structure) ? YAML.load(structureDetails.structure) : null;

  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.STRUCTURES.VIEW_DETAILS ]}>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={urlPageStructures()}>
                Structures list
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Col>

      <Content className='layout-content'>
        <Row>
          <Col xs={24} md={24} lg={8} style={{ padding: '0 5px' }}>
            <div className='card-table'>
              <Card
                title='Main details'
                loading={isLoading}
                style={{ marginBottom: '10px' }}
              >
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="ID:">
                    {structureDetails?.id || EMPTY_VALUE_PLACEHOLDER}
                  </Descriptions.Item>
                  <Descriptions.Item label="Name:">
                    {structureDetails?.name || EMPTY_VALUE_PLACEHOLDER}
                  </Descriptions.Item>
                  <Descriptions.Item label="Version:">
                    {structureDetails?.version || EMPTY_VALUE_PLACEHOLDER}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created at:">
                    <Tag color="blue">
                      {renderDateField(structureDetails.created_at)}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Updated at:">
                    <Tag color="geekblue">
                      {renderDateField(structureDetails.updated_at)}
                    </Tag>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
          </Col>
          <Col xs={24} md={24} lg={16} style={{ padding: '0 5px' }}>
            <Card
              title='Structure'
              loading={isLoading}
            >
              <ReactJson
                src={structureData}
                theme="summerfruit:inverted"
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </HasRights>
  );
};


export default PageStructureDetails;
