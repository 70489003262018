import React from 'react';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Col, Row, Spin } from 'antd';
import { mkSelectFilterOption } from '../../../../lib/mkSelectFilterOption';
import Yup from '../../../../vendor/yup';
import { LOADING_TIP_PLACEHOLDER } from '../../../../const/system';



const { Option } = Select;

const FormCreateEditTestButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditTestButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Min 10 symbols required')
    .max(255, 'Max 255 symbols required')
    .required('Name field is required'),
  config_id: Yup.string().nullable().required('Required to select configuration'),
  description: Yup.string().nullable().max(512, 'Max 512 symbols required'),
});


const FormCreateEditExperiment = ({
  onSubmit,
  onCancel,
  isEditTest,
  isLoading,
  configurationsOptionsList,
  initialValues,
  formErrors,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnMount
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, values, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Spin spinning={isLoading} tip={LOADING_TIP_PLACEHOLDER}>
              <Col span={24}>
                <Row gutter={[ 16, 16 ]}>
                  <Col xs={24} sm={12}>
                    <FormItem
                      className='ant-form-item-col'
                      name="name"
                      label="Name:"
                      required
                    >
                      <Input
                        name='name'
                        disabled={isEditTest}
                        placeholder='Specify experiment name'
                      />
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={12}>
                    <FormItem
                      className='ant-form-item-col'
                      name="config_id"
                      label="Configuration:"
                      required={!isEditTest}
                    >
                      <Select
                        showSearch
                        name="config_id"
                        placeholder="Select configuration"
                        disabled={isEditTest}
                        value={values.config_id}
                        optionFilterProp="children"
                        filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                      >
                        {configurationsOptionsList.map((option) => {
                          return (
                            <Option key={option.id} value={option.id}>
                              {option.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>

                  <Col span={24}>
                    <FormItem
                      className='ant-form-item-col'
                      name="description"
                      label="Description:"
                    >
                      <Input.TextArea
                        fast
                        name='description'
                        placeholder='Specify description'
                        rows={7}
                        showCount
                        maxLength={512}
                      />
                    </FormItem>
                    {!isEditTest && (
                      <div className="da-mb-0 da-text-color-black-80 da-text-color-dark-30 da-d-flex">
                        {'NOTE: You can\'t edit experiment name and configuration fields after creating the experiment.'}
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>

              <Row gutter={[ 16, 16 ]}>
                <FormCreateEditTestButtonsRow>
                  <FormCreateEditTestButtonWr>
                    <Button onClick={onCancel}>
                      Cancel
                    </Button>
                  </FormCreateEditTestButtonWr>
                  <FormCreateEditTestButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      disabled={!isValid || !dirty}
                    >
                      {isEditTest ? 'Update' : 'Create'}
                    </SubmitButton>
                  </FormCreateEditTestButtonWr>
                </FormCreateEditTestButtonsRow>
              </Row>
            </Spin>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditExperiment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  values: PropTypes.shape({
    config_id: PropTypes.string,
  }),
  configurationsOptionsList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isEditTest: PropTypes.bool,
};

FormCreateEditExperiment.defaultProps = {
  formErrors: {},
  isEditTest: false,
  configurationsOptionsList: [],
};

export default FormCreateEditExperiment;
