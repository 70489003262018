import { Button, Result } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../app/hooks';
import urlPageLogin from '../../urls/urlPageLogin';



const Error401 = () => {
  const navigate = useNavigate();
  const [ _, setLastLocation ] = useLocalStorage('prev_url');


  useEffect(() => {
    setLastLocation('/');
  }, []);


  return (
    <Result
      status='403'
      title="401 - Unauthorized"
      subTitle={
        <>
          <div>Sorry, access denied, authorization is failed.</div>
          <div>Please try refreshing the page and fill in correct credentials.</div>
        </>
      }
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate(urlPageLogin(), { replace: true });
          }}
        >
          Go to login
        </Button>
      }
    />
  );
};

export default Error401;
