import React from 'react';
import { Card, Row, Col, Empty, Spin } from 'antd';
import { CartesianGrid, Legend, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Area } from 'recharts';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import abbreviate from 'number-abbreviate';
import { COLUMN_CHART_COLOR_MAP } from '../../../Monitor/components/MonitorCharts/MonitorCharts.const';
import mkSecondsToDateRange from '../../../../lib/mkSecondsToDateRange';
import ChartsTooltip from '../../../Monitor/components/ChartsTooltip';



const WidgetMetricCharts = ({ title, data, isLoading, XLabel, YLabel, sinceTitle, hasTooltipDate }) => {
  const preparedChartsData = !isEmpty(data) ? data.map((item) => {
    const result = [];

    result.push(item); //Chart fields values
    return result;
  }).flat() : [];

  const Axis = Object.keys(preparedChartsData.reduce((result, obj) => Object.assign(result, obj), {}))
    .filter((item) => item !== 'date');

  return (
    <Card className="da-border-color-black-40 da-card-6 da-chart-text-color">
      <Row>
        <Col span={24} className='da-mb-16'>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="da-mr-8">{title}</h4>
            </Row>
            <h3 className="da-badge-text da-mb-0 da-text-color-black-80 da-text-color-dark-30 da-d-flex" style={{ lineHeight: '16px' }}>
              {sinceTitle}
              {!hasTooltipDate && (
                <span style={{ marginLeft: '6px' }}>
                  <ChartsTooltip />
                </span>
              )}
            </h3>
          </Row>
        </Col>

        <Col span={24} className='widget-metric-charts-col-wr'>
          <Spin spinning={!!isLoading} tip="Loading charts..." className='widget-metric-charts-wr'>
            {isEmpty(data) ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>No charts data available</span>
                }
              />
            ) : (
              <div id="metric_chart-card" className='widget-charts-container'>
                <ResponsiveContainer width="100%" height="100%" debounce={1}>
                  <AreaChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" label={!isEmpty(XLabel) ? XLabel : {}} />
                    <YAxis tickFormatter={abbreviate} label={!isEmpty(YLabel) ? YLabel : {}} />
                    <Tooltip formatter={(label) => hasTooltipDate ? `${mkSecondsToDateRange(Math.floor(label))}` : label} />
                    <Legend />
                    {Axis && Axis.map((axis, index) => (
                      <Area
                        key={axis}
                        connectNulls
                        type="monotone"
                        dataKey={axis}
                        stroke={COLUMN_CHART_COLOR_MAP[index]}
                        fill={COLUMN_CHART_COLOR_MAP[index]}
                      />
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            )}
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

WidgetMetricCharts.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  hasTooltipDate: PropTypes.bool,
  sinceTitle: PropTypes.string,
  XLabel: PropTypes.object,
  YLabel: PropTypes.object,
};

WidgetMetricCharts.defaultProps = {
  isLoading: false,
  hasTooltipDate: false,
  XLabel: {},
  YLabel: {},
  sinceTitle: 'From today',
};

export default WidgetMetricCharts;
