import React from 'react';
import { Divider, Avatar, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import avatar from '../../../../../assets/images/memoji/memoji-1.png';
import { useApp } from '../../../../../app/context/AppContext';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';



const MenuFooter = () => {
  const { userData } = useApp();

  return (
    <Row
      className="da-sidebar-footer da-pb-24 da-px-24 da-bg-color-dark-100"
      align="middle"
      justify="space-between"
    >
      <Divider className="da-border-color-black-20 da-border-color-dark-70 da-mt-0" />

      <Row align="middle">
        <Avatar
          size={36}
          className="da-mr-8"
          src={!isEmpty(userData) ? userData?.user?.avatar : avatar}
        />
        <div>
          <span
            className="da-d-block da-text-color-black-100 da-text-color-dark-0 da-p1-body"
            style={{ padding: '0 25px' }}
          >
            {!isEmpty(userData) ? userData?.user?.name : EMPTY_VALUE_PLACEHOLDER}
          </span>
        </div>
      </Row>
    </Row>
  );
};

export default MenuFooter;
