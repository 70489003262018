import React from 'react';
import { Form, Input, DatePicker, SubmitButton, FormItem, Select } from 'formik-antd';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row } from 'antd';
import capitalize from 'lodash/capitalize';
import Yup from '../../../../vendor/yup';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { COUNTRIES_LIST, SEMVER_REGEXP } from '../../../../const/system';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';
import { mkSelectFilterOption } from '../../../../lib/mkSelectFilterOption';



const { Option } = Select;


const validationSchema = Yup.object().shape({
  app_version: Yup.string().nullable().matches(SEMVER_REGEXP, 'Value is doesn`t matches with semver regular expression').max(100, 'Max 100 symbols required'),
  platform: Yup.string().nullable(),
  format: Yup.string().nullable(),
  network: Yup.string().nullable(),
  countries: Yup.string().nullable(),
  data_grouping: Yup.string().nullable().required('Data grouping value is required'),
  from: Yup.string().nullable().required('Start date is required'),
  to: Yup.string().nullable().required('End date is required'),
});

const MonitorFilter = ({ onSubmit, initialValues, isSubmitting, isAdsMonitorFilter, networkOptions }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      onSubmit={(values) => {
        const preparedValues = {
          ...values,
          from: values.from ? moment(values.from).format('YYYY-MM-DD') : '',
          to: values.to ? moment(values.to).format('YYYY-MM-DD') : '',
        };

        onSubmit(getNotEmptyObjectValues(preparedValues));
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setFieldValue } = props;

        const startDisabledDate = (current) => {
          const startDate = moment().subtract(30, 'd');
          const endDate = moment(new Date());

          return !(startDate.isSameOrBefore(current) && endDate.isAfter(current));
        };

        const endDisabledDate = (current) => {
          // eslint-disable-next-line react/prop-types
          const start = moment(values.from);
          const endDate = moment(new Date());

          return !(start.isSameOrBefore(current) && endDate.isAfter(current));
        };

        return (
          <Form layout="vertical">
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="data_grouping"
                label="Data grouping"
                required
              >
                <Select
                  name="data_grouping"
                  value={values.data_grouping}
                >
                  <Option value="hourly">Hourly</Option>
                  <Option value="daily">Daily</Option>
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="from"
                label="Start date"
                required
              >
                <DatePicker
                  name='from'
                  disabledDate={startDisabledDate}
                  onChange={(value) => {
                    if (moment(value).diff(moment(values.to)) > 0) {
                      setFieldValue('to', null);
                    }
                  }}
                />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="to"
                label="End date"
                required
              >
                <DatePicker
                  name='to'
                  disabledDate={endDisabledDate}
                />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="countries"
                label="Country"
              >
                <Select
                  showSearch
                  name="countries"
                  placeholder="Select country"
                  value={values.countries}
                  optionFilterProp="children"
                  filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  <Option value={null}>Not required</Option>
                  {COUNTRIES_LIST.map((option) => {
                    return (
                      <Option key={option.code} value={option.code}>
                        {`${option.code} - ${option.name}`}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="platform"
                label="Platform"
              >
                <Select
                  showSearch
                  name="platform"
                  placeholder="Select platform"
                  value={values.platform}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  <Option value={null}>Not required</Option>
                  <Option value='android'>Android</Option>
                  <Option value='ios'>iOS</Option>
                  <Option value='amazon'>Amazon</Option>
                </Select>
              </FormItem>
            </Col>
            {isAdsMonitorFilter && (
              <>
                <Col>
                  <FormItem
                    className='ant-form-item-col'
                    name="format"
                    label="Format"
                  >
                    <Select
                      showSearch
                      name="format"
                      placeholder="Select format"
                      value={values.format}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    >
                      <Option value={null}>Not required</Option>
                      <Option value='ad_interstitial_show'>Ad interstitial show</Option>
                      <Option value='ad_rewarded_show'>Ad rewarded show</Option>
                      <Option value='ad_banner_show'>Ad banner show</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col>
                  <FormItem
                    className='ant-form-item-col'
                    name="network"
                    label="Network"
                  >
                    <Select
                      showSearch
                      name="network"
                      value={values.network}
                      placeholder="Select network"
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    >
                      <Option value={null}>Not required</Option>
                      {networkOptions.map((option) => {
                        return (
                          <Option key={option.network} value={option.network}>
                            {capitalize(option.network)}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
              </>
            )}
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="app_version"
                label="Application version"
              >
                <Input name='app_version' placeholder='Specify app version' />
              </FormItem>
            </Col>
            <Row style={{ textAlign: 'center' }}>
              <Col span={24}>
                <SubmitButton
                  style={{ marginTop: '10px' }}
                  loading={isSubmitting}
                  disabled={!isValid || (isEqual(getObjectValuesByUrlParams(), values))}
                >
                  Load
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

MonitorFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    network: PropTypes.string,
    countries: PropTypes.string,
    platform: PropTypes.string,
    data_grouping: PropTypes.string,
    format: PropTypes.string,
    to: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  isAdsMonitorFilter: PropTypes.bool,
  networkOptions: PropTypes.array,
};

MonitorFilter.defaultProps = {
  isAdsMonitorFilter: false,
  networkOptions: [],
};

export default MonitorFilter;
