import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import TicketsService from '../services/ticketsService';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';



const initialState = {
  ticketsList: [],
  ticketsPagination: {},
  errors: [],
  loading: false,
};

const ticketsService = new TicketsService(Api);

export const getTicketsListData = createAsyncThunk(
  'tickets/getTicketsList',
  async (values) => {
    const response = await ticketsService.getTicketsList(values);

    if (!isEmpty(values)) {
      setUrlParams(values);
    }

    return response;
  },
);

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    resetTicketsListData: (state) => {
      state.ticketsList = [];
      state.ticketsPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTicketsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.ticketsList = action.payload.data;
        state.ticketsPagination = action.payload.meta.pagination;
      })
      .addCase(getTicketsListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.data;
      });
  },
});

// actions
export const { resetTicketsListData } = ticketsSlice.actions;

// selectors
export const selectTicketsLoading = (state) => state.tickets.loading;
export const selectTicketsList = (state) => state.tickets.ticketsList;
export const selectTicketsPagination = (state) => state.tickets.ticketsPagination;

// reducer
export default ticketsSlice.reducer;
