export const getObjectValuesByUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  const obj = {};

  // eslint-disable-next-line fp/no-loops,no-restricted-syntax
  for (const [ key, value ] of params) {
    if (key.includes('[')) {
      const index = key.match(/\[(\d+)\]/)[1];
      const nestedKey = key.match(/^([^[]+)/)[0];

      if (!obj[nestedKey]) {
        obj[nestedKey] = [];
      }
      obj[nestedKey][index] = value;
    } else {
      obj[key] = value;
    }
  }

  return obj;
};
