import React, { useEffect } from 'react';
import { Layout, Button, Row, Col, Select, Tooltip } from 'antd';
import { RiMenuFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useApp } from '../../../../app/context/AppContext';
import { GLOBAL_SETTINGS_PREFIX, LOCAL_SETTINGS_PREFIX } from '../../../../const/system';
import { getProjectsOptions } from '../../../../lib/getProjectsOptions';
import mkRedirectToDashboard from '../../../../lib/mkRedirectToDashboard';
import HeaderUser from './HeaderUser';
import MenuLogo from '../menu/logo';
import MenuHorizontal from '../menu/item/MenuHorizontal';
import MenuMobile from '../menu/mobile';
import { authLoading } from '../../../../app/auth/feature/AuthSlice';



const { Header } = Layout;
const { Option } = Select;

const HeaderHorizontal = ({ visible, setVisible }) => {
  const { projectSettings, setProjectSettings, setPrevProjectSettings, userSpaces } = useApp();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLoading = useSelector(authLoading);
  const optionsList = getProjectsOptions(userSpaces);
  const { spaceKey, projectKey } = projectSettings;


  useEffect(() => {
    if (pathname.includes(GLOBAL_SETTINGS_PREFIX)) {
      setProjectSettings({ spaceKey: null, projectKey: null });
    }
    if (pathname.includes(LOCAL_SETTINGS_PREFIX)) {
      setProjectSettings({ spaceKey, projectKey: null });
    }
  }, [ pathname ]);


  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const handleChangeProject = (value) => {
    const val = value.split('_');
    const appSettings = { spaceKey: val[0] === '' ? null : val[0], projectKey: val[1] === '' ? null : val[1] };

    setPrevProjectSettings(projectSettings);
    setProjectSettings(appSettings);
    mkRedirectToDashboard(navigate, appSettings);
  };


  const SelectOptionTooltip = ({ obj }) => {
    return (
      <Tooltip placement="right" title={obj.label}>
        {`[${obj.key}] ${obj.label}`.slice(0, 25)}
      </Tooltip>
    );
  };

  SelectOptionTooltip.propTypes = {
    obj: PropTypes.object.isRequired,
  };

  const optionValue = spaceKey ? `${spaceKey}_${projectKey ?? ''}` : null;

  const HeaderChildren = () => {
    return (
      <Row
        className="da-w-100 da-position-fixed"
        align="middle"
        justify="space-between"
      >
        <Col>
          <MenuLogo />

          <Col className="da-mobile-sidebar-button">
            <Button
              className="da-mobile-sidebar-button"
              type="text"
              onClick={showDrawer}
              icon={
                <RiMenuFill
                  size={24}
                  className="remix-icon da-text-color-black-80"
                />
              }
            />
          </Col>
        </Col>

        {pathname !== '/' && (
          <Select
            className='da-m-8'
            defaultValue={optionValue}
            loading={isLoading}
            style={{ width: 200 }}
            placeholder='Select environment'
            disabled={isLoading}
            onChange={handleChangeProject}
          >
            {optionsList.map((space) => {
              return (
                <React.Fragment key={space.key}>
                  <Option value={`${space.key}_`} className='space-select-option'>
                    <SelectOptionTooltip obj={space} />
                  </Option>
                  {space?.projects.map((project) => (
                    <Option key={project.id} value={space.key ? `${space.key}_${project.key ?? ''}` : null} style={{ fontSize: '13px', paddingLeft: 23 }}>
                      <SelectOptionTooltip obj={project} />
                    </Option>
                  ))}
                </React.Fragment>
              );
            })}
          </Select>
        )}

        <Col flex="1 0 0" className="da-mx-18">
          <Row justify="start" className="da-w-100">
            <Col span={24}>
              <MenuHorizontal />
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="middle">
            <HeaderUser />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Header
      style={{ position: 'fixed', width: '100%' }}
      className="da-header-horizontal da-header-full"
    >
      <Row justify="center" className="da-w-100" >
        <Col span={24}>
          <HeaderChildren />
        </Col>
      </Row>
      <MenuMobile
        onClose={onClose}
        visible={visible}
      />
    </Header>
  );
};

HeaderHorizontal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default HeaderHorizontal;
