import React, { useState, useRef } from 'react';
import {
  Button, Input, Space, Table,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import './styles.css';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from '../../../../../lib/useQuery';
import { setItemToQueryParams } from '../../../../../lib/setItemToQueryParams';



const UserEventsTable = ({ events }) => {
  const query = useQuery();
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ searchText, setSearchText ] = useState('');
  const [ searchColumn, setSearchColumn ] = useState('');
  const searchEl = useRef(null);

  const handlePageChange = (page) => {
    const queryPage = query.get('page');

    if (page !== queryPage) {
      setCurrentPage(Number(page));
      setItemToQueryParams('page', page);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    setSearchColumn('');
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(ev) => setSelectedKeys(ev.target.value ? [ ev.target.value ] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            disabled={isEmpty(selectedKeys[0])}
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            disabled={isEmpty(selectedKeys[0])}
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => (searchColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[ searchText ]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const columns = [
    {
      title: 'Date',
      dataIndex: 'datetime',
      key: 'datetime',
      width: '33%',
      render: (value) => moment(value.date).format('L HH:mm:ss.SSS Z'),
      sorter: (aValue, bValue) => (moment(aValue.datetime.date) >= moment(bValue.datetime.date) ? 1 : -1),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '33%',
      ...getColumnSearchProps('name'),
      sorter: (aValue, bValue) => aValue.name.localeCompare(bValue.name),
      render: (value) => value,
    },
    {
      title: 'Param',
      dataIndex: 'param',
      key: 'param',
      width: '33%',
      ...getColumnSearchProps('param'),
      sorter: (firstParam, secondParam) => {
        if (!firstParam.param) {
          firstParam.param = '';
        }
        if (!secondParam.param) {
          secondParam.param = '';
        }

        return firstParam.param.localeCompare(secondParam.param);
      },
    },
  ];

  const dataSource = () => events.map((event) => ({ ...event, key: event.datetime.date }));

  return (
    <Table
      size="small"
      rowClassName="event-table-row"
      dataSource={dataSource()}
      columns={columns}
      pagination={{
        size: 'small',
        current: currentPage,
        defaultPageSize: 100,
        position: [ 'bottomCenter' ],
        pageSizeOptions: [ 50, 100, 150, 200 ],
        onChange: (page) => {
          handlePageChange(page);
        },
      }}
    />
  );
};

UserEventsTable.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({
    datetime: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    param: PropTypes.string,
  })),
};

UserEventsTable.defaultProps = {
  events: [],
};

export default UserEventsTable;
