import React from 'react';
import moment from 'moment';
import { Badge, Button, Popconfirm, Row, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import urlPageAbTestDetails from '../../../../urls/urlPageAbTestDetails';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';
import { hasRights, Permissions } from '../../../../const/permissions';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



export const AB_TEST_STATUS_MAP = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  PAUSED: 'paused',
  ARCHIVED: 'archived',
};

export const AB_TEST_STATUS_COLOR = (status) => {
  switch (status) {
    case AB_TEST_STATUS_MAP.DRAFT: {
      return 'geekblue';
    }
    case AB_TEST_STATUS_MAP.ACTIVE: {
      return 'green';
    }
    case AB_TEST_STATUS_MAP.ARCHIVED: {
      return 'red';
    }
    case AB_TEST_STATUS_MAP.PAUSED: {
      return 'orange';
    }
    default: {
      return 'yellow';
    }
  }
};

export const AB_TEST_PUBLISH_STATUS_COLOR = (data) => {
  const updatedAt = moment(data.updated_at);
  const publishedAt = moment(data.published_at);
  const timeDifference = updatedAt.diff(publishedAt);

  if (timeDifference === 0) {
    return 'green';
  }
  if (timeDifference > 0) {
    return 'yellow';
  }
  return 'gray';
};

export const getAbTestTableColumns = (handleDeleteAbTest) => [
  {
    title: 'ID',
    align: 'center',
    width: '80px',
    fixed: 'left',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
    render: (value) => <Badge color={AB_TEST_PUBLISH_STATUS_COLOR(value)} text={value.id} />,
  },
  {
    title: 'Name',
    width: '230px',
    fixed: 'left',
    align: 'center',
    render: (item) => {
      return (
        <>
          {hasRights([ Permissions.AB_TESTS.VIEW_DETAILS ]) && !isEmpty(item.name) ? (
            <Link to={urlPageAbTestDetails({ abTestName: item.name })}>
              {item.name}
            </Link>
          ) : (<div>{item.name || EMPTY_VALUE_PLACEHOLDER}</div>)}
        </>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    fixed: 'left',
    width: '130px',
    align: 'center',
    render: (value) => <Badge color={AB_TEST_STATUS_COLOR(value)} text={capitalize(value)} />,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    width: '200px',
    align: 'center',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
    render: (value) => renderDateField(value),
  },
  {
    title: 'Created by',
    width: '230px',
    align: 'center',
    render: (item) => item?.author?.name ?? EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'First published at',
    dataIndex: 'first_published_at',
    width: '200px',
    align: 'center',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('first_published_at'),
    render: (value) => renderDateField(value),
  },
  {
    title: 'First publisher',
    width: '230px',
    align: 'center',
    render: (item) => item?.first_publisher?.name ?? EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Published at',
    dataIndex: 'published_at',
    width: '200px',
    align: 'center',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('published_at'),
    render: (value) => renderDateField(value),
  },
  {
    title: 'Publisher',
    width: '230px',
    align: 'center',
    render: (item) => item?.publisher?.name ?? EMPTY_VALUE_PLACEHOLDER,
  },
  ...(hasRights([ Permissions.AB_TESTS.VIEW_DETAILS, Permissions.AB_TESTS.DELETE ]) ? [
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: '150px',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            {hasRights([ Permissions.AB_TESTS.DELETE ]) && !item.is_published && (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteAbTest(item.id)}>
                <Tooltip placement="top" title='Delete ab test'>
                  <Button
                    size="small"
                    type='primary'
                    danger
                    className='btn-with-side-margin'
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            {hasRights([ Permissions.AB_TESTS.VIEW_DETAILS ]) && (
              <Tooltip placement="top" title='Details'>
                <Button size="small" type='primary' className='btn-with-side-margin'>
                  <Link to={urlPageAbTestDetails({ abTestName: item.name })}>
                    <EyeOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
