import isEmpty from 'lodash/isEmpty';



const dateFieldsNames = [
  'created_at',
  'started_at',
  'updated_at',
  'published_at',
  'first_published_at',
];


export const getPreparedFilterData = (obj) => {
  dateFieldsNames.map((dateField) => {
    if (Object.keys(obj).map((objKey) => objKey.includes(dateField)) && !isEmpty(obj[dateField]) && obj[dateField].length > 0) {
      obj[`${dateField}`] = {
        from: obj[dateField][0] ?? null,
        to: obj[dateField][1] ?? null,
      };
      // eslint-disable-next-line fp/no-delete
      delete obj[dateField][0];
      // eslint-disable-next-line fp/no-delete
      delete obj[dateField][1];
    }
    return obj;
  });

  return obj;
};


export const mkPreparedFilterData = (obj) => {
  dateFieldsNames.map((dateField) => {
    if (Object.keys(obj).some((objKey) => objKey.includes(dateField) && (obj[dateField]?.hasOwnProperty('from') || obj[dateField]?.hasOwnProperty('to')))) {
      const { from, to } = obj[dateField];

      if (isEmpty(from) && isEmpty(to)) {
        // eslint-disable-next-line fp/no-delete
        delete obj[dateField];
      } else {
        obj[dateField] = [ from ?? '', to ?? null ].filter((item) => item !== null);
      }
    }
    return obj;
  });
  return obj;
};
