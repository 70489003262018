import React from 'react';
import { Button, Tooltip, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { RiAmazonLine, RiAndroidLine, RiAppleLine, RiFileUnknowLine } from 'react-icons/ri';
import urlPageUserProfileDetails from '../../../../urls/urlPageUserProfileDetails';
import { hasRights, Permissions } from '../../../../const/permissions';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const { Paragraph } = Typography;

export const USER_PROFILES_PLATFORMS_ICONS = (platform) => {
  switch (platform) {
    case 'ios': {
      return <RiAppleLine size={20} />;
    }
    case 'android': {
      return <RiAndroidLine size={20} />;
    }
    case 'amazon': {
      return <RiAmazonLine size={20} />;
    }
    default: {
      return <RiFileUnknowLine size={20} />;
    }
  }
};

export const USER_PROFILES_TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
    render: (value) => (
      <Tooltip placement="top" title={value || EMPTY_VALUE_PLACEHOLDER}>
        <Paragraph ellipsis={{ rows: 1, symbol: '...' }} style={{ cursor: 'default' }}>
          {value || EMPTY_VALUE_PLACEHOLDER}
        </Paragraph>
      </Tooltip>
    ),
  },
  {
    title: 'Platforms',
    dataIndex: 'platforms',
    align: 'center',
    minWidth: '180px',
    render: (item) => {
      return (
        <div className='da-d-flex da-d-flex-full-center'>
          {item.map((platform, index) => (
            <div key={platform && index} className='da-mr-6 da-ml-6'>
              {USER_PROFILES_PLATFORMS_ICONS(platform)}
            </div>
          ))}
        </div>
      );
    },
  },
  {
    title: 'Registration date',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => <>{renderDateField(value)}</>,
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Last session',
    dataIndex: 'session',
    align: 'center',
    render: (value) => <>{renderDateField(value?.date_last)}</>,
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('session'),
  },
  ...(hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_DETAILS ]) ? [
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (item) => {
        return (
          <Tooltip placement="top" title='User profile details'>
            <Button
              size="small"
              type='primary'
              className='btn-with-side-margin'
            >
              <Link to={urlPageUserProfileDetails({ userId: item.id })}>
                <EyeOutlined />
              </Link>
            </Button>
          </Tooltip>
        );
      },
    } ] : []
  ),
];
