import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';
import ConfigurationVisualizeService from '../services/configurationRequestService';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  configVisualizeRequestList: [],
  configVisualizeRequestListPagination: {},
  errors: [],
  loading: false,
  createConfigRequestLoading: false,
  createConfigRequestData: {},
  createConfigRequestErrors: [],
  loadingRequestConfigPreview: false,
  requestConfigPreview: {},
  saveCreatedConfigRequestLoading: false,
  saveCreatedConfigRequestData: {},
  visualizedServerDataLoading: false,
  visualizedServerData: {},
};


const configVisualizeService = new ConfigurationVisualizeService(Api);

export const getConfigVisualizeRequestListData = createAsyncThunk(
  'configurationVisualize/getRequestList',
  async (values, { rejectWithValue }) => {
    try {
      const response = await configVisualizeService.getRequestList(values);

      if (!isEmpty(values)) {
        setUrlParams(values);
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createConfigRequest = createAsyncThunk(
  'configurationVisualize/createRequest',
  async (values, { rejectWithValue }) => {
    try {
      return await configVisualizeService.createRequest(values);
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getRequestConfigPreview = createAsyncThunk(
  'configurationVisualize/getRoleDetails',
  async (id) => {
    const response = await configVisualizeService.getRequestConfigPreviewData(id);

    return response.data;
  },
);

export const saveCreatedConfigRequest = createAsyncThunk(
  'configurationVisualize/saveCreatedRequest',
  async (values, { rejectWithValue }) => {
    try {
      const response = await configVisualizeService.saveCreatedRequest(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getVisualizedServerConfigRequest = createAsyncThunk(
  'configurationVisualize/visualizeServerConfigRequest',
  async (values, { rejectWithValue }) => {
    try {
      const response = await configVisualizeService.visualizeServerConfigRequest(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteRequest = createAsyncThunk(
  'configurationVisualize/deleteRequest',
  async (requestId) => {
    const response = await configVisualizeService.deleteRequest(requestId);

    return response.data;
  },
);


export const configurationVisualizeSlice = createSlice({
  name: 'configurationVisualize',
  initialState,
  reducers: {
    resetConfigVisualizeListData: (state) => {
      state.configVisualizeRequestList = [];
      state.configVisualizeRequestListPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfigVisualizeRequestListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getConfigVisualizeRequestListData.fulfilled, (state, action) => {
        state.loading = false;
        state.configVisualizeList = action.payload.data;
        state.configVisualizePagination = action.payload.meta.pagination;
      })
      .addCase(getConfigVisualizeRequestListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
      });
    builder
      .addCase(createConfigRequest.pending, (state) => {
        state.createConfigRequestLoading = true;
        state.createConfigRequestErrors = [];
      })
      .addCase(createConfigRequest.fulfilled, (state, action) => {
        state.createConfigRequestLoading = false;
        state.createConfigRequestData = action.payload;
      })
      .addCase(createConfigRequest.rejected, (state, action) => {
        state.createConfigRequestLoading = false;
        state.createConfigRequestErrors = action.payload.errors;
      });
    builder
      .addCase(getRequestConfigPreview.pending, (state) => {
        state.loadingRequestConfigPreview = true;
      })
      .addCase(getRequestConfigPreview.fulfilled, (state, action) => {
        state.loadingRequestConfigPreview = false;
        state.requestConfigPreview = action.payload;
      })
      .addCase(getRequestConfigPreview.rejected, (state) => {
        state.requestConfigPreview = false;
      });
    builder
      .addCase(saveCreatedConfigRequest.pending, (state) => {
        state.saveCreatedConfigRequestLoading = true;
      })
      .addCase(saveCreatedConfigRequest.fulfilled, (state, action) => {
        state.saveCreatedConfigRequestLoading = false;
        state.saveCreatedConfigRequestData = action.payload;
        mkNotification('success', 'Configuration request is successfully saved.');
      })
      .addCase(saveCreatedConfigRequest.rejected, (state) => {
        state.saveCreatedConfigRequestLoading = false;
      });
    builder
      .addCase(getVisualizedServerConfigRequest.pending, (state) => {
        state.visualizedServerDataLoading = true;
      })
      .addCase(getVisualizedServerConfigRequest.fulfilled, (state, action) => {
        state.visualizedServerDataLoading = false;
        state.visualizedServerData = action.payload;
      })
      .addCase(getVisualizedServerConfigRequest.rejected, (state) => {
        state.visualizedServerDataLoading = false;
      });
    builder
      .addCase(deleteRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRequest.fulfilled, (state) => {
        state.loading = false;
        mkNotification('success', 'Configuration request is successfully deleted.');
      })
      .addCase(deleteRequest.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { resetConfigVisualizeListData } = configurationVisualizeSlice.actions;

export const selectConfigVisualizeRequestsLoading = (state) => state.configurationVisualize.loading;
export const selectConfigVisualizeRequestsList = (state) => state.configurationVisualize.configVisualizeList;
export const selectConfigVisualizeRequestsPagination = (state) => state.configurationVisualize.configVisualizePagination;
export const selectErrors = (state) => state.configurationVisualize.errors;
export const selectCreateRequestLoading = (state) => state.configurationVisualize.createConfigRequestLoading;
export const selectCreateRequestData = (state) => state.configurationVisualize.createConfigRequestData;
export const selectCreateRequestErrors = (state) => state.configurationVisualize.createConfigRequestErrors;
export const selectConfigRequestPreviewLoading = (state) => state.configurationVisualize.loadingRequestConfigPreview;
export const selectConfigRequestPreview = (state) => state.configurationVisualize.requestConfigPreview;
export const selectSaveCreatedRequestLoading = (state) => state.configurationVisualize.saveCreatedConfigRequestLoading;
export const selectSaveCreatedRequestData = (state) => state.configurationVisualize.saveCreatedConfigRequestData;
export const selectVisualizedServerDataLoading = (state) => state.configurationVisualize.visualizedServerDataLoading;
export const selectVisualizedServerData = (state) => state.configurationVisualize.visualizedServerData;

export default configurationVisualizeSlice.reducer;
