import { Badge } from 'antd';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import { CONFIGURATIONS_STATUS_COLOR } from '../PageConfigurations/PageConfigurations.const';



export const CONFIG_MIXINS_TABLE_COLUMNS =  [
  {
    title: 'ID',
    width: '10%',
    align: 'center',
    dataIndex: 'id',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    align: 'center',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (value) => {
      return (
        <Badge color={CONFIGURATIONS_STATUS_COLOR(value)} text={capitalize(value)} />
      );
    },
  },
  {
    title: 'Priority',
    align: 'center',
    dataIndex: 'priority',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
    sorter: (_a, _b) => Number(_a.priority) - Number(_b.priority),
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: (_a, _b) => new Date(_a.created_at) - new Date(_b.created_at),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: (_a, _b) => new Date(_a.updated_at) - new Date(_b.updated_at),
  },
];

export const getRecentNonArrayPath = (obj, path) => {
  const pathArr = path.split('.');

  let currentObj = obj;

  let currentPath = '';

  // eslint-disable-next-line fp/no-loops
  for (let i = 0; i < pathArr.length; i++) {
    const key = pathArr[i];

    if (currentObj[key] !== undefined) {
      currentObj = currentObj[key];
      currentPath += (i > 0 ? '/' : '') + key;

      if (Array.isArray(currentObj)) {
        return currentPath;
      }
    } else {
      return currentPath;
    }
  }

  return currentPath;
};
