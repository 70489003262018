import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';



const getNotEmptyQueryValues = (values) => {
  return Object.keys(values).reduce((object, key) => {
    if (values[key] || values[key] === false) {
      object[key] = values[key];
    }
    return object;
  }, {});
};

export const mkQueryParams = (currentPage, currentLimit, currentOrder, currentSorting, currentSearch, currentFilters = []) => {
  const params = {
    page: currentPage || 1,
    limit: currentLimit || 10,
    orderBy: currentOrder,
    sortedBy: currentSorting === 'ascend' ? 'asc' : currentSorting === 'descend' ? 'desc' : currentSorting,
    search: currentSearch || '',
  };

  if (!isArray(currentFilters)) {
    throw new Error('Filter configs are incorrect, should be array');
  }

  currentFilters.forEach((filter) => {
    if (!isObject(filter) || !filter.hasOwnProperty('filterKey') || !filter.hasOwnProperty('filterValue')) {
      throw new Error('Filter configs are incorrect, filterKey or filterValue are absent');
    }

    params[filter.filterKey] = filter.filterValue;
  });

  return getNotEmptyQueryValues(params);
};
