import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { Card, Empty, List } from 'antd';
import EllipsisRow from '../EllipsisRow';



const ProjectsListCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ProjectsListCard = styled.div`
  display: block;
  width: 600px;
  
  @media (max-width: 620px) {
    width: 100%;
  }
`;

const ProjectsListContainer = styled.div`
  display: block;
  min-height: calc(100vh - 1200px);
  max-height: calc(100vh - 330px);
  overflow: scroll;
`;

const ProjectsListItemLabel = styled.div`
  font-weight: bold;
  padding: 7px 40px 7px 20px;
  background: #2d343608;
  box-shadow: inset 0 0 10px -5px rgba(34,60,80,0.18);

    &:hover {
        cursor: pointer;
        background-color: #add8e630;
        box-shadow: inset 0 0 10px -5px rgba(34,60,80,0.18);
    }
`;

const ProjectsListItem = styled.div`
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: unset;
  }
  
  &:hover {
    cursor: pointer;
    background-color: #add8e630;
    box-shadow: inset 0 0 10px -5px rgba(34,60,80,0.18);
  }
`;


const ProjectsList = ({ data, handleSetSettings, projectSettings }) => {
  const isSpace = !isEmpty(projectSettings?.spaceKey);
  const spaceData = !isEmpty(data) ? data.filter((item) => item.key === projectSettings.spaceKey) : [];

  return (
    <ProjectsListCardContainer>
      <ProjectsListCard>
        <Card title={`Please select ${!isSpace ? 'space or' : ''} project you want to work with:`}>
          <ProjectsListContainer>
            {!isEmpty(data) ? (
              <List
                header={null}
                footer={null}
                bordered
                dataSource={isSpace ? spaceData : data}
                renderItem={(item) => {
                  return (
                    <>
                      {!isSpace && (
                        <ProjectsListItemLabel
                          onClick={() => {
                            handleSetSettings({ spaceKey: item.key, projectKey: null });
                          }}
                        >
                          <EllipsisRow data={`[${item.key}] ${item.label}`} />
                        </ProjectsListItemLabel>
                      )}
                      {!isEmpty(item.projects) ? (item.projects.map((project) => (
                        <ProjectsListItem key={project.id}>
                          <List.Item
                            onClick={() => {
                              handleSetSettings({ spaceKey: item.key, projectKey: project.key });
                            }}
                          >
                            <EllipsisRow data={`[${project.key}] ${project.label}`} />
                          </List.Item>
                        </ProjectsListItem>
                      ))
                      ) : isSpace ? <Empty className='da-p-16' /> : null}
                    </>
                  );
                }}
              />
            ) : <Empty />}
          </ProjectsListContainer>
        </Card>
      </ProjectsListCard>
    </ProjectsListCardContainer>
  );
};


ProjectsList.propTypes = {
  data: PropTypes.array,
  projectSettings: PropTypes.object.isRequired,
  handleSetSettings: PropTypes.func.isRequired,
};

ProjectsList.defaultProps = {
  data: [],
};

export default ProjectsList;
