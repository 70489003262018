import React from 'react';
import { Button, Popconfirm, Row, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { hasRights, Permissions } from '../../../../const/permissions';
import urlPageStructureDetails from '../../../../urls/urlPageStructureDetails';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';



export const getStructuresTableColumns = (handleDeleteStructure) => [
  {
    title: 'ID',
    align: 'center',
    width: '10%',
    dataIndex: 'id',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
    render: (item) => {
      return (
        <>
          {hasRights([ Permissions.CONFIGS.STRUCTURES.VIEW_DETAILS ]) && item ? (
            <Link to={urlPageStructureDetails({ structureId: item })}>
              {item}
            </Link>
          ) : (<div>{item || EMPTY_VALUE_PLACEHOLDER}</div>)}
        </>
      );
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    align: 'center',
    render: (item) => item || EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Version',
    dataIndex: 'version',
    align: 'center',
    render: (item) => item || EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('updated_at'),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    width: '15%',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {hasRights([ Permissions.CONFIGS.STRUCTURES.DELETE ]) && (
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteStructure(item.id)}>
              <Tooltip placement="top" title='Delete structure'>
                <Button
                  size="small"
                  type='primary'
                  danger
                  className='btn-with-side-margin'
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
          {hasRights([ Permissions.CONFIGS.STRUCTURES.VIEW_DETAILS ], true) && (
            <Tooltip placement="top" title='Structure details'>
              <Button size="small" type='primary' className='btn-with-side-margin'>
                <Link to={urlPageStructureDetails({ structureId: item.id })}>
                  <EyeOutlined />
                </Link>
              </Button>
            </Tooltip>
          )}
        </Row>
      );
    },
  },
];
