import { Breadcrumb, Card, Col, Row, Spin } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import urlPageProjects from '../../../../urls/urlPageProjects';
import urlPageSpaceProjects from '../../../../urls/urlPageSpaceProjects';
import urlPageSpaces from '../../../../urls/urlPageSpaces';
import urlPageSettingsProjects from '../../../../urls/urlPageSettingsProjects';
import { Permissions } from '../../../../const/permissions';
import { GLOBAL_SETTINGS_PREFIX, LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import HasRights from '../../../../components/HasRights';
import FormCreateEditProject from './FormCreateEditProject';
import { useApp } from '../../../../app/context/AppContext';
import { useCurrentSettingsScope } from '../../../../app/hooks';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  createProject,
  updateProjectDetails,
  getProjectDetails,
  resetProjectDetailsData,
  selectProjectDetailsLoading,
  selectProjectDetailsData,
  selectFormCreateEditProjectErrors,
  selectFormCreateProjectLoading,
  selectFormEditProjectLoading,
} from '../../feature/projectDetailsSlice';
import {
  getProjectsListData,
  resetProjectsListData,
  selectProjectsLoading,
  selectSpacesList,
} from '../../../Administration/Projects/feature/projectsSlice';



const PageCreateEditProject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { projectSettings } = useApp();
  const { spaceId, projectId } = useParams();

  const dispatch = useDispatch();
  const isLoadingProjectDetails = useSelector(selectProjectDetailsLoading);
  const projectDetails = useSelector(selectProjectDetailsData);
  const isCreateLoading = useSelector(selectFormCreateProjectLoading);
  const isUpdateLoading = useSelector(selectFormEditProjectLoading);
  const formErrors = useSelector(selectFormCreateEditProjectErrors);
  const spacesList = useSelector(selectSpacesList);
  const isLoadingSpacesList = useSelector(selectProjectsLoading);
  const settingsScope = useCurrentSettingsScope();

  const isGlobalAdmin = settingsScope === GLOBAL_SETTINGS_PREFIX;
  const isEditProject = pathname.endsWith('edit');
  const initialValues = isEditProject ? projectDetails : { status: 'active', space_id: spaceId ? Number(spaceId) : null };
  const projectsListPath = !isEmpty(spaceId) ? urlPageSpaceProjects({ spaceId }) : isGlobalAdmin ? urlPageProjects() : urlPageSettingsProjects();
  const disableSpaceField = !!spaceId;

  useEffect(() => {
    dispatch(getProjectsListData({ limit: 1 }));

    if (isEditProject) {
      dispatch(getProjectDetails(projectId));
    }
    return () => {
      dispatch(resetProjectDetailsData());
      dispatch(resetProjectsListData());
    };
  }, []);


  const handleRedirectToProjects = (response) => {
    if (isFulfilledRequestStatus(response)) {
      navigate(projectsListPath);
    }
  };

  const handleSubmit = async (values) => {
    if (isEditProject) {
      await dispatch(updateProjectDetails({ projectId, values })).then((response) => {
        handleRedirectToProjects(response);
      });
    } else {
      await dispatch(createProject(values)).then((response) => {
        handleRedirectToProjects(response);
      });
    }
  };

  return (
    <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.PROJECTS.CREATE, Permissions.ADMINISTRATION.PROJECTS.UPDATE ]}>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            {settingsScope === GLOBAL_SETTINGS_PREFIX && (
              <Breadcrumb.Item>
                <Link to={urlPageSpaces()}>
                  Spaces list
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <Link
                to={projectsListPath}
              >
                Projects list
              </Link>
            </Breadcrumb.Item>
            {!isEditProject ? (
              <Breadcrumb.Item>Create</Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item>Update</Breadcrumb.Item>
            )}
          </Breadcrumb>
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Spin spinning={isLoadingProjectDetails || isLoadingSpacesList} tip={LOADING_TIP_PLACEHOLDER}>
            <FormCreateEditProject
              isGlobalAdmin={isGlobalAdmin}
              initialValues={initialValues}
              disableSpaceField={disableSpaceField}
              isEditProject={isEditProject}
              spacesList={spacesList}
              isSubmitting={isCreateLoading || isUpdateLoading}
              formErrors={formErrors}
              onSubmit={handleSubmit}
              onCancel={() => {
                navigate(-1);
              }}
            />
          </Spin>
        </Card>
      </div>
    </HasRights>
  );
};

export default PageCreateEditProject;
