import { setRequestParams } from '../../../lib/setRequestParams';



export default class UserProfileDevicesService {
  constructor (Api) {
    this.api = Api;
  }

  getUserProfilesDevicesList ({ userId, params }) {
    return this.api.get(`profiles/users/${userId}/devices${setRequestParams(params)}`);
  }
}
