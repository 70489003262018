import React from 'react';
import { Col, Layout, Row } from 'antd';
import moment from 'moment';



const { Footer } = Layout;


export default function MenuFooter () {
  return (
    <Footer className="da-bg-color-black-10 da-bg-color-dark-100">
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <p className="da-badge-text da-mb-0 da-text-color-dark-30">
            COPYRIGHT ©
            {moment().format('YYYY')}
            {' '}
            X-Flow, All rights Reserved
          </p>
        </Col>

        <Col md={12} span={24} className="da-mt-sm-8 da-text-sm-center da-text-right">
          <a
            href="https://x-flow.app/"
            target="_blank"
            className="da-badge-text da-text-color-dark-30" rel="noreferrer"
          >
            About us
          </a>
        </Col>
      </Row>
    </Footer>
  );
}
