import isArray from 'lodash/isArray';



export default class UserDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  getUserData ({ userId, include = null }) {
    let url = `users/${userId}`;

    if (include && isArray(include)) {
      url += `?include=${include.join(',')}`;
    }

    return this.api.get(url);
  }

  updateUserPolicies ({ userId, data }) {
    return this.api.put(`users/${userId}/policies`, data);
  }

  updateUserSpaces ({ userId, spaceIds }) {
    return this.api.patch(`users/${userId}/spaces`, { space_ids: spaceIds });
  }
}
