import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import ProjectUserDetailsService from '../services/projectUserDetailsService';
import { setUrlParams } from '../../../lib/setUrlParams';



const initialState = {
  errors: {},
  loading: false,
  userDetails: [],
};

const projectUserDetailsService = new ProjectUserDetailsService(Api);


export const getProjectUserDetails = createAsyncThunk(
  'projectUserDetails/getUserDetails',
  async ({ values }, { rejectWithValue }) => {
    try {
      const response = await projectUserDetailsService.getUserDetails({ values });

      if (!isEmpty(values)) {
        setUrlParams(values);
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const projectUserDetailsSlice = createSlice({
  name: 'projectUserDetails',
  initialState,
  reducers: {
    resetProjectUserDetailsData: (state) => {
      state.userDetails = [];
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectUserDetails.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(getProjectUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
      })
      .addCase(getProjectUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
      });
  },
});

// actions
export const { resetProjectUserDetailsData } = projectUserDetailsSlice.actions;

// selectors
export const selectFormErrors = (state) => state.projectUserDetails.errors;
export const selectProjectUserDetailsLoading = (state) => state.projectUserDetails.loading;
export const selectProjectUserDetailsData = (state) => state.projectUserDetails.userDetails;

// reducer
export default projectUserDetailsSlice.reducer;
