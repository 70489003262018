import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';
import ConfigurationMixinDetailsService from '../services/configurationMixinDetailsService';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  configurationMixinDetails: {},
  loadingConfigMixinDetails: false,
  updateConfigMixinLoading: false,
  updateConfigMixinErrors: {},
  configMixinTargetDetails: {},
  loadingConfigMixinTarget: false,
  createConfigMixinTargetingLoading: false,
  createConfigMixinTargetingErrors: {},
  updateConfigMixinTargetingLoading: false,
  updateConfigMixinTargetingErrors: {},
};

const configurationMixinDetailsService = new ConfigurationMixinDetailsService(Api);

export const getConfigMixinDetails = createAsyncThunk(
  'configurationMixin/getConfigMixin',
  async (mixinId, { rejectWithValue }) => {
    try {
      const response = await configurationMixinDetailsService.getConfigMixinDetails(mixinId);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateConfigMixinDetails = createAsyncThunk(
  'configurationMixin/updateConfigMixinDetails',
  async ({ configurationId, mixinId, data }, { rejectWithValue }) => {
    try {
      const response = await configurationMixinDetailsService.updateConfigMixinDetails({ configurationId, mixinId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getConfigMixinTargetDetails = createAsyncThunk(
  'configurationMixin/getConfigMixinTarget',
  async (mixinId, { rejectWithValue }) => {
    try {
      const response = await configurationMixinDetailsService.getConfigMixinTargetDetails(mixinId);

      return response.data[0];
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createConfigMixinTargeting = createAsyncThunk(
  'configurationMixin/createConfigMixinTargeting',
  async ({ mixinId, data }, { rejectWithValue }) => {
    try {
      const response = await configurationMixinDetailsService.createConfigMixinTargeting({ mixinId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateConfigMixinTargeting = createAsyncThunk(
  'configurationMixin/updateConfigMixinTargeting',
  async ({ mixinId, targetingId, data }, { rejectWithValue }) => {
    try {
      const response = await configurationMixinDetailsService.updateConfigMixinTargeting({ mixinId, targetingId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteConfigMixinTargeting = createAsyncThunk(
  'configurationMixin/deleteConfigMixinTargeting',
  async ({ mixinId, targetingId }) => {
    const response = await configurationMixinDetailsService.deleteConfigMixinTargeting({ mixinId, targetingId });

    return response.data;
  },
);

export const configurationMixinDetailsSlice = createSlice({
  name: 'configurationMixinDetails',
  initialState,
  reducers: {
    resetConfigMixinDetailsData: (state) => {
      state.configurationMixinDetails = {};
    },
    resetConfigMixinTargetDetailsData: (state) => {
      state.configMixinTargetDetails = {};
    },
    resetUpdateConfigMixinErrors: (state) => {
      state.updateConfigMixinErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfigMixinDetails.pending, (state) => {
        state.loadingConfigMixinDetails = true;
        state.loadingConfigMixinDetailsErrors = {};
      })
      .addCase(getConfigMixinDetails.fulfilled, (state, action) => {
        state.loadingConfigMixinDetails = false;
        state.configurationMixinDetails = action.payload;
      })
      .addCase(getConfigMixinDetails.rejected, (state, action) => {
        state.loadingConfigMixinDetails = false;
        state.loadingConfigMixinDetailsErrors = action.payload.errors;
      });

    builder
      .addCase(updateConfigMixinDetails.pending, (state) => {
        state.updateConfigMixinLoading = true;
        state.updateConfigMixinErrors = {};
      })
      .addCase(updateConfigMixinDetails.fulfilled, (state) => {
        state.updateConfigMixinLoading = false;
        mkNotification('success', 'Configuration mixin details is successfully updated.');
      })
      .addCase(updateConfigMixinDetails.rejected, (state, action) => {
        state.updateConfigMixinLoading = false;
        state.updateConfigMixinErrors = action.payload.errors;
      });

    builder
      .addCase(getConfigMixinTargetDetails.pending, (state) => {
        state.loadingConfigMixinTarget = true;
        state.errors = {};
      })
      .addCase(getConfigMixinTargetDetails.fulfilled, (state, action) => {
        state.loadingConfigMixinTarget = false;
        state.configMixinTargetDetails = action.payload;
      })
      .addCase(getConfigMixinTargetDetails.rejected, (state, action) => {
        state.loadingConfigMixinTarget = false;
        state.errors = action.payload.errors;
      });

    builder
      .addCase(createConfigMixinTargeting.pending, (state) => {
        state.createConfigMixinTargetingLoading = true;
        state.createConfigMixinTargetingErrors = {};
      })
      .addCase(createConfigMixinTargeting.fulfilled, (state) => {
        state.createConfigMixinTargetingLoading = false;
        mkNotification('success', 'Configuration mixin targeting is successfully created.');
      })
      .addCase(createConfigMixinTargeting.rejected, (state, action) => {
        state.createConfigMixinTargetingLoading = false;
        state.createConfigMixinTargetingErrors = action.payload.errors;
      });

    builder
      .addCase(updateConfigMixinTargeting.pending, (state) => {
        state.updateConfigMixinTargetingLoading = true;
        state.updateConfigMixinTargetingErrors = {};
      })
      .addCase(updateConfigMixinTargeting.fulfilled, (state) => {
        state.updateConfigMixinTargetingLoading = false;
        mkNotification('success', 'Configuration mixin target is successfully updated.');
      })
      .addCase(updateConfigMixinTargeting.rejected, (state, action) => {
        state.updateConfigMixinTargetingLoading = false;
        state.updateConfigMixinTargetingErrors = action.payload.errors;
      });

    builder
      .addCase(deleteConfigMixinTargeting.pending, (state) => {
        state.loadingConfigMixinDetails = true;
      })
      .addCase(deleteConfigMixinTargeting.fulfilled, (state) => {
        state.loadingConfigMixinDetails = false;
        mkNotification('success', 'Configuration mixin target is successfully deleted.');
      })
      .addCase(deleteConfigMixinTargeting.rejected, (state) => {
        state.loadingConfigMixinDetails = false;
      });

  },
});

// actions
export const {
  resetConfigMixinDetailsData,
  resetConfigMixinTargetDetailsData,
  resetUpdateConfigMixinErrors,
} = configurationMixinDetailsSlice.actions;

// selectors
export const selectConfigMixinDetailsLoading = (state) => state.configurationMixinDetails.loadingConfigMixinDetails;
export const selectConfigMixinDetails = (state) => state.configurationMixinDetails.configurationMixinDetails;

export const selectFormUpdateConfigMixinErrors = (state) => state.configurationMixinDetails.updateConfigMixinErrors;
export const selectFormUpdateConfigMixinLoading = (state) => state.configurationMixinDetails.updateConfigMixinLoading;

export const selectConfigMixinTargetDetailsLoading = (state) => state.configurationMixinDetails.loadingConfigMixinTarget;
export const selectConfigMixinTargetDetails = (state) => state.configurationMixinDetails.configMixinTargetDetails;

export const selectCreateConfigMixinTargetingLoading = (state) => state.configurationMixinDetails.createConfigMixinTargetingLoading;
export const selectCreateConfigMixinTargetingErrors = (state) => state.configurationMixinDetails.createConfigMixinTargetingErrors;

export const selectUpdateConfigMixinTargetingLoading = (state) => state.configurationMixinDetails.updateConfigMixinTargetingLoading;
export const selectUpdateConfigMixinTargetingErrors = (state) => state.configurationMixinDetails.updateConfigMixinTargetingErrors;


// reducer
export default configurationMixinDetailsSlice.reducer;
