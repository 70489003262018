import { Badge, Button, Card, Descriptions, Tag, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { EditOutlined } from '@ant-design/icons';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import { hasRights, Permissions } from '../../../../const/permissions';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';
import {
  EXPERIMENT_IN_SANDBOX_STATUS_COLOR,
  EXPERIMENT_STATUS_COLOR,
  EXPERIMENT_STATUSES_MAP, EXPERIMENTS_STATUS_TRANSLATIONS,
} from '../../pages/PageMixinsExperiments/PageMixinsExperiments.const';



const MixinsExperimentDetailsCard = ({ data, isLoading, isMixin, canEdit, handleEdit }) => {
  return (
    <div className='card-table'>
      <Card
        title='Experiment details'
        loading={isLoading}
        style={{ marginBottom: '10px' }}
        extra={[
          <div key='1'>
            {canEdit && hasRights([ Permissions.MIXINS_EXPERIMENTS.UPDATE ]) && (
              <Tooltip
                placement="bottom"
                title='Edit details'
              >
                <Button
                  size="small"
                  type='primary'
                  className='btn-with-side-margin warning-btn'
                  disabled={isLoading}
                  onClick={handleEdit}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}
          </div>,
        ]}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Id:">
            {data?.id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          {isMixin && (
            <Descriptions.Item label="Config id:">
              {data?.config_id || EMPTY_VALUE_PLACEHOLDER}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Name:">
            {data?.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Experiment status:">
            {!isEmpty(data?.status) ? (
              <Badge color={EXPERIMENT_STATUS_COLOR(data.status)} text={EXPERIMENTS_STATUS_TRANSLATIONS(data.status)} />
            ) : EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Experiment in sandbox:">
            <Badge color={EXPERIMENT_IN_SANDBOX_STATUS_COLOR(data.in_sandbox)} text={capitalize(data.in_sandbox)} />
          </Descriptions.Item>
          <Descriptions.Item label="Is draft:">
            <Tag color={data.is_draft ? 'success' : 'default'}>{capitalize(data.is_draft)}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Is paused:">
            <Tag color={data.is_paused ? 'volcano' : 'default'}>{capitalize(data.is_paused)}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Author:">
            {data?.author?.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Assigned to:">
            {data?.assigned?.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Created at:">
            <Tag color="blue">
              {renderDateField(data?.created_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Updated at:">
            <Tag color="geekblue">
              {renderDateField(data?.updated_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Published at:">
            <Tag color="purple">
              {renderDateField(data?.published_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Description:">
            {data?.description || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Configuration:">
            {(data?.config_id && data?.status !== EXPERIMENT_STATUSES_MAP.OBSOLETE) ? (
              <a
                href={urlPageConfigurationDetails({ configurationId: data.config_id })}
                target='_blank'
                rel="noreferrer"
              >
                Show details
              </a>
            ) : 'No details'}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};

MixinsExperimentDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMixin: PropTypes.bool,
  canEdit: PropTypes.bool,
  handleEdit: PropTypes.func,
};

MixinsExperimentDetailsCard.defaultProps = {
  isMixin: false,
  canEdit: false,
  handleEdit: () => {},
};

export default MixinsExperimentDetailsCard;
