import moment from 'moment';
import React from 'react';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const preparedExportValues = (data) => data.map((item) => {
  return {
    date: item.date,
    hints_received: item.hints_received,
    hints_used: item.hints_used,
  };
});

export const TABLE_HINTS_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.date, 'DD.MM.YYYY HH:mm') >= moment(_b.date, 'DD.MM.YYYY HH:mm') ? 1 : -1),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Received hints',
    dataIndex: 'hints_received',
    align: 'center',
    sorter: (_a, _b) => Number(_a.hints_received) - Number(_b.hints_received),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Used hints',
    dataIndex: 'hints_used',
    align: 'center',
    sorter: (_a, _b) => Number(_a.hints_used) - Number(_b.hints_used),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];
