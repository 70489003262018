import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import ConfigurationDetailsService from '../services/configurationDetailsService';
import { mkNotification } from '../../../lib/mkNotification';
import { setUrlParams } from '../../../lib/setUrlParams';



const initialState = {
  configurationDetails: {},
  errors: [],
  loading: false,
  formEditConfigLoading: false,
  formEditConfigErrors: [],
  configTargetDetails: {},
  loadingConfigTarget: false,
  configTargetingFormLoading: false,
  configTargetingFormErrors: {},
  formCreateConfigMixinLoading: false,
  formCreateConfigMixinErrors: {},
  abTestsData: [],
  loadingAbTests: false,
  loadingConfigMixins: false,
  configMixinsList: [],
  configMixinsListPagination: {},
  publishConfigurationLoading: false,
};

const configurationDetailsService = new ConfigurationDetailsService(Api);

export const getConfigurationDetails = createAsyncThunk(
  'configurations/getConfiguration',
  async (configurationId, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.getDetails(configurationId);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateConfigurationDetails = createAsyncThunk(
  'configurations/updateConfiguration',
  async ({ configurationId, data }, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.updateDetails({ configurationId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getConfigTargetDetails = createAsyncThunk(
  'configurations/getConfigTarget',
  async (configurationId, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.getConfigTargetDetails(configurationId);

      return response.data[0];
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createConfigTargeting = createAsyncThunk(
  'configurations/createConfigTargeting',
  async ({ configurationId, data }, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.createConfigTargeting({ configurationId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateConfigTargeting = createAsyncThunk(
  'configurations/updateConfigTargeting',
  async ({ configurationId, targetingId, data }, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.updateConfigTargeting({ configurationId, targetingId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createConfigMixin = createAsyncThunk(
  'configurations/createConfigMixin',
  async ({ configurationId, data }, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.createConfigMixin({ configurationId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getConfigMixinsList = createAsyncThunk(
  'configurations/getMixinsList',
  async ({ configurationId, params }, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.getConfigMixinsList({ configurationId, params });

      if (!isEmpty(params)) {
        setUrlParams(params);
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteConfigTargeting = createAsyncThunk(
  'configurations/deleteConfigTargeting',
  async ({ configurationId, targetingId }) => {
    const response = await configurationDetailsService.deleteConfigTargeting({ configurationId, targetingId });

    return response.data;
  },
);

export const getAbTestsListData = createAsyncThunk(
  'configurations/getAbTestsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.getAbTests();

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteConfigMixin = createAsyncThunk(
  'configurations/deleteConfigMixin',
  async ({ mixinId }) => {
    const response = await configurationDetailsService.deleteConfigMixin({ mixinId });

    return response.data;
  },
);

export const publishConfiguration = createAsyncThunk(
  'configurations/publishConfiguration',
  async (configurationId, { rejectWithValue }) => {
    try {
      const response = await configurationDetailsService.publishConfiguration(configurationId);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const configurationDetailsSlice = createSlice({
  name: 'configurationDetails',
  initialState,
  reducers: {
    resetConfigurationDetailsData: (state) => {
      state.configurationDetails = {};
    },
    resetConfigTargetDetailsData: (state) => {
      state.configTargetDetails = {};
    },
    resetAbTestsListData: (state) => {
      state.abTestsData = [];
    },
    resetConfigMixinDetailsData: (state) => {
      state.configurationMixinDetails = {};
    },
    resetConfigMixinsList: (state) => {
      state.configMixinsList = [];
      state.configMixinsListPagination = {};
    },
    resetCreateConfigMixinErrors: (state) => {
      state.formCreateConfigMixinErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfigurationDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getConfigurationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.configurationDetails = action.payload;
      })
      .addCase(getConfigurationDetails.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.data;
      });

    builder
      .addCase(updateConfigurationDetails.pending, (state) => {
        state.formEditConfigLoading = true;
      })
      .addCase(updateConfigurationDetails.fulfilled, (state, action) => {
        state.formEditConfigLoading = false;
        state.configurationDetails = action.payload;
        mkNotification('success', 'Configuration details is successfully updated.');
      })
      .addCase(updateConfigurationDetails.rejected, (state, action) => {
        state.formEditConfigLoading = false;
        state.formEditConfigErrors = action.errors;
      });

    builder
      .addCase(getConfigTargetDetails.pending, (state) => {
        state.loadingConfigTarget = true;
      })
      .addCase(getConfigTargetDetails.fulfilled, (state, action) => {
        state.loadingConfigTarget = false;
        state.configTargetDetails = action.payload;
      })
      .addCase(getConfigTargetDetails.rejected, (state, action) => {
        state.loadingConfigTarget = false;
        state.errors = action.payload.data;
      });

    builder
      .addCase(createConfigTargeting.pending, (state) => {
        state.configTargetingFormLoading = true;
        state.configTargetingFormErrors = {};
      })
      .addCase(createConfigTargeting.fulfilled, (state) => {
        state.configTargetingFormLoading = false;
        mkNotification('success', 'Configuration targeting is successfully created.');
      })
      .addCase(createConfigTargeting.rejected, (state, action) => {
        state.configTargetingFormLoading = false;
        state.configTargetingFormErrors = action.payload.errors;
      });

    builder
      .addCase(updateConfigTargeting.pending, (state) => {
        state.configTargetingFormLoading = true;
        state.configTargetingFormErrors = {};
      })
      .addCase(updateConfigTargeting.fulfilled, (state) => {
        state.configTargetingFormLoading = false;
        mkNotification('success', 'Configuration targeting is successfully updated.');
      })
      .addCase(updateConfigTargeting.rejected, (state, action) => {
        state.configTargetingFormLoading = false;
        state.configTargetingFormErrors = action.payload.errors;
      });

    builder
      .addCase(deleteConfigTargeting.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteConfigTargeting.fulfilled, (state) => {
        state.loading = false;
        mkNotification('success', 'Configuration targeting is successfully deleted.');
      })
      .addCase(deleteConfigTargeting.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(createConfigMixin.pending, (state) => {
        state.formCreateConfigMixinLoading = true;
        state.formCreateConfigMixinErrors = {};
      })
      .addCase(createConfigMixin.fulfilled, (state) => {
        state.formCreateConfigMixinLoading = false;
        mkNotification('success', 'Configuration mixin is successfully created.');
      })
      .addCase(createConfigMixin.rejected, (state, action) => {
        state.formCreateConfigMixinLoading = false;
        state.formCreateConfigMixinErrors = action.payload.errors;
      });

    builder
      .addCase(getAbTestsListData.pending, (state) => {
        state.loadingAbTests = true;
      })
      .addCase(getAbTestsListData.fulfilled, (state, action) => {
        state.loadingAbTests = false;
        state.abTestsData = action.payload;
      })
      .addCase(getAbTestsListData.rejected, (state) => {
        state.loadingAbTests = false;
      });

    builder
      .addCase(getConfigMixinsList.pending, (state) => {
        state.loadingConfigMixins = true;
      })
      .addCase(getConfigMixinsList.fulfilled, (state, action) => {
        state.loadingConfigMixins = false;
        state.configMixinsList = action.payload.data;
        state.configMixinsListPagination = action.payload.meta.pagination;
      })
      .addCase(getConfigMixinsList.rejected, (state) => {
        state.loadingConfigMixins = false;
      });

    builder
      .addCase(deleteConfigMixin.pending, (state) => {
        state.loadingConfigMixins = true;
      })
      .addCase(deleteConfigMixin.fulfilled, (state) => {
        state.loadingConfigMixins = false;
        mkNotification('success', 'Configuration mixin is successfully deleted.');
      })
      .addCase(deleteConfigMixin.rejected, (state) => {
        state.loadingConfigMixins = false;
      });

    builder
      .addCase(publishConfiguration.pending, (state) => {
        state.publishConfigurationLoading = true;
      })
      .addCase(publishConfiguration.fulfilled, (state) => {
        state.publishConfigurationLoading = false;
        mkNotification('success', 'Recent configuration is successfully published.');
      })
      .addCase(publishConfiguration.rejected, (state) => {
        state.publishConfigurationLoading = false;
      });
  },
});

// actions
export const {
  resetConfigurationDetailsData,
  resetConfigTargetDetailsData,
  resetAbTestsListData,
  resetConfigMixinsList,
  resetCreateConfigMixinErrors,
} = configurationDetailsSlice.actions;

// selectors
export const selectConfigurationDetailsLoading = (state) => state.configurationDetails.loading;
export const selectConfigurationDetails = (state) => state.configurationDetails.configurationDetails;
export const selectErrors = (state) => state.configurationDetails.errors;

export const selectFormEditConfigDetailsLoading = (state) => state.configurationDetails.formEditConfigLoading;
export const selectFormEditConfigDetailsErrors = (state) => state.configurationDetails.formEditConfigErrors;

export const selectConfigTargetDetailsLoading = (state) => state.configurationDetails.loadingConfigTarget;
export const selectConfigTargetDetails = (state) => state.configurationDetails.configTargetDetails;

//Targeting
export const selectConfigTargetingFormErrors = (state) => state.configurationDetails.configTargetingFormErrors;
export const selectConfigTargetingFormLoading = (state) => state.configurationDetails.configTargetingFormLoading;

//Abtests
export const selectFormConfigTargetingAbTestsLoading = (state) => state.configurationDetails.loadingAbTests;
export const selectFormConfigTargetingAbTests = (state) => state.configurationDetails.abTestsData;

//Mixins
export const selectConfigMixinsListLoading = (state) => state.configurationDetails.loadingConfigMixins;
export const selectConfigMixinsList = (state) => state.configurationDetails.configMixinsList;
export const selectConfigMixinsListPagination = (state) => state.configurationDetails.configMixinsListPagination;

export const selectFormCreateConfigMixinLoading = (state) => state.configurationDetails.formCreateConfigMixinLoading;
export const selectFormCreateConfigMixinErrors = (state) => state.configurationDetails.formCreateConfigMixinErrors;

export const selectPublishConfigurationLoading = (state) => state.configurationDetails.publishConfigurationLoading;

// reducer
export default configurationDetailsSlice.reducer;

