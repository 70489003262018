import { useRoutes } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { routes } from '../../routes';
import { useApp } from '../context/AppContext';
import { setRoutesPrefix } from '../../lib/setRoutesPrefix';



const Router = () => {
  const { prevProjectSettings, projectSettings } = useApp(null);
  const routesCopy = cloneDeep(routes);
  const modifiedRoutes = setRoutesPrefix(routesCopy, prevProjectSettings, projectSettings);

  return useRoutes(modifiedRoutes);
};

export default Router;
