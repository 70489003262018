import { Alert } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';



const AlertNotification = ({ closable = false, message, type }) => {
  return (
    <Alert
      style={{ width: '100%', marginLeft: '6px', textAlign: 'center' }}
      closable={closable}
      className='da-mb-18'
      message={message}
      type={type}
    />
  );
};

AlertNotification.propTypes = {
  closable: PropTypes.bool,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default AlertNotification;
