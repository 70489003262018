import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';
import MixinsVisualizeService from '../services/mixinsVisualizeService';



const initialState = {
  errors: {},
  mixinsList: [],
  mixinsListLoading: false,
  configurationData: {},
  configurationDataLoading: false,
  configurationRowData: [],
  configurationRowDataLoading: false,
};

const mixinsVisualizeService = new MixinsVisualizeService(Api);

export const getMixinsListData = createAsyncThunk(
  'mixinsVisualize/getMixinsListData',
  async ({ configId, params }, { rejectWithValue }) => {
    try {
      const response = await mixinsVisualizeService.getMixinsList({ configId, params });

      if (!isEmpty(params)) {
        setUrlParams(params);
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getConfigurationData = createAsyncThunk(
  'mixinsVisualize/getConfigurationData',
  async ({ configId, values }, { rejectWithValue }) => {
    try {
      const response = await mixinsVisualizeService.getConfigurationWithMixins({ configId, values });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const getConfigurationRowData = createAsyncThunk(
  'mixinsVisualize/getConfigurationRowData',
  async ({ configId, values }, { rejectWithValue }) => {
    try {
      const response = await mixinsVisualizeService.getConfigurationRowDetails({ configId, values });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const mixinsVisualizeSlice = createSlice({
  name: 'mixinsVisualize',
  initialState,
  reducers: {
    resetMixinsListData: (state) => {
      state.mixinsList = [];
    },
    resetConfigurationData: (state) => {
      state.configurationData = {};
    },
    resetConfigurationRowData: (state) => {
      state.configurationRowData = [];
    },
    setInitialConfigData: (state, action) => {
      state.configurationData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMixinsListData.pending, (state) => {
        state.mixinsListLoading = true;
      })
      .addCase(getMixinsListData.fulfilled, (state, action) => {
        state.mixinsListLoading = false;
        state.mixinsList = action.payload.data;
      })
      .addCase(getMixinsListData.rejected, (state, action) => {
        state.mixinsListLoading = false;
        state.errors = action.payload.errors;
      });

    builder
      .addCase(getConfigurationData.pending, (state) => {
        state.configurationDataLoading = true;
      })
      .addCase(getConfigurationData.fulfilled, (state, action) => {
        state.configurationDataLoading = false;
        state.configurationData = action.payload;
      })
      .addCase(getConfigurationData.rejected, (state) => {
        state.configurationDataLoading = false;
      });

    builder
      .addCase(getConfigurationRowData.pending, (state) => {
        state.configurationRowDataLoading = true;
      })
      .addCase(getConfigurationRowData.fulfilled, (state, action) => {
        state.configurationRowDataLoading = false;
        state.configurationRowData = action.payload;
      })
      .addCase(getConfigurationRowData.rejected, (state) => {
        state.configurationRowDataLoading = false;
      });
  },
});

// actions
export const {
  resetMixinsListData,
  resetConfigurationData,
  resetConfigurationRowData,
} = mixinsVisualizeSlice.actions;

// selectors
export const selectConfigurationData = (state) => state.mixinsVisualize.configurationData;
export const selectConfigurationDataLoading = (state) => state.mixinsVisualize.configurationDataLoading;
export const selectMixinsList = (state) => state.mixinsVisualize.mixinsList;
export const selectMixinsListLoading = (state) => state.mixinsVisualize.mixinsListLoading;
export const selectMixinsListErrors = (state) => state.mixinsVisualize.errors;
export const selectConfigurationRowData = (state) => state.mixinsVisualize.configurationRowData;
export const selectConfigurationRowDataLoading = (state) => state.mixinsVisualize.configurationRowDataLoading;


// reducer
export default mixinsVisualizeSlice.reducer;
