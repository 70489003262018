import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';
import UserProfileDeviceDetailsService from '../services/userProfileDeviceDetailsService';



const initialState = {
  deviceDetails: {},
  deviceAbTests: {},
  devicePurchases: [],
  devicePictures: [],
  errors: [],
  loadingDeviceDetails: false,
  loadingDeviceAbTests: false,
  loadingDevicePurchases: false,
  loadingDevicePictures: false,
};

const deviceDetailsService = new UserProfileDeviceDetailsService(Api);

export const getUserProfileDeviceDetailsData = createAsyncThunk(
  'deviceDetails/getUserProfileDeviceDetails',
  async (deviceId) => {
    const response = await deviceDetailsService.getUserProfileDeviceDetails(deviceId);

    return response.data;
  },
);

export const getUserProfileDeviceAbTestsData = createAsyncThunk(
  'deviceDetails/getUserProfileDeviceAbTests',
  async ({ deviceId, params }) => {
    return deviceDetailsService.getUserProfileDeviceAbTests({ deviceId, params });
  },
);

export const getUserProfileDevicePurchasesData = createAsyncThunk(
  'deviceDetails/getUserProfileDevicePurchases',
  async ({ deviceId, params }) => {
    return deviceDetailsService.getUserProfileDevicePurchases({ deviceId, params });
  },
);

export const getUserProfileDevicePicturesData = createAsyncThunk(
  'deviceDetails/getUserProfileDevicePictures',
  async ({ deviceId, params }) => {
    return deviceDetailsService.getUserProfileDevicePictures({ deviceId, params });
  },
);

export const userProfileDeviceDetailsSlice = createSlice({
  name: 'deviceDetails',
  initialState,
  reducers: {
    resetUserDeviceDetailsData: (state) => {
      state.deviceDetails = {};
    },
    resetUserDeviceAbTestsData: (state) => {
      state.deviceAbTests = {};
    },
    resetUserDevicePurchasesData: (state) => {
      state.devicePurchases = [];
    },
    resetUserDevicePicturesData: (state) => {
      state.devicePictures = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileDeviceDetailsData.pending, (state) => {
        state.loadingDeviceDetails = true;
      })
      .addCase(getUserProfileDeviceDetailsData.fulfilled, (state, action) => {
        state.loadingDeviceDetails = false;
        state.deviceDetails = action.payload;
      })
      .addCase(getUserProfileDeviceDetailsData.rejected, (state, action) => {
        state.loadingDeviceDetails = false;
        state.errors = action.payload;
      });

    builder
      .addCase(getUserProfileDeviceAbTestsData.pending, (state) => {
        state.loadingDeviceAbTests = true;
      })
      .addCase(getUserProfileDeviceAbTestsData.fulfilled, (state, action) => {
        state.loadingDeviceAbTests = false;
        state.deviceAbTests = action.payload.data;
      })
      .addCase(getUserProfileDeviceAbTestsData.rejected, (state, action) => {
        state.loadingDeviceAbTests = false;
        state.errors = action.payload;
      });

    builder
      .addCase(getUserProfileDevicePurchasesData.pending, (state) => {
        state.loadingDevicePurchases = true;
      })
      .addCase(getUserProfileDevicePurchasesData.fulfilled, (state, action) => {
        state.loadingDevicePurchases = false;
        state.devicePurchases = action.payload.data;
      })
      .addCase(getUserProfileDevicePurchasesData.rejected, (state, action) => {
        state.loadingDevicePurchases = false;
        state.errors = action.payload;
      });

    builder
      .addCase(getUserProfileDevicePicturesData.pending, (state) => {
        state.loadingDevicePictures = true;
      })
      .addCase(getUserProfileDevicePicturesData.fulfilled, (state, action) => {
        state.loadingDevicePictures = false;
        state.devicePictures = action.payload.data;
      })
      .addCase(getUserProfileDevicePicturesData.rejected, (state, action) => {
        state.loadingDevicePictures = false;
        state.errors = action.payload;
      });
  },
});

// actions
export const {
  resetUserDeviceDetailsData,
  resetUserDeviceAbTestsData,
  resetUserDevicePurchasesData,
  resetUserDevicePicturesData,
} = userProfileDeviceDetailsSlice.actions;

// selectors
export const selectUserDeviceDetailsLoading = (state) => state.userProfileDeviceDetails.loadingDeviceDetails;
export const selectUserDeviceDetails = (state) => state.userProfileDeviceDetails.deviceDetails;
export const selectErrors = (state) => state.userProfileDeviceDetails.deviceDetails;
export const selectUserDeviceAbTestsLoading = (state) => state.userProfileDeviceDetails.loadingDeviceAbTests;
export const selectUserDeviceAbTests = (state) => state.userProfileDeviceDetails.deviceAbTests;
export const selectUserDevicePurchasesLoading = (state) => state.userProfileDeviceDetails.loadingDevicePurchases;
export const selectUserDevicePurchases = (state) => state.userProfileDeviceDetails.devicePurchases;
export const selectUserDevicePicturesLoading = (state) => state.userProfileDeviceDetails.loadingDevicePictures;
export const selectUserDevicePictures = (state) => state.userProfileDeviceDetails.devicePictures;

// reducer
export default userProfileDeviceDetailsSlice.reducer;
