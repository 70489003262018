import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import AdsMonitoringService from '../services/adsMonitorService';
import { setUrlParams } from '../../../lib/setUrlParams';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  adsData: [],
  isLoadedInitialAds: false,
  getAdsPolling: false,
  networkOptions: [],
  loadingNetworks: false,
};

const adsMonitoringService = new AdsMonitoringService(Api);

export const getAdsMonitoringData = createAsyncThunk(
  'adsMonitoringEvents/getMonitoring',
  async (values, { rejectWithValue }) => {
    try {
      const response = await adsMonitoringService.getMonitoring(values);

      if (values) {
        setUrlParams(values);
      }

      if (isEmpty(response)) {
        mkNotification('info', 'No advertise monitoring data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const getNetworksData = createAsyncThunk(
  'networksOptions/get',
  () => {
    return adsMonitoringService.getNetworks();
  },
);


export const adsMonitorSlice = createSlice({
  name: 'adsMonitoringEvents',
  initialState,
  reducers: {
    resetAdsMonitoringData: (state) => {
      state.adsData = [];
      state.getAdsPolling = false;
      state.isLoadedInitialAds = false;
    },
    resetNetworksOptionsData: (state) => {
      state.networkOptions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdsMonitoringData.pending, (state) => {
        state.isLoadedInitialAds = true;
      })
      .addCase(getAdsMonitoringData.fulfilled, (state, action) => {
        state.isLoadedInitialAds = false;
        state.adsData = action.payload;
        state.getAdsPolling = isEmpty(action.payload) && action.payload.status === 202;
      })
      .addCase(getAdsMonitoringData.rejected, (state) => {
        state.isLoadedInitialAds = false;
      });

    builder
      .addCase(getNetworksData.pending, (state) => {
        state.loadingNetworks = true;
      })
      .addCase(getNetworksData.fulfilled, (state, action) => {
        state.loadingNetworks = false;
        state.networkOptions = action.payload;
      })
      .addCase(getNetworksData.rejected, (state) => {
        state.loadingNetworks = false;
      });
  },
});

// actions
export const { resetAdsMonitoringData, resetNetworksOptionsData } = adsMonitorSlice.actions;

// selectors
export const selectAdsLoading = (state) => state.adsMonitoring.isLoadedInitialAds;
export const selectAdsData = (state) => state.adsMonitoring.adsData;
export const selectAdsPolling = (state) => state.adsMonitoring.getAdsPolling;

export const selectLoadingNetworks = (state) => state.adsMonitoring.loadingNetworks;
export const selectNetworkOptions = (state) => state.adsMonitoring.networkOptions;

// reducer
export default adsMonitorSlice.reducer;
