import { Card, Descriptions, Tag } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import EllipsisRow from '../../../../../components/EllipsisRow/index';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../../const/system';



const UserDetailsCard = ({ data, isLoading, isGlobalAdmin }) => {
  return (
    <div className='card-table'>
      <Card
        title='User details'
        loading={isLoading}
        style={{ marginBottom: '10px' }}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Name:">
            {data?.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Email:">
            {data?.email || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Created at:">
            <Tag color="blue">
              {renderDateField(data?.created_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Updated at:">
            <Tag color="geekblue">
              {renderDateField(data?.updated_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Last login:">
            <Tag color="purple">
              {renderDateField(data?.last_login)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="User id:">
            {data?.id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          {isGlobalAdmin && (
            <Descriptions.Item label="Spaces:">
              {data?.spaces?.data.length > 0 ? data.spaces.data.map((space) => {
                return (
                  <Tag key={space.id} style={{ width: 140, textAlign: 'center' }}>
                    <EllipsisRow tooltipPlacement="bottom" data={space.name} />
                  </Tag>
                );
              }) : EMPTY_VALUE_PLACEHOLDER}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Card>
    </div>
  );
};

UserDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isGlobalAdmin: PropTypes.bool,
};

UserDetailsCard.defaultProps = {
  isGlobalAdmin: false,
};

export default UserDetailsCard;
