import React, { useEffect, useState } from 'react';
import { BackTop, Breadcrumb, Button, Card, Col, Layout, PageHeader, Row } from 'antd';
import 'moment/locale/ru';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import TicketHeader from './TicketDetailsHeader';
import TicketDetailsTable from './TicketDetailsTable';
import PollingNotificationModal from '../../../../components/PollingNotificationModal';
import {
  getTicketDetailsData,
  getTicketDetailsEventsData,
  resetTicketDetailsData,
  resetTicketDetailsEventsData,
  selectData,
  selectLoading,
  selectTicketEventsData,
  selectTicketEventsLoading, selectTicketEventsPolling,
} from '../../feature/ticketDetailsSlice';
import { exportToCsv } from '../../../../lib/exportToCsv';
import urlPageTickets from '../../../../urls/urlPageTickets';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const { Content } = Layout;

const PageTicketDetails = () => {
  const dispatch = useDispatch();
  const { projectSettings } = useApp();
  const { id } = useParams();
  const ticket = useSelector(selectData);
  const isLoading = useSelector(selectLoading);

  const ticketEvents = useSelector(selectTicketEventsData);
  const isEventsLoading = useSelector(selectTicketEventsLoading);
  const getEventsPolling = useSelector(selectTicketEventsPolling);
  const [ isActiveNotificationModal, setToggleNotificationModal ] = useState(false);

  const contactUsEventCountry = !isEmpty(ticketEvents) ? ticketEvents.filter((event) => event.name === 'contact_us_click')[0]?.country : EMPTY_VALUE_PLACEHOLDER;

  useEffect(() => {
    if (id !== ':id') {
      dispatch(getTicketDetailsData(id));
    }
    return () => {
      dispatch(resetTicketDetailsData());
      dispatch(resetTicketDetailsEventsData());
    };
  }, [ id ]);

  const pollingInterval = 30000; //30sec

  useEffect(() => {
    const ticketEventsIntervalId = setInterval(() => {
      return getEventsPolling ? dispatch(getTicketDetailsEventsData(id)) : null;
    }, pollingInterval);

    return () => {
      clearInterval(ticketEventsIntervalId);
    };
  }, [ getEventsPolling ]);

  useEffect(() => {
    if (!isEmpty(ticketEvents) || !getEventsPolling) {
      setToggleNotificationModal(false);
    }
  }, [ ticketEvents ]);

  const handleGetTicketEvents = () => {
    dispatch(getTicketDetailsEventsData(id));
    setToggleNotificationModal(true);
  };

  const preparedExportData = () => !isEmpty(ticketEvents) && ticketEvents.map((event) => ({ ...event, datetime: event.datetime.date, param: event.param || EMPTY_VALUE_PLACEHOLDER }));

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageTickets()}>
            Tickets list
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Ticket details</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        className="site-page-header"
        extra={!isEmpty(ticketEvents) ? [
          <Button
            type='secondary'
            size='small'
            key='go_to_event'
            disabled={isEmpty(contactUsEventCountry)}
            className='bordered-green-btn'
            onClick={() => {
              document.getElementById('contact_us_event').scrollIntoView({ block: 'center', behavior: 'smooth' });
            }}
          >
            Go to - Contact us click event
          </Button>,
          <Button
            type="primary"
            size='small'
            key='export_csv'
            disabled={isEmpty(preparedExportData())}
            onClick={() => {
              exportToCsv(preparedExportData(), `ticket_events_${id}`);
            }}
          >
            Export to CSV
          </Button>,
        ] : [
          <Button
            type="primary"
            size='small'
            key='export_csv'
            loading={getEventsPolling || isEventsLoading}
            onClick={handleGetTicketEvents}
          >
            Get ticket events
          </Button>,
        ]}
      />
      <Content className='layout-content'>
        <Row>
          <Col xs={24} md={24} lg={8} style={{ padding: '0 5px' }}>
            <div className="layout-content">
              <TicketHeader
                ticket={ticket}
                isLoading={isLoading}
                contactUsEventCountry={contactUsEventCountry}
              />
            </div>
          </Col>
          <Col xs={24} md={24} lg={16} style={{ padding: '0 5px' }}>
            <Content className='layout-content__hidden'>
              <Card className='card-has-table'>
                <div id='ticketTopCmp'>
                  <TicketDetailsTable
                    isLoading={getEventsPolling || isEventsLoading}
                    events={ticketEvents}
                  />
                  <BackTop
                    visibilityHeight={150}
                    target={() => document.getElementById('ticketTopCmp')}
                  >
                    <div className='back-top-button'>
                      <VerticalAlignTopOutlined />
                    </div>
                  </BackTop>
                </div>
              </Card>
            </Content>
          </Col>
        </Row>
      </Content>

      <PollingNotificationModal
        title='Ticket events notification'
        isActiveModal={isActiveNotificationModal}
        toggleModal={setToggleNotificationModal}
      />
    </>
  );
};

export default PageTicketDetails;
