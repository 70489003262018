import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import { setUrlParams } from '../../../../lib/setUrlParams';
import ProjectsService from '../services/projectsService';
import { mkNotification } from '../../../../lib/mkNotification';



const initialState = {
  projectsList: [],
  projectsPagination: {},
  loading: false,
};

const projectsService = new ProjectsService(Api);

export const getProjectsListData = createAsyncThunk(
  'projects/getProjectsList',
  async (values) => {
    const response = await projectsService.getProjectsList(values);

    if (!isEmpty(values)) {
      setUrlParams(values);
    }

    return response;
  },
);

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (projectId) => {
    const response = await projectsService.deleteProject(projectId);

    return response.data;
  },
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetProjectsListData: (state) => {
      state.projectsList = [];
      state.projectsPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.projectsList = action.payload.data;
        state.spaces = action.payload.meta?.custom?.spaces;
        state.projectsPagination = action.payload.meta.pagination;
      })
      .addCase(getProjectsListData.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state) => {
        state.loading = false;
        mkNotification('success', 'Project is successfully deleted.');
      })
      .addCase(deleteProject.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetProjectsListData } = projectsSlice.actions;

// selectors
export const selectProjectsLoading = (state) => state.projects.loading;
export const selectProjectsList = (state) => state.projects.projectsList;
export const selectSpacesList = (state) => state.projects.spaces;
export const selectProjectsPagination = (state) => state.projects.projectsPagination;


// reducer
export default projectsSlice.reducer;
