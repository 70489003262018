import moment from 'moment';
import React from 'react';
import { Button, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { RiHandCoinLine, RiImage2Line, RiShoppingBasketLine } from 'react-icons/ri';
import urlPageUserProfileDetailsDeviceAbTests from '../../../../urls/urlPageUserProfileDetailsDeviceAbTests';
import urlPageUserProfileDetailsDevicePurchases from '../../../../urls/urlPageUserProfileDetailsDevicePurchases';
import urlPageUserProfileDetailsDevicePics from '../../../../urls/urlPageUserProfileDetailsDevicePics';
import urlPageUserProfileDetailsDevice from '../../../../urls/urlPageUserProfileDetailsDevice';
import { hasRights, Permissions } from '../../../../const/permissions';
import { USER_PROFILES_PLATFORMS_ICONS } from '../PageUserProfiles/PageUserProfiles.const';
import { FEATURE_SHOW_DEVICE_ABTESTS } from '../../../../const/features';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const { Paragraph } = Typography;

export const USER_PROFILE_DEVICES_TABLE_COLUMNS = (userId, devicesListDataLoading) => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    sorter: (_a, _b) => Number(_a.id) - Number(_b.id),
    render: (value) => (
      <Tooltip placement="top" title='Device ID'>
        <Paragraph ellipsis={{ rows: 1, symbol: '...' }} style={{ cursor: 'default' }}>
          <Link to={urlPageUserProfileDetailsDevice({ userId, deviceId: value })}>
            {value || EMPTY_VALUE_PLACEHOLDER}
          </Link>
        </Paragraph>
      </Tooltip>
    ),
  },
  {
    title: 'App version',
    align: 'center',
    render: (item) => {
      return (
        <>
          <div>{item?.app?.version || EMPTY_VALUE_PLACEHOLDER}</div>
        </>
      );
    },
  },
  // {
  //   title: 'Manufacturer',
  //   align: 'center',
  //   render: (item) => {
  //     return (
  //       <>
  //         <div>{item?.info?.manufacturer || EMPTY_VALUE_PLACEHOLDER}</div>
  //       </>
  //     );
  //   },
  // },
  // {
  //   title: 'Model',
  //   align: 'center',
  //   render: (item) => {
  //     return (
  //       <>
  //         {!isEmpty(item?.info?.model) ? (
  //           <Link to={urlPageUserProfileDetailsDevice({ userId, deviceId: item.id })}>
  //             {item?.info?.model}
  //           </Link>
  //         ) : (<div>{item?.info?.model || EMPTY_VALUE_PLACEHOLDER}</div>)}
  //       </>
  //     );
  //   },
  // },
  {
    title: 'Platform',
    dataIndex: 'platform',
    align: 'center',
    render: (value) => (
      <div>{USER_PROFILES_PLATFORMS_ICONS(value.platform)}</div>
    ),
  },
  {
    title: 'Country',
    dataIndex: 'geo',
    align: 'center',
    render: (value) => (
      <div>{value.country || EMPTY_VALUE_PLACEHOLDER}</div>
    ),
  },
  {
    title: 'Region',
    dataIndex: 'geo',
    align: 'center',
    render: (value) => (
      <div>{value.region || EMPTY_VALUE_PLACEHOLDER}</div>
    ),
  },
  {
    title: 'Install date',
    dataIndex: 'installedAt',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.installedAt) >= moment(_b.installedAt) ? 1 : -1),
    render: (value) => <div>{renderDateField(value)}</div>,
  },
  ...(hasRights([
    Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_AB_TESTS,
    Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PURCHASES,
    Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PICTURES,
  ]) ?
    [ {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: '15%',
      render: (item) => {
        return (
          <>
            {hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_AB_TESTS ]) && FEATURE_SHOW_DEVICE_ABTESTS && (
              <Tooltip placement="top" title='Ab-tests'>
                <Button size="small" type='primary' className='btn-with-side-margin' danger>
                  <Link
                    to={urlPageUserProfileDetailsDeviceAbTests({ userId, deviceId: item.id })}
                    state={{ deviceData: item, isLoading: devicesListDataLoading }}
                  >
                    <RiHandCoinLine />
                  </Link>
                </Button>
              </Tooltip>
            )}

            {hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PURCHASES ]) && (
              <Tooltip placement="top" title='Purchases'>
                <Button size="small" type='primary' className='btn-with-side-margin'>
                  <Link
                    to={urlPageUserProfileDetailsDevicePurchases({ userId, deviceId: item.id })}
                    state={{ deviceData: item, isLoading: devicesListDataLoading }}
                  >
                    <RiShoppingBasketLine />
                  </Link>
                </Button>
              </Tooltip>
            )}

            {hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PICTURES ]) && (
              <Tooltip placement="top" title='Pictures'>
                <Button size="small" type='primary' className='btn-with-side-margin warning-btn'>
                  <Link
                    to={urlPageUserProfileDetailsDevicePics({ userId, deviceId: item.id })}
                    state={{ deviceData: item, isLoading: devicesListDataLoading }}
                  >
                    <RiImage2Line />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    } ] : []
  ),
];
