import React, { useEffect } from 'react';
import { BackTop, Breadcrumb, Button, Card, Col, Layout, Row, Table } from 'antd';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Link } from 'react-router-dom';
import MonitorFilter from '../../components/MonitorFilter';
import MonitorCharts from '../../components/MonitorCharts';
import { TABLE_APP_COLUMNS, preparedTableValues } from './PageApplicationMonitor.const';
import { exportToCsv } from '../../../../lib/exportToCsv';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { getObjectAverageValues } from '../../../../lib/getObjectAverageValues';
import { getAxis } from '../../../../lib/getAxis';
import { getPreparedChartsData } from '../../../../lib/getPreparedChartsData';
import {
  getAppMonitoringData,
  resetAppsMonitoringData,
  selectAppsData,
  selectAppsLoading,
} from '../../feature/applicationMonitorSlice';
import ChartsTooltip from '../../components/ChartsTooltip';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Content } = Layout;


const PageApplicationMonitor = () => {
  const { projectSettings } = useApp();
  const dispatch = useDispatch();
  const data = useSelector(selectAppsData);
  const isLoading = useSelector(selectAppsLoading);

  const arrayData = getPreparedChartsData(data, {
    app_install: 0,
    app_confirmation: 0,
    prev_app_install: 0,
    prev_app_confirmation: 0,
  }, '', true);
  const appMonitorData = arrayData.slice(1, arrayData.length);
  const avgChartValues = getObjectAverageValues(arrayData);
  const axisData = getAxis(avgChartValues);
  const tableAppData = preparedTableValues(appMonitorData);
  const tableTitle = 'App install per app confirm';

  const handleSetFilter = (values) => {
    dispatch(getAppMonitoringData(values));
  };

  useEffect(() => {
    if (!isEmpty(window.location.search)) {
      handleSetFilter(getObjectValuesByUrlParams());
    } else {
      dispatch(getAppMonitoringData());
    }
    return () => {
      dispatch(resetAppsMonitoringData());
    };
  }, []);

  const initialFilterValues = isEmpty(window.location.search) ? {
    app_version: '',
    platform: null,
    countries: null,
    data_grouping: 'hourly',
    from: moment().subtract(3, 'd').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  } : getObjectValuesByUrlParams();


  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Application monitoring</Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} lg={6}>
            <div className="layout-content">
              <CollapsibleFilterWr
                title='Filter'
                keyValue='app-monitor-filter'
                maxDeviceWidth={992}
              >
                <MonitorFilter
                  onSubmit={handleSetFilter}
                  isSubmitting={isLoading}
                  initialValues={initialFilterValues}
                />
              </CollapsibleFilterWr>
            </div>
          </Col>
          <Col xs={24} lg={18}>
            <Content className='layout-content' id='appsTopCmp'>
              <Card className='charts-container'>
                <ChartsTooltip />
                <MonitorCharts
                  isLoading={isLoading}
                  mainData={appMonitorData}
                  axisData={axisData}
                />
              </Card>

              <Card className='card-has-table'>
                <div className='table-title-content-wr da-mb-10'>
                  <div className='table-title'>{tableTitle}</div>
                  <Button
                    type="primary"
                    size='small'
                    disabled={isEmpty(tableAppData)}
                    onClick={() => {
                      exportToCsv(tableAppData, `${tableTitle}_${moment().format('DD/MM/YYYY')}`);
                    }}
                  >
                    Export to CSV
                  </Button>
                </div>

                <Table
                  loading={isLoading}
                  columns={TABLE_APP_COLUMNS}
                  rowKey="date"
                  dataSource={tableAppData}
                  pagination={{
                    position: [ 'bottomCenter' ],
                    pageSizeOptions: [ '10', '25', '50', '100' ],
                    size: 'small',
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                  }}
                  scroll={{ y: 240 }}
                />
              </Card>
            </Content>
            <BackTop
              visibilityHeight={150}
              target={() => document.getElementById('appsTopCmp')}
            >
              <div className='back-top-button'>
                <VerticalAlignTopOutlined />
              </div>
            </BackTop>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageApplicationMonitor;
