import React from 'react';
import { Card, Row, Col, Progress, Empty, Spin, Skeleton } from 'antd';
import { BarChart, Bar, Legend, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine, Brush } from 'recharts';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import abbreviate from 'number-abbreviate';
import { COLUMN_CHART_COLOR_MAP } from '../../../Monitor/components/MonitorCharts/MonitorCharts.const';
import { useCheckMobileScreen } from '../../../../app/hooks';
import { getObjectAverageValues } from '../../../../lib/getObjectAverageValues';
import { getPreparedChartsData } from '../../../../lib/getPreparedChartsData';



const WidgetColumnCard = ({ data, isLoading }) => {
  const preparedChartsData = getPreparedChartsData(data, {});
  const mainData = preparedChartsData.slice(1, preparedChartsData.length);
  const avgChartValues = getObjectAverageValues(preparedChartsData);
  const axisKeys = (values) => Object.keys(values);

  const Axis = preparedChartsData[0];
  const getTotalValues = (obj) => Object.values(obj).reduce((prev, curr) => prev + curr, 0);
  const totalValues = getTotalValues(avgChartValues);

  const prepareProgressArr = (obj) => Object.keys(obj).map((key) => ({ name: key, value: obj[key] }));
  const progressData = sortBy(prepareProgressArr(avgChartValues), 'value').reverse(); //Sorted by value progress data.
  const getPercents = (total, value) => ((value / total) * 100).toFixed(1);

  return (
    <div className='column-card'>
      <Card className="da-border-color-black-40 da-card-6 da-chart-text-color">
        <Row>
          <Col className="da-mb-16" span={24}>
            <Row justify="space-between">
              <h4 className="da-mr-8">Problems overview</h4>
              <h3 className="da-badge-text da-mb-0 da-mr-8 da-text-color-black-80 da-text-color-dark-30">
                Last 7 days
              </h3>
            </Row>
          </Col>

          {!useCheckMobileScreen() && (
            <Col xl={18} md={24}>
              <Spin spinning={!!isLoading} tip="Loading charts...">
                {isEmpty(mainData) ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <span>No charts data available</span>
                    }
                  />
                ) : (
                  <div id="column-card" className='widget-charts-column-container'>
                    <ResponsiveContainer width="100%" height="100%" debounce={1}>
                      <BarChart
                        width={500}
                        height={300}
                        data={mainData}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis tickFormatter={abbreviate} />
                        <Tooltip />
                        <Legend verticalAlign="top" wrapperStyle={{ top: '0' }} />
                        <ReferenceLine y={0} stroke="#000" />
                        <Brush dataKey="date" height={30} stroke="#666666" />
                        {axisKeys(avgChartValues).sort().map((axisField, index) => (
                          <Bar
                            key={axisField}
                            dataKey={axisField}
                            stroke={COLUMN_CHART_COLOR_MAP[index]}
                            fill={COLUMN_CHART_COLOR_MAP[index]}
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                )}
              </Spin>
            </Col>
          )}
          <Col xl={6} md={24} sm={24} xs={24}>
            <div className='progress-container'>
              {!isEmpty(Axis) && !isEmpty(mainData) ? (
                <>
                  {progressData.map((item, index) => (
                    <div className='da-m-6' key={item[index]}>
                      <Row justify='space-between'>
                        <Col>
                          <div className='da-ml-12'>{item.name}</div>
                        </Col>
                        <Col>
                          <h5>{item.value}</h5>
                        </Col>
                      </Row>
                      <Progress
                        size="small"
                        status="active"
                        percent={getPercents(totalValues, item.value)}
                        strokeColor={COLUMN_CHART_COLOR_MAP[index]}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <Skeleton paragraph={{ rows: 7 }} />
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

WidgetColumnCard.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array.isRequired,
};

WidgetColumnCard.defaultProps = {
  isLoading: false,
};

export default WidgetColumnCard;
