import { Empty } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';



const AbTestDetailsGroupsStatisticsChart = ({ data }) => {
  return (
    <>
      {!isEmpty(data) ? (
        <div id="column-card" className='abtest-charts-column-container'>
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="group" />
              <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
              <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
              <Tooltip />
              <Legend verticalAlign="top" wrapperStyle={{ top: '0' }} />
              <Bar yAxisId="left" dataKey="uniq_users" fill="#8884d8" />
              <Bar yAxisId="right" dataKey="user_percentage" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

AbTestDetailsGroupsStatisticsChart.propTypes = {
  data: PropTypes.array.isRequired,
};


export default AbTestDetailsGroupsStatisticsChart;
