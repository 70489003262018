import { Badge, Button, Row, Tag, Tooltip, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import React from 'react';
import capitalize from 'lodash/capitalize';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import { hasRights, Permissions } from '../../../../const/permissions';
import {
  REQUEST_ACTION_COLOR,
  REQUEST_STATUS_COLOR,
  REQUESTS_STATUS_TRANSLATIONS,
} from '../PageProjectUserDetails/PageProjectUserDetails.const';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';



const { Paragraph } = Typography;


export const requestListColumns = (handleRedirectToUserDetails) => [
  {
    title: 'ID',
    align: 'center',
    width: '7%',
    dataIndex: 'id',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
  },
  {
    title: 'Request ID',
    align: 'center',
    dataIndex: 'request_id',
    render: (value) => (
      <Tooltip placement="top" title={value || EMPTY_VALUE_PLACEHOLDER}>
        <Paragraph ellipsis={{ rows: 1, symbol: '...' }} style={{ cursor: 'default' }}>
          {value || EMPTY_VALUE_PLACEHOLDER}
        </Paragraph>
      </Tooltip>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'center',
    render: (value) => <Tag color={REQUEST_ACTION_COLOR(value)}>{capitalize(value) || EMPTY_VALUE_PLACEHOLDER}</Tag>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (value) => <Badge color={REQUEST_STATUS_COLOR(value)} text={REQUESTS_STATUS_TRANSLATIONS(value)} />,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('updated_at'),
  },
  {
    title: 'Executed at',
    dataIndex: 'executed_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('executed_at'),
  },
  ...(hasRights([ Permissions.REQUESTS.VIEW_DETAILS ]) ?
    [ {
      title: '',
      key: 'action-buttons',
      align: 'center',
      width: '10%',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            {hasRights([ Permissions.REQUESTS.VIEW_DETAILS ]) && (
              <Tooltip placement="top" title='User details'>
                <Button
                  size="small"
                  type='primary'
                  className='btn-with-side-margin'
                  onClick={() => handleRedirectToUserDetails(item)}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
