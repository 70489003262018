import { setRequestParams } from '../../../lib/setRequestParams';



export default class UserProfilesService {
  constructor (Api) {
    this.api = Api;
  }

  getUserProfilesList (params) {
    return this.api.get(`profiles/users${setRequestParams(params)}`);
  }
}
