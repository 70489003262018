import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LOADING_TIP_PLACEHOLDER } from '../../const/system';



const TPageAppLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const AppLoader = () => {
  return (
    <TPageAppLoaderContainer>
      <Spin tip={LOADING_TIP_PLACEHOLDER} />
    </TPageAppLoaderContainer>
  );
};


export default AppLoader;
