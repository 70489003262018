import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { init } from '@sentry/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import store from './app/store';
import Router from './app/root/Router';
import Config from './config';
import reportWebVitals from './reportWebVitals';
import AppContextProvider from './app/context/AppContext';
import './assets/less/yoda-theme.less';



const root = createRoot(document.getElementById('root'));


init({
  dsn: Config.VITE_SENTRY_DSN,
  environment: Config.VITE_SENTRY_ENV,
});

root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <GoogleOAuthProvider clientId={Config.VITE_GOOGLE_CLIENT_ID}>
        <AppContextProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppContextProvider>
      </GoogleOAuthProvider>
    </Provider>
  </Suspense>,
);

// eslint-disable-next-line no-console
console.log('Frontend version 0.17.0:', Config.LAST_COMMIT_DATA);

reportWebVitals();
