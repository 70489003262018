import { setRequestParams } from '../../../lib/setRequestParams';



export default class AdsMonitoringService {
  constructor (Api) {
    this.api = Api;
  }

  getMonitoring (params) {
    return this.api.get(`monitoring/advertise${setRequestParams(params)}`);
  }

  getNetworks () {
    return this.api.get('monitoring/advertise/networks');
  }
}
