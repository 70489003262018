import isEmpty from 'lodash/isEmpty';
import addUrlParam from './addUrlParam';



export const addRequestParams = (url, params) => {
  const urlObject = new URL(url, window.location.href);
  const { searchParams } = urlObject;

  if (!isEmpty(params)) {
    return `${url}${searchParams.size < 1 ? '?' : '&'}${addUrlParam(params)}`;
  }
  return url;
};
