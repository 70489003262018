import React from 'react';
import isNumber from 'lodash/isNumber';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../const/system';



export const PLATFORM_OVERVIEW_WIDGET_COLUMNS = [
  {
    title: 'Os name',
    dataIndex: 'os_name',
    align: 'center',
    width: '15%',
  },
  {
    title: 'App version',
    dataIndex: 'app_version',
    align: 'center',
    width: '15%',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'New users',
    dataIndex: 'new_users',
    align: 'center',
    width: '15%',
    render: (value) => <div>{isNumber(value) ? value : EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Games per session',
    dataIndex: 'games_per_session',
    align: 'center',
    width: '15%',
    render: (value) => <div>{isNumber(value) ? value : EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Sessions per user',
    dataIndex: 'sessions_per_user',
    align: 'center',
    width: '15%',
    render: (value) => <div>{isNumber(value) ? value : EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Impression per user',
    dataIndex: 'impressions_per_user',
    align: 'center',
    width: '15%',
    render: (value) => <div>{isNumber(value) ? value : EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];


export const TICKETS_BY_PLATFORM_WIDGET_COLUMNS = [
  {
    title: 'Platform',
    dataIndex: 'platform',
    align: 'center',
    width: '50%',
    render: (value) => <div style={{ overflowWrap: 'anywhere' }}>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Count',
    dataIndex: 'count',
    align: 'center',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];

export const TICKETS_CHANNELS_WIDGET_COLUMNS = [
  {
    title: 'Channel',
    dataIndex: 'channel',
    align: 'center',
  },
  {
    title: 'Count',
    dataIndex: 'count',
    align: 'center',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];

export const TICKETS_BY_APP_VERSION_WIDGET_COLUMNS = [
  {
    title: 'Version',
    dataIndex: 'version',
    align: 'center',
  },
  {
    title: 'Count',
    dataIndex: 'count',
    align: 'center',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];
