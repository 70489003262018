import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';
import MixinsExperimentDetailsService from '../services/mixinsExperimentDetailsService';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  experimentDetails: {},
  errors: [],
  experimentLoading: false,
  formEditExperimentLoading: false,
  formEditExperimentErrors: [],
  addExperimentCommentFormErrors: [],
  formCreateExperimentMixinLoading: false,
  formCreateExperimentMixinErrors: {},
  publishSandboxLoading: false,
  toggleAssigneeLoading: false,
  togglePauseLoading: false,
  sendExperimentWithStatusLoading: false,
  returnExperimentFormErrors: {},
};

const experimentDetailsService = new MixinsExperimentDetailsService(Api);

export const getExperimentDetails = createAsyncThunk(
  'mixinsExperimentDetails/getExperimentDetails',
  async (testId, { rejectWithValue }) => {
    try {
      const response = await experimentDetailsService.getExperimentDetails(testId);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateExperimentDetails = createAsyncThunk(
  'mixinsExperimentDetails/updateExperimentDetails',
  async ({ testId, data }, { rejectWithValue }) => {
    try {
      const response = await experimentDetailsService.updateExperimentDetails({ testId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const sendExperimentWithStatus = createAsyncThunk(
  'mixinsExperimentDetails/toggleExperimentWithStatus',
  async ({ testId, data }, { rejectWithValue }) => {
    try {
      const response = await experimentDetailsService.toggleExperimentStatus({ testId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createExperimentMixin = createAsyncThunk(
  'mixinsExperimentDetails/createExperimentMixin',
  async ({ testId, data }, { rejectWithValue }) => {
    try {
      const response = await experimentDetailsService.createExperimentMixin({ testId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const publishExperimentToSandbox = createAsyncThunk(
  'mixinsExperimentDetails/publishToSandbox',
  async ({ testId, data }, { rejectWithValue }) => {
    try {
      const response = await experimentDetailsService.publishToSandbox({ testId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleAssigneeExperiment = createAsyncThunk(
  'mixinsExperimentDetails/toggleAssignExperiment',
  async ({ testId, data }, { rejectWithValue }) => {
    try {
      const response = await experimentDetailsService.toggleAssignExperiment({ testId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const togglePauseExperiment = createAsyncThunk(
  'mixinsExperimentDetails/togglePauseExperiment',
  async ({ testId, data }, { rejectWithValue }) => {
    try {
      const response = await experimentDetailsService.togglePauseExperiment({ testId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getExperimentTargetDetails = createAsyncThunk(
  'mixinsExperimentDetails/getExperimentTarget',
  async (testId, { rejectWithValue }) => {
    try {
      const response = await experimentDetailsService.getExperimentTargetDetails(testId);

      return response.data[0];
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const mixinsExperimentDetailsSlice = createSlice({
  name: 'mixinsExperimentDetails',
  initialState,
  reducers: {
    resetExperimentDetailsData: (state) => {
      state.experimentDetails = {};
    },
    resetCreateExperimentMixinErrors: (state) => {
      state.formCreateExperimentMixinErrors = {};
    },
    resetReturnExperimentErrors: (state) => {
      state.returnExperimentFormErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExperimentDetails.pending, (state) => {
        state.experimentLoading = true;
      })
      .addCase(getExperimentDetails.fulfilled, (state, action) => {
        state.experimentLoading = false;
        state.experimentDetails = action.payload;
      })
      .addCase(getExperimentDetails.rejected, (state) => {
        state.experimentLoading = false;
      });

    builder
      .addCase(updateExperimentDetails.pending, (state) => {
        state.formEditExperimentLoading = true;
      })
      .addCase(updateExperimentDetails.fulfilled, (state, action) => {
        state.formEditExperimentLoading = false;
        state.experimentDetails = action.payload;
        mkNotification('success', 'Experiment details is successfully updated.');
      })
      .addCase(updateExperimentDetails.rejected, (state, action) => {
        state.formEditExperimentLoading = false;
        state.formEditExperimentErrors = action.payload.errors;
      });

    builder
      .addCase(getExperimentTargetDetails.pending, (state) => {
        state.loadingExperimentTarget = true;
      })
      .addCase(getExperimentTargetDetails.fulfilled, (state, action) => {
        state.loadingExperimentTarget = false;
        state.configTargetDetails = action.payload;
      })
      .addCase(getExperimentTargetDetails.rejected, (state, action) => {
        state.loadingExperimentTarget = false;
        state.errors = action.payload.data;
      });

    builder
      .addCase(createExperimentMixin.pending, (state) => {
        state.formCreateExperimentMixinLoading = true;
        state.formCreateExperimentMixinErrors = {};
      })
      .addCase(createExperimentMixin.fulfilled, (state) => {
        state.formCreateExperimentMixinLoading = false;
        mkNotification('success', 'Experiment mixin is successfully created.');
      })
      .addCase(createExperimentMixin.rejected, (state, action) => {
        state.formCreateExperimentMixinLoading = false;
        state.formCreateExperimentMixinErrors = action.payload.errors;
      });

    builder
      .addCase(publishExperimentToSandbox.pending, (state) => {
        state.publishSandboxLoading = true;
      })
      .addCase(publishExperimentToSandbox.fulfilled, (state) => {
        state.publishSandboxLoading = false;
        mkNotification('success', 'Recent experiment sandbox status is successfully updated.');
      })
      .addCase(publishExperimentToSandbox.rejected, (state) => {
        state.publishSandboxLoading = false;
      });

    builder
      .addCase(toggleAssigneeExperiment.pending, (state) => {
        state.toggleAssigneeLoading = true;
        state.returnExperimentFormErrors = {};
      })
      .addCase(toggleAssigneeExperiment.fulfilled, (state) => {
        state.toggleAssigneeLoading = false;
        mkNotification('success', 'Experiment owner is successfully updated.');
      })
      .addCase(toggleAssigneeExperiment.rejected, (state, action) => {
        state.toggleAssigneeLoading = false;
        state.returnExperimentFormErrors = action.payload.errors;
      });

    builder
      .addCase(togglePauseExperiment.pending, (state) => {
        state.togglePauseLoading = true;
      })
      .addCase(togglePauseExperiment.fulfilled, (state) => {
        state.togglePauseLoading = false;
        mkNotification('success', 'Experiment is successfully updated.');
      })
      .addCase(togglePauseExperiment.rejected, (state) => {
        state.togglePauseLoading = false;
      });

    builder
      .addCase(sendExperimentWithStatus.pending, (state) => {
        state.sendExperimentWithStatusLoading = true;
        state.returnExperimentFormErrors = {};
      })
      .addCase(sendExperimentWithStatus.fulfilled, (state) => {
        state.sendExperimentWithStatusLoading = false;
        mkNotification('success', 'Experiment status is successfully updated.');
      })
      .addCase(sendExperimentWithStatus.rejected, (state, action) => {
        state.sendExperimentWithStatusLoading = false;
        state.returnExperimentFormErrors = action.payload.errors;
      });
  },
});

// actions
export const {
  resetExperimentDetailsData,
  resetCreateExperimentMixinErrors,
  resetReturnExperimentErrors,
} = mixinsExperimentDetailsSlice.actions;

// selectors
export const selectExperimentDetailsLoading = (state) => state.mixinsExperimentDetails.experimentLoading;
export const selectExperimentDetailsData = (state) => state.mixinsExperimentDetails.experimentDetails;
export const selectErrors = (state) => state.mixinsExperimentDetails.errors;

export const selectUpdateExperimentDetailsLoading = (state) => state.mixinsExperimentDetails.formEditExperimentLoading;
export const selectUpdateExperimentDetailsErrors = (state) => state.mixinsExperimentDetails.formEditExperimentErrors;

export const selectFormCreateExperimentMixinLoading = (state) => state.mixinsExperimentDetails.formCreateExperimentMixinLoading;
export const selectFormCreateExperimentMixinErrors = (state) => state.mixinsExperimentDetails.formCreateExperimentMixinErrors;

export const selectPublishSandboxLoading = (state) => state.mixinsExperimentDetails.publishSandboxLoading;
export const selectToggleAssigneeLoading = (state) => state.mixinsExperimentDetails.toggleAssigneeLoading;
export const selectTogglePauseLoading = (state) => state.mixinsExperimentDetails.togglePauseLoading;
export const selectSendExperimentStatusLoading = (state) => state.mixinsExperimentDetails.sendExperimentWithStatusLoading;
export const selectReturnExperimentFormErrors = (state) => state.mixinsExperimentDetails.returnExperimentFormErrors;

// reducer
export default mixinsExperimentDetailsSlice.reducer;

