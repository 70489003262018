export default class DashboardService {
  constructor (Api) {
    this.api = Api;
  }

  getNewUsersData () {
    return this.api.get('widgets/new-users');
  }

  getGameSessionsData () {
    return this.api.get('widgets/game-sessions');
  }

  getSessionUsersData () {
    return this.api.get('widgets/session-users');
  }

  getImpressionsUsersData () {
    return this.api.get('widgets/impressions-users');
  }

  getPlatformOverviewData () {
    return this.api.get('widgets/platform-overview');
  }

  getNewTicketsData () {
    return this.api.get('widgets/new-tickets');
  }

  getTicketsPerDayData () {
    return this.api.get('widgets/tickets-per-day');
  }

  getTicketsByPlatformData () {
    return this.api.get('widgets/tickets-by-platform');
  }

  getTicketsChannelsData () {
    return this.api.get('widgets/tickets-channels');
  }

  getTicketsByAppVersionData () {
    return this.api.get('widgets/tickets-app-version');
  }

  getProblemsOverviewData () {
    return this.api.get('widgets/problems-overview');
  }

  getBeeActiveUsersData () {
    return this.api.get('widgets/bee/active-users');
  }

  getFinishedProjectsData () {
    return this.api.get('widgets/bee/finished-projects');
  }

  getReopenedProjectsData () {
    return this.api.get('widgets/bee/reopened-projects');
  }

  getAvgTimeData () {
    return this.api.get('widgets/bee/average-time');
  }

  getAvgTapsData () {
    return this.api.get('widgets/bee/average-taps');
  }

  getActiveProjectsData () {
    return this.api.get('widgets/bee/active-projects');
  }

  getTimeSpendingData () {
    return this.api.get('widgets/bee/time-spending');
  }
}
