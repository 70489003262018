import { InfoCircle } from 'react-iconly';
import { Button, Modal } from 'antd';
import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';



const ModalConfirmPublishConfiguration = ({
  isActiveModal,
  toggleModal,
  configurationId,
  handleSubmit,
}) => {
  return (
    <Modal
      title={
        <div className='da-d-flex-center'>
          <InfoCircle set="curved" />
          &nbsp;Notification
        </div>
      }
      width={500}
      centered
      closable
      destroyOnClose
      visible={isActiveModal}
      onCancel={() => toggleModal(false)}
      footer={[
        <Button
          key="cancel"
          onClick={() => toggleModal(false)}
        >
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={() => {
            toggleModal(false);
            handleSubmit({ configurationId });
          }}
        >
          OK
        </Button>,
      ]}
    >
      <div>Are you sure you want to publish recent configuration?</div>
    </Modal>
  );
};

ModalConfirmPublishConfiguration.propTypes = {
  isActiveModal: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  configurationId: oneOfType([ PropTypes.string, PropTypes.number ]),
};

ModalConfirmPublishConfiguration.defaultProps = {
  isActiveModal: false,
  configurationId: null,
};

export default ModalConfirmPublishConfiguration;
