import isEmpty from 'lodash/isEmpty';



export const getUniqueData = (data, uniqueField) => {
  let uniqueSet = [];

  if (!isEmpty(data)) {
    uniqueSet = new Set(data.flatMap((obj) => obj[uniqueField]));
  }

  return Array.from(uniqueSet);
};

