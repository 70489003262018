import moment from 'moment';
import React from 'react';
import { Badge, Tooltip, Typography } from 'antd';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const { Paragraph } = Typography;


export const PURCHASES_STATUS_COLOR = (status) => {
  switch (status) {
    case 'valid': {
      return 'green';
    }
    case 'sendInAdjust': {
      return 'orange';
    }
    case 'notValid': {
      return 'red';
    }
    default: {
      return '';
    }
  }
};

export const TRANSLATE_PURCHASE_STATUS = (status) => {
  switch (status) {
    case 'valid': {
      return 'Valid';
    }
    case 'sendInAdjust': {
      return 'Send in adjust';
    }
    case 'notValid': {
      return 'Not valid';
    }
    default: {
      return EMPTY_VALUE_PLACEHOLDER;
    }
  }
};

export const USER_PROFILE_PURCHASES_TABLE_COLUMNS = [
  {
    title: 'Id',
    dataIndex: 'id',
    width: '20%',
    align: 'center',
    sorter: (_a, _b) => Number(_a.id) - Number(_b.id),
    render: (value) => (
      <Tooltip placement="top" title={value || EMPTY_VALUE_PLACEHOLDER}>
        <Paragraph ellipsis={{ rows: 1, symbol: '...' }} style={{ cursor: 'default' }}>
          {value || EMPTY_VALUE_PLACEHOLDER}
        </Paragraph>
      </Tooltip>
    ),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    align: 'center',
    sorter: (_a, _b) => Number(_a.price) - Number(_b.price),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (value) => {
      return (
        <Badge
          color={PURCHASES_STATUS_COLOR(value)}
          text={TRANSLATE_PURCHASE_STATUS(value)}
        />
      );
    },
  },
  {
    title: 'Paid at',
    dataIndex: 'paidAt',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.paidAt) >= moment(_b.paidAt) ? 1 : -1),
    render: (value) => <div>{renderDateField(value)}</div>,
  },
];

export const USER_PROFILE_PICTURES_TABLE_COLUMNS = [
  {
    title: 'Id',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: 'Finished at',
    dataIndex: 'finished_at',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.finished_at) >= moment(_b.finished_at) ? 1 : -1),
    render: (value) => <div>{renderDateField(value)}</div>,
  },
];

