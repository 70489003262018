import { Breadcrumb, Button, Card, Col, Layout, Row, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import urlPageProjectUserDetails from '../../../../urls/urlPageProjectUserDetails';
import { hasRights, Permissions } from '../../../../const/permissions';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import { useApp } from '../../../../app/context/AppContext';
import { useLocalStorage } from '../../../../app/hooks';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { requestListColumns } from './PageConsentProvider.const';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import FormProjectRequestsFilter from '../../forms/FormProjectRequestsFilter';
import HasRights from '../../../../components/HasRights';
import {
  getRequestsListData,
  resetRequestsListData,
  selectRequestsFilterErrors,
  selectRequestsList,
  selectRequestsLoading,
  selectRequestsPagination,
} from '../../feature/projectRequestsSlice';



const { Content } = Layout;



const PageConsentProvider = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { projectSettings } = useApp();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ filterValues, setFilterValues ] = useLocalStorage('consent_provider_filter', {});

  const dispatch = useDispatch();
  const requestsList = useSelector(selectRequestsList);
  const isLoading = useSelector(selectRequestsLoading);
  const pagination = useSelector(selectRequestsPagination);
  const requestFilterErrors = useSelector(selectRequestsFilterErrors);
  const initialValues = getObjectValuesByUrlParams(queryParams);


  const handleSubmitFilter = (values) => {
    setFilterValues(values);
    dispatch(getRequestsListData({ params: { ...values, ...mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting) } }));
  };


  useEffect(() => {
    handleSubmitFilter(filterValues);
    return () => {
      dispatch(resetRequestsListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting ]);


  const handleRedirectToUserDetails = (params) => {
    navigate(`${urlPageProjectUserDetails()}?request_id=${params.request_id}`);
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <HasRights allowedPermissions={[ Permissions.REQUESTS.VIEW_LIST ]}>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Requests list</Breadcrumb.Item>
          </Breadcrumb>

          <Col>
            {hasRights([ Permissions.REQUESTS.USER_DETAILS ]) && (
              <Button
                style={{ margin: '10px 0' }}
                size='middle'
                type="primary"
              >
                <Link to={urlPageProjectUserDetails()}>
                  Find user
                </Link>
              </Button>
            )}
          </Col>
        </Row>
      </Col>

      <Content className='layout-content'>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} md={24} lg={6}>
            <CollapsibleFilterWr
              title='Filter'
              keyValue='mixin-visualize-filter'
              maxDeviceWidth={992}
            >
              <Spin spinning={isLoading} tip="Loading options...">
                <FormProjectRequestsFilter
                  initialValues={initialValues}
                  isSubmitting={isLoading}
                  formErrors={requestFilterErrors}
                  onSubmit={handleSubmitFilter}
                />
              </Spin>
            </CollapsibleFilterWr>
          </Col>
          <Col xs={24} md={24} lg={18}>
            <Card>
              <Table
                sticky
                onChange={handleChangeTableParams}
                columns={requestListColumns(handleRedirectToUserDetails)}
                dataSource={requestsList}
                rowKey="id"
                loading={isLoading}
                pagination={{
                  size: 'small',
                  current: pagination?.current_page,
                  pageSize: pagination?.per_page,
                  total: pagination?.total,
                  position: [ 'bottomCenter' ],
                  pageSizeOptions: [ '10', '25', '50' ],
                  showSizeChanger: true,
                }}
                scroll={{ y: 550 }}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </HasRights>
  );
};


export default PageConsentProvider;
