import React from 'react';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Yup from '../../../../vendor/yup';



const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .min(50, 'Min 50 symbols required')
    .max(255, 'Max 255 symbols required')
    .required('Description field is required'),
});

const FormReturnExperiment = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit({ comment: values.comment.trim() });
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <FormItem
              className='ant-form-item-col'
              name="comment"
              label="Description:"
              required
            >
              <Input.TextArea
                name='comment'
                disabled={isSubmitting}
                placeholder='Specify comment'
                rows={7}
                showCount
                maxLength={255}
              />
            </FormItem>

            <ButtonsRow>
              <ButtonWr>
                <SubmitButton
                  style={{ width: '100%' }}
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Submit
                </SubmitButton>
              </ButtonWr>
            </ButtonsRow>
          </Form>
        );
      }}
    </Formik>
  );
};

FormReturnExperiment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

FormReturnExperiment.defaultProps = {
  formErrors: {},
};

export default FormReturnExperiment;
