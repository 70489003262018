import React, { useEffect } from 'react';
import { Breadcrumb, Card, Layout, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  getPermissionsListData,
  resetPermissionsListData,
  selectPermissionsList,
  selectPermissionsLoading,
} from '../../feature/permissionsSlice';
import { PERMISSIONS_TABLE_COLUMNS } from './PagePermissions.const';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { useApp } from '../../../../../app/context/AppContext';



const { Content } = Layout;

const PagePermissions = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { projectSettings } = useApp();
  const permissionsList = useSelector(selectPermissionsList);
  const isLoading = useSelector(selectPermissionsLoading);


  useEffect(() => {
    dispatch(getPermissionsListData());
    return () => {
      resetPermissionsListData();
    };
  }, [ pathname ]);

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Permissions</Breadcrumb.Item>
      </Breadcrumb>
      <Content className='layout-content'>
        <Card className='da-mb-24'>
          <Table
            sticky
            pagination={false}
            loading={isLoading}
            rowKey="id"
            columns={PERMISSIONS_TABLE_COLUMNS}
            dataSource={permissionsList}
            scroll={{ y: 250 }}
          />
        </Card>
      </Content>
    </>
  );
};

export default PagePermissions;
