import { setRequestParams } from '../../../lib/setRequestParams';



export default class ContactUsMonitoringService {
  constructor (Api) {
    this.api = Api;
  }

  getContactUsMonitoring (params) {
    return this.api.get(`monitoring/contact${setRequestParams(params)}`);
  }
}
