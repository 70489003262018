import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';
import { mkNotification } from '../../../lib/mkNotification';
import MixinExperimentMixinDetailsService from '../services/mixinExperimentMixinDetailsService';



const initialState = {
  experimentMixinDetailsData: {},
  loadingExperimentMixinDetails: false,
  updateExperimentMixinLoading: false,
  updateExperimentMixinErrors: {},
  experimentMixinTargetDetails: {},
  loadingExperimentMixinTarget: false,
  createExperimentMixinTargetingLoading: false,
  createExperimentMixinTargetingErrors: {},
  updateExperimentMixinTargetingLoading: false,
  updateExperimentMixinTargetingErrors: {},
};

const mixinExperimentMixinDetailsService = new MixinExperimentMixinDetailsService(Api);

export const getExperimentMixinDetails = createAsyncThunk(
  'experimentMixin/getExperimentMixin',
  async ({ testId, mixinId }, { rejectWithValue }) => {
    try {
      const response = await mixinExperimentMixinDetailsService.getExperimentMixinDetails({ testId, mixinId });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateExperimentMixinDetails = createAsyncThunk(
  'experimentMixin/updateExperimentMixinDetails',
  async ({ testId, mixinId, data }, { rejectWithValue }) => {
    try {
      const response = await mixinExperimentMixinDetailsService.updateExperimentMixinDetails({ testId, mixinId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getExperimentMixinTargetDetails = createAsyncThunk(
  'experimentMixin/getExperimentMixinTarget',
  async ({ testId, mixinId }, { rejectWithValue }) => {
    try {
      const response = await mixinExperimentMixinDetailsService.getExperimentMixinTargetDetails({ testId, mixinId });

      return response.data[0];
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createExperimentMixinTargeting = createAsyncThunk(
  'experimentMixin/createExperimentMixinTargeting',
  async ({ testId, mixinId, data }, { rejectWithValue }) => {
    try {
      const response = await mixinExperimentMixinDetailsService.createExperimentMixinTargeting({ testId, mixinId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateExperimentMixinTargeting = createAsyncThunk(
  'experimentMixin/updateExperimentMixinTargeting',
  async ({ testId, mixinId, targetingId, data }, { rejectWithValue }) => {
    try {
      const response = await mixinExperimentMixinDetailsService.updateExperimentMixinTargeting({ testId, mixinId, targetingId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const experimentMixinDetailsSlice = createSlice({
  name: 'mixinsExperimentMixinDetails',
  initialState,
  reducers: {
    resetExperimentMixinDetailsData: (state) => {
      state.experimentMixinDetailsData = {};
    },
    resetExperimentMixinTargetDetailsData: (state) => {
      state.experimentMixinTargetDetails = {};
    },
    resetUpdateExperimentMixinErrors: (state) => {
      state.updateExperimentMixinErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExperimentMixinDetails.pending, (state) => {
        state.loadingExperimentMixinDetails = true;
        state.loadingExperimentMixinDetailsErrors = {};
      })
      .addCase(getExperimentMixinDetails.fulfilled, (state, action) => {
        state.loadingExperimentMixinDetails = false;
        state.experimentMixinDetailsData = action.payload;
      })
      .addCase(getExperimentMixinDetails.rejected, (state, action) => {
        state.loadingExperimentMixinDetails = false;
        state.loadingExperimentMixinDetailsErrors = action.payload.errors;
      });

    builder
      .addCase(updateExperimentMixinDetails.pending, (state) => {
        state.updateExperimentMixinLoading = true;
        state.updateExperimentMixinErrors = {};
      })
      .addCase(updateExperimentMixinDetails.fulfilled, (state) => {
        state.updateExperimentMixinLoading = false;
        mkNotification('success', 'Experiment mixin details is successfully updated.');
      })
      .addCase(updateExperimentMixinDetails.rejected, (state, action) => {
        state.updateExperimentMixinLoading = false;
        state.updateExperimentMixinErrors = action.payload.errors;
      });

    builder
      .addCase(getExperimentMixinTargetDetails.pending, (state) => {
        state.loadingExperimentMixinTarget = true;
        state.errors = {};
      })
      .addCase(getExperimentMixinTargetDetails.fulfilled, (state, action) => {
        state.loadingExperimentMixinTarget = false;
        state.experimentMixinTargetDetails = action.payload;
      })
      .addCase(getExperimentMixinTargetDetails.rejected, (state, action) => {
        state.loadingExperimentMixinTarget = false;
        state.errors = action.payload.errors;
      });

    builder
      .addCase(createExperimentMixinTargeting.pending, (state) => {
        state.createExperimentMixinTargetingLoading = true;
        state.createExperimentMixinTargetingErrors = {};
      })
      .addCase(createExperimentMixinTargeting.fulfilled, (state) => {
        state.createExperimentMixinTargetingLoading = false;
        mkNotification('success', 'Experiment mixin targeting is successfully created.');
      })
      .addCase(createExperimentMixinTargeting.rejected, (state, action) => {
        state.createExperimentMixinTargetingLoading = false;
        state.createExperimentMixinTargetingErrors = action.payload.errors;
      });

    builder
      .addCase(updateExperimentMixinTargeting.pending, (state) => {
        state.updateExperimentMixinTargetingLoading = true;
        state.updateExperimentMixinTargetingErrors = {};
      })
      .addCase(updateExperimentMixinTargeting.fulfilled, (state) => {
        state.updateExperimentMixinTargetingLoading = false;
        mkNotification('success', 'Experiment mixin target is successfully updated.');
      })
      .addCase(updateExperimentMixinTargeting.rejected, (state, action) => {
        state.updateExperimentMixinTargetingLoading = false;
        state.updateExperimentMixinTargetingErrors = action.payload.errors;
      });
  },
});

// actions
export const {
  resetExperimentMixinDetailsData,
  resetExperimentMixinTargetDetailsData,
  resetUpdateExperimentMixinErrors,
} = experimentMixinDetailsSlice.actions;

// selectors
export const selectExperimentMixinDetailsLoading = (state) => state.mixinsExperimentMixinDetails.loadingExperimentMixinDetails;
export const selectExperimentMixinDetails = (state) => state.mixinsExperimentMixinDetails.experimentMixinDetailsData;

export const selectFormUpdateExperimentMixinErrors = (state) => state.mixinsExperimentMixinDetails.updateExperimentMixinErrors;
export const selectFormUpdateExperimentMixinLoading = (state) => state.mixinsExperimentMixinDetails.updateExperimentMixinLoading;

export const selectExperimentMixinTargetDetailsLoading = (state) => state.mixinsExperimentMixinDetails.loadingExperimentMixinTarget;
export const selectExperimentMixinTargetDetails = (state) => state.mixinsExperimentMixinDetails.experimentMixinTargetDetails;

export const selectCreateExperimentMixinTargetingLoading = (state) => state.mixinsExperimentMixinDetails.createExperimentMixinTargetingLoading;
export const selectCreateExperimentMixinTargetingErrors = (state) => state.mixinsExperimentMixinDetails.createExperimentMixinTargetingErrors;

export const selectUpdateExperimentMixinTargetingLoading = (state) => state.mixinsExperimentMixinDetails.updateExperimentMixinTargetingLoading;
export const selectUpdateExperimentMixinTargetingErrors = (state) => state.mixinsExperimentMixinDetails.updateExperimentMixinTargetingErrors;

// reducer
export default experimentMixinDetailsSlice.reducer;
