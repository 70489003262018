import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Col, Menu, Spin } from 'antd';
import { Paper, Bookmark } from 'react-iconly';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import menuImg from '../../../../assets/images/illustrations/menu-img.svg';
import urlPageUserProfileDetails from '../../../../urls/urlPageUserProfileDetails';
import urlPageUserProfileDetailsDevice from '../../../../urls/urlPageUserProfileDetailsDevice';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const UserProfileMenu = ({ onCloseDrawer, devices, isLoadingDevices }) => {
  const { userId } = useParams();
  const { pathname } = useLocation();

  const splitLocation = pathname.split('/');
  const menuIconClass = 'remix-icon da-mr-8';

  return (
    <Col flex="230px" className="da-profile-menu da-py-24">
      <div className="da-w-100">
        <Spin spinning={isLoadingDevices} tip="Menu loading...">
          <Menu
            mode="inline"
            className="da-w-100 da-profile-menu-body"
            theme='light'
          >
            <Menu.Item
              key="some_key"
              icon={<Paper set="curved" className={menuIconClass} />}
              className={`
              da-mb-16 da-pl-24 da-pr-32
              ${[ 'devices', 'ab-tests', 'purchases', 'pictures' ].includes(splitLocation[splitLocation.length - 1])
      ? 'ant-menu-item-selected'
      : 'ant-menu-item-selected-in-active'}
            `}
              onClick={onCloseDrawer}
            >
              <Link to={urlPageUserProfileDetails({ userId })}>
                All devices
              </Link>
            </Menu.Item>

            {!isEmpty(devices) && devices.map((item) => {
              return (
                <Menu.Item
                  key={item.id}
                  icon={<Bookmark set="curved" className={menuIconClass} />}
                  className={`
              da-mb-16 da-pl-24 da-pr-32
              ${splitLocation[splitLocation.length - 1] === `${item.id}`
                  ? 'ant-menu-item-selected'
                  : 'ant-menu-item-selected-in-active'}
            `}
                  onClick={onCloseDrawer}
                >
                  <Link to={urlPageUserProfileDetailsDevice({ userId, deviceId: item.id })}>{`${item.platform?.platform} id: ${item?.id || EMPTY_VALUE_PLACEHOLDER}`}</Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </Spin>
      </div>

      <div className="da-profile-menu-footer">
        <img src={menuImg} alt="menu_img" />
      </div>

    </Col>
  );
};


UserProfileMenu.propTypes = {
  onCloseDrawer: PropTypes.func,
  devices: PropTypes.array,
  isLoadingDevices: PropTypes.bool,
};

UserProfileMenu.defaultProps = {
  onCloseDrawer: () => {},
  devices: [],
  isLoadingDevices: false,
};

export default UserProfileMenu;
