import React, { useEffect, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Col, Drawer, Layout, Row } from 'antd';
import { RiCloseFill, RiMenuFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '../../../../app/hooks';
import urlPageUserProfiles from '../../../../urls/urlPageUserProfiles';
import UserProfileMenu from '../../components/UserProfileMenu';
import UserInformation from '../../components/UserInformation';
import HasRights from '../../../../components/HasRights';
import { Permissions } from '../../../../const/permissions';
import {
  getUserProfilesDevicesListData,
  selectDevicesList,
  selectDevicesLoading,
} from '../../feature/userProfileDevicesSlice';
import {
  getUserProfileDetailsData,
  selectUserDetails,
  selectUserDetailsLoading,
} from '../../feature/userProfileDetailsSlice';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Content } = Layout;

const PageUserProfileDetails = () => {
  const dispatch = useDispatch();
  const { projectSettings } = useApp();
  const { userId } = useParams();

  const [ visible, setVisible ] = useState(false);
  const hideMainMenu = useMediaQuery('(max-width: 992px)');
  const userData = useSelector(selectUserDetails);
  const userDataLoading = useSelector(selectUserDetailsLoading);
  const devicesListData = useSelector(selectDevicesList);
  const devicesListDataLoading = useSelector(selectDevicesLoading);

  const params = { page: 1, limit: 1000 };
  const showPublicQuery = false;

  useEffect(() => {
    dispatch(getUserProfileDetailsData(userId));
    dispatch(getUserProfilesDevicesListData({ userId, params, showPublicQuery }));
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <HasRights allowedPermissions={[ Permissions.USER_PROFILES.VIEW_ALL ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageUserProfiles()}>
            User profiles list
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>User details</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={[ 32, 32 ]} className="da-mb-16 da-overflow-scroll">
        <Drawer
          className="da-profile-mobile-menu"
          placement="left"
          closable
          onClose={onClose}
          visible={visible}
          closeIcon={
            <RiCloseFill
              className="remix-icon da-text-color-black-80"
              size={24}
            />
          }
        >
          <UserProfileMenu
            onCloseDrawer={onClose}
            devices={devicesListData}
            isLoadingDevices={devicesListDataLoading}
          />
        </Drawer>

        <Content>
          <Col span={24}>
            <Row className="da-profile-mobile-menu-btn da-bg-color-black-0 da-bg-color-dark-100 da-border-radius da-py-12 da-px-sm-8 da-px-24 da-mb-16">
              <Button
                className="da-p-0"
                type="text"
                onClick={showDrawer}
                icon={
                  <RiMenuFill
                    size={24}
                    className="remix-icon da-text-color-black-80 da-text-color-dark-30"
                  />
                }
              />
            </Row>

            <Row className="da-bg-color-black-0 da-bg-color-dark-100 da-border-radius da-pr-sm-16 da-pr-32">
              {!hideMainMenu && (
                <UserProfileMenu
                  devices={devicesListData}
                  isLoadingDevices={devicesListDataLoading}
                />
              )}

              <Col flex="1 1" className="da-p-sm-18 da-p-24 da-overflow-hidden" style={{ width: '100px' }}>
                <UserInformation
                  userData={userData}
                  userDataLoading={userDataLoading}
                />
                <Outlet />
              </Col>
            </Row>
          </Col>
        </Content>
      </Row>
    </HasRights>
  );
};

export default PageUserProfileDetails;
