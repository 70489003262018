import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Layout, Modal, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RiCloseFill } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import urlPageUsers from '../../../../../urls/urlPageUsers';
import urlPageSettingsUsers from '../../../../../urls/urlPageSettingsUsers';
import FormGlobalEditUserPolicies from './FormGlobalEditUserPolicies';
import FormCreateEditUser from '../PageUsers/FormCreateEditUser';
import UserDetailsCard from '../../components/UserDetailsCard';
import HasRights from '../../../../../components/HasRights';
import FormLocalEditUserPolicies from './FormLocalEditUserPolicies';
import { isFulfilledRequestStatus } from '../../../../../lib/isRequestSuccess';
import { hasRights, Permissions } from '../../../../../const/permissions';
import { GLOBAL_SETTINGS_PREFIX } from '../../../../../const/system';
import { useCheckMobileScreen, useCurrentSettingsScope } from '../../../../../app/hooks';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { useApp } from '../../../../../app/context/AppContext';
import {
  getGlobalPoliciesInitialValues,
  getLocalPoliciesInitialValues,
  mkLocalPoliciesOptions,
  mkPreparedRequestData,
} from './PageUserDetails.const';
import { selectFormCreateEditUserErrors } from '../../feature/usersSlice';
import {
  getUserDetails,
  updateUserPolicies,
  updateUserSpaces,
  selectUserDetails,
  resetUserDetails,
  selectUserEditFormLoading,
  selectUserDetailsLoading,
  selectErrors,
  selectUserDetailsPoliciesList,
} from '../../feature/userDetailsSlice';



const { Content } = Layout;

const PageUserDetails = () => {
  const dispatch = useDispatch();
  const { projectSettings } = useApp();
  const { userId } = useParams();
  const [ isActiveForm, setFormActive ] = useState(true);
  const [ isActiveUserModal, setToggleUpdateUserModal ] = useState(false);
  const [ savedFormValues, setSavedFormValues ] = useState({});
  const settingsScope = useCurrentSettingsScope();
  const isMobile = useCheckMobileScreen(575);
  const isGlobalAdmin = settingsScope === GLOBAL_SETTINGS_PREFIX;
  const includeParams =  [ isGlobalAdmin ? 'spaces.projects,policies,spaces' : 'spaces.projects,policies' ];

  const userDetails = useSelector(selectUserDetails);
  const userPoliciesList = useSelector(selectUserDetailsPoliciesList);
  const userSpaces = userPoliciesList?.spaces;
  const userPoliciesFormErrors = useSelector(selectErrors);
  const userPoliciesOptions = useSelector(selectUserDetailsPoliciesList);
  const isLoading = useSelector(selectUserDetailsLoading);
  const formUpdateUserErrors = useSelector(selectFormCreateEditUserErrors);
  const isLoadingUpdateUserSpaces = useSelector(selectUserEditFormLoading);

  const userPoliciesData = userDetails?.policies;
  const globalAdminSpaces = userDetails?.spaces?.data ?? [];

  const handleGetUserDetails = () => {
    dispatch(getUserDetails({ userId, include: includeParams }));
  };

  useEffect(() => {
    handleGetUserDetails();
    return () => {
      dispatch(resetUserDetails());
    };
  }, []);


  const toggleUpdateUserModal = () => {
    setToggleUpdateUserModal(!isActiveUserModal);
  };

  const handleUpdateUserSpaces = async (values) => {
    await dispatch(updateUserSpaces({ userId, spaceIds: values.space_ids }))
      .then((response) => {
        if (isFulfilledRequestStatus(response)) {
          handleGetUserDetails();
          toggleUpdateUserModal();
          if (!isActiveForm) {
            setFormActive(true);
          }
        }
      });
  };

  const handleUpdateUserPolicies = async (values) => {
    setSavedFormValues(values);
    const data = mkPreparedRequestData({ ...values }, isGlobalAdmin);

    await dispatch(updateUserPolicies({ userId, data }))
      .then((response) => {
        if (isFulfilledRequestStatus(response)) {
          handleGetUserDetails();
          setFormActive(true);
        }
      });
  };

  return (
    <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]}>
      <Modal
        title="Update user"
        width={416}
        centered
        destroyOnClose
        visible={isActiveUserModal}
        onCancel={toggleUpdateUserModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <FormCreateEditUser
          initialValues={{
            name: userDetails?.name,
            email: userDetails?.email,
            space_ids: userDetails?.spaces?.data.map((space) => space.id) ?? [],
          }}
          spacesOptions={userSpaces}
          formErrors={formUpdateUserErrors}
          onSubmit={handleUpdateUserSpaces}
          isSubmitting={isLoadingUpdateUserSpaces || isLoading}
          spaceKey={projectSettings?.spaceKey}
          isEdit
        />
      </Modal>

      <Row justify="space-between">
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={mkRedirectToDashboardPath(projectSettings)}>
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={isGlobalAdmin ? urlPageUsers() : urlPageSettingsUsers()}>
              Users list
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit user details</Breadcrumb.Item>
        </Breadcrumb>

        {hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER ]) && (
          <Button
            style={{ margin: '10px 0' }}
            size='middle'
            type="primary"
            loading={isLoading}
            onClick={toggleUpdateUserModal}
          >
            Update user
          </Button>
        )}
      </Row>

      <Content className='layout-content'>
        <Row>
          <Col xs={24} md={24} lg={8} style={{ padding: '0 5px' }}>
            <UserDetailsCard
              isLoading={isLoading || isLoadingUpdateUserSpaces}
              isGlobalAdmin={isGlobalAdmin}
              data={userDetails}
            />
          </Col>
          <Col xs={24} md={24} lg={16} style={{ padding: '0 5px' }}>
            <Card
              title='User policies'
              loading={isLoading || isLoadingUpdateUserSpaces}
            >
              {isGlobalAdmin ? (
                <FormGlobalEditUserPolicies
                  isMobile={isMobile}
                  initialValues={!isEmpty(userPoliciesFormErrors) ? savedFormValues : getGlobalPoliciesInitialValues(userPoliciesData)}
                  policiesOptions={userPoliciesOptions}
                  globalAdminSpaces={globalAdminSpaces}
                  formErrors={userPoliciesFormErrors}
                  isSubmitting={isLoading || isLoadingUpdateUserSpaces}
                  isEditDisabled={isActiveForm}
                  handleSetFormEdit={setFormActive}
                  onSubmit={handleUpdateUserPolicies}
                />
              ) : (
                <FormLocalEditUserPolicies
                  isMobile={isMobile}
                  initialValues={!isEmpty(userPoliciesFormErrors) ? savedFormValues : getLocalPoliciesInitialValues(userPoliciesData)}
                  policiesOptions={mkLocalPoliciesOptions(userPoliciesOptions, projectSettings)}
                  formErrors={userPoliciesFormErrors}
                  isSubmitting={isLoading || isLoadingUpdateUserSpaces}
                  isEditDisabled={isActiveForm}
                  handleSetFormEdit={setFormActive}
                  onSubmit={handleUpdateUserPolicies}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </HasRights>
  );
};

export default PageUserDetails;
