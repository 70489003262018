import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Card, Table, Tabs } from 'antd';
import { RiHandCoinLine, RiImage2Line, RiShoppingBasketLine } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import DeviceInformation from '../../components/DeviceInformation';
import {
  USER_PROFILE_PICTURES_TABLE_COLUMNS,
  USER_PROFILE_PURCHASES_TABLE_COLUMNS,
} from '../PageUserProfileDetailsDeviceDetails/PageUserProfileDetailsDeviceDetails.const';
import DeviceAbTestsDrawer from '../../components/DeviceAbTestsDrawer';
import DeviceCurrentAbTestCard from '../../components/DeviceCurrentAbTestCard';
import { hasRights, Permissions } from '../../../../const/permissions';
import {
  getUserProfileDeviceAbTestsData,
  getUserProfileDeviceDetailsData,
  getUserProfileDevicePicturesData,
  getUserProfileDevicePurchasesData,
  selectUserDeviceAbTests,
  selectUserDeviceAbTestsLoading,
  selectUserDeviceDetails,
  selectUserDeviceDetailsLoading,
  selectUserDevicePictures,
  selectUserDevicePicturesLoading,
  selectUserDevicePurchases,
  selectUserDevicePurchasesLoading,
} from '../../feature/userProfileDeviceDetailsSlice';
import { FEATURE_SHOW_DEVICE_ABTESTS } from '../../../../const/features';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { mkQueryParams } from '../../../../lib/mkQueryParams';



const { TabPane } = Tabs;


const PageUserProfileDevice = () => {
  const dispatch = useDispatch();
  const { deviceId } = useParams();
  const { pathname, search } = useLocation();
  const splitLocation = pathname.split('/');

  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);

  const [ isDrawerVisible, setDrawerVisible ] = useState(false);
  const [ clickedTabs, setClickedTabs ] = useState([]);
  const [ defaultTabActiveKey, setDefaultTabActiveKey ] = useState('device_abtests');
  const deviceData = useSelector(selectUserDeviceDetails);
  const deviceDataLoading = useSelector(selectUserDeviceDetailsLoading);
  const deviceAbTests = useSelector(selectUserDeviceAbTests);
  const deviceAbTestsLoading = useSelector(selectUserDeviceAbTestsLoading);
  const devicePurchases = useSelector(selectUserDevicePurchases);
  const devicePurchasesLoading = useSelector(selectUserDevicePurchasesLoading);
  const devicePictures = useSelector(selectUserDevicePictures);
  const devicePicturesLoading = useSelector(selectUserDevicePicturesLoading);


  useEffect(() => {
    const params = mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting);

    dispatch(getUserProfileDeviceDetailsData(deviceId));

    if (FEATURE_SHOW_DEVICE_ABTESTS && splitLocation[splitLocation.length - 1] === String(deviceId)) {
      dispatch(getUserProfileDeviceAbTestsData({ deviceId, params }));
      setDefaultTabActiveKey('device_abtests');
    }

    //@TODO: Temporary used by default device purchases.
    //@TODO: Delete statement below when ab-test is would be enabled.

    if (splitLocation[splitLocation.length - 1] === String(deviceId)) {
      setDefaultTabActiveKey('device_purchases');
      dispatch(getUserProfileDevicePurchasesData({ deviceId, params }));
    }

    setClickedTabs([]);
  }, [ deviceId, splitLocation[splitLocation.length - 1], currentPage, currentLimit, currentOrder, currentSorting ]);

  const handleShowDrawer = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const callbackTabClicked = (key) => {
    const params = mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting);

    if (key === 'device_purchases' && !clickedTabs.includes(key)
    && hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PURCHASES ])) {
      dispatch(getUserProfileDevicePurchasesData({ deviceId, params }));
    }
    if (key === 'device_pictures' && !clickedTabs.includes(key)
    && hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PICTURES ])) {
      dispatch(getUserProfileDevicePicturesData({ deviceId, params }));
    }
    if (!clickedTabs.includes(key)) {
      clickedTabs.push(key);
    }
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <>
      {hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_DEVICE_DETAILS ]) && (
        <DeviceInformation
          deviceData={deviceData}
          deviceDataLoading={deviceDataLoading}
        />
      )}

      {hasRights([
        Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_AB_TESTS,
        Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PURCHASES,
        Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PICTURES,
      ]) && (
        <Tabs
          activeKey={defaultTabActiveKey}
          onChange={(activeKey) => {
            setDefaultTabActiveKey(activeKey);
          }}
          onTabClick={callbackTabClicked}
        >
          {hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_AB_TESTS ]) && FEATURE_SHOW_DEVICE_ABTESTS && (
            <TabPane
              key="device_abtests"
              tab={
                <span>
                  <RiHandCoinLine className="remix-icon" />
                  Ab-tests
                </span>
              }
            >
              <div className='table-title-content-wr da-mb-10'>
                <div className='table-title'>
                  Current Ab-test
                </div>
                <Button
                  className='da-mb-10'
                  size='small'
                  type="primary"
                  disabled={isEmpty(deviceAbTests.history)}
                  onClick={handleShowDrawer}
                >
                  Show history
                </Button>
              </div>
              <Card style={{ margin: 'unset' }}>
                <DeviceCurrentAbTestCard
                  abTestData={deviceAbTests.current}
                  deviceAbTestsLoading={deviceAbTestsLoading}
                />
              </Card>
            </TabPane>
          )}
          {hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PURCHASES ]) && (
            <TabPane
              key="device_purchases"
              tab={
                <span>
                  <RiShoppingBasketLine className="remix-icon" />
                  Purchases
                </span>
              }
            >
              <Table
                loading={devicePurchasesLoading}
                columns={USER_PROFILE_PURCHASES_TABLE_COLUMNS}
                rowKey="id"
                dataSource={devicePurchases}
                onChange={handleChangeTableParams}
                pagination={{
                  position: [ 'bottomCenter' ],
                  pageSizeOptions: [ '10', '25', '50' ],
                  size: 'small',
                  locale: { items_per_page: '' },
                  showSizeChanger: true,
                }}
              />
            </TabPane>
          )}
          {hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PICTURES ]) && (
            <TabPane
              key="device_pictures"
              tab={
                <span>
                  <RiImage2Line className="remix-icon" />
                  Pictures
                </span>
              }
            >
              <Table
                loading={devicePicturesLoading}
                columns={USER_PROFILE_PICTURES_TABLE_COLUMNS}
                rowKey="id"
                dataSource={devicePictures}
                onChange={handleChangeTableParams}
                pagination={{
                  position: [ 'bottomCenter' ],
                  pageSizeOptions: [ '10', '25', '50' ],
                  size: 'small',
                  locale: { items_per_page: '' },
                  showSizeChanger: true,
                }}
              />
            </TabPane>
          )}
        </Tabs>
      )}

      <DeviceAbTestsDrawer
        handleShowDrawer={handleShowDrawer}
        handleOnClose={handleCloseDrawer}
        visible={isDrawerVisible}
        // deviceName={deviceData?.info?.model || EMPTY_VALUE_PLACEHOLDER}
        deviceName={String(deviceData?.id) || EMPTY_VALUE_PLACEHOLDER}
        deviceHistory={deviceAbTests.history}
      />
    </>
  );
};

export default PageUserProfileDevice;
