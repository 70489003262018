import React from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Field, FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Card, Col, Divider, Popconfirm, Row } from 'antd';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { DeleteOutlined } from '@ant-design/icons';
import Yup from '../../../../../../vendor/yup';
import { hasRights, Permissions } from '../../../../../../const/permissions';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { mkValidateFormFields } from '../../../../../../lib/mkValidateFormFields';
import { getEnvOptions } from '../../../../../../lib/getEnvOptions';
import { APP_ENVIRONMENTS } from '../../../../../../const/system';



const { Option } = Select;


const FormEditUserDetailsButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormEditUserDetailsButtonWr = styled.div`
  width: 130px;
  padding: 0 10px;
  margin-top: 20px;
`;


const validationSchema = Yup.object().shape({
  roles: Yup.array().nullable(),
  permissions: Yup.array().nullable(),
  spaces: Yup.array().nullable(),
  space_data: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().nullable(),
      roles: Yup.array().nullable(),
      permissions: Yup.array().nullable(),
      projects: Yup.array(),
    }),
  ),
});


const FormGlobalEditUserPolicies = ({
  onSubmit,
  initialValues,
  isSubmitting,
  isEditDisabled,
  handleSetFormEdit,
  formErrors,
  policiesOptions,
  globalAdminSpaces,
  isMobile,
}) => {
  const rowGutter = isMobile ? [] : [ 16, 16 ];
  const globalPermissions = policiesOptions?.permissions ?? [];
  const globalRoles = getEnvOptions(policiesOptions?.roles, [ APP_ENVIRONMENTS.GLOBAL ]);
  const spacePermissions = getEnvOptions(policiesOptions?.permissions, [ APP_ENVIRONMENTS.SPACE, APP_ENVIRONMENTS.PROJECT ]);
  const spaceRoles = getEnvOptions(policiesOptions?.roles, [ APP_ENVIRONMENTS.SPACE ]);
  const projectPermissions = getEnvOptions(policiesOptions?.permissions, [ APP_ENVIRONMENTS.PROJECT ]);
  const projectRoles = getEnvOptions(policiesOptions?.roles, [ APP_ENVIRONMENTS.PROJECT ]);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setFieldValue, resetForm, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Row gutter={rowGutter}>
              <Col xs={24} sm={12}>
                <FormItem
                  label="Roles:"
                  name='roles'
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    name="roles"
                    disabled={isEditDisabled}
                    style={{ width: '100%' }}
                    placeholder="Select roles"
                    value={values.roles}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {globalRoles.map((role) => (
                      <Option key={role.id} value={role.id}>
                        {role.display_name || role.description}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem
                  label="Spaces:"
                  name='spaces'
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    name="spaces"
                    disabled={isEditDisabled || isEmpty(globalAdminSpaces)}
                    style={{ width: '100%' }}
                    placeholder="Select spaces"
                    value={values.spaces}
                    onChange={(value) => {
                      setFieldValue('space_data', values.space_data.filter((item) => value.includes(item.id)));
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {!isEmpty(globalAdminSpaces) ? globalAdminSpaces.map((space) => (
                      <Option key={space.id} value={space.id}>
                        {`[${space.key}] ${space.name}`}
                      </Option>
                    )) : null}
                  </Select>
                </FormItem>

                {isEmpty(globalAdminSpaces) && (
                  <div className="da-mb-0 da-text-color-black-80 da-text-color-dark-30 da-d-flex">
                    NOTE: Spaces field would be enabled in case of user have attached space(s).
                  </div>
                )}
              </Col>
              <Col xs={24} sm={12}>
                <FormItem
                  label="Permissions:"
                  name='permissions'
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    disabled={isEditDisabled}
                    name="permissions"
                    style={{ width: '100%' }}
                    placeholder="Select permissions"
                    value={values.permissions}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {globalPermissions.map((permission) => (
                      <Option key={permission.id} value={permission.id}>
                        {permission.display_name || permission.description}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>

            {values?.spaces.length > 0 && (
              <>
                <Divider orientation="left" className='dashboard-widgets-divider'>
                  Spaces
                </Divider>
                <Row gutter={[ 16, 16 ]}>
                  <Col span={24}>
                    <FieldArray
                      name="space_data"
                      value={values.space_data}
                    >
                      {({ remove }) => {
                        const spaceOptions = !isEmpty(globalAdminSpaces) ? values?.spaces.map((item) => globalAdminSpaces.filter((space) => space.id === item)).flat() ?? [] : [];

                        return (
                          <>
                            {spaceOptions.map((space, index) => {
                              const isValidSpaceCard =
                                !isEmpty(values.space_data[index]?.roles) ||
                                !isEmpty(values.space_data[index]?.permissions) ||
                                !isEmpty(values.space_data[index]?.projects);

                              return (
                                <FormItem
                                  /* eslint-disable-next-line react/no-array-index-key */
                                  key={index}
                                  name="space_data"
                                >
                                  <Card
                                    style={{ background: isValidSpaceCard ? '#f0f8ff63' : '#f5d1d14d' }}
                                    title={`[${space.key}] ${space.name}`}
                                    extra={
                                      <>
                                        <Popconfirm
                                          title="Sure to delete this space?"
                                          onConfirm={() => {
                                            remove(index);
                                            setFieldValue('spaces', values.spaces.filter((item) => item !== space.id));
                                          }}
                                        >
                                          <Button
                                            size='small'
                                            danger
                                            disabled={isEditDisabled}
                                            icon={<DeleteOutlined />}
                                          />
                                        </Popconfirm>
                                      </>
                                    }
                                  >
                                    <Field name={`space_data.${index}`}>
                                      {({ field: { name }, form: { setFieldValue } }) => {
                                        if (isEmpty(values.space_data[index])) {
                                          setTimeout(() => setFieldValue(`${name}.id`, values.spaces[index]), 0);
                                        }

                                        return (
                                          <>
                                            <Row gutter={rowGutter}>
                                              <Col xs={24} sm={12}>
                                                <FormItem
                                                  label="Space roles:"
                                                  name='roles'
                                                >
                                                  <Select
                                                    mode="multiple"
                                                    allowClear
                                                    showSearch
                                                    name={`${name}.roles`}
                                                    disabled={isEditDisabled}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select space roles"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                  >
                                                    {spaceRoles.map((role) => (
                                                      <Option key={role.id} value={role.id}>
                                                        {role.display_name || role.description}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </FormItem>

                                                <FormItem
                                                  label="Space projects:"
                                                  name='space_projects_ids'
                                                >
                                                  <Select
                                                    mode="multiple"
                                                    allowClear
                                                    showSearch
                                                    name={`${name}.space_projects_ids`}
                                                    disabled={isEditDisabled}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => {
                                                      if (value) {
                                                        setFieldValue(`${name}.projects`, values?.space_data[index]?.projects.filter((item) => value.includes(item.id)));
                                                      }
                                                    }}
                                                    placeholder="Select space projects"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                  >
                                                    {spaceOptions[index]?.projects?.data.map((project) => (
                                                      <Option key={project.id} value={project.id}>
                                                        {`[${project.key}] ${project.name}`}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </FormItem>
                                              </Col>

                                              <Col xs={24} sm={12}>
                                                <FormItem
                                                  label="Space permissions:"
                                                  name='permissions'
                                                >
                                                  <Select
                                                    mode="multiple"
                                                    allowClear
                                                    showSearch
                                                    disabled={isEditDisabled}
                                                    name={`${name}.permissions`}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select space permissions"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                  >
                                                    {spacePermissions.map((permission) => (
                                                      <Option key={permission.id} value={permission.id}>
                                                        {permission.display_name || permission.description}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </FormItem>
                                              </Col>
                                            </Row>

                                            {values?.space_data?.[index]?.hasOwnProperty('space_projects_ids') && !isEmpty(values?.space_data?.[index]?.space_projects_ids) && (
                                              <>
                                                <Divider orientation="left" className='dashboard-widgets-divider'>
                                                  Projects
                                                </Divider>
                                                <Row gutter={[ 16, 16 ]}>
                                                  <Col span={24}>
                                                    <FieldArray
                                                      name={`${name}.projects`}
                                                      value={values?.space_data[index]?.projects}
                                                    >
                                                      {({ remove }) => {
                                                        const projectsOptions = values?.space_data[index]?.space_projects_ids.map((item) => spaceOptions[index]?.projects?.data.filter((project) => project.id === item)).flat() ?? [];

                                                        return (
                                                          <>
                                                            {projectsOptions.map((project, projectIndex) => {
                                                              const isValidProjectCard =
                                                                !isEmpty(values?.space_data[index]?.projects?.[projectIndex]?.permissions) ||
                                                                !isEmpty(values?.space_data[index]?.projects?.[projectIndex]?.roles);

                                                              return (
                                                                <FormItem
                                                                  /* eslint-disable-next-line react/no-array-index-key */
                                                                  key={projectIndex}
                                                                  name={`${name}.projects`}
                                                                >
                                                                  <Card
                                                                    style={{ background: isValidProjectCard ? '#faebd74d' : '#f5d1d14d' }}
                                                                    title={`[${project.key}] ${project.name}`}
                                                                    extra={
                                                                      <>
                                                                        {!isEmpty(`${name}.space_projects_ids`) && (
                                                                          <Popconfirm
                                                                            title="Sure to delete this project?"
                                                                            onConfirm={() => {
                                                                              remove(projectIndex);
                                                                              setFieldValue(`${name}.space_projects_ids`, values?.space_data[index]?.space_projects_ids.filter((item) => item !== project.id));
                                                                            }}
                                                                          >
                                                                            <Button
                                                                              size='small'
                                                                              danger
                                                                              disabled={isEditDisabled}
                                                                              icon={<DeleteOutlined />}
                                                                            />
                                                                          </Popconfirm>
                                                                        )}
                                                                      </>
                                                                    }
                                                                  >
                                                                    {/* eslint-disable-next-line react/no-array-index-key */}
                                                                    <Field name={`${name}.projects.${projectIndex}`} key={projectIndex}>
                                                                      {({ field: { name }, form: { setFieldValue } }) => {
                                                                        if (!values?.space_data?.[index]?.projects?.hasOwnProperty(`${projectIndex}`)) {
                                                                          setTimeout(() => setFieldValue(`${name}.id`, values?.space_data[index]?.space_projects_ids[projectIndex]), 0);
                                                                        }

                                                                        return (
                                                                          <Row gutter={rowGutter}>
                                                                            <Col xs={24} sm={12}>
                                                                              <FormItem
                                                                                label="Project roles:"
                                                                                name='roles'
                                                                              >
                                                                                <Select
                                                                                  mode="multiple"
                                                                                  allowClear
                                                                                  showSearch
                                                                                  name={`${name}.roles`}
                                                                                  disabled={isEditDisabled}
                                                                                  style={{ width: '100%' }}
                                                                                  placeholder="Select project roles"
                                                                                  optionFilterProp="children"
                                                                                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                                                >
                                                                                  {projectRoles.filter((role) => role.space_id === null || role.space_id === space.id).map((role) => (
                                                                                    <Option key={role.id} value={role.id}>
                                                                                      {role.display_name || role.description}
                                                                                    </Option>
                                                                                  ))}
                                                                                </Select>
                                                                              </FormItem>
                                                                            </Col>
                                                                            <Col xs={24} sm={12}>
                                                                              <FormItem
                                                                                label="Project permissions:"
                                                                                name='permissions'
                                                                              >
                                                                                <Select
                                                                                  mode="multiple"
                                                                                  allowClear
                                                                                  showSearch
                                                                                  disabled={isEditDisabled}
                                                                                  name={`${name}.permissions`}
                                                                                  style={{ width: '100%' }}
                                                                                  placeholder="Select project permissions"
                                                                                  optionFilterProp="children"
                                                                                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                                                >
                                                                                  {projectPermissions.map((permission) => (
                                                                                    <Option key={permission.id} value={permission.id}>
                                                                                      {permission.display_name || permission.description}
                                                                                    </Option>
                                                                                  ))}
                                                                                </Select>
                                                                              </FormItem>
                                                                            </Col>
                                                                          </Row>
                                                                        );
                                                                      }}
                                                                    </Field>

                                                                    <ErrorBoundary
                                                                      message='Required to fill at least one of project fields'
                                                                      disabled={isValidProjectCard}
                                                                    />
                                                                  </Card>
                                                                </FormItem>
                                                              );
                                                            })}
                                                          </>
                                                        );
                                                      }}
                                                    </FieldArray>
                                                  </Col>
                                                </Row>
                                              </>
                                            )}
                                          </>
                                        );
                                      }}
                                    </Field>

                                    <ErrorBoundary
                                      message='Required to fill at least one of space fields'
                                      disabled={isValidSpaceCard}
                                    />
                                  </Card>
                                </FormItem>
                              );
                            })}
                          </>
                        );
                      }}
                    </FieldArray>
                  </Col>
                </Row>
              </>
            )}


            {hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_POLICIES ]) && (
              <FormEditUserDetailsButtonsRow>
                <FormEditUserDetailsButtonWr>
                  <Button
                    style={{ width: '100%' }}
                    type='secondary'
                    onClick={() => {
                      resetForm();
                      handleSetFormEdit(!isEditDisabled);
                    }}
                  >
                    {isEditDisabled ? 'Edit' : 'Cancel'}
                  </Button>
                </FormEditUserDetailsButtonWr>

                {!isEditDisabled && (
                  <FormEditUserDetailsButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      loading={isSubmitting}
                      disabled={!isValid || !dirty || mkValidateFormFields(values?.space_data)}
                    >
                      Save
                    </SubmitButton>
                  </FormEditUserDetailsButtonWr>
                )}
              </FormEditUserDetailsButtonsRow>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

FormGlobalEditUserPolicies.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSetFormEdit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    spaces: PropTypes.array,
    space_data: PropTypes.array,
    roles: PropTypes.array,
    permissions: PropTypes.array,
  }),
  policiesOptions: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
  globalAdminSpaces: PropTypes.array,
  formErrors: PropTypes.object,
  isMobile: PropTypes.bool,
};

FormGlobalEditUserPolicies.defaultProps = {
  values: {
    spaces: [],
    roles: [],
    permissions: [],
  },
  policiesOptions: {},
  globalAdminSpaces: [],
  formErrors: {},
  isMobile: false,
};

export default FormGlobalEditUserPolicies;
