import React from 'react';
import { Card, Empty, Row, Spin, Tooltip } from 'antd';
import { RiArrowRightDownLine, RiArrowRightUpLine, RiInformationLine } from 'react-icons/ri';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const WidgetItemStatistic = ({
  title,
  info,
  data,
  isLoading,
  footerText,
}) => {
  return (
    <Card className="da-border-color-black-40 da-mb-16 da-card-2">
      <Row justify="space-between">
        <h5 className="da-mr-8">{title}</h5>
        <Tooltip placement="topRight" title={info}>
          <RiInformationLine
            className="da-text-color-dark-0"
            size={18}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </Row>

      <Spin spinning={isLoading} tip="Loading...">
        {isEmpty(data) ? (
          <Empty
            imageStyle={{ display: 'none' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span>No data available</span>
            }
          />
        ) : (
          <>
            <Row justify="space-around">
              <h2 className="da-my-4">{isNumber(data.total) ? data.total : EMPTY_VALUE_PLACEHOLDER}</h2>
            </Row>

            <Row justify="space-between" className='da-mt-10'>
              <h3 className="da-badge-text da-mb-0 da-text-color-black-80 da-text-color-dark-30">
                {footerText}
              </h3>
              <Row align='center'>
                {data.is_positive ? (
                  <RiArrowRightUpLine className="da-text-color-success-1" size={16} />
                ) : (
                  <RiArrowRightDownLine className="da-text-color-danger-1" size={16} />
                )}
                {data.change && (
                  <p className={`da-mb-0 da-badge-text ${data.is_positive ? 'da-text-color-success-1' : 'da-text-color-danger-1'}`}>
                    {`${Number(data.change).toFixed()}%`}
                  </p>
                )}
              </Row>
            </Row>
          </>
        )}
      </Spin>
    </Card>
  );
};


WidgetItemStatistic.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
};

WidgetItemStatistic.defaultProps = {
  isLoading: false,
  data: {},
};

export default WidgetItemStatistic;
