import React from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Field, FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Card, Col, Divider, Popconfirm, Row } from 'antd';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { DeleteOutlined } from '@ant-design/icons';
import Yup from '../../../../../../vendor/yup';
import { hasRights, Permissions } from '../../../../../../const/permissions';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { mkValidateFormFields } from '../../../../../../lib/mkValidateFormFields';
import { getEnvOptions } from '../../../../../../lib/getEnvOptions';
import { APP_ENVIRONMENTS } from '../../../../../../const/system';



const { Option } = Select;


const FormEditUserDetailsButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormEditUserDetailsButtonWr = styled.div`
  width: 130px;
  padding: 0 10px;
  margin-top: 20px;
`;


const validationSchema = Yup.object().shape({
  roles: Yup.array().nullable(),
  permissions: Yup.array().nullable(),
  projects: Yup.array().nullable(),
});


const FormLocalEditUserPolicies = ({
  onSubmit,
  initialValues,
  isSubmitting,
  isEditDisabled,
  handleSetFormEdit,
  formErrors,
  policiesOptions,
  isMobile,
}) => {
  const rowGutter = isMobile ? [] : [ 16, 16 ];
  const spacePermissions = getEnvOptions(policiesOptions?.permissions, [ APP_ENVIRONMENTS.SPACE, APP_ENVIRONMENTS.PROJECT ]);
  const spaceRoles = getEnvOptions(policiesOptions?.roles, [ APP_ENVIRONMENTS.SPACE ]);
  const projectPermissions = getEnvOptions(policiesOptions?.permissions, [ APP_ENVIRONMENTS.PROJECT ]);
  const projectRoles = getEnvOptions(policiesOptions?.roles, [ APP_ENVIRONMENTS.PROJECT ]);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setFieldValue, resetForm, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Row gutter={rowGutter}>
              <Col xs={24} sm={12}>
                <FormItem
                  label="Roles:"
                  name='roles'
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    name="roles"
                    disabled={isEditDisabled}
                    style={{ width: '100%' }}
                    placeholder="Select roles"
                    value={values.roles}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {!isEmpty(spaceRoles) ? spaceRoles.map((role) => (
                      <Option key={role.id} value={role.id}>
                        {role.display_name || role.description}
                      </Option>
                    )) : null}
                  </Select>
                </FormItem>

                <FormItem
                  label="Projects:"
                  name='projects_ids'
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    name="projects_ids"
                    disabled={isEditDisabled}
                    style={{ width: '100%' }}
                    placeholder="Select projects"
                    value={values.projects_ids}
                    onChange={(value) => {
                      setFieldValue('projects', values.projects.filter((item) => value.includes(item.id)));
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {!isEmpty(policiesOptions?.projects) ? policiesOptions.projects.map((project) => (
                      <Option key={project.id} value={project.id}>
                        {`[${project.key}] ${project.name}`}
                      </Option>
                    )) : null}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} sm={12}>
                <FormItem
                  label="Permissions:"
                  name='permissions'
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    disabled={isEditDisabled}
                    name="permissions"
                    style={{ width: '100%' }}
                    placeholder="Select permissions"
                    value={values.permissions}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {!isEmpty(spacePermissions) ? spacePermissions.map((permission) => (
                      <Option key={permission.id} value={permission.id}>
                        {permission.display_name || permission.description}
                      </Option>
                    )) : null}
                  </Select>
                </FormItem>
              </Col>
            </Row>

            {values?.projects_ids.length > 0 && (
              <>
                <Divider orientation="left" className='dashboard-widgets-divider'>
                  Projects
                </Divider>
                <Row gutter={[ 16, 16 ]}>
                  <Col span={24}>
                    <FieldArray
                      name="projects"
                      value={values.projects}
                    >
                      {({ remove }) => {
                        const projectsOptions = !isEmpty(policiesOptions?.projects) ? values?.projects_ids.map((item) => policiesOptions?.projects.filter((project) => project.id === item)).flat() ?? [] : [];

                        return (
                          <>
                            {projectsOptions.map((project, index) => {
                              const isValidProjectCard = !isEmpty(values?.projects?.[index]?.permissions) || !isEmpty(values?.projects?.[index]?.roles);

                              return (
                                <FormItem
                                  /* eslint-disable-next-line react/no-array-index-key */
                                  key={index}
                                  name="projects"
                                >
                                  <Card
                                    style={{ background: isValidProjectCard ? '#f0f8ff63' : '#f5d1d14d' }}
                                    title={`[${project.key}] ${project.name}`}
                                    extra={
                                      <>
                                        <Popconfirm
                                          title="Sure to delete this project?"
                                          onConfirm={() => {
                                            remove(index);
                                            setFieldValue('projects_ids', values.projects_ids.filter((item) => item !== project.id));
                                          }}
                                        >
                                          <Button
                                            size='small'
                                            danger
                                            disabled={isEditDisabled}
                                            icon={<DeleteOutlined />}
                                          />
                                        </Popconfirm>
                                      </>
                                    }
                                  >
                                    <Field name={`projects.${index}`}>
                                      {({ field: { name }, form: { setFieldValue } }) => {
                                        if (isEmpty(values.projects[index])) {
                                          setTimeout(() => setFieldValue(`${name}.id`, values.projects_ids[index]), 0);
                                        }

                                        return (
                                          <>
                                            <Row gutter={rowGutter}>
                                              <Col xs={24} sm={12}>
                                                <FormItem
                                                  label="Project roles:"
                                                  name='roles'
                                                >
                                                  <Select
                                                    mode="multiple"
                                                    allowClear
                                                    showSearch
                                                    name={`${name}.roles`}
                                                    disabled={isEditDisabled}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select project roles"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                  >
                                                    {projectRoles.map((role) => (
                                                      <Option key={role.id} value={role.id}>
                                                        {role.display_name || role.description}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </FormItem>
                                              </Col>

                                              <Col xs={24} sm={12}>
                                                <FormItem
                                                  label="Project permissions:"
                                                  name='permissions'
                                                >
                                                  <Select
                                                    mode="multiple"
                                                    allowClear
                                                    showSearch
                                                    disabled={isEditDisabled}
                                                    name={`${name}.permissions`}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select project permissions"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                                  >
                                                    {projectPermissions.map((permission) => (
                                                      <Option key={permission.id} value={permission.id}>
                                                        {permission.display_name || permission.description}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </FormItem>
                                              </Col>
                                            </Row>
                                          </>
                                        );
                                      }}
                                    </Field>

                                    <ErrorBoundary
                                      message='Required to fill at least one of project fields'
                                      disabled={isValidProjectCard}
                                    />
                                  </Card>
                                </FormItem>
                              );
                            })}
                          </>
                        );
                      }}
                    </FieldArray>
                  </Col>
                </Row>
              </>
            )}

            {hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_POLICIES ]) && (
              <FormEditUserDetailsButtonsRow>
                <FormEditUserDetailsButtonWr>
                  <Button
                    style={{ width: '100%' }}
                    type='secondary'
                    onClick={() => {
                      resetForm();
                      handleSetFormEdit(!isEditDisabled);
                    }}
                  >
                    {isEditDisabled ? 'Edit' : 'Cancel'}
                  </Button>
                </FormEditUserDetailsButtonWr>

                {!isEditDisabled && (
                  <FormEditUserDetailsButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      loading={isSubmitting}
                      disabled={!isValid || !dirty || mkValidateFormFields(values?.projects)}
                    >
                      Save
                    </SubmitButton>
                  </FormEditUserDetailsButtonWr>
                )}
              </FormEditUserDetailsButtonsRow>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

FormLocalEditUserPolicies.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSetFormEdit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    projects_ids: PropTypes.array,
    projects: PropTypes.array,
    roles: PropTypes.array,
    permissions: PropTypes.array,
  }),
  policiesOptions: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
  formErrors: PropTypes.object,
  isMobile: PropTypes.bool,
};

FormLocalEditUserPolicies.defaultProps = {
  values: {
    projects_ids: [],
    projects: [],
    roles: [],
    permissions: [],
  },
  policiesOptions: {},
  formErrors: {},
  isMobile: false,
};

export default FormLocalEditUserPolicies;
