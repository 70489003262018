import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Layout, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import urlPageRoles from '../../../../../urls/urlPageRoles';
import urlPageSettingsRoles from '../../../../../urls/urlPageSettingsRoles';
import FormCreateEditRole from './FormCreateEditRole';
import FormEditPermissions from './FormEditPermissions';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { useApp } from '../../../../../app/context/AppContext';
import { hasRights, Permissions } from '../../../../../const/permissions';
import HasRights from '../../../../../components/HasRights';
import { useCurrentSettingsScope } from '../../../../../app/hooks';
import { GLOBAL_SETTINGS_PREFIX } from '../../../../../const/system';
import { isFulfilledRequestStatus } from '../../../../../lib/isRequestSuccess';
import {
  updateRolePermissions,
  getRolesDetailsData,
  updateRoleDetailsData,
  resetRoleDetailsData,
  getRolePermissions,
  resetCreateEditRoleErrors,
  selectAllowedPermissions,
  selectFormEditRoleLoading,
  selectFormEditRoleErrors,
  selectRoleDetails,
  selectRoleDetailsLoading,
  selectUpdatePermissionsLoading,
  selectUpdatePermissionsErrors,
  selectRolePermissions,
} from '../../feature/roleDetailsSlice';



const { Content } = Layout;


const PageEditRole = () => {
  const dispatch = useDispatch();
  const { roleId } = useParams();
  const { projectSettings } = useApp();
  const [ isActiveFormEditRole, setFormEditRoleActive ] = useState(true);
  const [ isActiveEditPermissionsForm, setFormEditPermissionsActive ] = useState(true);

  const roleDetails = useSelector(selectRoleDetails);
  const permissionsOptions = useSelector(selectAllowedPermissions);
  const rolePermissions = useSelector(selectRolePermissions);
  const isUpdatePermissionsLoading = useSelector(selectUpdatePermissionsLoading);
  const formEditRoleErrors = useSelector(selectFormEditRoleErrors);
  const isFormEditRoleLoading = useSelector(selectFormEditRoleLoading);
  const isLoading = useSelector(selectRoleDetailsLoading);
  const settingsScope = useCurrentSettingsScope();
  const formEditPermissionsErrors = useSelector(selectUpdatePermissionsErrors);

  const isGlobalScope = settingsScope === GLOBAL_SETTINGS_PREFIX;
  const rolePermissionsIds = !isEmpty(rolePermissions) ? rolePermissions.map((item) => item.id) : [];


  const handleResetFormEditErrors = () => {
    dispatch(resetCreateEditRoleErrors());
  };


  useEffect(() => {
    dispatch(getRolesDetailsData(roleId));
    dispatch(getRolePermissions(roleId));
    return () => {
      dispatch(resetRoleDetailsData());
      handleResetFormEditErrors();
    };
  }, []);


  const handleSubmitEditRoleForm = async (values) => {
    await dispatch(updateRoleDetailsData({ roleId, ...{ data: values } }))
      .then((response) => {
        if (isFulfilledRequestStatus(response)) {
          setFormEditRoleActive(true);
        }
      });
  };

  const handleSubmitFormEditPermissions = async (values) => {
    await dispatch(updateRolePermissions({ roleId, ...{ permissions: values.permissions } }))
      .then((response) => {
        if (isFulfilledRequestStatus(response)) {
          dispatch(getRolePermissions(roleId));
          setFormEditPermissionsActive(true);
        }
      });
  };

  const editPermissionsInitialValues = { ...{ permissions: rolePermissionsIds } };

  return (
    <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={isGlobalScope ? urlPageRoles() : urlPageSettingsRoles()}>
            Roles list
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit role</Breadcrumb.Item>
      </Breadcrumb>
      <Content className='layout-content'>
        <Row gutter={[ 16, 16 ]}>
          {hasRights([
            Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.EDIT_ROLE_DETAILS ||
              Permissions.ADMINISTRATION.ROLES.ROLES_LIST.CREATE_ROLE,
            Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW,
          ]) && (
            <Col xs={24} md={12} xl={12} style={{ padding: '0 5px' }}>
              <Card
                title='Role details'
                loading={isLoading}
                style={{ marginBottom: '10px' }}
              >
                <FormCreateEditRole
                  initialValues={roleDetails}
                  formErrors={formEditRoleErrors}
                  onSubmit={handleSubmitEditRoleForm}
                  isRoleEdit
                  isSubmitting={isFormEditRoleLoading}
                  handleSetFormEdit={setFormEditRoleActive}
                  isEditDisabled={isActiveFormEditRole}
                  isGlobalScope={isGlobalScope}
                  handleResetEditFormErrors={handleResetFormEditErrors}
                />
              </Card>
            </Col>
          )}
          {hasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW ]) && (
            <Col xs={24} md={12} xl={12} style={{ padding: '0 5px' }}>
              <Card
                title='Role permissions'
                loading={isLoading}
              >
                <FormEditPermissions
                  initialValues={editPermissionsInitialValues}
                  formErrors={formEditPermissionsErrors}
                  permissionsOptions={permissionsOptions}
                  onSubmit={handleSubmitFormEditPermissions}
                  isSubmitting={isUpdatePermissionsLoading}
                  handleSetFormEdit={setFormEditPermissionsActive}
                  isDisabled={isActiveEditPermissionsForm}
                  canEditRole={!!(isGlobalScope || (!isGlobalScope && roleDetails?.space_id))}
                />
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </HasRights>
  );
};

export default PageEditRole;
