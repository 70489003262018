import { Breadcrumb, Button, Card, Col, Input, Row, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
// eslint-disable-next-line lodash/import-scope
import { debounce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import urlPageSpaces from '../../../../../urls/urlPageSpaces';
import urlPageCreateProject from '../../../../../urls/urlPageCreateProject';
import urlPageSettingsCreateProject from '../../../../../urls/urlPageSettingsCreateProject';
import urlPageSpaceCreateProject from '../../../../../urls/urlPageSpaceCreateProject';
import { GLOBAL_SETTINGS_PREFIX } from '../../../../../const/system';
import { hasRights, Permissions } from '../../../../../const/permissions';
import { getProjectsListColumns } from './PageProjects.const';
import { getQueryParams } from '../../../../../lib/getQueryParams';
import { mkQueryParams } from '../../../../../lib/mkQueryParams';
import { isFulfilledRequestStatus } from '../../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { useApp } from '../../../../../app/context/AppContext';
import { useCurrentSettingsScope } from '../../../../../app/hooks';
import {
  deleteProject,
  getProjectsListData,
  resetProjectsListData,
  selectProjectsList,
  selectProjectsLoading,
  selectProjectsPagination,
} from '../../feature/projectsSlice';



const PageProjects = () => {
  const { spaceId } = useParams();
  const { projectSettings } = useApp();
  const { search } = useLocation();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ searchText, setSearchText ] = useState('');
  const requestFilters = [ { 'filterKey': 'name', 'filterValue': searchText } ];

  const dispatch = useDispatch();
  const projectsList = useSelector(selectProjectsList);
  const isLoading = useSelector(selectProjectsLoading);
  const pagination = useSelector(selectProjectsPagination);
  const settingsScope = useCurrentSettingsScope();


  if (!isEmpty(spaceId)) {
    requestFilters.push({ 'filterKey': 'space_id', 'filterValue': spaceId });
  }

  const handleGetProjects = () => {
    dispatch(getProjectsListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting, '', requestFilters)));
  };


  useEffect(() => {
    handleGetProjects();
    return () => {
      dispatch(resetProjectsListData());
    };
  }, [ currentPage, currentLimit, currentOrder, currentSorting, searchText ]);


  const handleDeleteProject = async (projectId) => {
    await dispatch(deleteProject(projectId)).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        handleGetProjects();
      }
    });
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);


  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  return (
    <>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            {settingsScope === GLOBAL_SETTINGS_PREFIX && (
              <Breadcrumb.Item>
                <Link to={urlPageSpaces()}>
                  Spaces list
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>Projects</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Input
              placeholder="Search..."
              allowClear={!isEmpty(searchText)}
              style={{ width: 230, margin: '10px 0' }}
              onChange={debouncedSearch}
              prefix={<SearchOutlined fill='#9393ab' />}
            />
            {hasRights([ Permissions.ADMINISTRATION.PROJECTS.CREATE ]) && (
              <Button
                style={{ margin: '10px 0 10px 15px' }}
                size='middle'
                type="primary"
              >
                <Link
                  to={!isEmpty(spaceId) ? urlPageSpaceCreateProject({ spaceId }) : settingsScope === GLOBAL_SETTINGS_PREFIX
                    ? urlPageCreateProject()
                    : urlPageSettingsCreateProject()}
                >
                  Create project
                </Link>
              </Button>
            )}
          </div>
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Table
            sticky
            onChange={handleChangeTableParams}
            columns={getProjectsListColumns(handleDeleteProject, spaceId, settingsScope)}
            dataSource={projectsList}
            rowKey="id"
            loading={isLoading}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 550 }}
          />
        </Card>
      </div>
    </>
  );
};


export default PageProjects;
