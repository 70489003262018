import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';
import UserEventsService from '../services/userEventsService';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  data: [],
  loading: false,
};

const userEventsService = new UserEventsService(Api);

export const getUserEventsData = createAsyncThunk(
  'userEvents/getAll',
  async (values, { rejectWithValue }) => {
    try {
      const response = await userEventsService.getAll(values);

      if (values) {
        setUrlParams(values);
      }

      if (isEmpty(response)) {
        mkNotification('info', 'No user events data available.');
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const userEventsSlice = createSlice({
  name: 'userEvents',
  initialState,
  reducers: {
    resetUserEventsData: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserEventsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserEventsData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUserEventsData.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetUserEventsData } = userEventsSlice.actions;

// selectors
export const selectLoading = (state) => state.userEvents.loading;
export const selectData = (state) => state.userEvents.data;

// reducer
export default userEventsSlice.reducer;
