import React from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'antd';
import { hasRights, Permissions } from '../../../../../../const/permissions';
import Yup from '../../../../../../vendor/yup';



const { Option } = Select;

const FormCreateEditRoleButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditRoleButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;



const validationSchema = Yup.object().shape({
  permissions: Yup.array()
    .min(1, 'Required to select minimum 1 permission')
    .required('Required to select user permissions'),
});


const FormEditPermissions = ({
  onSubmit,
  initialValues,
  isSubmitting,
  permissionsOptions,
  formErrors,
  isDisabled,
  canEditRole,
  handleSetFormEdit,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, resetForm, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <FormItem
              label="Permissions:"
              name='permissions'
              required
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                disabled={isDisabled || isSubmitting}
                name="permissions"
                style={{ width: '100%' }}
                placeholder="Select permissions"
                value={values.permissions}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {permissionsOptions.map((permission) => (
                  <Option key={permission.id} value={permission.id}>
                    {permission.display_name || permission.description}
                  </Option>
                ))}
              </Select>
            </FormItem>

            {hasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.EDIT_PERMISSIONS ]) && canEditRole && (
              <FormCreateEditRoleButtonsRow>
                <FormCreateEditRoleButtonWr>
                  <Button
                    style={{ width: '100%' }}
                    type='secondary'
                    disabled={isSubmitting}
                    onClick={() => {
                      resetForm();
                      handleSetFormEdit(!isDisabled);
                    }}
                  >
                    {isDisabled ? 'Edit' : 'Cancel'}
                  </Button>
                </FormCreateEditRoleButtonWr>

                {!isDisabled && (
                  <FormCreateEditRoleButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      loading={isSubmitting}
                      disabled={!isValid || !dirty || isSubmitting}
                    >
                      Save
                    </SubmitButton>
                  </FormCreateEditRoleButtonWr>
                )}
              </FormCreateEditRoleButtonsRow>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

FormEditPermissions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSetFormEdit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    permissions: PropTypes.array,
  }),
  permissionsOptions: PropTypes.array,
  formErrors: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  canEditRole: PropTypes.bool,
};

FormEditPermissions.defaultProps = {
  values: {
    permissions: [],
  },
  permissionsOptions: [],
  formErrors: [],
  canEditRole: true,
};

export default FormEditPermissions;
