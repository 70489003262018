import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Col, Menu, Row } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  ADMINISTRATION_HEADER,
  LOCAL_SETTINGS_PREFIX,
  GLOBAL_SETTINGS_PREFIX,
  APP_ENVIRONMENTS,
} from '../../../../../const/system';
import navigation from '../../../../Navigation/Vertical';
import { useApp } from '../../../../../app/context/AppContext';
import { getCurrentSpace } from '../../../../../lib/getCurrentSpace';
import { getAllUserPolicies } from '../../../../../lib/getAllUserPolicies';
import { Permissions } from '../../../../../const/permissions';



const { SubMenu } = Menu;

const MenuItem = ({ onClose }) => {
  // Location
  const { pathname } = useLocation();
  const splitLocation = pathname.split('/');
  const { userPolicies, projectSettings, userSpaces, userPermissions } = useApp();
  const currentSpace = getCurrentSpace(projectSettings?.spaceKey, userSpaces);
  const currentSpaceModules = currentSpace?.config.modules ?? [];

  // Menu
  const splitLocationUrl = `${splitLocation[splitLocation.length - 2]}/${splitLocation[splitLocation.length - 1]}`;
  const allowedMenuItems = navigation.filter((menuItem) => userPermissions?.some((permission) => !isEmpty(menuItem) ? menuItem.groupPermissions.includes(permission) : null));
  const menuItems = allowedMenuItems.filter((item) => !isEmpty(projectSettings.projectKey) ? item : item.title === ADMINISTRATION_HEADER);

  const menuItem = menuItems.map((item, index) => {
    // Totally hide 'Administration' menu item if user does not have permission to watch any administration menu item
    const hideAdministrationMenuItem = item.header === ADMINISTRATION_HEADER && ![
      Permissions.ADMINISTRATION.GLOBAL_ADMIN_MENU,
      Permissions.ADMINISTRATION.SPACE_ADMIN_MENU,
    ].some((permission) => userPermissions.includes(permission));

    // Hide non 'Administration' menu item if current space does not have certain module or if it is non-project scope
    const hideMenuItemBySpaceModules = item.title !== ADMINISTRATION_HEADER &&
        (!currentSpaceModules.includes(item.id) || !projectSettings.projectKey);

    if (hideMenuItemBySpaceModules || hideAdministrationMenuItem) {
      return null;
    }

    if (item.header) {
      // eslint-disable-next-line react/no-array-index-key
      return <Menu.ItemGroup key={index} title={item.header} />;
    }

    if (item.children) {
      return (
        <SubMenu key={item.id} icon={item.icon} title={item.title}>
          {item.children.map((children) => {
            // Hide 'Administration -> Global' menu item if user does not have permission
            const hideMenuItem = (!isEmpty(projectSettings.spaceKey) &&
              !userPermissions.includes(Permissions.ADMINISTRATION.GLOBAL_ADMIN_MENU) && children.id === GLOBAL_SETTINGS_PREFIX) ||
              ((isEmpty(projectSettings.spaceKey) && children.id === LOCAL_SETTINGS_PREFIX));

            if ((userPermissions.some((permission) => !isEmpty(children?.permission) ? children.permission === permission :
              children?.groupPermissions.map((groupItem) => groupItem === permission))) && !hideMenuItem) {
              if (!children.children) {
                const childrenNavLink = children.navLink.split('/');

                return (
                // Level 2
                  <Menu.Item
                    onClick={onClose}
                    key={children.id}
                    className={
                      splitLocationUrl ===
                      `${childrenNavLink[childrenNavLink.length - 2]
                      }/${childrenNavLink[childrenNavLink.length - 1]}`
                        ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'
                    }
                  >
                    <Link to={children.navLink}>{children.title}</Link>
                  </Menu.Item>
                );
              }

              // Mark parent menu item 'active' if any of child items is active
              let isActiveParentMenuItem = false;

              if (children.children) {
                isActiveParentMenuItem = children.children.some((menuItem) => {
                  const childrenItemLink = menuItem.navLink.split('/');

                  return splitLocationUrl === `${childrenItemLink[childrenItemLink.length - 2]
                  }/${childrenItemLink[childrenItemLink.length - 1]}`;
                });
              }

              return (
              // Level 3
                <SubMenu
                  key={children.id}
                  title={
                    <Row
                      key={children.title}
                      className={`${isActiveParentMenuItem ? 'ant-menu-item-selected' : ''} menu-item da-hover-fill-primary-1 da-hover-bg-color-primary-4 da-hover-text-color-primary-1 da-transition da-px-12 da-py-10 da-border-radius-lg`}
                    >
                      <Col>{children.title}</Col>
                    </Row>
                  }
                >
                  {children.children.map((childItem) => {
                    const globalUserPermissions = getAllUserPolicies(
                      userPolicies,
                      projectSettings,
                      'permissions',
                      [ APP_ENVIRONMENTS.SPACE, APP_ENVIRONMENTS.PROJECT ],
                    );

                    const showChildItem = children.id === GLOBAL_SETTINGS_PREFIX ? globalUserPermissions.some((permission) => childItem.permission === permission)
                      : userPermissions.some((permission) => childItem.permission === permission);

                    if (!showChildItem) {
                      return null;
                    }

                    const childrenItemLink = childItem.navLink.split('/');

                    return (
                      <Menu.Item
                        key={childItem.id}
                        onClick={onClose}
                        className={
                          splitLocationUrl ===
                          `${childrenItemLink[childrenItemLink.length - 2]
                          }/${childrenItemLink[childrenItemLink.length - 1]}`
                            ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'
                        }
                      >
                        <Link to={childItem.navLink}>{childItem.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return null;
          })}
        </SubMenu>
      );
    }
    const itemNavLink = item.navLink.split('/');

    if (userPermissions.includes(item.groupPermissions)) {
      return (
      // Level 1
        <Menu.Item
          key={`${item.id}-mobile`}
          icon={item.icon}
          onClick={onClose}
          className={
            `${splitLocation[splitLocation.length - 2]
            }/${splitLocation[splitLocation.length - 1]}` ===
          `${itemNavLink[itemNavLink.length - 2]}/${itemNavLink[itemNavLink.length - 1]}`
              ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'
          }
        >
          <Link to={item.navLink}>{item.title}</Link>
        </Menu.Item>
      );
    }
    return null;
  });

  return (
    <Menu
      theme='light'
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : null,
        splitLocation[splitLocation.length - 2],
      ]}
    >
      {menuItem}
    </Menu>
  );
};

MenuItem.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MenuItem;
