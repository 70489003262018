import { Collapse, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCheckMobileScreen } from '../../app/hooks';



const { Panel } = Collapse;

const CollapsibleFilterWr = ({ children, title, keyValue, isLoading, maxDeviceWidth, defaultActiveKey }) => {
  const [ activeKey, setActiveKey ] = useState([]);
  const collapsedByDefault = !useCheckMobileScreen(maxDeviceWidth);

  useEffect(() => {
    if (collapsedByDefault) {
      setActiveKey(keyValue);
    } else {
      setActiveKey([]);
    }
  }, [ collapsedByDefault ]);

  return (
    <Spin spinning={isLoading}>
      <Collapse
        defaultActiveKey={defaultActiveKey || activeKey}
        activeKey={activeKey}
        onChange={(value) => {
          setActiveKey(value);
        }}
      >
        <Panel header={title} key={keyValue} isActive>
          {children}
        </Panel>
      </Collapse>
    </Spin>
  );
};

CollapsibleFilterWr.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  keyValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  maxDeviceWidth: PropTypes.number,
  defaultActiveKey: PropTypes.string,
};

CollapsibleFilterWr.defaultProps = {
  isLoading: false,
  defaultActiveKey: '',
  maxDeviceWidth: 768,
};

export default CollapsibleFilterWr;
