export const getAxis = (obj) => {
  const nonPrevValues = Object.fromEntries(
    Object.entries(obj).filter(([ key ]) => !key.startsWith('prev_')),
  );

  const maxValue = Math.max.apply(null, Object.values(nonPrevValues));
  const rightAxisLabel = Object.keys(obj).filter((key) => (obj[key] === maxValue) && !key.startsWith('prev_'));

  return {
    rightAxis: Object.keys(obj).filter((key) => (obj[key] === maxValue) && !key.startsWith('prev_'))[0],
    leftAxis: Object.keys(obj).filter((key) => obj[key] !== maxValue && !key.startsWith('prev_')),
    rightPrevAxis: `prev_${Object.keys(obj).filter((key) => obj[key] === maxValue)[0]}`,
    leftPrevAxis: Object.keys(obj).filter((key) => obj[key] !== maxValue && key.startsWith('prev_') && key !== `prev_${rightAxisLabel}`),
  };
};
