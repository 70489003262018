import { Col, Collapse, Row, Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useCheckMobileScreen } from '../../../../app/hooks';
import { USER_PROFILES_PLATFORMS_ICONS } from '../../pages/PageUserProfiles/PageUserProfiles.const';
import DescriptionValueRow from '../../../../components/DescriptionValueRow';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const { Panel } = Collapse;

const UserInformation = ({ userData, userDataLoading }) => {
  const isMobile = useCheckMobileScreen();

  return (
    <Spin spinning={userDataLoading} tip="Loading user information...">
      <Collapse defaultActiveKey={[ 'user_info' ]} >
        <Panel header={<h5 style={{ margin: 'unset' }}>User information</h5>} key="user_info">
          <Row justify={isMobile ? 'inherit' : 'space-around'}>
            <Col
              xs={24} sm={24} lg={12} md={12} xl={8}
              className="da-profile-content-list da-mt-8 da-pb-sm-0 da-pb-24"
            >
              <ul>
                <DescriptionValueRow
                  firstRow
                  title='User id'
                  value={userData.id}
                />
                <DescriptionValueRow
                  title='Registration date'
                  value={renderDateField(userData?.created_at)}
                />
                <DescriptionValueRow
                  title='Devices'
                  value={userData?.counts?.devices}
                />
                <DescriptionValueRow
                  title='Purchases'
                  value={userData?.counts?.purchases}
                />
                <DescriptionValueRow
                  title='Pictures'
                  value={userData?.counts?.pics}
                />
              </ul>
            </Col>

            <Col
              xs={24} sm={24} lg={10} md={10}
              className="da-profile-content-list da-mt-8 da-pb-sm-0 da-pb-sm-10 da-pb-24"
            >
              <ul>
                <li className="da-mt-18">
                  <span className='da-p1-body'>Platforms</span>
                  <span className='da-d-flex da-d-flex-full-center' style={{ justifyContent: 'flex-start' }}>
                    {userData.platforms ? userData.platforms.map((platform, index) => (
                      <div key={platform && index} className='da-mr-6 da-ml-6'>
                        {USER_PROFILES_PLATFORMS_ICONS(platform)}
                      </div>
                    )) : EMPTY_VALUE_PLACEHOLDER}
                  </span>
                </li>
                <DescriptionValueRow
                  title='Sessions count'
                  value={userData?.session?.count}
                />
                <DescriptionValueRow
                  title='First session'
                  value={renderDateField(userData?.session?.date_first)}
                />
                <DescriptionValueRow
                  title='Last session'
                  value={renderDateField(userData?.session?.date_last)}
                />
              </ul>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Spin>
  );
};


UserInformation.propTypes = {
  userData: PropTypes.object,
  userDataLoading: PropTypes.bool,
};

UserInformation.defaultProps = {
  userData: {},
  userDataLoading: false,
};

export default UserInformation;
