import React, { useEffect } from 'react';
import { Button, Layout, Table, BackTop, Card, Tabs, Row, Col, Breadcrumb } from 'antd';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Link } from 'react-router-dom';
import MonitorFilter from '../../components/MonitorFilter';
import MonitorCharts from '../../components/MonitorCharts';
import {
  APP_OPEN_TABLE_COLUMNS,
  GAME_FINISHED_TABLE_COLUMNS,
  preparedTableValues,
} from './PageGamesMonitor.const';
import { exportToCsv } from '../../../../lib/exportToCsv';
import {
  getGameMonitoringData,
  resetGameMonitoringData,
  selectGameData, selectGameLoading,
} from '../../feature/gamesMonitorSlice';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { getObjectAverageValues } from '../../../../lib/getObjectAverageValues';
import { getAxis } from '../../../../lib/getAxis';
import { getPreparedChartsData } from '../../../../lib/getPreparedChartsData';
import ChartsTooltip from '../../components/ChartsTooltip';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Content } = Layout;
const { TabPane } = Tabs;

const PageGamesMonitor = () => {
  const { projectSettings } = useApp();
  const dispatch = useDispatch();
  const data = useSelector(selectGameData);
  const isLoading = useSelector(selectGameLoading);

  const arrayData = getPreparedChartsData(data,
    {
      game_finished: 0,
      active_users: 0,
      app_open: 0,
      prev_active_users: 0,
      prev_game_finished: 0,
      prev_app_open: 0,
    }, '', true);
  const gamesMonitorData = arrayData.slice(1, arrayData.length);
  const avgChartValues = getObjectAverageValues(arrayData);
  const axisData = getAxis(avgChartValues);

  const firstTabTitle = 'Game finish per active user';
  const secondTabTitle = 'App open per active user';

  const handleSetFilter = (values) => {
    dispatch(getGameMonitoringData(values));
  };

  useEffect(() => {
    if (!isEmpty(window.location.search)) {
      handleSetFilter(getObjectValuesByUrlParams());
    } else {
      dispatch(getGameMonitoringData());
    }
    return () => {
      dispatch(resetGameMonitoringData());
    };
  }, []);

  const initialFilterValues = isEmpty(window.location.search) ? {
    app_version: '',
    platform: null,
    countries: null,
    data_grouping: 'hourly',
    from: moment().subtract(3, 'd').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  } : getObjectValuesByUrlParams();

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Games monitoring</Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} lg={6}>
            <div className="layout-content">
              <CollapsibleFilterWr
                title='Filter'
                keyValue='games-monitor-filter'
                maxDeviceWidth={992}
              >
                <MonitorFilter
                  onSubmit={handleSetFilter}
                  isSubmitting={isLoading}
                  initialValues={initialFilterValues}
                />
              </CollapsibleFilterWr>
            </div>
          </Col>
          <Col xs={24} lg={18}>
            <Content className='layout-content' id='gamesTopCmp'>
              <Card className='charts-container'>
                <ChartsTooltip />
                <MonitorCharts
                  isLoading={isLoading}
                  mainData={gamesMonitorData}
                  axisData={axisData}
                />
              </Card>

              <Card className='card-has-table'>
                <Tabs defaultActiveKey="groups_statistics">
                  <TabPane tab={firstTabTitle} key="game_finish_per_active_user">
                    <div className='content-right'>
                      <Button
                        className='da-mb-10'
                        size='small'
                        type="primary"
                        disabled={isEmpty(gamesMonitorData)}
                        onClick={() => {
                          exportToCsv(
                            preparedTableValues(gamesMonitorData, 'game_finished'),
                            `${firstTabTitle}_${moment().format('DD/MM/YYYY')}`,
                          );
                        }}
                      >
                        Export to CSV
                      </Button>
                    </div>
                    <Table
                      loading={isLoading}
                      columns={GAME_FINISHED_TABLE_COLUMNS}
                      rowKey="date"
                      dataSource={preparedTableValues(gamesMonitorData, 'game_finished')}
                      pagination={{
                        position: [ 'bottomCenter' ],
                        pageSizeOptions: [ '10', '25', '50', '100' ],
                        size: 'small',
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                      }}
                    />
                  </TabPane>
                  <TabPane tab={secondTabTitle} key="app_open_per_active_user">
                    <div className='content-right'>
                      <Button
                        className='da-mb-10'
                        size='small'
                        type="primary"
                        disabled={isEmpty(gamesMonitorData)}
                        onClick={() => {
                          exportToCsv(
                            preparedTableValues(gamesMonitorData, 'app_open'),
                            `${secondTabTitle}_${moment().format('DD/MM/YYYY')}`,
                          );
                        }}
                      >
                        Export to CSV
                      </Button>
                    </div>
                    <Table
                      loading={isLoading}
                      columns={APP_OPEN_TABLE_COLUMNS}
                      rowKey="date"
                      dataSource={preparedTableValues(gamesMonitorData, 'app_open')}
                      pagination={{
                        position: [ 'bottomCenter' ],
                        pageSizeOptions: [ '10', '25', '50', '100' ],
                        size: 'small',
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                      }}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Content>
            <BackTop
              visibilityHeight={150}
              target={() => document.getElementById('gamesTopCmp')}
            >
              <div className='back-top-button'>
                <VerticalAlignTopOutlined />
              </div>
            </BackTop>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageGamesMonitor;
