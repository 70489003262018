import { getQueryParams } from './getQueryParams';



export const getDefaultTableSorting = (fieldName) => {
  const queryParams = getQueryParams(window.location.search);
  const sortedByField = queryParams.sortedBy;

  let defaultSorting = '';

  if (queryParams.orderBy === fieldName) {
    defaultSorting = sortedByField === 'asc' ? 'ascend' : sortedByField === 'desc' ? 'descend' : sortedByField;
  }

  return defaultSorting;
};
