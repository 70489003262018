import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { mkNotification } from '../../../lib/mkNotification';
import { setUrlParams } from '../../../lib/setUrlParams';
import StructuresService from '../services/structuresService';



const initialState = {
  structuresList: [],
  structuresPagination: {},
  errors: [],
  loading: false,
  formCreateEditStructureLoading: false,
  formCreateEditStructureErrors: [],
};

const structuresService = new StructuresService(Api);

export const getStructuresListData = createAsyncThunk(
  'structures/getStructuresList',
  async (params) => {
    const response = await structuresService.getStructuresList(params);

    if (!isEmpty(params)) {
      setUrlParams(params);
    }

    return response;
  },
);

export const createStructure = createAsyncThunk(
  'structures/createStructure',
  async (values, { rejectWithValue }) => {
    try {
      const response = await structuresService.createStructure(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteStructure = createAsyncThunk(
  'structures/deleteStructure',
  async (structureId) => {
    const response = await structuresService.deleteStructure(structureId);

    return response.data;
  },
);

export const structuresSlice = createSlice({
  name: 'structures',
  initialState,
  reducers: {
    resetStructuresListData: (state) => {
      state.structuresList = [];
      state.structuresPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStructuresListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStructuresListData.fulfilled, (state, action) => {
        state.loading = false;
        state.structuresList = action.payload.data;
        state.structuresPagination = action.payload.meta.pagination;
      })
      .addCase(getStructuresListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.data;
      });

    builder
      .addCase(createStructure.pending, (state) => {
        state.formCreateEditStructureLoading = true;
      })
      .addCase(createStructure.fulfilled, (state) => {
        state.formCreateEditStructureLoading = false;
        mkNotification('success', 'Structure is successfully created.');
      })
      .addCase(createStructure.rejected, (state, action) => {
        state.formCreateEditStructureLoading = false;
        state.formCreateEditStructureErrors = action.payload.errors;
      });
    builder
      .addCase(deleteStructure.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStructure.fulfilled, (state) => {
        state.loading = false;
        mkNotification('success', 'Structure is successfully deleted.');
      })
      .addCase(deleteStructure.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetStructuresListData } = structuresSlice.actions;

// selectors
export const selectStructuresLoading = (state) => state.structures.loading;
export const selectStructuresList = (state) => state.structures.structuresList;
export const selectStructuresPagination = (state) => state.structures.structuresPagination;
export const selectErrors = (state) => state.structures.errors;

export const selectFormEditStructureLoading = (state) => state.structures.formCreateEditStructureLoading;
export const selectFormEditStructureErrors = (state) => state.structures.formCreateEditStructureErrors;


// reducer
export default structuresSlice.reducer;
