import isEmpty from 'lodash/isEmpty';
import Axios from 'axios';



const getUserIP = () => {
  const storedUserIP = localStorage.getItem('user_ip');
  const setStoreUserIP = (value) => localStorage.setItem('user_ip', value);

  if (isEmpty(storedUserIP)) {
    Axios.get('https://api.db-ip.com/v2/free/self/ipAddress').then((res) => {
      setStoreUserIP(JSON.stringify(res.data));
    }).catch((err) => {
      if (err) {
        setStoreUserIP('');
      }
    });
  }
  return JSON.parse(storedUserIP);
};

export default getUserIP;
