import { APP_ENVIRONMENTS } from '../const/system';



export const getAllUserPolicies = (userPolicies, projectSettings, policy, except = []) => {
  let policies = [];

  if (!except.includes(APP_ENVIRONMENTS.GLOBAL)) {
    policies = userPolicies?.[policy] ?? [];
  }

  // merge space scoped permissions
  if (projectSettings?.spaceKey && !except.includes(APP_ENVIRONMENTS.SPACE)) {
    const spaces = userPolicies?.spaces ?? [];

    if (spaces.length > 0) {
      const currentSpace = spaces.find((space) => space.key === projectSettings.spaceKey);

      policies = [ ...policies, ...currentSpace?.[policy] ?? [] ];

      const projects = currentSpace?.projects ?? [];

      // merge project scoped permissions
      if (projectSettings.projectKey && projects.length > 0 && !except.includes(APP_ENVIRONMENTS.PROJECT)) {
        const currentProject = projects.find((project) => project.key === projectSettings.projectKey);

        policies = [ ...policies, ...currentProject?.[policy] ?? [] ];
      }
    }
  }

  return policies;
};

