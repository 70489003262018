import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from 'antd';



const { Paragraph } = Typography;

const EllipsisRow = ({ data, rowsValue, tooltipPlacement }) => {
  return (
    <Tooltip placement={tooltipPlacement} title={data}>
      <Paragraph ellipsis={{ rows: rowsValue, symbol: '...' }} >
        {data}
      </Paragraph>
    </Tooltip>
  );
};

EllipsisRow.propTypes = {
  data: PropTypes.string.isRequired,
  rowsValue: PropTypes.number,
  tooltipPlacement: PropTypes.string,
};

EllipsisRow.defaultProps = {
  rowsValue: 1,
  tooltipPlacement: 'top',
};

export default EllipsisRow;
