import React, { useEffect } from 'react';
import { Breadcrumb, Col, Layout, Row, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import urlPageLogs from '../../../../../urls/urlPageLogs';
import { Permissions } from '../../../../../const/permissions';
import HasRights from '../../../../../components/HasRights';
import LogDetailsCard from '../../components/LogDetailsCard';
import LogDetailsDiffCard from '../../components/LogDetailsDiffCard';
import { useApp } from '../../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { mkPreparedData } from './PageLogDetails.const';
import {
  getLogDetailsData,
  resetLogDetailsData,
  selectLogDetails,
  selectLogDetailsLoading,
} from '../../feature/logsSlice';
import { useCurrentSettingsScope } from '../../../../../app/hooks';
import { GLOBAL_SETTINGS_PREFIX } from '../../../../../const/system';
import urlPageSettingsLogs from '../../../../../urls/urlPageSettingsLogs';



const { Content } = Layout;

const PageLogDetails = () => {
  const dispatch = useDispatch();
  const { logId } = useParams();
  const { projectSettings } = useApp();

  const data = useSelector(selectLogDetails);
  const isLoading = useSelector(selectLogDetailsLoading);
  const settingsScope = useCurrentSettingsScope();


  useEffect(() => {
    dispatch(getLogDetailsData(logId));
    return () => {
      dispatch(resetLogDetailsData());
    };
  }, []);

  const logData = !isEmpty(data?.params) ? data.params : {};
  const hasDiff = logData.hasOwnProperty('before');

  return (
    <HasRights allowedPermissions={[ Permissions.ADMINISTRATION.LOGS.VIEW_DETAILS ]}>
      <Spin spinning={isLoading} tip="Loading details. Please wait...">
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={mkRedirectToDashboardPath(projectSettings)}>
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={settingsScope === GLOBAL_SETTINGS_PREFIX
                ? urlPageLogs()
                : urlPageSettingsLogs()}
            >
              Logs list
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Log details</Breadcrumb.Item>
        </Breadcrumb>
        <Content className='layout-content'>
          <Row>
            <Col xs={24} md={24} lg={8} style={{ padding: '0 5px' }}>
              <LogDetailsCard
                isLoading={isLoading}
                data={data}
              />
            </Col>
            <Col xs={24} md={24} lg={16} style={{ padding: '0 5px' }}>
              <LogDetailsDiffCard
                hasDiff={hasDiff}
                isLoading={isLoading}
                data={mkPreparedData(logData, hasDiff)}
              />
            </Col>
          </Row>
        </Content>
      </Spin>
    </HasRights>
  );
};


export default PageLogDetails;
