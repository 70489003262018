import { setRequestParams } from '../../../../lib/setRequestParams';



export default class SpacesService {
  constructor (Api) {
    this.api = Api;
  }

  getSpacesList (params) {
    return this.api.get(`spaces${setRequestParams(params)}`);
  }

  createSpace (values) {
    return this.api.post('spaces', values);
  }

  updateSpace ({ spaceId, data }) {
    return this.api.put(`spaces/${spaceId}`, data);
  }

  deleteSpace (spaceId) {
    return this.api.delete(`spaces/${spaceId}`);
  }
}
