import isEmpty from 'lodash/isEmpty';



let previousKey = '';

let previousObj = {};

export const getUiSchema = (obj) => {
  const result = obj;

  // eslint-disable-next-line fp/no-loops,no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      if (obj.hasOwnProperty('properties')) {
        previousObj[previousKey] = obj.properties;
        getUiSchema(obj.properties);
      }
      previousKey = key;
      previousObj = obj;
      getUiSchema(obj[key]);
    } else {
      if (obj.hasOwnProperty('example')) {
        previousObj[previousKey] = { 'ui:placeholder': obj.example };
        previousKey = key;
        previousObj = obj;
      }
      if (obj.hasOwnProperty('readOnly') && (
        obj.hasOwnProperty('anyOf') ||
        obj.hasOwnProperty('oneOf') ||
        obj.hasOwnProperty('allOf'))) {
        previousObj[previousKey] = { 'ui:disabled': obj.readOnly };
        previousKey = key;
        previousObj = obj;
      }
    }
  }
  return result;
};

export const removeDefaultOnChange = (obj) => {
  const res = obj;

  if (!isEmpty(res)) {
    Object.values(res.properties).map((item) => {
      if (item.hasOwnProperty('properties')) {
        Object.keys(item.properties).forEach((key) => {
          if (item.properties[key].hasOwnProperty('default')) {
            // eslint-disable-next-line fp/no-delete
            delete item.properties[key].default;
          }
        });
      }
      return res;
    });
  }
  return res;
};

export const removeEmptyValues = (obj) => {
  const res = obj;

  // eslint-disable-next-line fp/no-loops,no-restricted-syntax,guard-for-in
  for (const key in obj) {
    const value = obj[key];
    const hasProperties = value && Object.keys(value).length > 0;

    if (value === null || value === '') {
      // eslint-disable-next-line fp/no-delete
      delete obj[key];
      // eslint-disable-next-line lodash/prefer-lodash-typecheck
    } else if ((typeof value === 'object' && !Array.isArray(value)) && hasProperties) {
      removeEmptyValues(value);
    }
  }
  return res;
};
