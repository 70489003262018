import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';
import StructureDetailsService from '../services/structureDetailsService';



const initialState = {
  structureDetails: {},
  errors: [],
  loading: false,
};

const structureDetailsService = new StructureDetailsService(Api);

export const getStructureDetails = createAsyncThunk(
  'structures/getStructure',
  async (structureId, { rejectWithValue }) => {
    try {
      const response = await structureDetailsService.getDetails(structureId);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const structureDetailsSlice = createSlice({
  name: 'structureDetails',
  initialState,
  reducers: {
    resetStructureDetailsData: (state) => {
      state.structureDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStructureDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStructureDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.structureDetails = action.payload;
      })
      .addCase(getStructureDetails.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.data;
      });
  },
});

// actions
export const { resetStructureDetailsData } = structureDetailsSlice.actions;

// selectors
export const selectStructureDetailsLoading = (state) => state.structureDetails.loading;
export const selectStructureDetails = (state) => state.structureDetails.structureDetails;
export const selectErrors = (state) => state.structureDetails.errors;


// reducer
export default structureDetailsSlice.reducer;
