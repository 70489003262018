import React from 'react';
import { Card, Col, Empty, Row, Spin } from 'antd';
import { RiArrowRightDownLine, RiArrowRightUpLine } from 'react-icons/ri';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { useCheckMobileScreen } from '../../../../app/hooks';
import mkSecondsToDateRange from '../../../../lib/mkSecondsToDateRange';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const WidgetItemMetricSideIconWr = styled.div`
  display: flex;
  padding: 6px;
  border-radius: 5px;
  background: #1689fc1f;
`;


const WidgetItemMetric = ({ title, icon, data, isLoading, image, hasDate }) => {
  const isMobile = useCheckMobileScreen();
  const preparedTotal = hasDate && data.total ? mkSecondsToDateRange(data.total) : data.total;

  return (
    <Card className="da-border-color-black-40 da-mb-16 da-card-2">
      <Row justify="space-between">
        <h5 className="da-mr-8">{title}</h5>
        {icon && (
          <WidgetItemMetricSideIconWr>
            {icon}
          </WidgetItemMetricSideIconWr>
        )}
      </Row>

      <Spin spinning={isLoading} tip="Loading...">
        {isEmpty(data) ? (
          <Empty
            imageStyle={{ display: 'none' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span>No data available</span>
            }
          />
        ) : (
          <>
            <Row justify="start">
              <h2 className="da-my-4">{isNumber(data.total) ? preparedTotal : EMPTY_VALUE_PLACEHOLDER}</h2>
            </Row>

            <Row justify="space-between" className='da-mt-10'>
              <h3 className="da-badge-text da-mb-0 da-text-color-black-80 da-text-color-dark-30">
                Since last month
              </h3>
              <Row align='center'>
                {data.is_positive ? (
                  <RiArrowRightUpLine className="da-text-color-success-1" size={16} />
                ) : (
                  <RiArrowRightDownLine className="da-text-color-danger-1" size={16} />
                )}
                {data.change && (
                  <p className={`da-mb-0 da-badge-text ${data.is_positive ? 'da-text-color-success-1' : 'da-text-color-danger-1'}`}>
                    {`${Number(data.change).toFixed()}%`}
                  </p>
                )}
              </Row>
            </Row>
          </>
        )}
        {image && !isMobile && (
          <Col className="da-text-center" span={24}>
            {image}
          </Col>
        )}
      </Spin>
    </Card>
  );
};


WidgetItemMetric.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  hasDate: PropTypes.bool,
  data: PropTypes.object,
  icon: PropTypes.any,
  image: PropTypes.any,
};

WidgetItemMetric.defaultProps = {
  icon: null,
  image: null,
  isLoading: false,
  hasDate: false,
  data: {},
};

export default WidgetItemMetric;
