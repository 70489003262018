import React, { useCallback } from 'react';
import { Dropdown, Avatar, Button, Col, Menu } from 'antd';
import { Logout } from 'react-iconly';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import avatarImg from '../../../../assets/images/memoji/memoji-1.png';
import { logoutUser } from '../../../../app/auth/feature/AuthSlice';
import { useApp } from '../../../../app/context/AppContext';



export default function HeaderUser () {
  const dispatch = useDispatch();
  const { userData } = useApp();


  const logout = useCallback(() => {
    dispatch(logoutUser());
  }, []);


  const menu = (
    <Menu theme="light">
      <Menu.Item
        key={5}
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        <Button
          className='link-btn'
          type='link'
          onClick={logout}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Col className="da-d-flex-center" onClick={(ev) => ev.preventDefault()}>
          <Avatar
            src={!isEmpty(userData?.user?.avatar) ? userData?.user?.avatar : avatarImg}
            size={40}
          />
        </Col>
      </Dropdown>
    </Col>
  );
}
