export default class ProjectDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  getDetails (projectId) {
    return this.api.get(`projects/${projectId}`);
  }

  createProject (values) {
    return this.api.post('projects', values);
  }

  updateProject ({ projectId, values }) {
    return this.api.put(`projects/${projectId}`, values);
  }
}
