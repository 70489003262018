import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import AbTestDetailsService from '../services/abTestDetailsService';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  data: {},
  loading: false,
  statisticsData: [],
  statisticsLoading: false,
  isLoadedInitialStatistics: false,
  getStatisticsPolling: false,
  createAbTestData: [],
  createAbTestErrors: {},
  createAbTestLoading: false,
  updateAbTestData: [],
  updateAbTestErrors: {},
  updateAbTestLoading: false,
  publishAbTestLoading: false,
};

const abTestDetailsService = new AbTestDetailsService(Api);

export const getAbTestDetails = createAsyncThunk(
  'abTestDetails/getAll',
  async (abTestName, { rejectWithValue }) => {
    try {
      const response = await abTestDetailsService.getDetails(abTestName);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getStatisticsData = createAsyncThunk(
  'abTestDetails/getStatistic',
  async (name, { rejectWithValue }) => {
    try {
      return abTestDetailsService.getStatistic(name);
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createAbTest = createAsyncThunk(
  'abTests/createAbTest',
  async (values, { rejectWithValue }) => {
    try {
      const response = await abTestDetailsService.createAbTest(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateAbTest = createAsyncThunk(
  'abTests/updateAbTest',
  async ({ abTestName, data }, { rejectWithValue }) => {
    try {
      const response = await abTestDetailsService.updateAbTest({ abTestName, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const publishAbTest = createAsyncThunk(
  'abTests/publishAbTest',
  async ({ abTestId }, { rejectWithValue }) => {
    try {
      const response = await abTestDetailsService.publishTest({ abTestId });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const abTestDetailsSlice = createSlice({
  name: 'abTestDetails',
  initialState,
  reducers: {
    resetAbTestDetails: (state) => {
      state.data = {};
    },
    resetStatisticsData: (state) => {
      state.statisticsData = [];
      state.getStatisticsPolling = false;
      state.isLoadedInitialStatistics = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAbTestDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAbTestDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAbTestDetails.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getStatisticsData.pending, (state) => {
        state.statisticsLoading = true;
      })
      .addCase(getStatisticsData.fulfilled, (state, action) => {
        state.statisticsLoading = false;
        state.isLoadedInitialStatistics = action.payload.status === 200;
        state.statisticsData = action.payload.data;
        state.getStatisticsPolling = isEmpty(action.payload.data) && action.payload.status === 202;
      })
      .addCase(getStatisticsData.rejected, (state) => {
        state.statisticsLoading = false;
      });

    builder
      .addCase(createAbTest.pending, (state) => {
        state.createAbTestLoading = true;
        state.createAbTestErrors = {};
      })
      .addCase(createAbTest.fulfilled, (state, action) => {
        state.createAbTestLoading = false;
        state.createAbTestData = action.payload;
        mkNotification('success', 'Ab-test is successfully created.');
      })
      .addCase(createAbTest.rejected, (state, action) => {
        state.createAbTestLoading = false;

        if (action.payload.hasOwnProperty('error')) {
          mkNotification('error', action.payload.error);
        } else {
          state.createAbTestErrors = action.payload.errors;
        }
      });

    builder
      .addCase(updateAbTest.pending, (state) => {
        state.updateAbTestLoading = true;
        state.updateAbTestErrors = {};
      })
      .addCase(updateAbTest.fulfilled, (state, action) => {
        state.updateAbTestLoading = false;
        state.updateAbTestData = action.payload;
        mkNotification('success', 'Ab-test is successfully updated.');
      })
      .addCase(updateAbTest.rejected, (state, action) => {
        state.updateAbTestLoading = false;
        state.updateAbTestErrors = action.payload.errors;
      });

    builder
      .addCase(publishAbTest.pending, (state) => {
        state.publishAbTestLoading = true;
      })
      .addCase(publishAbTest.fulfilled, (state, action) => {
        state.publishAbTestLoading = false;
        state.data = action.payload;
        mkNotification('success', 'Ab-test is successfully published.');
      })
      .addCase(publishAbTest.rejected, (state) => {
        state.publishAbTestLoading = false;
      });
  },
});

// actions
export const { resetAbTestDetails, resetStatisticsData } = abTestDetailsSlice.actions;

// selectors
export const selectLoading = (state) => state.abTestDetails.loading;
export const selectData = (state) => state.abTestDetails.data;

export const selectStatisticsLoading = (state) => state.abTestDetails.statisticsLoading;
export const selectIsLoadedInitialStatistics = (state) => state.abTestDetails.isLoadedInitialStatistics;
export const selectStatisticsData = (state) => state.abTestDetails.statisticsData;
export const selectStatisticsPolling = (state) => state.abTestDetails.getStatisticsPolling;
export const selectCreateAbTestLoading = (state) => state.abTestDetails.createAbTestLoading;
export const selectCreateAbTestData = (state) => state.abTestDetails.createAbTestData;
export const selectCreateAbTestErrors = (state) => state.abTestDetails.createAbTestErrors;
export const selectUpdateAbTestLoading = (state) => state.abTestDetails.updateAbTestLoading;
export const selectUpdateAbTestData = (state) => state.abTestDetails.updateAbTestData;
export const selectUpdateAbTestErrors = (state) => state.abTestDetails.updateAbTestErrors;
export const selectPublishAbTestLoading = (state) => state.abTestDetails.publishAbTestLoading;

// reducer
export default abTestDetailsSlice.reducer;
