import React, { useEffect, useState } from 'react';
import { BackTop, Breadcrumb, Button, Card, Col, Layout, Row, Table } from 'antd';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Link } from 'react-router-dom';
import MonitorFilter from '../../components/MonitorFilter';
import MonitorCharts from '../../components/MonitorCharts';
import { preparedTableValues, ADS_TABLE_COLUMNS } from './PageAdsMonitor.const';
import { exportToCsv } from '../../../../lib/exportToCsv';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import {
  getAdsMonitoringData, getNetworksData,
  resetAdsMonitoringData,
  resetNetworksOptionsData,
  selectAdsData, selectAdsLoading,
  selectAdsPolling,
  selectLoadingNetworks,
  selectNetworkOptions,
} from '../../feature/adsMonitorSlice';
import { getObjectAverageValues } from '../../../../lib/getObjectAverageValues';
import { getAxis } from '../../../../lib/getAxis';
import { getPreparedChartsData } from '../../../../lib/getPreparedChartsData';
import ChartsTooltip from '../../components/ChartsTooltip';
import PollingNotificationModal from '../../../../components/PollingNotificationModal';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import { MONITORING_POLLING_INTERVAL } from '../../../../const/system';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Content } = Layout;

const PageAdsMonitor = () => {
  const { projectSettings } = useApp();
  const dispatch = useDispatch();
  const [ savedFilterValues, setSaveFilterValues ] = useState(null);
  const [ isActiveNotificationModal, setToggleNotificationModal ] = useState(false);

  const data = useSelector(selectAdsData);
  const getAdsPolling = useSelector(selectAdsPolling);
  const isLoading = useSelector(selectAdsLoading);
  const isLoadingNetworks = useSelector(selectLoadingNetworks);
  const networkOptions = useSelector(selectNetworkOptions);

  const arrayData = getPreparedChartsData(data, {
    impressions_per_user: 0,
    ecpm: 0,
    prev_impressions_per_user: 0,
    prev_ecpm: 0,
  }, '', true);
  const adsMonitorData = arrayData.slice(1, arrayData.length);
  const avgChartValues = getObjectAverageValues(arrayData);
  const axisData = getAxis(avgChartValues);
  const tableAdsData = preparedTableValues(adsMonitorData);
  const tableTitle = 'Impressions per user per ECPM per user';

  const handleSetFilter = (values) => {
    if (moment(values.to).diff(moment(values.from), 'days') > 3) {
      setSaveFilterValues(values);
      setToggleNotificationModal(true);
    } else {
      dispatch(getNetworksData());
    }
    dispatch(getAdsMonitoringData(values));
  };

  useEffect(() => {
    const adsIntervalId = setInterval(() => {
      return getAdsPolling ? dispatch(getAdsMonitoringData(savedFilterValues)) : null;
    }, MONITORING_POLLING_INTERVAL);

    return () => {
      clearInterval(adsIntervalId);
    };
  }, [ getAdsPolling ]);

  useEffect(() => {
    if (!isEmpty(data) || !getAdsPolling) {
      setToggleNotificationModal(false);
    }
  }, [ data ]);

  useEffect(() => {
    if (!isEmpty(window.location.search)) {
      handleSetFilter(getObjectValuesByUrlParams());
    } else {
      dispatch(getAdsMonitoringData());
      dispatch(getNetworksData());
    }
    return () => {
      dispatch(resetAdsMonitoringData());
      dispatch(resetNetworksOptionsData());
    };
  }, []);

  const initialFilterValues = isEmpty(window.location.search) ? {
    app_version: '',
    platform: null,
    format: null,
    network: null,
    countries: null,
    data_grouping: 'hourly',
    from: moment().subtract(3, 'd').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  } : getObjectValuesByUrlParams();


  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Advertise monitoring</Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} lg={6}>
            <div className="layout-content">
              <CollapsibleFilterWr
                title='Filter'
                keyValue='ads-monitor-filter'
                maxDeviceWidth={992}
              >
                <MonitorFilter
                  isAdsMonitorFilter
                  networkOptions={networkOptions}
                  onSubmit={handleSetFilter}
                  isSubmitting={isLoading || isLoadingNetworks || getAdsPolling}
                  initialValues={initialFilterValues}
                />
              </CollapsibleFilterWr>
            </div>
          </Col>
          <Col xs={24} lg={18}>
            <Content className='layout-content' id='adsTopCmp'>
              <Card className='charts-container'>
                <ChartsTooltip />
                <MonitorCharts
                  isLoading={isLoading}
                  mainData={adsMonitorData}
                  axisData={axisData}
                />
              </Card>

              <Card className='card-has-table'>
                <div className='table-title-content-wr da-mb-10'>
                  <div className='table-title'>{tableTitle}</div>
                  <Button
                    size='small'
                    type="primary"
                    disabled={isEmpty(tableAdsData)}
                    onClick={() => {
                      exportToCsv(tableAdsData, `${tableTitle}_${moment().format('DD/MM/YYYY')}`);
                    }}
                  >
                    Export to CSV
                  </Button>
                </div>

                <Table
                  loading={isLoading}
                  columns={ADS_TABLE_COLUMNS}
                  dataSource={tableAdsData}
                  pagination={{
                    position: [ 'bottomCenter' ],
                    pageSizeOptions: [ '10', '25', '50', '100' ],
                    size: 'small',
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                  }}
                  scroll={{ y: 240 }}
                />
              </Card>
            </Content>
            <BackTop
              visibilityHeight={150}
              target={() => document.getElementById('adsTopCmp')}
            >
              <div className='back-top-button'>
                <VerticalAlignTopOutlined />
              </div>
            </BackTop>
          </Col>
        </Row>
      </Content>

      <PollingNotificationModal
        title='Ads notification'
        isActiveModal={isActiveNotificationModal}
        toggleModal={setToggleNotificationModal}
      />
    </>
  );
};

export default PageAdsMonitor;
