import React from 'react';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { Tooltip } from 'antd';
import { RiInformationLine } from 'react-icons/ri';
import Config from '../../../../../../config';
import Yup from '../../../../../../vendor/yup';
import { mkPreparedFormData } from '../PageUsers.const';
import mkRegexFromDomainsArray from '../../../../../../lib/mkRegexFromDomainsArray';



const { Option } = Select;


const FormCreateEditRoleButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditRoleButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;


const allowedDomains = !isEmpty(Config.VITE_ALLOWED_EMAIL_DOMAINS) ? Config.VITE_ALLOWED_EMAIL_DOMAINS.split(', ') : [];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Min 2 symbols required')
    .max(50, 'Max 50 symbols required')
    .required('Name field is required'),
  email: Yup.string()
    .email('Invalid email format')
    .matches(mkRegexFromDomainsArray(allowedDomains), 'Invalid email domain')
    .required('Email is required'),
  space_ids: Yup.array().nullable(),
});


const FormCreateEditUser = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
  spacesOptions,
  spaceKey,
  isEdit,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(mkPreparedFormData(values, spaceKey, spacesOptions));
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, values, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <FormItem
              className='ant-form-item-col'
              name="name"
              label="Name:"
              required
            >
              <Input
                name='name'
                disabled={isEdit || isSubmitting}
                placeholder='Specify user name'
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="email"
              required
              label={
                <div className='content-right'>
                  Email:
                  <Tooltip placement="top" title={`Register allowed domains: ${allowedDomains.length !== 0 ? allowedDomains.map((domain) => ` ${domain}`) : ''}`}>
                    <RiInformationLine size={18} />
                  </Tooltip>
                </div>
              }
            >
              <Input
                name='email'
                disabled={isEdit || isSubmitting}
                placeholder='Specify email'
              />
            </FormItem>

            {!spaceKey && (
              <FormItem
                className='ant-form-item-col'
                name="space_ids"
                label={
                  <div className='content-right'>
                    Spaces:
                    <Tooltip placement="top" title="On user spaces deleting, the roles and rights related to this space will be deleted">
                      <RiInformationLine size={18} />
                    </Tooltip>
                  </div>
                }
              >
                <Select
                  mode='multiple'
                  allowClear
                  showSearch
                  disabled={isSubmitting}
                  name="space_ids"
                  value={values?.space_ids}
                  placeholder="Select spaces"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(spacesOptions) ? spacesOptions.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {`[${item.key}] ${item.name}`}
                      </Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            )}

            <FormCreateEditRoleButtonsRow>
              <FormCreateEditRoleButtonWr>
                <SubmitButton
                  style={{ width: '100%' }}
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  {isEdit ? 'Update' : 'Create'}
                </SubmitButton>
              </FormCreateEditRoleButtonWr>
            </FormCreateEditRoleButtonsRow>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditUser.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    space_ids: PropTypes.array,
  }),
  spacesOptions: PropTypes.array,
  spaceKey: PropTypes.string,
  isEdit: PropTypes.bool,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

FormCreateEditUser.defaultProps = {
  formErrors: {},
  spacesOptions: [],
  isEdit: false,
  values: {},
};

export default FormCreateEditUser;
