import React from 'react';
import {
  Activity, Game, User,
  Category, Discount,
  Document, Filter2, Graph,
  Password, People, Unlock, Discovery,
  PaperPlus, Heart, Message, Paper, Setting,
} from 'react-iconly';
import { Permissions } from '../../../const/permissions';
import urlPageGamesMonitor from '../../../urls/urlPageGamesMonitor';
import urlPageAppsMonitor from '../../../urls/urlPageAppsMonitor';
import urlPageAdsMonitor from '../../../urls/urlPageAdsMonitor';
import urlPageHintsMonitor from '../../../urls/urlPageHintsMonitor';
import urlPageContactUsMonitor from '../../../urls/urlPageContactUsMonitor';
import urlPageUserEvents from '../../../urls/urlPageUserEvents';
import urlPageEventsSequence from '../../../urls/urlPageEventsSequence';
import urlPageUsers from '../../../urls/urlPageUsers';
import urlPagePermissions from '../../../urls/urlPagePermissions';
import urlPageRoles from '../../../urls/urlPageRoles';
import urlPageUserProfiles from '../../../urls/urlPageUserProfiles';
import urlPageTickets from '../../../urls/urlPageTickets';
import urlPageConfigurations from '../../../urls/urlPageConfigurations';
import urlPageStructures from '../../../urls/urlPageStructures';
import urlPageMixinVisualize from '../../../urls/urlPageMixinVisualize';
import urlPageConfigVisualize from '../../../urls/urlPageConfigVisualize';
import urlPageProjects from '../../../urls/urlPageProjects';
import urlPageSpaces from '../../../urls/urlPageSpaces';
import urlPageAbTests from '../../../urls/urlPageAbTests';
import urlPageConsentProvider from '../../../urls/urlPageConsentProvider';
import urlPageLogs from '../../../urls/urlPageLogs';
import urlPageSettingsUsers from '../../../urls/urlPageSettingsUsers';
import urlPageSettingsPermissions from '../../../urls/urlPageSettingsPermissions';
import urlPageSettingsRoles from '../../../urls/urlPageSettingsRoles';
import urlPageSettingsProjects from '../../../urls/urlPageSettingsProjects';
import urlPageSettingsLogs from '../../../urls/urlPageSettingsLogs';
import urlPageMixinsExperiments from '../../../urls/urlPageMixinsExperiments';



const pages = [
  {
    header: 'Administration',
    groupPermissions: Permissions.ADMINISTRATION.VIEW_ALL,
    subMenu: [
      {
        id: 'administration',
        title: 'Global',
        icon: <Setting set="curved" className="remix-icon" />,
        permission: Permissions.ADMINISTRATION.GLOBAL_ADMIN_MENU,
        children: [
          {
            id: 'administration-users-list',
            title: 'Users',
            icon: <People set="curved" />,
            navLink: urlPageUsers(),
            permission: Permissions.ADMINISTRATION.USERS.USERS_LIST.VIEW_PAGE,
          },
          {
            id: 'administration-permissions-list',
            title: 'Permissions',
            icon: <Password set="curved" className="remix-icon" />,
            navLink: urlPagePermissions(),
            permission: Permissions.ADMINISTRATION.PERMISSIONS.PERMISSIONS_LIST.VIEW_PAGE,
          },
          {
            id: 'administration-roles-list',
            title: 'Roles',
            icon: <Unlock set="curved" className="remix-icon" />,
            navLink: urlPageRoles(),
            permission: Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE,
          },
          {
            id: 'administration-spaces-list',
            title: 'Spaces',
            icon: <Graph set="curved" className="remix-icon" />,
            navLink: urlPageSpaces(),
            permission: Permissions.ADMINISTRATION.SPACES.VIEW_LIST,
          },
          {
            id: 'administration-projects-list',
            title: 'Projects',
            icon: <Category set="curved" className="remix-icon" />,
            navLink: urlPageProjects(),
            permission: Permissions.ADMINISTRATION.PROJECTS.VIEW_LIST,
          },
          {
            id: 'administration-logs-list',
            title: 'Logs',
            icon: <Paper set="curved" />,
            navLink: urlPageLogs(),
            permission: Permissions.ADMINISTRATION.LOGS.VIEW_PAGE,
          },
        ],
      },
      {
        id: 'settings',
        title: 'Space',
        icon: <Graph set="curved" className="remix-icon" />,
        permission: Permissions.ADMINISTRATION.SPACE_ADMIN_MENU,
        children: [
          {
            id: 'settings-users-list',
            title: 'Users',
            icon: <People set="curved" />,
            navLink: urlPageSettingsUsers(),
            permission: Permissions.ADMINISTRATION.USERS.USERS_LIST.VIEW_PAGE,
          },
          {
            id: 'settings-permissions-list',
            title: 'Permissions',
            icon: <Password set="curved" className="remix-icon" />,
            navLink: urlPageSettingsPermissions(),
            permission: Permissions.ADMINISTRATION.PERMISSIONS.PERMISSIONS_LIST.VIEW_PAGE,
          },
          {
            id: 'settings-roles-list',
            title: 'Roles',
            icon: <Unlock set="curved" className="remix-icon" />,
            navLink: urlPageSettingsRoles(),
            permission: Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE,
          },
          {
            id: 'settings-projects-list',
            title: 'Projects',
            icon: <Category set="curved" className="remix-icon" />,
            navLink: urlPageSettingsProjects(),
            permission: Permissions.ADMINISTRATION.PROJECTS.VIEW_LIST,
          },
          {
            id: 'settings-logs-list',
            title: 'Logs',
            icon: <Paper set="curved" />,
            navLink: urlPageSettingsLogs(),
            permission: Permissions.ADMINISTRATION.LOGS.VIEW_PAGE,
          },
        ],
      },
    ],
  },
  {
    id: 'user-profiles',
    header: 'User profiles',
    icon: <People set="curved" />,
    navLink: urlPageUserProfiles(),
    groupPermissions: Permissions.USER_PROFILES.VIEW_ALL,
  },
  {
    id: 'support',
    header: 'Support',
    groupPermissions: Permissions.SUPPORT.VIEW_ALL,
    subMenu: [
      {
        id: 'tickets',
        title: 'Tickets',
        icon: <Document set="curved" className="remix-icon" />,
        navLink: urlPageTickets(),
        permission: Permissions.SUPPORT.TICKET.VIEW_PAGE,
      },
      {
        id: 'user-events',
        title: 'User Events',
        icon: <User set="curved" className="remix-icon" />,
        navLink: urlPageUserEvents(),
        permission: Permissions.SUPPORT.USER_EVENTS.VIEW_PAGE,
      },
      {
        id: 'events-sequence',
        title: 'Events Sequence',
        icon: <Filter2 set="curved" className="remix-icon" />,
        navLink: urlPageEventsSequence(),
        permission: Permissions.SUPPORT.EVENTS_SEQUENCE.VIEW_PAGE,
      },
    ],
  },
  {
    id: 'monitoring',
    header: 'Monitoring',
    groupPermissions: Permissions.MONITORING.VIEW_ALL,
    subMenu: [
      {
        id: 'games',
        title: 'Games',
        icon: <Game set="curved" className="remix-icon" />,
        navLink: urlPageGamesMonitor(),
        permission: Permissions.MONITORING.GAMES_MONITOR.VIEW_PAGE,
      },
      {
        id: 'apps',
        title: 'Applications',
        icon: <Category set="curved" className="remix-icon" />,
        navLink: urlPageAppsMonitor(),
        permission: Permissions.MONITORING.APPLICATION_MONITOR.VIEW_PAGE,
      },
      {
        id: 'ads',
        title: 'Advertise',
        icon: <Activity set="curved" className="remix-icon" />,
        navLink: urlPageAdsMonitor(),
        permission: Permissions.MONITORING.ADS_MONITOR.VIEW_PAGE,
      },
      {
        id: 'hints',
        title: 'Hints',
        icon: <Heart set="curved" className="remix-icon" />,
        navLink: urlPageHintsMonitor(),
        permission: Permissions.MONITORING.HINTS.VIEW_PAGE,
      },
      {
        id: 'contact-us',
        title: 'Contact us',
        icon: <Message set="curved" className="remix-icon" />,
        navLink: urlPageContactUsMonitor(),
        permission: Permissions.MONITORING.CONTACT_US.VIEW_PAGE,
      },
    ],
  },
  {
    id: 'ab-tests',
    header: 'AB tests',
    icon: <Discount set="curved" className="remix-icon" />,
    navLink: urlPageAbTests(),
    groupPermissions: Permissions.AB_TESTS.VIEW_PAGE,
  },
  {
    id: 'config',
    header: 'Configs',
    groupPermissions: [
      Permissions.CONFIGS.CONFIGURATIONS.VIEW_LIST,
      Permissions.CONFIGS.STRUCTURES.VIEW_LIST,
    ],
    subMenu: [
      {
        id: 'configurations',
        title: 'Configurations',
        icon: <Document set="curved" className="remix-icon" />,
        navLink: urlPageConfigurations(),
        permission: Permissions.CONFIGS.CONFIGURATIONS.VIEW_LIST,
      },
      {
        id: 'config-visualize',
        title: 'Config visualize',
        icon: <Paper set="curved" className="remix-icon" />,
        navLink: urlPageConfigVisualize(),
        permission: Permissions.CONFIGS.CONFIG_VISUALIZE.VIEW_LIST_AND_DETAILS,
      },
      {
        id: 'mixin-visualize',
        title: 'Mixin visualize',
        icon: <Discovery set="curved" className="remix-icon" />,
        navLink: urlPageMixinVisualize(),
        permission: Permissions.CONFIGS.MIXIN_VISUALIZE.VIEW_MIXINS_LIST,
      },
      {
        id: 'structures',
        title: 'Structures',
        icon: <PaperPlus set="curved" className="remix-icon" />,
        navLink: urlPageStructures(),
        permission: Permissions.CONFIGS.STRUCTURES.VIEW_LIST,
      },
    ],
  },
  {
    id: 'experiments',
    header: 'Experiments',
    groupPermissions: [
      Permissions.MIXINS_EXPERIMENTS.VIEW_ALL_LIST,
      Permissions.MIXINS_EXPERIMENTS.VIEW_OWN_LIST,
      Permissions.MIXINS_EXPERIMENTS.VIEW_FREE_LIST,
    ],
    subMenu: [
      {
        id: 'mixins-experiments',
        title: 'Mixins',
        icon: <Graph set="curved" className="remix-icon" />,
        navLink: urlPageMixinsExperiments(),
        groupPermissions: [
          Permissions.MIXINS_EXPERIMENTS.VIEW_ALL_LIST,
          Permissions.MIXINS_EXPERIMENTS.VIEW_OWN_LIST,
          Permissions.MIXINS_EXPERIMENTS.VIEW_FREE_LIST,
        ],
      },
    ],
  },
  {
    id: 'consent-provider',
    header: 'Consent provider',
    icon: <Category set="curved" className="remix-icon" />,
    navLink: urlPageConsentProvider(),
    groupPermissions: [ Permissions.REQUESTS.VIEW_LIST ],
  },
];

export default pages;
