import { InfoCircle } from 'react-iconly';
import { Alert, Button, Modal, Tag } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';



const ModalConfirmPublishExperimentIPsContent = styled.div`
  display: block;
  min-height: 25px;
  max-height: 150px;
  overflow: scroll;
  width: 100%;
`;

const ModalConfirmPublishExperimentText = styled.div`
  margin: 0 12px 12px 12px;
`;


const ModalConfirmPublishExperiment = ({
  isActiveModal,
  toggleModal,
  handleSubmit,
  targetLink,
  hasIpCheck,
  userIP,
  testTargetIPs,
  isBlockSandbox,
  isRevertFromSandbox,
}) => {
  return (
    <Modal
      title={
        <div className='da-d-flex-center'>
          <InfoCircle set="curved" />
          &nbsp;Notification
        </div>
      }
      width={540}
      centered
      closable
      destroyOnClose
      visible={isActiveModal}
      onCancel={() => toggleModal(false)}
      footer={(!isEmpty(testTargetIPs) && hasIpCheck) || !hasIpCheck ? [
        <Button
          key="cancel"
          onClick={() => toggleModal(false)}
        >
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={() => {
            toggleModal(false);
            handleSubmit();
          }}
        >
          OK
        </Button>,
      ] : []}
    >
      <div>
        {isRevertFromSandbox ? (
          <ModalConfirmPublishExperimentText>
            {`Are you sure you want exclude ${isBlockSandbox ? 'and unblock' : ''} recent experiment from sandbox?`}
          </ModalConfirmPublishExperimentText>
        ) : (
          <>
            <ModalConfirmPublishExperimentText>
              {`Are you sure you want to send ${isBlockSandbox ? 'and block' : ''} recent experiment to sandbox?`}
            </ModalConfirmPublishExperimentText>
            {hasIpCheck && (
              <Alert
                type="info"
                message={
                  <>
                    <div className='da-mb-12'>
                      {isEmpty(testTargetIPs) ? 'Recent target IPs list is empty. You need to add IP address.' : `Please check if provided target IPs list is correct and includes your IP address ${userIP}:`}
                    </div>
                    {!isEmpty(testTargetIPs) && (
                      <ModalConfirmPublishExperimentIPsContent>
                        {testTargetIPs.map((item) => {
                          return (
                            <div key={item}>
                              <Tag>{item}</Tag>
                            </div>
                          );
                        })}
                      </ModalConfirmPublishExperimentIPsContent>
                    )}
                    <div className='da-mt-12'>
                      You can edit targeting IPs list&nbsp;
                      <a href={targetLink}>here</a>
                    </div>
                  </>
                }
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

ModalConfirmPublishExperiment.propTypes = {
  isActiveModal: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  testTargetIPs: PropTypes.array,
  targetLink: PropTypes.string,
  userIP: PropTypes.string,
  isSandbox: PropTypes.bool,
  hasIpCheck: PropTypes.bool,
  isBlockSandbox: PropTypes.bool,
  isRevertFromSandbox: PropTypes.bool,
};

ModalConfirmPublishExperiment.defaultProps = {
  testTargetIPs: [],
  userIP: null,
  targetLink: null,
  isActiveModal: false,
  hasIpCheck: false,
  isSandbox: false,
  isBlockSandbox: false,
  isRevertFromSandbox: false,
};

export default ModalConfirmPublishExperiment;
