import { Breadcrumb, Button, Card, Checkbox, Col, Input, Layout, List, Modal, Row, Spin, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';
import './index.css';
import ReactJson from 'react-json-view';
import SearchableJsonViewer from 'searchable-react-json-view';
import { RiInformationLine } from 'react-icons/ri';
import { useCheckMobileScreen, useLocalStorage } from '../../../../app/hooks';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import FormMixinVisualizeFilter from '../../forms/FormMixinVisualizeFilter';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import { CONFIG_MIXINS_TABLE_COLUMNS, getRecentNonArrayPath } from './PageMixinVisualize.const';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import {
  getAllConfigurationsListData,
  resetConfigurationsListData,
  selectAllConfigurationsList,
  selectConfigurationsLoading,
} from '../../feature/configurationsSlice';
import {
  resetConfigurationData,
  getMixinsListData,
  resetMixinsListData,
  resetConfigurationRowData,
  selectConfigurationData,
  selectConfigurationDataLoading,
  selectMixinsList,
  selectMixinsListLoading,
  getConfigurationData,
  getConfigurationRowData,
  selectConfigurationRowDataLoading,
  selectConfigurationRowData, mixinsVisualizeSlice, selectMixinsListErrors,
} from '../../feature/mixinsVisualizeSlice';



const { Content } = Layout;


const MixinColContainer = styled(Col)`
  border-left: ${({ isMobile }) => isMobile ? 'unset' : '1px solid #f0f0f0'};
  overflow: auto;
  padding-left: 20px !important;
`;

const MixinsListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 13px;
  width: 100%;
`;


const PageMixinVisualize = () => {
  const { projectSettings } = useApp();
  const { configurationId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useCheckMobileScreen();
  const initialFormData = getObjectValuesByUrlParams();
  const [ searchText, setSearchText ] = useState('');
  const [ configId, setConfigId ] = useState(null);
  const [ selectedRowPath, setSelectedRowPath ] = useState(null);
  const [ selectedMixinIds, setSelectedMixinIds ] = useState([]);
  const [ isActiveMixinDetailsModal, setToggleMixinDetailsModal ] = useState(false);
  const [ isActiveConfigRowDetailsModal, setToggleConfigRowDetailsModal ] = useState(false);
  const [ storedMixinDetails, setMixinDetails ] = useState(null);
  const [ filterValues, setFilterValues ] = useLocalStorage('mixin_visualize_filter', {});

  const configsList = useSelector(selectAllConfigurationsList);
  const isLoadingConfigsList = useSelector(selectConfigurationsLoading);
  const configurationData = useSelector(selectConfigurationData);
  const isLoadingConfig = useSelector(selectConfigurationDataLoading);
  const configurationRowData = useSelector(selectConfigurationRowData);
  const isLoadingConfigRow = useSelector(selectConfigurationRowDataLoading);
  const mixinsList = useSelector(selectMixinsList);
  const filterErrors = useSelector(selectMixinsListErrors);
  const isLoadingMixinsList = useSelector(selectMixinsListLoading);

  const configObj = cloneDeep(configurationData);
  const preparedMixinDetails = !isEmpty(storedMixinDetails) ? storedMixinDetails.config : null;
  const mixinsIdsList = !isEmpty(mixinsList) ? mixinsList.map((item) => item.id) : [];
  const initialValues = { config: !isEmpty(configurationId) ? Number(configurationId) : null,
    ...getNotEmptyObjectValues({
      ...initialFormData,
      lat: !isEmpty(initialFormData.lat) ? JSON.parse(initialFormData.lat) : null,
      special_install_date: !isEmpty(initialFormData?.special_install_date) ? JSON.parse(initialFormData?.special_install_date) : null,
    }) };


  const handleSetFilter = (values) => {
    setFilterValues(values);
    const configId = isEmpty(values) ? configurationId : Number(values.config);

    if (configId) {
      setConfigId(configId);

      window.scrollTo(0, 0);

      dispatch(getMixinsListData({ configId, params: values })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          const responseData = response.payload.data;
          const mixins = !isEmpty(responseData) ? responseData.map((item) => item.id) : [];

          setSelectedMixinIds(mixins);

          dispatch(getConfigurationData({ configId, values: { mixins } }));
        }
      });
    }

    return () => {
      dispatch(resetMixinsListData());
      dispatch(resetConfigurationData());
    };
  };

  useEffect(() => {
    dispatch(getAllConfigurationsListData({ limit: -1 }));
    if (!isEmpty(configurationId) || !isEmpty(filterValues)) {
      handleSetFilter(filterValues);
    }

    return () => {
      dispatch(resetConfigurationsListData());
      dispatch(resetConfigurationData());
      dispatch(resetMixinsListData());
    };
  }, []);

  const handleGetConfigRowDetails = (values) => {
    dispatch(mixinsVisualizeSlice.actions.setInitialConfigData(configObj));
    dispatch(getConfigurationRowData({ configId, values })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        setSelectedRowPath(values.path);
        setToggleConfigRowDetailsModal(true);
      }
    });
  };

  const onCheckboxChange = (itemId) => {
    const ind = mixinsIdsList.indexOf(itemId);
    const selectedBatchIds = ind !== -1 ? mixinsIdsList.slice(0, ind + 1) : [];

    setSelectedMixinIds(selectedBatchIds);
    dispatch(getConfigurationData({ configId, values: { mixins: selectedBatchIds } }));
  };

  const handleToggleMixinDetailsModal = (data) => {
    if (!isEmpty(data)) {
      setMixinDetails(data);
    } else {
      setMixinDetails(null);
    }
    setToggleMixinDetailsModal(!isActiveMixinDetailsModal);
  };

  const handleHideConfigRowDetailsModal = () => {
    setToggleConfigRowDetailsModal(!isActiveConfigRowDetailsModal);
    setSelectedRowPath(null);
    return () => {
      resetConfigurationRowData();
    };
  };


  return (
    <>
      <Modal
        title={`"${storedMixinDetails?.name}" mixin details`}
        width={700}
        centered
        destroyOnClose
        visible={isActiveMixinDetailsModal}
        onCancel={handleToggleMixinDetailsModal}
        footer={null}
        bodyStyle={{
          minHeight: '200px',
          maxHeight: 'calc(100vh - 500px)',
          overflow: 'scroll',
        }}
      >
        <ReactJson
          src={preparedMixinDetails}
          theme="summerfruit:inverted"
        />
      </Modal>

      <Modal
        title={`"${selectedRowPath}" mixins`}
        width='60%'
        centered
        destroyOnClose
        visible={isActiveConfigRowDetailsModal}
        onCancel={handleHideConfigRowDetailsModal}
        footer={null}
        bodyStyle={{
          padding: 'unset',
          margin: '10px 15px',
          minHeight: '100px',
          maxHeight: '80vh',
          overflow: 'scroll',
        }}
      >
        <Table
          sticky
          columns={CONFIG_MIXINS_TABLE_COLUMNS}
          loading={isLoadingConfigRow}
          rowKey="id"
          dataSource={configurationRowData}
          pagination={false}
          scroll={{ y: 550 }}
        />
      </Modal>

      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Mixin visualize</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Col>

      <Content className='layout-content'>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} md={24} lg={6}>
            <CollapsibleFilterWr
              title='Filter'
              keyValue='mixin-visualize-filter'
              maxDeviceWidth={992}
            >
              <Spin spinning={isLoadingConfigsList} tip="Loading configurations...">
                <FormMixinVisualizeFilter
                  initialValues={initialValues}
                  configOptions={configsList}
                  formErrors={filterErrors}
                  isSubmitting={isLoadingConfigsList || isLoadingMixinsList}
                  onSubmit={handleSetFilter}
                />
              </Spin>
            </CollapsibleFilterWr>
          </Col>
          <Col xs={24} md={24} lg={18}>
            <Card className='card-has-table' title='Mixin visualize'>
              <Row gutter={[ 16, 16 ]}>
                <Col xs={24} md={16}>
                  <Spin spinning={isLoadingConfig || isLoadingConfigRow} tip='Loading condiguration...'>
                    <Input
                      style={{ marginBottom: 15 }}
                      name='search'
                      size='small'
                      placeholder='Search...'
                      allowClear={!isEmpty(searchText)}
                      onChange={(event) => setSearchText(event.target.value)}
                      prefix={<SearchOutlined fill='#9393ab' />}
                    />
                    <SearchableJsonViewer
                      validationMessage=''
                      src={configurationData}
                      theme="summerfruit:inverted"
                      highlightSearch={searchText}
                      enableClipboard={false}
                      onDelete={(data) => {
                        handleGetConfigRowDetails(({ path: getRecentNonArrayPath(
                          configObj,
                          `${[ ...data.namespace, data.name ].join('.')}`,
                        ),
                        }));

                        return false;
                      }}
                    />
                  </Spin>
                </Col>
                <MixinColContainer xs={24} md={8} isMobile={isMobile}>
                  <List
                    header={
                      <MixinsListHeader>
                        <b>Mixins list</b>
                        <Tooltip placement="topLeft" title="Sorting by priority: Low > High">
                          <RiInformationLine size={18} />
                        </Tooltip>
                      </MixinsListHeader>
                    }
                    footer={null}
                    loading={isLoadingMixinsList}
                    dataSource={mixinsList}
                    renderItem={(item) => (
                      <List.Item
                        style={{ padding: '8px 0 !important' }}
                        actions={[
                          <Checkbox
                            key={item.id}
                            defaultChecked={selectedMixinIds.includes(item.id)}
                            checked={selectedMixinIds.includes(item.id)}
                            size='small'
                            onClick={() => {
                              onCheckboxChange(item.id);
                            }}
                          />,
                        ]}
                      >
                        <Tooltip placement="top" title={item.name || EMPTY_VALUE_PLACEHOLDER}>
                          <Button
                            type='link'
                            size='small'
                            className="ellipsis-text"
                            onClick={() => {
                              handleToggleMixinDetailsModal(item);
                            }}
                          >
                            <div className="ellipsis-text">{item.name || EMPTY_VALUE_PLACEHOLDER}</div>
                          </Button>
                        </Tooltip>
                      </List.Item>
                    )}
                  />
                </MixinColContainer>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  );
};


export default PageMixinVisualize;
