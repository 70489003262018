import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../../services/Api/Api';
import UserDetailsService from '../services/userDetailsService';
import { mkNotification } from '../../../../lib/mkNotification';



const initialState = {
  userData: {},
  userPoliciesOptions: {},
  errors: {},
  loading: false,
  formLoading: false,
};

const userDetailsService = new UserDetailsService(Api);

export const getUserDetails = createAsyncThunk(
  'users/getUserDetails',
  async ({ userId, include }) => {
    return userDetailsService.getUserData({ userId, include });
  },
);

export const updateUserPolicies = createAsyncThunk(
  'users/updateUserPolicies',
  async ({ userId, data }, { rejectWithValue }) => {
    try {
      const response = await userDetailsService.updateUserPolicies({ userId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const updateUserSpaces = createAsyncThunk(
  'users/updateUserSpaces',
  async ({ userId, spaceIds }, { rejectWithValue }) => {
    try {
      const response = await userDetailsService.updateUserSpaces({ userId, spaceIds });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    resetUserDetails: (state) => {
      state.userData = {};
      state.userPoliciesOptions = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload.data;
        state.userPoliciesOptions = action.payload.meta.custom;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });

    builder
      .addCase(updateUserPolicies.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(updateUserPolicies.fulfilled, (state) => {
        state.formLoading = false;
        mkNotification('success', 'User policies is successfully updated.');
      })
      .addCase(updateUserPolicies.rejected, (state, action) => {
        state.formLoading = false;
        state.errors = action.payload;
      });

    builder
      .addCase(updateUserSpaces.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(updateUserSpaces.fulfilled, (state) => {
        state.formLoading = false;
        mkNotification('success', 'User details is successfully updated.');
      })
      .addCase(updateUserSpaces.rejected, (state, action) => {
        state.formLoading = false;
        state.errors = action.payload;
      });
  },
});

// actions
export const { resetUserDetails } = userDetailsSlice.actions;

// selectors
export const selectUserDetailsLoading = (state) => state.userDetails.loading;
export const selectUserDetails = (state) => state.userDetails.userData;
export const selectUserDetailsPoliciesList = (state) => state.userDetails.userPoliciesOptions;
export const selectErrors = (state) => state.userDetails.errors;
export const selectUserEditFormLoading = (state) => state.userDetails.formLoading;

// reducer
export default userDetailsSlice.reducer;
