import { EMPTY_VALUE_PLACEHOLDER } from '../const/system';



const mkSecondsToDateRange = (sec) => {
  const years = Math.floor(sec / 31536000);
  const days = Math.floor((sec % 31536000) / 86400);
  const hours = Math.floor(((sec % 31536000) % 86400) / 3600);
  const minutes = Math.floor((((sec % 31536000) % 86400) % 3600) / 60);
  const seconds = (((sec % 31536000) % 86400) % 3600) % 60;


  const yy = years !== 0 ? `${years}y` : '';
  const dd = days !== 0 ? `${days}d` : '';
  const hh = hours !== 0 ? `${hours}h` : '';
  const mm = minutes !== 0 ? `${minutes}m` : '';
  const ss = seconds !== 0 ? `${seconds}s` : '';
  const result = `${yy} ${dd} ${hh} ${mm} ${ss}`;

  return sec === 0 ? '0s' : result.trim() !== '' ? result : EMPTY_VALUE_PLACEHOLDER;
};

export default mkSecondsToDateRange;

