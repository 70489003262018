import addUrlParam from '../../../lib/addUrlParam';



export default class UserEventsService {
  constructor (Api) {
    this.api = Api;
  }

  getAll (params) {
    return this.api.get(`events/user?${addUrlParam(params)}`);
  }
}
