import { setRequestParams } from '../../../lib/setRequestParams';



export default class ConfigurationsService {
  constructor (Api) {
    this.api = Api;
  }

  getConfigurationsList (params) {
    return this.api.get(`configs${setRequestParams(params)}`);
  }

  createConfiguration (values) {
    return this.api.post('configs', values);
  }

  deleteConfiguration (configurationId) {
    return this.api.delete(`configs/${configurationId}`);
  }
}
