import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';
import EventsSequenceService from '../services/eventsSequenceService';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  data: [],
  eventsOptionsData: [],
  parametersOptionsData: [],
  error: {},
  loading: false,
  eventsOptionsLoading: false,
  paramsOptionsLoading: false,
};

const userEventsService = new EventsSequenceService(Api);

export const getEventsSequenceData = createAsyncThunk(
  'eventsSequence/getEventsSequence',
  async (values, { rejectWithValue }) => {
    try {
      const response = await userEventsService.getEventsSequence(values);

      setUrlParams(values);

      if (isEmpty(response)) {
        mkNotification('info', 'No data available.');
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data.errors);
    }
  },
);

export const getEventsOptions = createAsyncThunk(
  'eventsOptions/get',
  () => {
    return userEventsService.getEvents();
  },
);

export const getParametersOptions = createAsyncThunk(
  'parametersOptions/get',
  () => {
    return userEventsService.getParameters();
  },
);


export const eventsSequenceSlice = createSlice({
  name: 'eventsSequence',
  initialState,
  reducers: {
    resetEventsSequenceData: (state) => {
      state.data = [];
      state.error = null;
    },
    resetEventsOptions: (state) => {
      state.eventsOptionsData = [];
      state.error = null;
    },
    resetParametersOptions: (state) => {
      state.parametersOptionsData = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventsSequenceData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEventsSequenceData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = isArray(action.payload) ? action.payload : []; //Added to check correct response data
      })
      .addCase(getEventsSequenceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getEventsOptions.pending, (state) => {
        state.eventsOptionsLoading = true;
      })
      .addCase(getEventsOptions.fulfilled, (state, action) => {
        state.eventsOptionsLoading = false;
        state.eventsOptionsData = action.payload;
      })
      .addCase(getEventsOptions.rejected, (state) => {
        state.eventsOptionsLoading = false;
      });

    builder
      .addCase(getParametersOptions.pending, (state) => {
        state.paramsOptionsLoading = true;
      })
      .addCase(getParametersOptions.fulfilled, (state, action) => {
        state.paramsOptionsLoading = false;
        state.parametersOptionsData = action.payload;
      })
      .addCase(getParametersOptions.rejected, (state) => {
        state.paramsOptionsLoading = false;
      });
  },
});

// actions
export const { resetEventsSequenceData, resetEventsOptions } = eventsSequenceSlice.actions;

// selectors
export const selectLoading = (state) => state.eventsSequence.loading;
export const selectData = (state) => state.eventsSequence.data;
export const selectErrors = (state) => state.eventsSequence.error; //add set form errors

export const selectEventsOptions = (state) => state.eventsSequence.eventsOptionsData;
export const selectEventsOptionsLoading = (state) => state.eventsSequence.eventsOptionsLoading;
export const selectParamsOptionsLoading = (state) => state.eventsSequence.paramsOptionsLoading;
export const selectParametersOptions = (state) => state.eventsSequence.parametersOptionsData;


// reducer
export default eventsSequenceSlice.reducer;
