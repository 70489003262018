import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Layout, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import urlPageTickets from '../../../../urls/urlPageTickets';
import { TICKETS_LIST_TABLE_COLUMNS } from './PageTickets.const';
import {
  getTicketsListData,
  selectTicketsList,
  selectTicketsLoading,
  selectTicketsPagination,
} from '../../feature/ticketsSlice';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Content } = Layout;

const PageTickets = () => {
  const { pathname, search } = useLocation();
  const { projectSettings } = useApp();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy || 'desc');
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy || 'ticket_id');

  const dispatch = useDispatch();
  const ticketsList = useSelector(selectTicketsList);
  const isLoading = useSelector(selectTicketsLoading);
  const pagination = useSelector(selectTicketsPagination);

  useEffect(() => {
    if (pathname === urlPageTickets()) {
      dispatch(getTicketsListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
    }
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting ]);

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Tickets list</Breadcrumb.Item>
      </Breadcrumb>
      <Content className='layout-content'>
        <Card className='da-mb-24'>
          <Table
            sticky
            loading={isLoading}
            rowKey="id"
            columns={TICKETS_LIST_TABLE_COLUMNS}
            dataSource={ticketsList}
            onChange={handleChangeTableParams}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50', '100' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 550 }}
          />
        </Card>
      </Content>
    </>
  );
};

export default PageTickets;
