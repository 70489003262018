import moment from 'moment';
import { EMPTY_VALUE_PLACEHOLDER } from '../const/system';



const mkTimeFromSeconds = (seconds) => {
  if (!seconds || isNaN(seconds)) {
    return EMPTY_VALUE_PLACEHOLDER;
  }

  const duration = moment.duration(seconds, 'seconds');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const secondsLeft = duration.seconds();

  const parts = [];

  if (days > 0) parts.push(`${days}d`);
  if (hours > 0 || days > 0) parts.push(`${hours}h`);
  if (minutes > 0 || hours > 0 || days > 0) parts.push(`${minutes}m`);
  if (minutes === 0) {
    if (secondsLeft > 0 || minutes > 0 || hours > 0 || days > 0) parts.push('less than 1 minute');
  }

  return parts.join(' ');
};

export default mkTimeFromSeconds;
