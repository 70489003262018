import React from 'react';
import { Form, Input, DatePicker, SubmitButton, FormItem, Select } from 'formik-antd';
import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Col, Row } from 'antd';
import Yup from '../../../../../vendor/yup';
import { getObjectValuesByUrlParams } from '../../../../../lib/getObjectValuesByUrlParams';



const validationSchema = Yup.object().shape({
  platform: Yup.string().nullable().required('Platform is required'),
  parameter: Yup.string().required('Parameter is required'),
  uId: Yup.string().required('User Id is required'),
  date_start: Yup.string().nullable().required('Start date is required'),
  date_end: Yup.string().nullable().required('End date is required'),
});

const UserEventsFilter = ({ onSubmit, initialValues, isSubmitting }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      onSubmit={(values) => {
        const preparedValues = {
          ...values,
          date_start: values.date_start ? moment(values.date_start).format('YYYYMMDD') : '',
          date_end: values.date_end ? moment(values.date_end).format('YYYYMMDD') : '',
        };

        onSubmit(preparedValues);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setFieldValue } = props;

        const startDisabledDate = (current) => {
          const startDate = moment().subtract(30, 'd');
          const endDate = moment(new Date());

          return !(startDate.isSameOrBefore(current) && endDate.isAfter(current));
        };

        const endDisabledDate = (current) => {
          // eslint-disable-next-line react/prop-types
          const start = moment(values.date_start);
          const endDate = moment(new Date());

          return !(start.isSameOrBefore(current) && endDate.isAfter(current));
        };

        return (
          <Form layout="vertical">
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="parameter"
                label="Parameter"
                required
              >
                <Input name='parameter' />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="platform"
                label="Platform"
                required
              >
                <Select
                  name="platform"
                  placeholder="Select platform"
                >
                  <Select.Option value="ios">iOS</Select.Option>
                  <Select.Option value="android">Android</Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="uId"
                label="User Id"
                required
              >
                <Input
                  name='uId'
                  placeholder='Input user id'
                />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="date_start"
                label="Start date"
                required
              >
                <DatePicker
                  name='date_start'
                  disabledDate={startDisabledDate}
                  onChange={(value) => {
                    if (moment(value).diff(moment(values.date_end)) > 0) {
                      setFieldValue('date_end', null);
                    }
                  }}
                />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="date_end"
                label="End date"
                required
              >
                <DatePicker
                  name='date_end'
                  disabledDate={endDisabledDate}
                />
              </FormItem>
            </Col>

            <Row style={{ textAlign: 'center' }}>
              <Col span={24}>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || (isEqual(getObjectValuesByUrlParams(), values))}
                >
                  Search
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

UserEventsFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    date_end: PropTypes.string,
  }),
};

export default UserEventsFilter;
