import React, { useEffect } from 'react';
import { BackTop, Breadcrumb, Card, Col, Layout, Row, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useApp } from '../../../../app/context/AppContext';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import EventsSequenceFilter from './EventsSequenceFilter';
import {
  getEventsOptions,
  getEventsSequenceData,
  getParametersOptions,
  resetEventsSequenceData,
  selectData,
  selectErrors,
  selectEventsOptions,
  selectLoading,
  selectParametersOptions,
  selectParamsOptionsLoading,
  selectEventsOptionsLoading,
} from '../../feature/eventsSequenceSlice';
import {
  EVENTS_SEQUENCE_TABLE_COLUMNS,
  mkPreparedEditFormValues,
  preparedEvSequenceFormValues,
} from './PageEventsSequence.const';



const { Content } = Layout;

const PageUserEvents = () => {
  const { projectSettings } = useApp();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const data = useSelector(selectData);
  const isLoading = useSelector(selectLoading);
  const eventsOptions = useSelector(selectEventsOptions);
  const parametersOptions = useSelector(selectParametersOptions);
  const loadingEventsOptions = useSelector(selectEventsOptionsLoading);
  const loadingParamsOptions = useSelector(selectParamsOptionsLoading);
  const formErrors = useSelector(selectErrors);

  const handleSetFilter = (values) => {
    window.scrollTo(0, 0);
    dispatch(getEventsSequenceData(preparedEvSequenceFormValues(values)));
  };

  useEffect(() => {
    if (!isEmpty(search)) {
      handleSetFilter(mkPreparedEditFormValues(getObjectValuesByUrlParams()));
    }
    return () => {
      dispatch(resetEventsSequenceData());
    };
  }, []);

  useEffect(() => {
    dispatch(getEventsOptions());
    dispatch(getParametersOptions());
  }, []);


  const initialFilterValues = isEmpty(search) ? {
    date_start: null,
    date_end: null,
    app_versions: [],
    user_platform: null,
    event_first: null,
    event_second: null,
    parameter_1_name: null,
    parameter_2_name: null,
    parameter_1_val: null,
    parameter_2_val: null,
    customEventsOptions: [],
    customParametersOptions: [],
  } : mkPreparedEditFormValues(getObjectValuesByUrlParams());

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Events sequence</Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} lg={6}>
            <div className="layout-content">
              <CollapsibleFilterWr
                title='Filter'
                keyValue='event-sequence-filter'
                maxDeviceWidth={992}
              >
                <Spin spinning={loadingEventsOptions || loadingParamsOptions} tip="Loading options...">
                  <EventsSequenceFilter
                    isSubmitting={isLoading}
                    formErrors={formErrors}
                    eventsOptions={eventsOptions}
                    parametersOptions={parametersOptions}
                    onSubmit={handleSetFilter}
                    initialValues={initialFilterValues}
                  />
                </Spin>
              </CollapsibleFilterWr>
            </div>
          </Col>
          <Col xs={24} lg={18}>
            <Content className='layout-content' id='sequenceTopCmp'>
              <Card className='card-has-table'>
                <Table
                  columns={EVENTS_SEQUENCE_TABLE_COLUMNS}
                  loading={isLoading}
                  dataSource={data}
                  pagination={{
                    position: [ 'bottomCenter' ],
                    pageSizeOptions: [ '10', '25', '50' ],
                    size: 'small',
                    showSizeChanger: true,
                  }}
                />
              </Card>
              <BackTop
                visibilityHeight={150}
                target={() => document.getElementById('sequenceTopCmp')}
              >
                <div className='back-top-button'>
                  <VerticalAlignTopOutlined />
                </div>
              </BackTop>
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageUserEvents;
