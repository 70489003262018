export const setItemToQueryParams = (name, value) => {
  const querySearch = new URLSearchParams(window.location.search);

  if (querySearch.has(name)) {
    querySearch.delete(name);
  }
  querySearch.set(name, value);

  const newQuery = `${window.location.pathname}?${querySearch.toString()}`;

  window.history.replaceState(null, '', newQuery);
};
