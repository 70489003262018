import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import Config from '../../config';
import { getLocalToken } from '../../lib/getLocalToken';
import { mkNotification } from '../../lib/mkNotification';
import { getUrlProjectSettings } from '../../lib/getUrlProjectSettings';
import { addRequestParams } from '../../lib/addRequestParams';
import urlPageNotFound from '../../urls/urlPageNotFound';



const axiosInstance = axios.create();

const refreshUserData = () => {
  document.dispatchEvent(new CustomEvent('isUserDataExpired', {}));
};

export const interceptor = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getLocalToken();
      const { spaceKey, projectKey } = getUrlProjectSettings();

      if (token && token.hasOwnProperty('access_token')) {
        config.headers.Accept = 'application/json';
        config.headers.Authorization = `Bearer ${token.access_token}`;

        if (!isEmpty(spaceKey)) {
          config.headers['x-space'] = spaceKey;
        }

        if (!isEmpty(projectKey)) {
          config.headers['x-project'] = projectKey;
        }
      }

      config.baseURL = `${config.headers?.test === 'true' ? Config.VITE_API_URL_TEST : Config.VITE_API_URL}/`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );


  axiosInstance.interceptors.response.use(
    (res) => {
      const regex = /\/configs\/tests\/\d*\?include=mixin,config,user/;

      if (res.request.responseURL.match(regex)?.length > 0) {
        localStorage.setItem('isUnblockableSandboxExperiment', JSON.stringify(res.headers.hasOwnProperty('x-unblockable')));
      }

      if (res.headers.hasOwnProperty('user-expired')) {
        refreshUserData();
      }

      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      const localToken = getLocalToken();

      if (err.response && localToken) {
        if (err.response.status === 401 && originalConfig._retry) {
          document.dispatchEvent(new CustomEvent('UnanuthorizedUserEv', {}));
        }
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            axiosInstance.defaults.headers.common.Authorization = localToken.token;
            const response = await axiosInstance.post('/refresh-token', {
              refresh_token: localToken.refresh_token,
            });

            localStorage.setItem('token', JSON.stringify(response?.data));

            return axiosInstance(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        }
      }
      if (err.response && err.response.status === 403) {
        refreshUserData();
      }
      if (err.response.status === 404) {
        window.location.replace(urlPageNotFound());
      }
      if (err.response.status >= 400 < 500) {
        mkNotification('error', err.response.data.message);
      }
      if (err.response.status >= 500) {
        mkNotification('error', 'Server error! Contact the web development team.');
      }
      return Promise.reject(err);
    },
  );
};


const params = JSON.parse(localStorage.getItem('test_mode_params')) ?? '';

const Api = {
  get: async (url, config = {}) => {
    const response = await axiosInstance.get(`${addRequestParams(url, params)}`, config);

    return response.hasOwnProperty('data') ? response.data : {};
  },
  post: async (url, data, config = {}) => {
    const response = await axiosInstance.post(`${addRequestParams(url, params)}`, data, config);

    return response.hasOwnProperty('data') ? response.data : {};
  },
  put: async (url, data, config = {}) => {
    const response = await axiosInstance.put(`${addRequestParams(url, params)}`, data, config);

    return response.hasOwnProperty('data') ? response.data : {};
  },
  patch: async (url, data, config = {}) => {
    const response = await axiosInstance.patch(`${addRequestParams(url, params)}`, data, config);

    return response.hasOwnProperty('data') ? response.data : {};
  },
  delete: async (url, config = {}) => {
    const response = await axiosInstance.delete(`${addRequestParams(url, params)}`, config);

    return response.hasOwnProperty('data') ? response.data : {};
  },

  //Custom method, used for delayed requests
  getWithStatus: async (url) => {
    const response = await axiosInstance.get(url);

    return response.hasOwnProperty('data') ? { status: response.status, ...response.data } : {};
  },
  //Custom method, used to get users list in test mode
  getTest: async (url, config = {}) => {
    const response = await axiosInstance.get(url, config);

    return response.hasOwnProperty('data') ? response.data : {};
  },
};

export default Api;
