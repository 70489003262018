import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Descriptions } from 'antd';



export const getNotEmptyDescriptionItem = (value, label = '') => {
  if (!isEmpty(value)) {
    return (
      <Descriptions.Item label={label}>
        {value}
      </Descriptions.Item>
    );
  }
  return null;
};
