import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';
import AppSettingsService from '../services/appSettingsService';



const initialState = {
  usersList: [],
  loading: false,
};

const appSettingsService = new AppSettingsService(Api);

export const getAppUsersListData = createAsyncThunk(
  'settings/getUsersList',
  async (params) => {
    return appSettingsService.getTestModeUsersList(params);
  },
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetAppUsersListData: (state) => {
      state.usersList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppUsersListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAppUsersListData.fulfilled, (state, action) => {
        state.loading = false;
        state.usersList = action.payload.data;
      })
      .addCase(getAppUsersListData.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetAppUsersListData } = settingsSlice.actions;

// selectors
export const selectAppSettingsUsersLoading = (state) => state.appSettings.loading;
export const selectAppSettingsUsersList = (state) => state.appSettings.usersList;

// reducer
export default settingsSlice.reducer;
