import urlPageSpaceDashboard from '../urls/urlPageSpaceDashboard';
import urlPageProjectDashboard from '../urls/urlPageProjectDashboard';



const mkRedirectToDashboard = (navigate, item) => {
  if (item.projectKey !== null) {
    navigate(urlPageProjectDashboard(item), { replace: true });
  } else {
    navigate(urlPageSpaceDashboard({ spaceKey: item.spaceKey }), { replace: true });
  }
  window.location.reload();
};

export const mkRedirectToDashboardPath = (params) => {
  if (params.projectKey !== null) {
    return urlPageProjectDashboard(params);
  }

  if (params.spaceKey !== null) {
    return urlPageSpaceDashboard({ spaceKey: params.spaceKey });
  }

  return '/';
};

export default mkRedirectToDashboard;
