import { Badge, Descriptions, Tag } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { AB_TEST_PUBLISH_STATUS_COLOR, AB_TEST_STATUS_COLOR } from '../../pages/PageAbTests/PageAbTests.const';
import CollapsibleCardWr from '../../../../components/CollapsibleCardWr';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const AbTestDetailsCard = ({ data, isLoading }) => {
  return (
    <CollapsibleCardWr
      title='Details'
      isLoading={isLoading}
      keyValue="abTest-details"
      defaultActiveKey="abTest-details"
      isCollapsible
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Id:">
          <Badge color={AB_TEST_PUBLISH_STATUS_COLOR(data)} text={data.id ?? EMPTY_VALUE_PLACEHOLDER} />
        </Descriptions.Item>
        <Descriptions.Item label="Name:">
          {data?.name || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="Status:">
          {!isEmpty(data?.status) ? (
            <Badge color={AB_TEST_STATUS_COLOR(data.status)} text={capitalize(data.status)} />
          ) : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>

        <Descriptions.Item label="Is published:">
          {data?.is_published ? 'Yes' : 'No'}
        </Descriptions.Item>

        {data?.is_published && (
          <>
            <Descriptions.Item label="Published at:">
              <Tag color="magenta">
                {renderDateField(data?.published_at)}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Publisher:">
              {data?.publisher?.name || EMPTY_VALUE_PLACEHOLDER}
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item label="Groups:">
          {!isEmpty(data?.data?.groups) ? data.data.groups.map((group) => (
            <Tag key={group.name} color="purple">
              {`${group.name} - ${group.percent}%`}
            </Tag>
          )) : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>

        <Descriptions.Item label="Priority:">
          {data?.data?.priority || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="Percent:">
          {data?.data?.percent ? `${data.data.percent} %` : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>

        <Descriptions.Item label="Affiliation:">
          {capitalize(data?.data?.affiliation) || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>

        <Descriptions.Item label="Link:">
          {data?.data?.link ? (
            <a
              href={data.data.link}
              target='_blank'
              rel="noreferrer"
            >
              {data.data.link}
            </a>
          ) : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>

        <Descriptions.Item label="Description:">
          {data?.data?.description || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>

        <Descriptions.Item label="Created at:">
          <Tag color="blue">
            {renderDateField(data?.created_at)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Updated at:">
          <Tag color="pink">
            {renderDateField(data?.updated_at)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Finished at:">
          <Tag color="volcano">
            {renderDateField(data?.data?.finished_at)}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label="First published at:">
          <Tag color="cyan">
            {renderDateField(data?.first_published_at)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="First publisher:">
          {data?.first_publisher?.name || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>

        <Descriptions.Item label="Author:">
          {data?.author?.name || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
      </Descriptions>
    </CollapsibleCardWr>
  );
};

AbTestDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default AbTestDetailsCard;
