import moment from 'moment';



export const mapToArrayOfDates = (arr = []) => {
  const res = [];

  arr.forEach((obj) => {
    const value = Object.values(obj).map((value) => moment.utc(String(value)).local());

    res.push(value);
  });
  return res;
};
