import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Menu, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { RiArrowDropRightLine } from 'react-icons/ri';
import {
  ADMINISTRATION_HEADER,
  GLOBAL_SETTINGS_PREFIX,
  LOCAL_SETTINGS_PREFIX,
  APP_ENVIRONMENTS,
} from '../../../../../const/system';
import { useApp } from '../../../../../app/context/AppContext';
import navigation from '../../../../Navigation/Horizontal';
import { getCurrentSpace } from '../../../../../lib/getCurrentSpace';
import { getAllUserPolicies } from '../../../../../lib/getAllUserPolicies';
import { Permissions } from '../../../../../const/permissions';



const { SubMenu } = Menu;

const MenuHorizontal = ({ onClose }) => {
  // Location
  const { userPolicies, projectSettings, userSpaces, userPermissions } = useApp();
  const { pathname } = useLocation();
  const splitLocation = pathname.split('/');
  const currentSpace = getCurrentSpace(projectSettings?.spaceKey, userSpaces);
  const currentSpaceModules = currentSpace?.config.modules ?? [];

  // Menu
  const splitLocationUrl = `${splitLocation[splitLocation.length - 2]}/${splitLocation[splitLocation.length - 1]}`;
  const allowedMenuItems = navigation.filter((menuItem) => userPermissions?.some((permission) => !isEmpty(menuItem) ?
    menuItem.groupPermissions.includes(permission) : null));
  const menuItems = allowedMenuItems.filter((item) => (
    ![ GLOBAL_SETTINGS_PREFIX, LOCAL_SETTINGS_PREFIX ].includes(splitLocation[splitLocation.length - 2])) ?
    item : item.header === ADMINISTRATION_HEADER);

  const menuItem = menuItems.map((item) => {
    // Totally hide 'Administration' menu item if user does not have permission to watch any administration menu item
    const hideAdministrationMenuItem = item.header === ADMINISTRATION_HEADER && ![
      Permissions.ADMINISTRATION.GLOBAL_ADMIN_MENU,
      Permissions.ADMINISTRATION.SPACE_ADMIN_MENU,
    ].some((permission) => userPermissions.includes(permission));

    // Hide non 'Administration' menu item if current space does not have certain module or if it is non-project scope
    const hideMenuItemBySpaceModules = item.header !== ADMINISTRATION_HEADER &&
        (!currentSpaceModules.includes(item.id) || !projectSettings.projectKey);

    if (hideMenuItemBySpaceModules || hideAdministrationMenuItem) {
      return null;
    }

    // Mark parent menu item 'active' if any of child items is active
    let isActiveParentMenuItem = false;

    if (item.subMenu) {
      isActiveParentMenuItem = item.subMenu.some((menuItem) => {
        return menuItem.id === splitLocation[splitLocation.length - 1] ||
          menuItem.id === splitLocation[splitLocation.length - 2];
      });
    }

    return item.subMenu ? (
      <SubMenu
        key={item.header}
        title={
          <Row
            key={item.header}
            align="middle"
            className={`${isActiveParentMenuItem ? 'ant-menu-item-selected' : ''} menu-item da-hover-fill-primary-1 da-hover-bg-color-primary-4 da-hover-text-color-primary-1 da-transition da-px-12 da-py-10 da-border-radius-lg`}
          >
            <Col style={{ paddingRight: '5px' }}>{item.header}</Col>
            <Col className="da-d-flex-align-center da-ml-8">
              <svg className="da-fill-black-80" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99999 3.78145L8.29999 0.481445L9.24266 1.42411L4.99999 5.66678L0.757324 1.42411L1.69999 0.481445L4.99999 3.78145Z" />
              </svg>
            </Col>
          </Row>
        }
      >
        {item.subMenu.map((value) => {
          // Hide 'Administration -> Global' menu item if user does not have permission
          const hideMenuItem = (!isEmpty(projectSettings.spaceKey) &&
              !userPermissions.includes(Permissions.ADMINISTRATION.GLOBAL_ADMIN_MENU) && value.id === GLOBAL_SETTINGS_PREFIX) ||
              ((isEmpty(projectSettings.spaceKey) && value.id === LOCAL_SETTINGS_PREFIX));

          if ((userPermissions.some((permission) => !isEmpty(value?.permission) ? value.permission === permission :
            value?.groupPermissions.map((groupItem) => groupItem === permission))) && !hideMenuItem) {
            return (value.children ? (
            // Level 2
              <SubMenu
                key={value.id}
                icon={value.icon}
                title={
                  <Row
                    key={value.title}
                    align="middle"
                    className="menu-item da-hover-text-color-primary-1 da-transition da-border-radius-lg"
                  >
                    <Col>{value.title}</Col>
                    <Col className="da-d-flex-align-center da-ml-8 da-mx-10">
                      <RiArrowDropRightLine style={{ marginTop: '15px' }} className="remix-icon da-fill-black-80" />
                    </Col>
                  </Row>
                }
                className={splitLocation[splitLocation.length - 2] === value.id && 'ant-menu-submenu-selected'}
              >
                {value.children.map((childItem) => {
                  const globalUserPermissions = getAllUserPolicies(
                    userPolicies,
                    projectSettings,
                    'permissions',
                    [ APP_ENVIRONMENTS.SPACE, APP_ENVIRONMENTS.PROJECT ],
                  );

                  const showChildItem = value.id === GLOBAL_SETTINGS_PREFIX ? globalUserPermissions.some((permission) => childItem.permission === permission)
                    : userPermissions.some((permission) => childItem.permission === permission);

                  if (!showChildItem) {
                    return null;
                  }

                  const childNavItemSplitLink = childItem.navLink.split('/');

                  return childItem.children ? (
                    <SubMenu key={childItem.id} icon={childItem.icon} title={childItem.title}>
                      {
                        // Level 3
                        childItem.children ? (
                          childItem.children.map((childItem1) => (
                            <Menu.Item
                              key={childItem1.id}
                              className={
                                splitLocationUrl ===
                                `${childItem1.navLink.split('/')[childItem1.navLink.split('/').length - 2]
                                }/${childItem1.navLink.split('/')[childItem1.navLink.split('/').length - 1]}`
                                  ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'
                              }
                              onClick={onClose}
                            >
                              <Link to={childItem1.navLink}>{childItem1.title}</Link>
                            </Menu.Item>
                          ))
                        ) : (
                          <Menu.Item key={childItem.id}>
                            <Link to={childItem.navLink}>{childItem.title}</Link>
                          </Menu.Item>
                        )
                      }
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={childItem.id}
                      icon={childItem.icon}
                      className={splitLocationUrl ===
                      `${childNavItemSplitLink[childNavItemSplitLink.length - 2]
                      }/${childNavItemSplitLink[childNavItemSplitLink.length - 1]}`
                        ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'}
                      onClick={onClose}
                    >
                      <Link to={childItem.navLink}>{childItem.title}</Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            ) : (
            // Level 1
              <Menu.Item
                key={value.id}
                icon={value.icon}
                onClick={onClose}
                className={
                  `${splitLocation[splitLocation.length - 2]}/${
                    splitLocation[splitLocation.length - 1]}` ===
                    `${value.navLink.split('/')[value.navLink.split('/').length - 2]
                    }/${value.navLink.split('/')[value.navLink.split('/').length - 1]}`
                    ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'
                }
              >
                <Link to={value.navLink}>{value.title}</Link>
              </Menu.Item>
            ));
          }
          return null;
        })}
      </SubMenu>
    ) : (
      <Menu.Item
        key={item.header}
        className={
          `${splitLocation[splitLocation.length - 2]}/${
            splitLocation[splitLocation.length - 1]}` ===
            `${item.navLink.split('/')[item.navLink.split('/').length - 2]
            }/${item.navLink.split('/')[item.navLink.split('/').length - 1]}`
            ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'
        }
      >
        <Link to={item.navLink}>{item.header}</Link>
      </Menu.Item>
    );
  });

  return (
    <Menu mode="horizontal" theme='light'>
      {menuItem}
    </Menu>
  );
};

MenuHorizontal.propTypes = {
  onClose: PropTypes.func,
};

MenuHorizontal.defaultProps = {
  onClose: () => {},
};

export default MenuHorizontal;
