import { setRequestParams } from '../../../lib/setRequestParams';



export default class AuthService {
  constructor (Api) {
    this.api = Api;
  }

  getAuth (code) {
    return this.api.post('login', { code });
  }

  refreshUser (params) {
    return this.api.getTest(`profile${setRequestParams(params)}`);
  }
}
