import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Button, Tooltip, Modal } from 'antd';
import { Outlet } from 'react-router-dom';
import { Setting } from 'react-iconly';
import { RiCloseFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useApp } from '../../app/context/AppContext';
import { useLocalStorage } from '../../app/hooks';
import HeaderHorizontal from './components/header/HeaderHorizontal';
import MenuFooter from './components/footer';
import ScrollTop from './components/scroll-to-top';
import FormTestersMode from '../../app/auth/forms/FormTestersMode';
import { isFulfilledRequestStatus } from '../../lib/isRequestSuccess';
import { USER_TEST_MODE_QUERY } from '../../const/system';
import { FEATURE_SHOW_TEST_MODE } from '../../const/features';
import { authLoading, refreshUser } from '../../app/auth/feature/AuthSlice';
import {
  getAppUsersListData,
  resetAppUsersListData,
  selectAppSettingsUsersList,
  selectAppSettingsUsersLoading,
} from '../../app/auth/feature/AppSettingsSlice';



const { Content } = Layout;

const HorizontalLayout = () => {
  const dispatch = useDispatch();
  const { testParams } = useApp();
  const [ _, setTestModeParams ] = useLocalStorage('test_mode_params');
  const [ visible, setVisible ] = useState(false);
  const [ isActiveTestersModeModal, setToggleTestersModeModal ] = useState(false);

  const usersList = useSelector(selectAppSettingsUsersList);
  const isLoadingUsers = useSelector(selectAppSettingsUsersLoading);
  const isRefreshingUser = useSelector(authLoading);
  const developerId = testParams?.developer_id ?? null;
  const isActiveTestMode = !!developerId;


  useEffect(() => {
    if (FEATURE_SHOW_TEST_MODE) {
      dispatch(getAppUsersListData({ limit: -1, ...USER_TEST_MODE_QUERY }));
    }
    return () => {
      dispatch(resetAppUsersListData());
    };
  }, []);


  const toggleTestersModeModal = () => {
    setToggleTestersModeModal(!isActiveTestersModeModal);
  };

  const handleRefreshUserData = (params) => {
    dispatch(refreshUser(params)).then((res) => {
      if (isFulfilledRequestStatus(res)) {
        toggleTestersModeModal();
        window.location.reload();
      }
    });
  };

  const handleStopTestMode = () => {
    localStorage.removeItem('test_mode_params');
    handleRefreshUserData();
  };

  const handleApplyTestMode = (values) => {
    const testModeQueryParams = { ...USER_TEST_MODE_QUERY, developer_id: values.developer_id };

    setTestModeParams(testModeQueryParams);
    handleRefreshUserData(testModeQueryParams);
  };


  return (
    <>
      <Modal
        title="Testers mode"
        width={440}
        centered
        destroyOnClose
        visible={isActiveTestersModeModal}
        onCancel={toggleTestersModeModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <FormTestersMode
          initialValues={{ developer_id: developerId ?? null }}
          formErrors={[]}
          usersList={usersList}
          isActiveTestMode={isActiveTestMode}
          onSubmit={handleApplyTestMode}
          handleStopTestMode={handleStopTestMode}
          isSubmitting={isRefreshingUser}
        />
      </Modal>

      <div className='custom-sticky-menu'>
        <HeaderHorizontal
          visible={visible}
          setVisible={setVisible}
        />
      </div>
      <Layout className="da-app-layout da-bg-color-dark-90" style={{ paddingTop: '5.2rem' }}>
        <Content className="da-content-main">
          <Row justify="center">
            <Col span={24}>
              <Outlet />
            </Col>
          </Row>
        </Content>

        {!isActiveTestersModeModal && FEATURE_SHOW_TEST_MODE && (
          <Tooltip placement="left" title='Test mode'>
            <div className='test-settings'>
              <Button
                type="secondary"
                shape="circle"
                icon={<Setting />}
                loading={isLoadingUsers || isRefreshingUser}
                disabled={isLoadingUsers || isRefreshingUser}
                className={`ant-btn-icon-only ant-btn-circle da-primary-shadow ${isActiveTestMode ? 'progress-btn-circle' : 'warning-btn-circle'}`}
                onClick={toggleTestersModeModal}
              />
            </div>
          </Tooltip>
        )}
        <MenuFooter />
        <ScrollTop />
      </Layout>
    </>
  );
};

export default HorizontalLayout;
