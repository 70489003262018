import React from 'react';
import { Card, Row, Col, Empty, Spin } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { COLUMN_CHART_COLOR_MAP } from '../../../Monitor/components/MonitorCharts/MonitorCharts.const';



const WidgetMetricPieChart = ({ title, data, isLoading }) => {
  const preparedData = !isEmpty(data) ? data.map((item) => ({ name: item.name, count: Number(item.count) })) : [];

  return (
    <Card className="da-border-color-black-40 da-card-6 da-chart-text-color">
      <Row>
        <Col span={24} className='da-mb-16'>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="da-mr-8">{title}</h4>
            </Row>
            <h3 className="da-badge-text da-mb-0 da-text-color-black-80 da-text-color-dark-30">
              Last 30 days
            </h3>
          </Row>
        </Col>

        <Col span={24} className='widget-metric-charts-col-wr'>
          <Spin spinning={!!isLoading} tip="Loading charts..." className='widget-metric-charts-wr'>
            {isEmpty(data) ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>No charts data available</span>
                }
              />
            ) : (
              <div id="metric_chart-card" className='widget-charts-container'>
                <ResponsiveContainer width="100%" height="100%" debounce={1}>
                  <PieChart>
                    <Pie
                      data={preparedData}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      label='name'
                      paddingAngle={5}
                      isAnimationActive={false}
                      dataKey="count"
                    >
                      {data.map((item, index) => (
                        <Cell key={item.name} fill={COLUMN_CHART_COLOR_MAP[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            )}
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

WidgetMetricPieChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

WidgetMetricPieChart.defaultProps = {
  isLoading: false,
};

export default WidgetMetricPieChart;
