import { Breadcrumb, Button, Modal, Spin } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircle } from 'react-iconly';
import isEmpty from 'lodash/isEmpty';
import urlPageSpaceDashboard from '../../../../urls/urlPageSpaceDashboard';
import urlPageConfigurations from '../../../../urls/urlPageConfigurations';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';
import urlPageConfigMixinDetails from '../../../../urls/urlPageConfigMixinDetails';
import urlPageCreateConfigMixinTargeting from '../../../../urls/urlPageCreateConfigMixinTargeting';
import FormCreateEditConfigMixin from '../../forms/FormCreateEditConfigMixin';
import HasRights from '../../../../components/HasRights';
import { Permissions } from '../../../../const/permissions';
import { useApp } from '../../../../app/context/AppContext';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import {
  createConfigMixin,
  selectFormCreateConfigMixinLoading,
  selectFormCreateConfigMixinErrors,
  resetCreateConfigMixinErrors,
  getConfigurationDetails,
  selectConfigurationDetails,
  selectConfigurationDetailsLoading,
} from '../../feature/configurationDetailsSlice';
import {
  getPreparedFormData,
  predefinedFormCreateInitialValues,
  preparedToRequestFormData,
} from './PageCreateEditConfigMixin.const';
import {
  getConfigMixinDetails,
  updateConfigMixinDetails,
  selectConfigMixinDetails,
  selectConfigMixinDetailsLoading,
  selectFormUpdateConfigMixinErrors,
  selectFormUpdateConfigMixinLoading,
  resetUpdateConfigMixinErrors,
} from '../../feature/configurationMixinDetailsSlice';



const PageCreateEditConfigTargeting = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { projectSettings, userName } = useApp();
  const { pathname } = location;
  const { configurationId, configurationName, mixinId } = useParams();
  const isCreateConfigMixin = pathname.endsWith('create');
  const [ isActiveNotificationModal, setToggleNotificationModal ] = useState(false);
  const [ savedMixinId, setMixinId ] = useState('');
  const [ serverErrors, setServerErrors ] = useState({});


  const isLoadingCreateMixin = useSelector(selectFormCreateConfigMixinLoading);
  const createMixinErrors = useSelector(selectFormCreateConfigMixinErrors);

  const mixinDetails = useSelector(selectConfigMixinDetails);
  const isLoadingMixinDetails = useSelector(selectConfigMixinDetailsLoading);
  const isLoadingUpdateMixin = useSelector(selectFormUpdateConfigMixinLoading);
  const updateMixinErrors = useSelector(selectFormUpdateConfigMixinErrors);

  const configurationDetails = useSelector(selectConfigurationDetails);
  const isLoadingConfigDetails = useSelector(selectConfigurationDetailsLoading);


  useEffect(() => {
    if (!isCreateConfigMixin) {
      dispatch(getConfigurationDetails(configurationId)).then(() => {
        dispatch(getConfigMixinDetails(mixinId));
      });
    } else {
      dispatch(getConfigurationDetails(configurationId));
    }
  }, []);

  useEffect(() => {
    const errors = { ...createMixinErrors, ...updateMixinErrors };

    if (!isEmpty(errors)) {
      setServerErrors(errors);
    }
  }, [ createMixinErrors, updateMixinErrors ]);

  const handleRedirectToConfigurationDetails = () => {
    setToggleNotificationModal(false);
    navigate(`${urlPageConfigurationDetails({ configurationId })}?active=mixins`);
  };

  const handleRedirectToMixinDetails = (response) => {
    if (isFulfilledRequestStatus(response)) {
      navigate(urlPageConfigMixinDetails({ configurationId, configurationName, mixinId }));
    }
  };

  const handleSubmitFormData = async (values) => {
    const data = preparedToRequestFormData(values);

    if (isCreateConfigMixin) {
      await dispatch(createConfigMixin({ configurationId, data })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          resetCreateConfigMixinErrors();
          setToggleNotificationModal(true);
          setMixinId(response.payload.id);
        }
      });
    } else {
      await dispatch(updateConfigMixinDetails({ configurationId, mixinId, data })).then((response) => {
        resetUpdateConfigMixinErrors();
        handleRedirectToMixinDetails(response);
      });
    }
  };

  const configData = !isEmpty(configurationDetails) ? JSON.parse(configurationDetails.config) : {};
  const initialValues = !isCreateConfigMixin ?
    getPreparedFormData(mixinDetails, configurationId, configurationName) :
    predefinedFormCreateInitialValues({ configId: configurationId, configName: configurationName, authorName: userName });

  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.CREATE, Permissions.CONFIGS.CONFIGURATIONS.MIXINS.UPDATE ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={urlPageSpaceDashboard(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageConfigurations()}>
            Configurations list
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageConfigurationDetails({ configurationId })}>
            Configuration details
          </Link>
        </Breadcrumb.Item>
        {!isCreateConfigMixin && (
          <Breadcrumb.Item>
            <Link to={urlPageConfigMixinDetails({ configurationId, configurationName, mixinId })}>
              Mixin details
            </Link>
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item>
          {isCreateConfigMixin ? 'Create mixin' : 'Edit'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Modal
        title={
          <div className='da-d-flex-center'>
            <InfoCircle set="curved" />
            &nbsp;Notification
          </div>
        }
        width={500}
        centered
        closable
        destroyOnClose
        visible={isActiveNotificationModal}
        onCancel={handleRedirectToConfigurationDetails}
        footer={[
          <Button
            key="cancel"
            onClick={handleRedirectToConfigurationDetails}
          >
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setToggleNotificationModal(false);
              navigate(urlPageCreateConfigMixinTargeting({
                configurationId,
                configurationName,
                mixinId: savedMixinId,
              }), { replace: true });
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div>Do you want to create target for this mixin?</div>
      </Modal>

      <Spin
        spinning={isLoadingConfigDetails || isLoadingMixinDetails || isLoadingCreateMixin || isLoadingUpdateMixin}
        tip={isLoadingCreateMixin || isLoadingUpdateMixin ? 'Please wait...' : 'Loading configuration mixin form...'}
      >
        <FormCreateEditConfigMixin
          initialValues={initialValues}
          configData={configData}
          isEditForm={!isCreateConfigMixin}
          serverErrors={serverErrors}
          setServerErrors={setServerErrors}
          formErrors={serverErrors}
          onCancel={() => {
            navigate(-1);
          }}
          isSubmitting={isLoadingCreateMixin || isLoadingUpdateMixin}
          onSubmit={handleSubmitFormData}
        />
      </Spin>
    </HasRights>
  );
};

export default PageCreateEditConfigTargeting;
