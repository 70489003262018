import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { mkNotification } from '../../../lib/mkNotification';
import ContactUsMonitoringService from '../services/contactUsMonitorService';
import { setUrlParams } from '../../../lib/setUrlParams';



const initialState = {
  contactUsData: [],
  loading: false,
};

const contactUsMonitoringService = new ContactUsMonitoringService(Api);

export const getContactUsMonitoringData = createAsyncThunk(
  'contactUsMonitoringEvents/getMonitoring',
  async (values, { rejectWithValue }) => {
    try {
      const response = await contactUsMonitoringService.getContactUsMonitoring(values);

      if (!isEmpty(values)) {
        setUrlParams(values);
      }

      if (isEmpty(response)) {
        mkNotification('info', 'No contact us monitoring data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const contactUsMonitorSlice = createSlice({
  name: 'contactUsMonitoringEvents',
  initialState,
  reducers: {
    resetContactUsMonitoringData: (state) => {
      state.contactUsData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactUsMonitoringData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContactUsMonitoringData.fulfilled, (state, action) => {
        state.loading = false;
        state.contactUsData = action.payload;
      })
      .addCase(getContactUsMonitoringData.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetContactUsMonitoringData } = contactUsMonitorSlice.actions;

// selectors
export const selectContactUsLoading = (state) => state.contactUsMonitoring.loading;
export const selectContactUsData = (state) => state.contactUsMonitoring.contactUsData;

// reducer
export default contactUsMonitorSlice.reducer;
