import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Layout, Row, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getLogsTableColumns } from './PageLogs.const';
import { mkQueryParams } from '../../../../../lib/mkQueryParams';
import { getQueryParams } from '../../../../../lib/getQueryParams';
import { useApp } from '../../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { getObjectValuesByUrlParams } from '../../../../../lib/getObjectValuesByUrlParams';
import { getPreparedFilterData, mkPreparedFilterData } from '../../../../../lib/getPreparedFilterData';
import CollapsibleFilterWr from '../../../../../components/CollapsibleFilterWr';
import FormLogsFilter from '../../forms/FormLogsFilter';
import { useCurrentSettingsScope, useLocalStorage } from '../../../../../app/hooks';
import { GLOBAL_SETTINGS_PREFIX } from '../../../../../const/system';
import {
  getLogsListData,
  resetLogsListData,
  selectLogsErrors,
  selectLogsList,
  selectLogsLoading,
  selectLogsOptions,
  selectLogsPagination,
} from '../../feature/logsSlice';



const { Content } = Layout;

const PageLogs = () => {
  const { pathname, search } = useLocation();
  const { projectSettings, userSpaces } = useApp();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ filterValues, setFilterValues ] = useLocalStorage('logs_filter', {});

  const dispatch = useDispatch();
  const logsList = useSelector(selectLogsList);
  const logsOptions = useSelector(selectLogsOptions);
  const pagination = useSelector(selectLogsPagination);
  const isLoading = useSelector(selectLogsLoading);
  const formErrors = useSelector(selectLogsErrors);
  const settingsScope = useCurrentSettingsScope();
  const isGlobalAdmin = settingsScope === GLOBAL_SETTINGS_PREFIX;
  const initialFilterValues = getObjectValuesByUrlParams(queryParams);

  const handleSetFilter = (values) => {
    window.scrollTo(0, 0);
    setFilterValues(values);
    dispatch(getLogsListData({ ...mkPreparedFilterData(values), ...mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting) }));
  };


  useEffect(() => {
    dispatch(getLogsListData({ ...mkPreparedFilterData(filterValues), ...mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting) }));
    return () => {
      dispatch(resetLogsListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting ]);


  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Logs list</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Col>

      <Content className='layout-content'>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} md={24} lg={6}>
            <CollapsibleFilterWr
              title='Filter'
              keyValue='mixin-visualize-filter'
              maxDeviceWidth={992}
            >
              <Spin spinning={isLoading} tip="Loading options...">
                <FormLogsFilter
                  initialValues={getPreparedFilterData(initialFilterValues)}
                  isSubmitting={isLoading}
                  spacesData={userSpaces}
                  currentSpaceKey={projectSettings?.spaceKey}
                  isGlobalAdmin={isGlobalAdmin}
                  optionsData={logsOptions}
                  formErrors={formErrors}
                  onSubmit={handleSetFilter}
                />
              </Spin>
            </CollapsibleFilterWr>
          </Col>
          <Col xs={24} md={24} lg={18}>
            <Card>
              <Table
                sticky
                loading={isLoading}
                rowKey="id"
                columns={getLogsTableColumns(settingsScope)}
                dataSource={logsList}
                onChange={handleChangeTableParams}
                pagination={{
                  size: 'small',
                  current: pagination?.current_page,
                  pageSize: pagination?.per_page,
                  total: pagination?.total,
                  position: [ 'bottomCenter' ],
                  pageSizeOptions: [ '10', '25', '50' ],
                  showSizeChanger: true,
                }}
                scroll={{ y: 550 }}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  );
};


export default PageLogs;
