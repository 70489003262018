import React from 'react';
import { Button, Popconfirm, Row, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { hasRights, Permissions } from '../../../../const/permissions';
import urlPageConfigVisualizeDetails from '../../../../urls/urlPageConfigVisualizeDetails';
import urlPageUserDetails from '../../../../urls/urlPageUserDetails';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';



export const getRequestListColumns = (handleDeleteRequest) => [
  {
    title: 'Name',
    align: 'center',
    render: (item) => item.name || EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Author',
    align: 'center',
    render: (item) => {
      return (
        <>
          {hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]) ? (
            <Link to={urlPageUserDetails({ userId: item.created_by.id })}>
              {item.created_by.name}
            </Link>
          ) : (<div>{item.created_by.name || EMPTY_VALUE_PLACEHOLDER}</div>)}
        </>
      );
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Last request at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('updated_at'),
  },
  ...(hasRights([
    Permissions.CONFIGS.CONFIG_VISUALIZE.DELETE,
    Permissions.CONFIGS.CONFIG_VISUALIZE.VIEW_LIST_AND_DETAILS,
  ]) ?
    [
      {
        title: 'Actions',
        key: 'action',
        align: 'center',
        width: '20%',
        render: (item) => {
          return (
            <Row className="da-h-100" align="middle" justify="center">
              {(hasRights([ Permissions.CONFIGS.CONFIG_VISUALIZE.DELETE ]) || item?.user_can_delete) && (
                <Popconfirm title="Sure to delete saved request?" onConfirm={() => handleDeleteRequest(item.id)}>
                  <Tooltip placement="top" title="Delete saved request">
                    <Button
                      size="small"
                      type="primary"
                      danger
                      className="btn-with-side-margin"
                    >
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              )}
              {hasRights([ Permissions.CONFIGS.CONFIG_VISUALIZE.VIEW_LIST_AND_DETAILS ]) && (
                <Tooltip placement="top" title="View details">
                  <Button
                    size="small"
                    type="primary"
                    className="btn-with-side-margin"
                  >
                    <Link to={urlPageConfigVisualizeDetails({ requestId: item.id })}>
                      <EyeOutlined />
                    </Link>
                  </Button>
                </Tooltip>
              )}
            </Row>
          );
        },
      } ] : []
  ),
];
