import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';
import UserProfileDetailsService from '../services/userProfileDetailsService';



const initialState = {
  userDetails: {},
  errors: [],
  loadingUserDetails: false,
};

const userDetailsService = new UserProfileDetailsService(Api);

export const getUserProfileDetailsData = createAsyncThunk(
  'userDetails/getUserProfileDetails',
  async (userId) => {
    const response = await userDetailsService.getUserProfileDetails(userId);

    return response.data;
  },
);

export const userProfileDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    resetUserDetailsData: (state) => {
      state.userDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileDetailsData.pending, (state) => {
        state.loadingUserDetails = true;
      })
      .addCase(getUserProfileDetailsData.fulfilled, (state, action) => {
        state.loadingUserDetails = false;
        state.userDetails = action.payload;
      })
      .addCase(getUserProfileDetailsData.rejected, (state, action) => {
        state.loadingUserDetails = false;
        state.errors = action.payload;
      });
  },
});

// actions
export const { resetUserDetailsData } = userProfileDetailsSlice.actions;

// selectors
export const selectUserDetailsLoading = (state) => state.userProfileDetails.loadingUserDetails;
export const selectUserDetails = (state) => state.userProfileDetails.userDetails;
export const selectErrors = (state) => state.userProfileDetails.userDetails;

// reducer
export default userProfileDetailsSlice.reducer;
