import { Col, Row, Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useCheckMobileScreen } from '../../../../app/hooks';
import DescriptionValueRow from '../../../../components/DescriptionValueRow';
import { renderDateField } from '../../../../const/system';



const DeviceCurrentAbTestCard = ({ abTestData, deviceAbTestsLoading }) => {
  const isMobile = useCheckMobileScreen();

  return (
    <>
      <Spin spinning={!!deviceAbTestsLoading} tip="Loading ab-tests information...">
        <Row align="top" justify={isMobile ? 'inherit' : 'space-around'}>
          <Col
            xs={24} sm={24} lg={12} md={12} xl={8}
            className="da-profile-content-list da-mt-8 da-pb-sm-0 da-pb-24"
          >
            <ul>
              <DescriptionValueRow
                firstRow
                title='Id'
                value={abTestData.id}
              />
              <DescriptionValueRow
                title='Name'
                value={abTestData.name}
              />
              <DescriptionValueRow
                title='Group'
                value={abTestData.group}
              />
            </ul>
          </Col>

          <Col
            xs={24} sm={24} lg={10} md={10}
            className="da-profile-content-list da-mt-8 da-pb-sm-0 da-pb-24"
          >
            <ul>
              <DescriptionValueRow
                firstRow
                title='Started at'
                value={renderDateField(abTestData?.startedAt)}
              />
              <DescriptionValueRow
                title='Finished at'
                value={renderDateField(abTestData?.finishedAt)}
              />
            </ul>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

DeviceCurrentAbTestCard.propTypes = {
  abTestData: PropTypes.object,
  deviceAbTestsLoading: PropTypes.bool,
};

DeviceCurrentAbTestCard.defaultProps = {
  abTestData: {},
  deviceAbTestsLoading: false,
};

export default DeviceCurrentAbTestCard;
