import { Badge, Button, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { RiArchiveLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import React from 'react';
import urlPageMixinsExperimentDetails from '../../../../urls/urlPageMixinsExperimentDetails';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import { hasRights, Permissions } from '../../../../const/permissions';



export const EXPERIMENT_STATUSES_MAP = {
  CREATED: 'created',
  ON_CHECKING: 'on_checking',
  REVIEW: 'review',
  FREE: 'free',
  APPROVED: 'approved',
  RELEASED: 'released',
  OBSOLETE: 'obsolete',
  ARCHIVED: 'archived',
};

export const EXPERIMENTS_STATUS_TRANSLATIONS = (status) => {
  switch (status) {
    case EXPERIMENT_STATUSES_MAP.CREATED: {
      return 'Created';
    }
    case EXPERIMENT_STATUSES_MAP.ON_CHECKING: {
      return 'On checking';
    }
    case EXPERIMENT_STATUSES_MAP.FREE: {
      return 'Free';
    }
    case EXPERIMENT_STATUSES_MAP.REVIEW: {
      return 'Review';
    }
    case EXPERIMENT_STATUSES_MAP.APPROVED: {
      return 'Approved';
    }
    case EXPERIMENT_STATUSES_MAP.RELEASED: {
      return 'Released';
    }
    case EXPERIMENT_STATUSES_MAP.OBSOLETE: {
      return 'Obsolete';
    }
    case EXPERIMENT_STATUSES_MAP.ARCHIVED: {
      return 'Archived';
    }
    default: {
      return EMPTY_VALUE_PLACEHOLDER;
    }
  }
};


export const EXPERIMENT_STATUS_COLOR = (status) => {
  switch (status) {
    case EXPERIMENT_STATUSES_MAP.CREATED: case EXPERIMENT_STATUSES_MAP.FREE: {
      return 'gray';
    }
    case EXPERIMENT_STATUSES_MAP.ON_CHECKING: case EXPERIMENT_STATUSES_MAP.REVIEW: {
      return 'yellow';
    }
    case EXPERIMENT_STATUSES_MAP.APPROVED: case EXPERIMENT_STATUSES_MAP.RELEASED: {
      return 'green';
    }
    case EXPERIMENT_STATUSES_MAP.ARCHIVED: {
      return 'red';
    }
    default: {
      return 'black';
    }
  }
};

export const EXPERIMENT_IN_SANDBOX_STATUS_COLOR = (status) => {
  switch (status) {
    case true: {
      return 'green';
    }
    case false: {
      return 'red';
    }
    default: {
      return 'red';
    }
  }
};


export const getSandboxListColumns = (userId, defaultFilterValues, allowedStatusesList, handleArchiveExperiment) => [
  {
    title: 'Name',
    align: 'center',
    width: '25%',
    render: (item) => {
      return (
        <div>
          <div className='da-mb-10'>{item?.name ?? EMPTY_VALUE_PLACEHOLDER}</div>
          <div className='da-mb-10'>{item?.config?.data?.name ?? EMPTY_VALUE_PLACEHOLDER}</div>
          {item.is_draft && (<Tag>Draft</Tag>)}
          {item.is_paused && (<Tag color="volcano">Paused</Tag>)}
          {item.in_sandbox && (<Tag color="green">Sandbox</Tag>)}
          {item.config.data.is_blocked && ![
            EXPERIMENT_STATUSES_MAP.APPROVED,
            EXPERIMENT_STATUSES_MAP.RELEASED,
            EXPERIMENT_STATUSES_MAP.ARCHIVED,
            EXPERIMENT_STATUSES_MAP.OBSOLETE,
          ].includes(item.status) && (
            <Tag color="red">Blocked</Tag>
          )}
        </div>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    filters: allowedStatusesList.map((status) => (
      {
        text: <span>{EXPERIMENTS_STATUS_TRANSLATIONS(status)}</span>,
        value: status,
      }
    )),
    defaultFilteredValue: defaultFilterValues,
    onFilter: (value, record) => value === record.status,
    render: (item) => <Badge color={EXPERIMENT_STATUS_COLOR(item)} text={EXPERIMENTS_STATUS_TRANSLATIONS(item)} />,
    sorter: (_a, _b) => _a.value - _b.value,
  },
  {
    title: 'Assigned to',
    align: 'center',
    render: (item) => <div>{item?.assigned?.name || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: (_a, _b) => new Date(_a.created_at) - new Date(_b.created_at),
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: (_a, _b) => new Date(_a.updated_at) - new Date(_b.updated_at),
    defaultSortOrder: getDefaultTableSorting('updated_at'),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    width: '15%',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {hasRights([ Permissions.MIXINS_EXPERIMENTS.VIEW_DETAILS ]) && (
            <Tooltip placement="top" title='Experiment details'>
              <Button size="small" type='primary' className='btn-with-side-margin'>
                <Link to={urlPageMixinsExperimentDetails({ testId: item.id, testName: item.name })}>
                  <EyeOutlined />
                </Link>
              </Button>
            </Tooltip>
          )}
          {hasRights([ Permissions.MIXINS_EXPERIMENTS.ARCHIVE ]) &&
            [ EXPERIMENT_STATUSES_MAP.CREATED, EXPERIMENT_STATUSES_MAP.ON_CHECKING ].includes(item.status) &&
            item?.assigned?.id === userId && !item.is_paused && !item.in_sandbox && (
            <Popconfirm
              disabled={item.status === EXPERIMENT_STATUSES_MAP.ARCHIVED}
              title="Sure to archive this experiment?"
              onConfirm={() => {
                handleArchiveExperiment(item);
              }}
            >
              <Tooltip placement="top" title={item.status !== EXPERIMENT_STATUSES_MAP.ARCHIVED ? 'Archive experiment' : 'Archived'}>
                <Button
                  size="small"
                  type='primary'
                  disabled={item.status === EXPERIMENT_STATUSES_MAP.ARCHIVED}
                  className='btn-with-side-margin warning-btn'
                >
                  <RiArchiveLine />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
        </Row>
      );
    },
  },
];
