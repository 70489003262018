import { Breadcrumb, Button, Card, Col, Row, Table } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { hasRights, Permissions } from '../../../../const/permissions';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { useLocalStorage } from '../../../../app/hooks';
import urlPageMixinsExperiments from '../../../../urls/urlPageMixinsExperiments';
import urlPageCreateMixinsExperiment from '../../../../urls/urlPageCreateMixinsExperiment';
import { getSandboxListColumns, EXPERIMENT_STATUSES_MAP } from './PageMixinsExperiments.const';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import {
  selectExperimentsList,
  selectExperimentsListLoading,
  selectExperimentsPagination,
  selectExperimentsStatuses,
  resetTestsListData,
  getExperimentsListData,
  archiveExperiment,
} from '../../feature/mixinsExperimentsSlice';



const PageMixinsExperiments = () => {
  const { pathname, search } = useLocation();
  const { projectSettings } = useApp();
  const userId = JSON.parse(localStorage.getItem('user'))?.user?.id;
  const dispatch = useDispatch();
  const queryParams = getQueryParams(search);
  const [ storedSandboxTestsListStatusFilter, setStoreSandboxTestsListStatusFilter ] = useLocalStorage(
    'experiments_status_filter',
    Object.values(EXPERIMENT_STATUSES_MAP).filter((status) => ![ EXPERIMENT_STATUSES_MAP.OBSOLETE, EXPERIMENT_STATUSES_MAP.ARCHIVED ].includes(status)),
  );
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ currentStatuses, setCurrentStatuses ] = useState(queryParams.status);

  const experimentsList = useSelector(selectExperimentsList);
  const isLoading = useSelector(selectExperimentsListLoading);
  const pagination = useSelector(selectExperimentsPagination);
  const allowedStatusesList = useSelector(selectExperimentsStatuses);

  const hasDefaultFilterValues = !isEmpty(storedSandboxTestsListStatusFilter);
  const defaultFilterValues = hasDefaultFilterValues ? storedSandboxTestsListStatusFilter :
    allowedStatusesList.filter((status) => ![ EXPERIMENT_STATUSES_MAP.OBSOLETE, EXPERIMENT_STATUSES_MAP.ARCHIVED ].includes(status));


  const handleGetTestsList = () => {
    dispatch(getExperimentsListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting, '', [ {
      filterKey: 'status',
      filterValue: !isEmpty(defaultFilterValues) ? defaultFilterValues : currentStatuses,
    } ])));
  };


  useEffect(() => {
    if ((pathname === urlPageMixinsExperiments())) {
      handleGetTestsList();
    }

    if (!hasDefaultFilterValues) {
      setStoreSandboxTestsListStatusFilter(defaultFilterValues);
    }

    return () => {
      dispatch(resetTestsListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting, currentStatuses ]);


  const handleArchiveExperiment = (data) => {
    dispatch(archiveExperiment({ testId: data.id })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        handleGetTestsList();
      }
    });
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    window.scrollTo(0, 0);
    const filterStatusesArr = Object.values(filters)[0];

    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }

    if (!isEqual(filterStatusesArr, queryParams.status)) {
      setCurrentStatuses(filterStatusesArr);
    }
    setStoreSandboxTestsListStatusFilter(filterStatusesArr);
  };

  return (
    <>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Mixins experiments</Breadcrumb.Item>
          </Breadcrumb>
          {hasRights([ Permissions.MIXINS_EXPERIMENTS.CREATE ]) && (
            <Button
              style={{ margin: '10px 0' }}
              size='middle'
              type="primary"
            >
              <Link to={urlPageCreateMixinsExperiment()}>
                Create experiment
              </Link>
            </Button>
          )}
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Table
            sticky
            columns={getSandboxListColumns(userId, defaultFilterValues, allowedStatusesList, handleArchiveExperiment)}
            onChange={handleChangeTableParams}
            dataSource={experimentsList}
            rowKey="id"
            loading={isLoading}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 550 }}
          />
        </Card>
      </div>
    </>
  );
};

export default PageMixinsExperiments;
