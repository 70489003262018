import React from 'react';
import { Button, Checkbox, Col, Divider, Row, Tooltip } from 'antd';
import { DatePicker, Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { RiInformationLine } from 'react-icons/ri';
import { Permissions, hasRights } from '../../../../const/permissions';
import { COUNTRIES_LIST, SEMVER_REGEXP } from '../../../../const/system';
import Yup from '../../../../vendor/yup';
import { mkSelectFilterOption } from '../../../../lib/mkSelectFilterOption';
import {
  AB_TEST_AFFILIATION_OPTIONS,
  AB_TESTS_LANGUAGES_OPTIONS,
  DEVICE_TYPE_OPTIONS,
  PLATFORM_OPTIONS,
} from '../../../AbTests/forms/FormCreateEditAbTest/FormCreateEditAbTest.const';



const { Option } = Select;


export const FormCreateConfigVisualizeButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const FormCreateConfigVisualizeButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;


const validationSchema = Yup.lazy(() => {
  const semverField = Yup.string().nullable().matches(SEMVER_REGEXP, 'Value is doesn`t matches with semver regular expression').max(100, 'Max 100 symbols required');

  return (
    Yup.object().shape({
      utc: Yup.number().integer()
        .typeError('Specify integer value')
        .min(-12, 'Min value -12')
        .max(12, 'Max value 12'),
      locale: Yup.string().nullable()
        .max(50, 'Max 50 symbols required')
        .required('Required field'),
      user_id: Yup.string()
        .min(12, 'Min 12 symbols required')
        .max(255, 'Max 255 symbols required')
        .required('Required field'),
      ad_id: Yup.string().max(255, 'Max 255 symbols required').required('Required field'),
      adv_id: Yup.string().nullable().max(255, 'Max 255 symbols required'),
      alt_id: Yup.string().nullable()
        .min(12, 'Min 12 symbols required')
        .max(255, 'Max 255 symbols required'),
      sync_id: Yup.string().nullable()
        .min(12, 'Min 12 symbols required')
        .max(255, 'Max 255 symbols required'),
      language: Yup.string().nullable().required('Required field'),
      app_install_date: Yup.string().nullable().required('Required field'),
      platform: Yup.string().nullable().required('Required field'),
      device_manufacturer: Yup.string()
        .min(2, 'Min 2 symbols required')
        .max(100, 'Max 100 symbols required')
        .required('Required field'),
      device_type: Yup.string().nullable().required('Required field'),
      device_model: Yup.string()
        .min(2, 'Min 2 symbols required')
        .max(100, 'Max 100 symbols required')
        .matches(/^[a-zA-Z0-9 :_!-]*$/, 'Only latin letters, digits, spaces and :_!- is required')
        .required('Required field'),
      ad_banner_size: Yup.string().nullable().required('Required field'),
      os_version: semverField.required('Required field'),
      app_version: semverField.required('Required field'),
      ad_version: semverField.required('Required field'),
      ab_name: Yup.string()
        .min(2, 'Min 2 symbols required')
        .max(50, 'Max 50 symbols required').nullable(),
      ab_group: Yup.string().nullable().when('ab_name', {
        is: (ab_name) => !isEmpty(ab_name),
        then: () => Yup.string()
          .min(2, 'Min 2 symbols required')
          .max(50, 'Max 50 symbols required')
          .nullable().required('Required to select ab test group'),
      }),
      ab_affiliation: Yup.string().nullable().when('ab_group', {
        is: (ab_group) => !isEmpty(ab_group),
        then: () => Yup.string()
          .min(2, 'Min 2 symbols required')
          .max(50, 'Max 50 symbols required')
          .nullable().required('Required to specify ab test affiliation'),
      }),
      dev_ab_name: Yup.string().nullable(),
      dev_ab_group: Yup.string().nullable().when('dev_ab_name', {
        is: (dev_ab_name) => !isEmpty(dev_ab_name),
        then: () => Yup.string().nullable().required('Required to select ab test group'),
      }),
      dev_ab_affiliation: Yup.string().nullable().when('dev_ab_group', {
        is: (dev_ab_group) => !isEmpty(dev_ab_group),
        then: () => Yup.string().nullable().required('Required to specify ab test affiliation'),
      }),
      device_performance: Yup.number().integer()
        .typeError('Specify integer value')
        .min(0, 'Should be equal or more than 0')
        .max(65000, 'Should be less or equal 65000').nullable(),
      ram: Yup.number().integer()
        .typeError('Specify integer value')
        .min(0, 'Should be equal or more than 0')
        .max(65000, 'Should be less or equal 65000').nullable(),
      custom_target: Yup.string().matches(/^[a-zA-Z0-9 (){}_:.,+-]*$/, 'Only latin letters, digits and (){}_:.,+- is required')
        .max(255, 'Max 255 symbols required').nullable(),
      event: Yup.string().max(1024, 'Max 1024 symbols required').nullable()
        .test(
          'valid-json',
          'Invalid JSON string',
          (value) => {
            if (!isEmpty(value)) {
              try {
                JSON.parse(value);
              // eslint-disable-next-line no-unused-vars
              } catch (error) {
                return false;
              }
              return true;
            }
            return true;
          },
        ),
    })
  );
});



const FormCreateConfigVisualize = ({
  onSubmit,
  initialValues,
  isSubmitting,
  onCancel,
  formErrors,
}) => {
  return (
    <Formik
      enableReinitialize
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, values, setFieldValue } = props;

        return (
          <Form layout="vertical">
            <Row gutter={16}>
              <Divider orientation="left">Identifiers</Divider>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="user_id" label="User Id:" required>
                  <Input
                    name="user_id"
                    placeholder='Specify user Id'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="ad_id" label="Ad Id:" required>
                  <Input
                    name="ad_id"
                    placeholder='Specify Ad Id'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="adv_id" label="Advertising Id:">
                  <Input
                    name="adv_id"
                    placeholder='Specify advertising Id'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="alt_id" label="Alternative Id:">
                  <Input
                    name="alt_id"
                    placeholder='Specify alternative Id'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="sync_id" label="Sync Id:">
                  <Input
                    name="sync_id"
                    placeholder='Specify sync id'
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Divider orientation="left">Location</Divider>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="language" label="Language:" required>
                  <Select
                    allowClear
                    showSearch
                    name='language'
                    placeholder="Select language"
                    optionFilterProp="children"
                    filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {AB_TESTS_LANGUAGES_OPTIONS.map((option) => {
                      return (
                        <Option key={option.key} value={option.key}>
                          {option.label}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="locale" label="Locale:" required>
                  <Input
                    name="locale"
                    placeholder='Specify locale'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="utc" label="Timezone:">
                  <Input
                    type='number'
                    name="utc"
                    placeholder='Specify utc'
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Divider orientation="left">App</Divider>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="app_version" label="App Version:" required>
                  <Input
                    name="app_version"
                    placeholder='Specify app version'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem
                  name="app_install_date"
                  required
                  label={
                    <div className='content-right'>
                      Install Date:
                      <Tooltip placement="top" title="Time zone - UTC +0">
                        <RiInformationLine size={18} />
                      </Tooltip>
                    </div>
                  }
                >
                  <DatePicker
                    showTime
                    name="app_install_date"
                    placeholder='Specify install date'
                    onChange={(value) => {
                      if (!isEmpty(value)) {
                        setFieldValue('app_install_date', moment(value).utc(false).format('YYYY-MM-DD HH:mm:ss'));
                      }
                    }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="is_new_user" label="User new:">
                  <Checkbox
                    name="is_new_user"
                    defaultChecked={values.is_new_user}
                    onChange={(event) => {
                      setFieldValue('is_new_user', event.target.checked);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Divider orientation="left">Device</Divider>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="platform" label="Platform:" required>
                  <Select
                    allowClear
                    showSearch
                    name="platform"
                    placeholder="Select platform"
                    optionFilterProp="children"
                    filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {PLATFORM_OPTIONS.map((option) => {
                      return (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="os_version" label="Os Version:" required>
                  <Input
                    name="os_version"
                    placeholder='Specify OS version'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="device_manufacturer" label="Device Manufacturer:" required>
                  <Input
                    name="device_manufacturer"
                    placeholder='Specify device manufacturer'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="device_type" label="Device Type:" required>
                  <Select
                    allowClear
                    showSearch
                    name='device_type'
                    placeholder="Select device type"
                    optionFilterProp="children"
                    filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {DEVICE_TYPE_OPTIONS.map((option) => {
                      return (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="device_model" label="Device Model:" required>
                  <Input
                    name="device_model"
                    placeholder='Specify device model'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="ram" label="Ram:">
                  <Input
                    type='number'
                    name="ram"
                    placeholder='Specify device ram'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="device_performance" label="Device Performance:">
                  <Input
                    type='number'
                    name="device_performance"
                    placeholder='Specify device performance'
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Divider orientation="left">Ads</Divider>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="ad_banner_size" label="Ad banner Size:" required>
                  <Select
                    allowClear
                    showSearch
                    name='ad_banner_size'
                    onChange={(value) => {
                      setFieldValue('ad_banner_size', value);
                    }}
                    placeholder="Select device type"
                    value={values.ad_banner_size}
                    optionFilterProp="children"
                    filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {DEVICE_TYPE_OPTIONS.map((option) => {
                      return (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="ad_version" label="Ad version:" required>
                  <Input
                    name="ad_version"
                    placeholder='Specify Ad version'
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6} lg={3}>
                <FormItem name="ad_in_app" label="Ad inapp purchased:">
                  <Select
                    name="ad_in_app"
                    placeholder="Select ad inapp purchased"
                    value={values.ad_in_app}
                  >
                    <Option value={null}>Empty</Option>
                    <Option value={1}>True</Option>
                    <Option value={0}>False</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6} lg={3}>
                <FormItem name="ad_distribution" label="Ad Distribution:">
                  <Select
                    name="ad_distribution"
                    placeholder="Select ad distribution"
                    value={values.ad_distribution}
                  >
                    <Option value={null}>Empty</Option>
                    <Option value={1}>True</Option>
                    <Option value={0}>False</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Divider orientation="left">A/B Tests</Divider>
              <Col xs={24} sm={12} md={6}>
                <FormItem name="ab_name" label="AbName:">
                  <Input
                    name="ab_name"
                    placeholder="Specify AbTest name"
                    onChange={(event) => {
                      setFieldValue('ab_name', event.target.value);

                      if (isEmpty(event.target.value)) {
                        setFieldValue('ab_group', '');
                        setFieldValue('ab_affiliation', null);
                      }
                    }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem
                  name="ab_group"
                  label="AbGroup:"
                  required={!isEmpty(values?.ab_name)}
                >
                  <Input
                    name="ab_group"
                    placeholder="Specify AbTest group"
                    disabled={isEmpty(values?.ab_name)}
                    onChange={(event) => {
                      setFieldValue('ab_group', event.target.value);

                      if (isEmpty(event.target.value)) {
                        setFieldValue('ab_affiliation', null);
                      }
                    }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <FormItem
                  name="ab_affiliation"
                  label="AbAffilation:"
                  required={!isEmpty(values?.ab_group)}
                >
                  <Select
                    allowClear
                    name="ab_affiliation"
                    placeholder="Select AbAffilation"
                    value={values.ab_affiliation}
                    disabled={isEmpty(values?.ab_name) || isEmpty(values?.ab_group)}
                  >
                    <Option value='none'>None</Option>
                    {AB_TEST_AFFILIATION_OPTIONS.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>

            {hasRights([ Permissions.CONFIGS.CONFIG_VISUALIZE.VIEW_DEV_FIELDS_ON_CREATE ]) && (
              <>
                <Row gutter={16}>
                  <Divider orientation="left">Development</Divider>
                  <Col xs={24} sm={12} md={6}>
                    <FormItem name="country_code" label="Country:">
                      <Select
                        allowClear
                        showSearch
                        name="country_code"
                        placeholder="Select country"
                        optionFilterProp="children"
                        filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                      >
                        {COUNTRIES_LIST.map((option) => {
                          return (
                            <Option key={option.code} value={option.code}>
                              {`${option.code} - ${option.name}`}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <FormItem name="country_region" label="Region:">
                      <Input
                        name="country_region"
                        placeholder='Specify region'
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={12} md={6}>
                    <FormItem name="dev_ab_name" label="AbTest name:">
                      <Input
                        name="dev_ab_name"
                        placeholder="Specify AbTest name"
                        onChange={(event) => {
                          setFieldValue('dev_ab_name', event.target.value);

                          if (isEmpty(event.target.value)) {
                            setFieldValue('dev_ab_group', '');
                            setFieldValue('dev_ab_affiliation', null);
                          }
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <FormItem
                      className='ant-form-item-col'
                      name='dev_ab_group'
                      label="AbTest group:"
                      required={!isEmpty(values?.dev_ab_name)}
                    >
                      <Input
                        name="dev_ab_group"
                        placeholder="Specify AbTest group"
                        disabled={isEmpty(values?.dev_ab_name)}
                        onChange={(event) => {
                          setFieldValue('dev_ab_group', event.target.value);

                          if (isEmpty(event.target.value)) {
                            setFieldValue('dev_ab_affiliation', null);
                          }
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <FormItem
                      name="dev_ab_affiliation"
                      required={!isEmpty(values?.dev_ab_group)}
                      label="AbTest Affiliation:"
                    >
                      <Select
                        allowClear
                        name="dev_ab_affiliation"
                        placeholder="Select AbTest Affilation"
                        value={values.dev_ab_affiliation}
                        disabled={isEmpty(values?.dev_ab_name) || isEmpty(values?.dev_ab_group)}
                      >
                        <Option value='none'>None</Option>
                        {AB_TEST_AFFILIATION_OPTIONS.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} md={18} lg={12}>
                    <FormItem
                      name="event"
                      label="Event:"
                    >
                      <Input.TextArea
                        name="event"
                        placeholder='Specify event'
                        rows={7}
                        showCount
                        maxLength={1024}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </>
            )}

            <Row gutter={16}>
              <Divider orientation="left">Other</Divider>
              <Col xs={24} md={12}>
                <FormItem name="custom_target" label="Custom Target:">
                  <Input.TextArea
                    name="custom_target"
                    placeholder='Specify custom target'
                    rows={7}
                    showCount
                    maxLength={255}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6} lg={3}>
                <FormItem name="limit_tracking" label="Limit Tracking:">
                  <Select
                    name="limit_tracking"
                    placeholder="Select limit tracking"
                    value={values.limit_tracking}
                  >
                    <Option value={null}>Empty</Option>
                    <Option value={1}>True</Option>
                    <Option value={0}>False</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={6} lg={3}>
                <FormItem name="limit_ad_tracking" label="Limit Ad Tracking:">
                  <Select
                    name="limit_ad_tracking"
                    placeholder="Select limit ad tracking"
                    value={values.limit_ad_tracking}
                  >
                    <Option value={null}>Empty</Option>
                    <Option value={1}>True</Option>
                    <Option value={0}>False</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]}>
              <FormCreateConfigVisualizeButtonsRow>
                <FormCreateConfigVisualizeButtonWr>
                  <Button onClick={onCancel}>
                    Cancel
                  </Button>
                </FormCreateConfigVisualizeButtonWr>
                <FormCreateConfigVisualizeButtonWr>
                  <SubmitButton
                    style={{ width: '100%' }}
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Next
                  </SubmitButton>
                </FormCreateConfigVisualizeButtonWr>
              </FormCreateConfigVisualizeButtonsRow>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};


FormCreateConfigVisualize.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    ab_name: PropTypes.string,
    ab_group: PropTypes.string,
    ab_affiliation: PropTypes.string,
    is_new_user: PropTypes.bool,
    ad_in_app: PropTypes.any,
    ad_distribution: PropTypes.any,
    ad_banner_size: PropTypes.string,
    limit_tracking: PropTypes.any,
    limit_ad_tracking: PropTypes.any,
    dev_ab_name: PropTypes.string,
    dev_ab_group: PropTypes.string,
    dev_ab_affiliation: PropTypes.string,
  }),
};

FormCreateConfigVisualize.defaultProps = {
  formErrors: [],
  setFieldValue: () => {},
  values: {
    ab_name: '',
    ab_group: '',
    ab_affiliation: null,
    dev_ab_name: '',
    dev_ab_group: '',
    dev_ab_affiliation: null,
    ad_in_app: null,
    is_new_user: false,
    ad_distribution: 1,
    limit_tracking: 0,
    limit_ad_tracking: 0,
    ad_banner_size: 'phone',
  },
};

export default FormCreateConfigVisualize;
