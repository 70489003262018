import React from 'react';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { FastField, FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Popconfirm, Row, Spin, Alert } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { RiAddFill, RiArrowDownLine, RiArrowUpLine } from 'react-icons/ri';
import { checkScalar, checkPathType, checkObjPathNesting } from './FormCreateEditConfigMixin.const';
import Yup from '../../../../vendor/yup';
import './index.css';



const { Option } = Select;

const FormCreateEditAbTestButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
`;

const FormCreateEditAbTestButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z\d-_ ]*$/, 'Only latin literatures, numbers, spaces, _ and - is allowed')
    .min(5, 'Min 5 symbols required')
    .max(100, 'Max 100 symbols required')
    .required('Name is required'),
  status: Yup.string().required('Required to select status'),
  priority: Yup.string()
    .matches(/^\d+$/, 'Only positive digits is required')
    .required('Required field'),
  config: Yup.array().test(
    'is-valid-mixin-config', 'Invalid mixin configuration',
    (value) => !isEmpty(value),
  ).required('Required to fill mixin configuration'),
});


const FormCreateEditConfigMixin = ({
  onSubmit,
  onCancel,
  initialValues,
  configData,
  isSubmitting,
  formErrors,
  isEditForm,
  serverErrors,
  setServerErrors,
}) => {
  const customErrors = cloneDeep(serverErrors);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { dirty, values, isValid, errors } = props;

        const configValues = !isEmpty(values?.config) ? values.config : [];
        const getNumberOfCards = (type) => configValues.filter((item) => item.type === type).length;

        if (!isEmpty(customErrors) && isEmpty(Object.values(customErrors?.config).filter((item) => Object.keys(item).length !== 0))) {
          setServerErrors({});
        }

        return (
          <Spin spinning={isEditForm && isEmpty(initialValues)} tip="Loading form data...">
            <Form layout="vertical" style={{ width: '100%' }}>
              <Row gutter={[ 16, 16 ]}>
                <Col span={24}>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Mixin details
                  </Divider>
                  <Row gutter={[ 16, 16 ]}>
                    <Col span={24}>
                      <Card>
                        <Row gutter={16}>
                          <Col xs={24} md={12} lg={6}>
                            <FormItem
                              className='ant-form-item-col'
                              name="config_name"
                              label="Configuration name:"
                            >
                              <Input
                                name='config_name'
                                disabled
                              />
                            </FormItem>
                          </Col>
                          {isEditForm && (
                            <Col xs={24} md={12} lg={4}>
                              <FormItem
                                className='ant-form-item-col'
                                name="author_name"
                                label="Author name:"
                              >
                                <Input
                                  name='author_name'
                                  disabled
                                />
                              </FormItem>
                            </Col>
                          )}
                          <Col xs={24} md={12} lg={6}>
                            <FormItem
                              className='ant-form-item-col'
                              name="name"
                              label='Mixin name'
                              required={!isEditForm}
                            >
                              <Input
                                fast
                                name='name'
                                placeholder='Specify mixin name'
                                disabled={isEditForm}
                              />
                            </FormItem>
                          </Col>

                          <Col xs={24} md={12} lg={!isEditForm ? 6 : 4}>
                            <FormItem
                              className='ant-form-item-col'
                              name="status"
                              label="Status:"
                              required
                            >
                              <Select
                                name="status"
                                placeholder="Select status"
                                value={values.status}
                              >
                                <Option value="enabled">Enabled</Option>
                                <Option value="paused">Paused</Option>
                              </Select>
                            </FormItem>
                          </Col>

                          <Col xs={24} md={12} lg={!isEditForm ? 6 : 4}>
                            <FormItem
                              className='ant-form-item-col'
                              name="priority"
                              label='Priority:'
                              required
                            >
                              <Input
                                fast
                                name='priority'
                                placeholder='Specify priority'
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Mixin configuration
                  </Divider>
                  {isEmpty(configValues) && (
                    <Alert
                      className='da-mb-18'
                      message="Notification"
                      description="Mixin configuration should not be empty. Please fill the form below."
                      type="info"
                    />
                  )}
                  <Row gutter={[ 16, 16 ]}>
                    <Col xs={24} md={8} xl={8}>
                      <FieldArray
                        name="config"
                        value={values.config}
                      >
                        {(arrayHelpers) => {
                          let createCardIndex = 0;

                          const createItemsIndexes = [];

                          return (
                            <>
                              {!isEmpty(configValues) && configValues.map((mixin, index) => {
                                if (mixin.type === 'create') {
                                  createCardIndex++;
                                  createItemsIndexes.push(index);

                                  return (
                                    <Card
                                      /* eslint-disable-next-line react/no-array-index-key */
                                      key={index}
                                      title={`Create # ${createCardIndex}`}
                                      extra={[
                                        <div key='create_up'>
                                          {createCardIndex !== 1 && (
                                            <Button
                                              style={{ marginRight: '6px' }}
                                              size='small'
                                              icon={<RiArrowUpLine />}
                                              onClick={() => {
                                                const currIndex = createItemsIndexes.indexOf(index);

                                                arrayHelpers.swap(index, createItemsIndexes[currIndex - 1]);
                                              }}
                                            />
                                          )}
                                        </div>,
                                        <div key='create_down'>
                                          {createCardIndex !== getNumberOfCards('create') && (
                                            <Button
                                              style={{ marginRight: '6px' }}
                                              size='small'
                                              icon={<RiArrowDownLine />}
                                              onClick={() => {
                                                const currIndex = createItemsIndexes.indexOf(index);

                                                arrayHelpers.swap(index, createItemsIndexes[currIndex + 1]);
                                              }}
                                            />
                                          )}
                                        </div>,
                                        <Popconfirm
                                          key='create_delete_item'
                                          title="Sure to delete this mixin?"
                                          onConfirm={() => arrayHelpers.remove(index)}
                                        >
                                          <Button
                                            size='small'
                                            danger
                                            icon={<DeleteOutlined />}
                                          />
                                        </Popconfirm>,
                                      ]}
                                    >
                                      {/* eslint-disable-next-line react/no-array-index-key */}
                                      <FastField name={`config.${index}`} key={index}>
                                        {({ field: {}, form: { setFieldValue, setErrors } }) => {
                                          setErrors(!isEmpty(customErrors) ? customErrors : errors);

                                          return (
                                            <>
                                              <Row gutter={16}>
                                                <Col span={24}>
                                                  <FormItem
                                                    className='ant-form-item-col'
                                                    name={`config.${index}.priority`}
                                                    label="Priority:"
                                                    required
                                                  >
                                                    <Input
                                                      fast
                                                      name={`config.${index}.priority`}
                                                      placeholder='Specify priority'
                                                      validate={(value) => {
                                                        let error;

                                                        if (isEmpty(value)) {
                                                          error = 'Required field';
                                                        }
                                                        return error;
                                                      }}
                                                      onChange={(event) => {
                                                        setFieldValue(`config.${index}.priority`, event.target.value.replace(/\D/g, ''));
                                                      }}
                                                    />
                                                  </FormItem>
                                                </Col>

                                                <Col span={24}>
                                                  <FormItem
                                                    className='ant-form-item-col'
                                                    name={`config.${index}.path`}
                                                    label="Path:"
                                                    required
                                                  >
                                                    <Input
                                                      fast
                                                      addonBefore="#"
                                                      name={`config.${index}.path`}
                                                      validate={(value) => {
                                                        const preparedPath = !isEmpty(value) && value.includes('/') ? value.split('/').join('.') : value;
                                                        const hasScalar = checkScalar(configData, preparedPath);
                                                        const isSimilarPath = checkObjPathNesting(configData, preparedPath);
                                                        const isDuplicatedPath = configValues.filter((item) => item.type === 'create' && item.path === value).length > 1;

                                                        let error;

                                                        if (isEmpty(value)) {
                                                          error = 'Required field';
                                                        }

                                                        if (hasScalar) {
                                                          error = 'Recent path cannot exist inside a scalar';
                                                        }
                                                        if (isSimilarPath) {
                                                          error = 'You can not create a value in an existing path, please use UPDATE instead';
                                                        }
                                                        if (isDuplicatedPath) {
                                                          error = 'Path should be unique';
                                                        }
                                                        if (preparedPath[preparedPath.length - 1] === '.') {
                                                          error = 'Last character of recent path should not content "/"';
                                                        }
                                                        return error;
                                                      }}
                                                      placeholder='Specify path'
                                                      onChange={(event) => {
                                                        setFieldValue(`config.${index}.path`, event.target.value.replace(/\s/g, ''));
                                                        if (!isEmpty(customErrors)) {
                                                          // eslint-disable-next-line fp/no-delete
                                                          delete customErrors?.config[index]?.path;
                                                        }
                                                      }}
                                                    />
                                                  </FormItem>
                                                </Col>
                                              </Row>
                                              <Row gutter={16}>
                                                <Col span={24}>
                                                  <FormItem
                                                    className='ant-form-item-col'
                                                    name={`config.${index}.value`}
                                                    label="Value:"
                                                    required
                                                  >
                                                    <Input.TextArea
                                                      name={`config.${index}.value`}
                                                      placeholder='Specify value'
                                                      validate={(value) => {
                                                        let error;

                                                        if (isEmpty(value)) {
                                                          error = 'Required field';
                                                        }
                                                        return error;
                                                      }}
                                                      onChange={(event) => {
                                                        setFieldValue(`config.${index}.value`, event.target.value);
                                                        if (!isEmpty(customErrors)) {
                                                          // eslint-disable-next-line fp/no-delete
                                                          delete customErrors?.config[index]?.value;
                                                        }
                                                      }}
                                                    />
                                                  </FormItem>
                                                </Col>
                                              </Row>
                                            </>
                                          );
                                        }}
                                      </FastField>
                                    </Card>
                                  );
                                }
                                return null;
                              }) }
                              <Row align='center'>
                                <Button
                                  type='primary'
                                  icon={<RiAddFill />}
                                  onClick={() => arrayHelpers.push({ type: 'create', path: '', value: '', priority: '1' })}
                                >
                                  Create
                                </Button>
                              </Row>
                            </>
                          );
                        }}
                      </FieldArray>
                    </Col>
                    <Col xs={24} md={8} xl={8}>
                      <FieldArray
                        name="config"
                        value={values.config}
                      >
                        {(arrayHelpers) => {
                          let updateCardIndex = 0;

                          const updateItemsIndexes = [];

                          return (
                            <>
                              {!isEmpty(configValues) && configValues.map((mixin, index) => {
                                if (mixin.type === 'update') {
                                  updateCardIndex++;
                                  updateItemsIndexes.push(index);

                                  return (
                                    <Card
                                      /* eslint-disable-next-line react/no-array-index-key */
                                      key={index}
                                      title={`Update # ${updateCardIndex}`}
                                      extra={[
                                        <div key='create_up'>
                                          {updateCardIndex !== 1 && (
                                            <Button
                                              style={{ marginRight: '6px' }}
                                              size='small'
                                              icon={<RiArrowUpLine />}
                                              onClick={() => {
                                                const currIndex = updateItemsIndexes.indexOf(index);

                                                arrayHelpers.swap(index, updateItemsIndexes[currIndex - 1]);
                                              }}
                                            />
                                          )}
                                        </div>,
                                        <div key='create_down'>
                                          {updateCardIndex !== getNumberOfCards('update') && (
                                            <Button
                                              style={{ marginRight: '6px' }}
                                              size='small'
                                              icon={<RiArrowDownLine />}
                                              onClick={() => {
                                                const currIndex = updateItemsIndexes.indexOf(index);

                                                arrayHelpers.swap(index, updateItemsIndexes[currIndex + 1]);
                                              }}
                                            />
                                          )}
                                        </div>,
                                        <Popconfirm
                                          key='create_delete_item'
                                          title="Sure to delete this mixin?"
                                          onConfirm={() => arrayHelpers.remove(index)}
                                        >
                                          <Button
                                            size='small'
                                            danger
                                            icon={<DeleteOutlined />}
                                          />
                                        </Popconfirm>,
                                      ]}
                                    >
                                      {/* eslint-disable-next-line react/no-array-index-key */}
                                      <FastField name={`config.${index}`} key={index}>
                                        {({ field: {}, form: { setFieldValue } }) => {
                                          return (
                                            <>
                                              <Row gutter={16}>
                                                <Col span={24}>
                                                  <FormItem
                                                    className='ant-form-item-col'
                                                    name={`config.${index}.priority`}
                                                    label="Priority:"
                                                    required
                                                  >
                                                    <Input
                                                      fast
                                                      name={`config.${index}.priority`}
                                                      placeholder='Specify priority'
                                                      validate={(value) => {
                                                        let error;

                                                        if (isEmpty(value)) {
                                                          error = 'Required field';
                                                        }
                                                        return error;
                                                      }}
                                                      onChange={(event) => {
                                                        setFieldValue(`config.${index}.priority`, event.target.value.replace(/\D/g, ''));
                                                      }}
                                                    />
                                                  </FormItem>
                                                </Col>
                                                <Col span={24}>
                                                  <FormItem
                                                    className='ant-form-item-col'
                                                    name={`config.${index}.path`}
                                                    label="Path:"
                                                    required
                                                  >
                                                    <Input
                                                      fast
                                                      addonBefore="#"
                                                      name={`config.${index}.path`}
                                                      validate={(value) => {
                                                        const preparedPath = !isEmpty(value) && value.includes('/') ? value.split('/').join('.') : value;
                                                        const valueType = checkPathType(configData, preparedPath);

                                                        let error;

                                                        if (valueType === undefined) {
                                                          error = 'Path doesnt exist in current config';
                                                        }
                                                        return error;
                                                      }}
                                                      placeholder='Specify path'
                                                      onChange={(event) => {
                                                        setFieldValue(`config.${index}.path`, event.target.value.replace(/\s/g, ''));
                                                        if (!isEmpty(customErrors)) {
                                                          // eslint-disable-next-line fp/no-delete
                                                          delete customErrors?.config[index]?.path;
                                                        }
                                                      }}
                                                    />
                                                  </FormItem>
                                                </Col>
                                              </Row>
                                              <Row gutter={16}>
                                                <Col span={24}>
                                                  <FormItem
                                                    className='ant-form-item-col'
                                                    name={`config.${index}.value`}
                                                    label="Value:"
                                                    required
                                                  >
                                                    <Input.TextArea
                                                      fast
                                                      name={`config.${index}.value`}
                                                      placeholder='Specify value'
                                                      validate={(value) => {
                                                        let error;

                                                        if (isEmpty(value)) {
                                                          error = 'Required field';
                                                        }
                                                        return error;
                                                      }}
                                                      onChange={(event) => {
                                                        setFieldValue(`config.${index}.value`, event.target.value);
                                                        if (!isEmpty(customErrors)) {
                                                          // eslint-disable-next-line fp/no-delete
                                                          delete customErrors?.config[index]?.value;
                                                        }
                                                      }}
                                                    />
                                                  </FormItem>
                                                </Col>
                                              </Row>
                                            </>
                                          );
                                        }}
                                      </FastField>
                                    </Card>
                                  );
                                }
                                return null;
                              }) }
                              <Row align='center'>
                                <Button
                                  type='primary'
                                  icon={<RiAddFill />}
                                  onClick={() => arrayHelpers.push({ type: 'update', path: '', value: '', priority: '1' })}
                                >
                                  Update
                                </Button>
                              </Row>
                            </>
                          );
                        }}
                      </FieldArray>
                    </Col>
                    <Col xs={24} md={8} xl={8}>
                      <FieldArray
                        name="config"
                        value={values.config}
                      >
                        {(arrayHelpers) => {
                          let deleteCardIndex = 0;

                          const deleteItemsIndexes = [];

                          return (
                            <>
                              {!isEmpty(configValues) && configValues.map((mixin, index) => {
                                if (mixin.type === 'delete') {
                                  deleteCardIndex++;
                                  deleteItemsIndexes.push(index);

                                  return (
                                    <Card
                                      /* eslint-disable-next-line react/no-array-index-key */
                                      key={index}
                                      title={`Delete # ${deleteCardIndex}`}
                                      extra={[
                                        <div key='create_up'>
                                          {deleteCardIndex !== 1 && (
                                            <Button
                                              style={{ marginRight: '6px' }}
                                              size='small'
                                              icon={<RiArrowUpLine />}
                                              onClick={() => {
                                                const currIndex = deleteItemsIndexes.indexOf(index);

                                                arrayHelpers.swap(index, deleteItemsIndexes[currIndex - 1]);
                                              }}
                                            />
                                          )}
                                        </div>,
                                        <div key='create_down'>
                                          {deleteCardIndex !== getNumberOfCards('delete') && (
                                            <Button
                                              style={{ marginRight: '6px' }}
                                              size='small'
                                              icon={<RiArrowDownLine />}
                                              onClick={() => {
                                                const currIndex = deleteItemsIndexes.indexOf(index);

                                                arrayHelpers.swap(index, deleteItemsIndexes[currIndex + 1]);
                                              }}
                                            />
                                          )}
                                        </div>,
                                        <Popconfirm
                                          key='create_delete_item'
                                          title="Sure to delete this mixin?"
                                          onConfirm={() => arrayHelpers.remove(index)}
                                        >
                                          <Button
                                            size='small'
                                            danger
                                            icon={<DeleteOutlined />}
                                          />
                                        </Popconfirm>,
                                      ]}
                                    >
                                      {/* eslint-disable-next-line react/no-array-index-key */}
                                      <FastField name={`config.${index}`} key={index}>
                                        {({ field: {}, form: { setFieldValue } }) => {
                                          return (
                                            <>
                                              <Row gutter={16}>
                                                <Col span={24}>
                                                  <FormItem
                                                    className='ant-form-item-col'
                                                    name={`config.${index}.priority`}
                                                    label="Priority:"
                                                    required
                                                  >
                                                    <Input
                                                      fast
                                                      name={`config.${index}.priority`}
                                                      placeholder='Specify priority'
                                                      validate={(value) => {
                                                        let error;

                                                        if (isEmpty(value)) {
                                                          error = 'Required field';
                                                        }
                                                        return error;
                                                      }}
                                                      onChange={(event) => {
                                                        setFieldValue(`config.${index}.priority`, event.target.value.replace(/\D/g, ''));
                                                      }}
                                                    />
                                                  </FormItem>
                                                </Col>
                                                <Col span={24}>
                                                  <FormItem
                                                    className='ant-form-item-col'
                                                    name={`config.${index}.path`}
                                                    label="Path:"
                                                    required
                                                  >
                                                    <Input
                                                      fast
                                                      addonBefore="#"
                                                      name={`config.${index}.path`}
                                                      validate={(value) => {
                                                        const preparedPath = !isEmpty(value) && value.includes('/') ? value.split('/').join('.') : value;
                                                        const valueType = checkPathType(configData, preparedPath);

                                                        let error;

                                                        if (isEmpty(value)) {
                                                          error = 'Required field';
                                                        }

                                                        if (valueType === undefined) {
                                                          error = 'Path doesnt exist in current config';
                                                        }
                                                        return error;
                                                      }}
                                                      placeholder='Specify path'
                                                      onChange={(event) => {
                                                        setFieldValue(`config.${index}.path`, event.target.value.replace(/\s/g, ''));
                                                        if (!isEmpty(customErrors)) {
                                                          // eslint-disable-next-line fp/no-delete
                                                          delete customErrors?.config[index]?.path;
                                                        }
                                                      }}
                                                    />
                                                  </FormItem>
                                                </Col>
                                              </Row>
                                            </>
                                          );
                                        }}
                                      </FastField>
                                    </Card>
                                  );
                                }
                                return null;
                              }) }
                              <Row align='center'>
                                <Button
                                  type='primary'
                                  icon={<RiAddFill />}
                                  onClick={() => arrayHelpers.push({ type: 'delete', path: '', value: '', priority: '1' })}
                                >
                                  Delete
                                </Button>
                              </Row>
                            </>
                          );
                        }}
                      </FieldArray>
                    </Col>
                  </Row>
                </Col>

                <FormCreateEditAbTestButtonsRow>
                  <FormCreateEditAbTestButtonWr>
                    <Button onClick={onCancel}>
                      Cancel
                    </Button>
                  </FormCreateEditAbTestButtonWr>
                  <FormCreateEditAbTestButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      loading={isSubmitting}
                      disabled={!dirty || !isValid}
                    >
                      {isEditForm ? 'Update' : 'Create'}
                    </SubmitButton>
                  </FormCreateEditAbTestButtonWr>
                </FormCreateEditAbTestButtonsRow>
              </Row>
            </Form>
          </Spin>
        );
      }}
    </Formik>
  );
};

FormCreateEditConfigMixin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isMixinTargeting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  setServerErrors: PropTypes.func,
  initialValues: PropTypes.shape({
    config: PropTypes.array,
  }).isRequired,
  formErrors: PropTypes.object,
  serverErrors: PropTypes.object,
  configData: PropTypes.object.isRequired,
  values: PropTypes.shape({
    status: PropTypes.string,
    config: PropTypes.array,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  isEditForm: PropTypes.bool,
};

FormCreateEditConfigMixin.defaultProps = {
  formErrors: {},
  serverErrors: {},
  setServerErrors: () => {},
  isEditForm: false,
  isMixinTargeting: false,
  values: {
    status: null,
    config: [],
  },
};

export default FormCreateEditConfigMixin;
