import { Card, Descriptions, Tag } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { LOGS_ACTION_COLOR } from '../../pages/PageLogs/PageLogs.const';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../../const/system';



const LogDetailsCard = ({ data, isLoading }) => {
  return (
    <div className='card-table'>
      <Card
        title='Log details'
        loading={isLoading}
        style={{ marginBottom: '10px' }}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Space id:">
            {data?.space_id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Project id:">
            {data?.project_id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Log id:">
            {data?.id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Category:">
            {data?.category || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Model id:">
            {data?.model_id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Model:">
            {data?.model || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Action:">
            {data?.action ?
              <Tag color={LOGS_ACTION_COLOR(data.action)}>{capitalize(data?.action) || EMPTY_VALUE_PLACEHOLDER}</Tag> : EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Type:">
            {data?.type ? capitalize(data.type) : EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="User id:">
            {data?.user?.id || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="User name:">
            {data?.user?.name || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Created at:">
            <Tag color="blue">
              {renderDateField(data?.created_at)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Updated at:">
            <Tag color="geekblue">
              {renderDateField(data?.updated_at)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};

LogDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default LogDetailsCard;
