import isEmpty from 'lodash/isEmpty';
import addUrlParam from '../../../lib/addUrlParam';



export default class StructuresService {
  constructor (Api) {
    this.api = Api;
  }

  getStructuresList (params) {
    return this.api.get(`configs/structures?${addUrlParam(!isEmpty(params) ? params : { limit: 0 })}`);
  }

  createStructure (values) {
    return this.api.post('configs/structures', values);
  }

  deleteStructure (structureId) {
    return this.api.delete(`configs/structures/${structureId}`);
  }
}
