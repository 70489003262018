import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import UserProfilesService from '../services/userProfilesService';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';



const initialState = {
  usersList: [],
  errors: [],
  usersPagination: {},
  loadingUsersList: false,
};

const userProfilesService = new UserProfilesService(Api);

export const getUserProfilesListData = createAsyncThunk(
  'users/getUserProfilesList',
  async (values) => {
    const response = await userProfilesService.getUserProfilesList(values);

    if (!isEmpty(values)) {
      setUrlParams(values);
    }

    return response;
  },
);

export const userProfilesSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsersListData: (state) => {
      state.usersList = [];
      state.usersPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfilesListData.pending, (state) => {
        state.loadingUsersList = true;
      })
      .addCase(getUserProfilesListData.fulfilled, (state, action) => {
        state.loadingUsersList = false;
        state.usersList = action.payload.data;
        state.usersPagination = action.payload.meta.custom.pagination;
      })
      .addCase(getUserProfilesListData.rejected, (state, action) => {
        state.loadingUsersList = false;
        state.errors = action.payload.data;
      });
  },
});

// actions
export const { resetUsersListData } = userProfilesSlice.actions;

// selectors
export const selectUserProfilesLoading = (state) => state.userProfiles.loadingUsersList;
export const selectUserProfilesList = (state) => state.userProfiles.usersList;
export const selectUserProfilesPagination = (state) => state.userProfiles.usersPagination;
export const selectErrors = (state) => state.userProfiles.usersList;

// reducer
export default userProfilesSlice.reducer;
