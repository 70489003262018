import React, { useState } from 'react';
import { Descriptions, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import './styles.css';
import isEmpty from 'lodash/isEmpty';
import CollapsibleCardWr from '../../../../../components/CollapsibleCardWr';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';



const { Paragraph } = Typography;

const CopyableParagraph = (value) => {
  const val = value?.hasOwnProperty('value') ? value?.value : value;

  return <Paragraph copyable={!isEmpty(val)}>{!isEmpty(val) ? val : EMPTY_VALUE_PLACEHOLDER}</Paragraph>;
};

const TicketDetailsHeader = ({ ticket, contactUsEventCountry, isLoading }) => {
  const [ ellipsis, setEllipsis ] = useState(true);
  const hasTicketData = !isEmpty(ticket);

  return (
    <div className='card-table'>
      <CollapsibleCardWr
        title='Details'
        isLoading={isLoading}
        keyValue='ticket-details-card'
        maxDeviceWidth={992}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Description:">
            <Paragraph
              className={hasTicketData ? 'paragraph' : ''}
              onClick={() => {
                setEllipsis((prevState) => !prevState);
              }}
              ellipsis={ellipsis ? { rows: 2, symbol: '...' } : false}
            >
              {ticket?.description ?? EMPTY_VALUE_PLACEHOLDER}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Link:">
            {hasTicketData ? (
              <a
                href={ticket.link}
                target='_blank'
                rel="noreferrer"
              >
                {ticket.link}
              </a>
            ) : (
              EMPTY_VALUE_PLACEHOLDER
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Status:">{ticket?.status ?? EMPTY_VALUE_PLACEHOLDER}</Descriptions.Item>
          <Descriptions.Item label="Tags:">
            { hasTicketData ? ticket.tags && ticket.tags.map((tag, index) => {
              let color = 'purple';

              if (index % 3 === 0) {
                color = 'cyan';
              } else if (index % 3 === 2) {
                color = 'blue';
              } else if (index % 3 === 1) {
                color = 'geekblue';
              }

              return <Tag color={color} key={tag[index]}>{tag}</Tag>;
            }) : EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
        </Descriptions>
      </CollapsibleCardWr>

      <CollapsibleCardWr
        title='Parameters'
        isLoading={isLoading}
        keyValue='ticket-parameters-card'
        maxDeviceWidth={992}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="App name:">
            <CopyableParagraph value={ticket?.parameters?.appname} />
          </Descriptions.Item>
          <Descriptions.Item label="Created at:">
            {ticket?.created_at ? ticket?.created_at.split('T')[0] : EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
          <Descriptions.Item label="Platform:">
            <CopyableParagraph value={ticket?.parameters?.platform} />
          </Descriptions.Item>
          <Descriptions.Item label="Version:">
            <CopyableParagraph value={ticket?.parameters?.version} />
          </Descriptions.Item>
          <Descriptions.Item label="Country:">
            <Paragraph copyable={contactUsEventCountry !== EMPTY_VALUE_PLACEHOLDER}>{contactUsEventCountry}</Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Id:">
            <CopyableParagraph value={ticket?.parameters?.id} />
          </Descriptions.Item>
          <Descriptions.Item label="Router id:">
            <CopyableParagraph value={ticket?.parameters?.aId} />
          </Descriptions.Item>
          <Descriptions.Item label="Device id:">
            <CopyableParagraph value={ticket?.parameters?.dId} />
          </Descriptions.Item>
          <Descriptions.Item label="pId:">
            <CopyableParagraph value={ticket?.parameters?.pId} />
          </Descriptions.Item>
          <Descriptions.Item label="uId:">
            <CopyableParagraph value={ticket?.parameters?.uId} />
          </Descriptions.Item>
        </Descriptions>
      </CollapsibleCardWr>
    </div>
  );
};

TicketDetailsHeader.propTypes = {
  ticket: PropTypes.shape({
    object: PropTypes.string,
    ticket_id: PropTypes.number,
    status: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    created_at: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string,
    parameters: PropTypes.object,
  }),
  contactUsEventCountry: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

TicketDetailsHeader.defaultProps = {
  ticket: {},
};

export default TicketDetailsHeader;
