import isEmpty from 'lodash/isEmpty';
import addUrlParam from '../../../lib/addUrlParam';



export default class MixinsVisualizeService {
  constructor (Api) {
    this.api = Api;
  }

  getMixinsList ({ configId, params }) {
    return this.api.get(`visualize/mixin/config/${configId}/mixins?${addUrlParam(!isEmpty(params) ? params : { limit: -1 })}`);
  }

  getConfigurationWithMixins ({ configId, values }) {
    return this.api.post(`visualize/mixin/config/${configId}`, values);
  }

  getConfigurationRowDetails ({ configId, values }) {
    return this.api.post(`visualize/mixin/config/${configId}/mixins/row`, values);
  }
}
