import { setRequestParams } from '../../../lib/setRequestParams';



export default class UserProfileDeviceDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  getUserProfileDeviceDetails (deviceId) {
    return this.api.get(`profiles/devices/${deviceId}`);
  }

  getUserProfileDeviceAbTests ({ deviceId, params }) {
    return this.api.get(`profiles/devices/${deviceId}/tests${setRequestParams(params)}`);
  }

  getUserProfileDevicePurchases ({ deviceId, params }) {
    return this.api.get(`profiles/devices/${deviceId}/purchases${setRequestParams(params)}`);
  }

  getUserProfileDevicePictures ({ deviceId, params }) {
    return this.api.get(`profiles/devices/${deviceId}/pictures${setRequestParams(params)}`);
  }
}
