import url from './url';



export default url(({
  configurationId = ':configurationId',
  configurationName = ':configurationName',
  mixinId = ':mixinId',
  targetingId = ':targetingId',
}) => `/configurations/${configurationId}/${configurationName}/mixin/${mixinId}/targeting/${targetingId}/edit`);
