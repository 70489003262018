export const checkGroupsUniqueNames = (groups) => {
  const filteredArr = groups.filter((group) => group.name);
  const uniqueArr = [ ...new Set(filteredArr.map((group) => group.name)) ];

  return filteredArr.length === uniqueArr.length;
};

export const getTotalPercentage = (arrValues) => (
  arrValues.groups.reduce((acc, curr) => acc + Number(curr.percent), 0)
) === 100;


export const DEVICE_TYPE_OPTIONS = [
  { value: 'phone', label: 'Phone' },
  { value: 'tablet', label: 'Tablet' },
];

export const AB_TEST_AFFILIATION_OPTIONS = [
  { value: 'content', label: 'Content' },
  { value: 'ad', label: 'Ad' },
  { value: 'ad_app', label: 'Ad App' },
  { value: 'port', label: 'Port' },
  { value: 'ab', label: 'Ab' },
  { value: 'config', label: 'Config' },
];

export const PLATFORM_OPTIONS = [
  { value: 'ios', label: 'iOS' },
  { value: 'amazon', label: 'Amazon' },
  { value: 'android', label: 'Google Play' },
];

export const USERS_TYPES_OPTIONS = [
  { value: 'old', label: 'Old' },
  { value: 'new', label: 'New' },
];

export const LAW_TYPES_OPTIONS = [
  { value: 'gdpr', label: 'GDPR' },
  { value: 'ccpa', label: 'CCPA' },
];

export const LAT_TYPES_OPTIONS = [
  { value: null, label: 'Any' },
  { value: true, label: 'Enabled' },
  { value: false, label: 'Disabled' },
];

export const LAT_TYPES_TRANSLATIONS = (type) => {
  switch (type) {
    case true: {
      return 'Enabled';
    }
    case false: {
      return 'Disabled';
    }
    default: {
      return 'Any';
    }
  }
};

export const AB_TESTS_LANGUAGES_OPTIONS = [
  { key: 'English', label: 'English' },
  { key: 'Russian', label: 'Russian' },
  { key: 'German', label: 'German' },
  { key: 'French', label: 'French' },
  { key: 'Italian', label: 'Italian' },
  { key: 'Japanese', label: 'Japanese' },
  { key: 'Korean', label: 'Korean' },
  { key: 'Portuguese', label: 'Portuguese' },
  { key: 'Spanish', label: 'Spanish' },
  { key: 'Swedish', label: 'Swedish' },
  { key: 'Thai', label: 'Thai' },
  { key: 'Turkish', label: 'Turkish' },
  { key: 'Hindi', label: 'Hindi' },
  { key: 'Polish', label: 'Polish' },
  { key: 'Indonesian', label: 'Indonesian' },
  { key: 'Chinesetraditional', label: 'Chinese Traditional' },
  { key: 'Chinesesimplified', label: 'Chinese Simplified' },
];

export const DEVICE_MANUFACTURES_OPTIONS = [
  { value: 'acer', label: 'Acer' },
  { value: 'alcatel', label: 'Alcatel' },
  { value: 'alldocube', label: 'Alldocube' },
  { value: 'amazon', label: 'Amazon' },
  { value: 'apple', label: 'Apple' },
  { value: 'archos', label: 'Archos' },
  { value: 'arian', label: 'Arian' },
  { value: 'asus', label: 'ASUS' },
  { value: 'blackview', label: 'Blackview' },
  { value: 'bqmobile', label: 'BQ-Mobile' },
  { value: 'chuwi', label: 'Chuwi' },
  { value: 'crownmicro', label: 'CrownMicro' },
  { value: 'cube', label: 'Cube' },
  { value: 'dell', label: 'Dell' },
  { value: 'dexp', label: 'DEXP' },
  { value: 'digma', label: 'Digma' },
  { value: 'durabook', label: 'Durabook' },
  { value: 'estar', label: 'eSTAR' },
  { value: 'flycat', label: 'Flycat' },
  { value: 'geofox', label: 'GEOFOX' },
  { value: 'ginzzu', label: 'Ginzzu' },
  { value: 'haier', label: 'Haier' },
  { value: 'honor', label: 'HONOR' },
  { value: 'hp', label: 'HP' },
  { value: 'huawei', label: 'Huawei' },
  { value: 'inoi', label: 'Inoi' },
  { value: 'irbis', label: 'IRBIS' },
  { value: 'keener', label: 'Keener' },
  { value: 'krez', label: 'Krez' },
  { value: 'lenovo', label: 'Lenovo' },
  { value: 'lexand', label: 'Lexand' },
  { value: 'mocrosoft', label: 'Microsoft' },
  { value: 'mio', label: 'Mio' },
  { value: 'modecom', label: 'MODECOM' },
  { value: 'myphone', label: 'MyPhone' },
  { value: 'nokia', label: 'Nokia' },
  { value: 'oscal', label: 'Oscal' },
  { value: 'oukitel', label: 'Oukitel' },
  { value: 'prestigio', label: 'Prestigio' },
  { value: 'ritmix', label: 'Ritmix' },
  { value: 'roverpad', label: 'RoverPad' },
  { value: 'samsung', label: 'Samsung' },
  { value: 'tcl', label: 'TCL' },
  { value: 'texet', label: 'TeXet' },
  { value: 'turbopad', label: 'Turbopad' },
  { value: 'ulefone', label: 'Ulefone' },
  { value: 'umidigi', label: 'Umidigi' },
  { value: 'vertex', label: 'Vertex' },
  { value: 'wexler', label: 'Wexler' },
  { value: 'xiaomi', label: 'Xiaomi' },
  { value: 'agm', label: 'AGM' },
  { value: 'ark', label: 'Ark' },
  { value: 'atomic', label: 'Atomic' },
  { value: 'benq', label: 'BenQ' },
  { value: 'blackberry', label: 'BlackBerry' },
  { value: 'bq', label: 'BQ' },
  { value: 'caterpillar', label: 'Caterpillar' },
  { value: 'conquest', label: 'Conquest' },
  { value: 'cubot', label: 'Cubot' },
  { value: 'doogee', label: 'Doogee' },
  { value: 'elephone', label: 'Elephone' },
  { value: 'fly', label: 'Fly' },
  { value: 'gigabyte', label: 'Gigabyte' },
  { value: 'google', label: 'Google' },
  { value: 'htc', label: 'HTC' },
  { value: 'jiayu', label: 'Jiayu' },
  { value: 'jinga', label: 'Jinga' },
  { value: 'keneksi', label: 'Keneksi' },
  { value: 'leagoo', label: 'Leagoo' },
  { value: 'lg', label: 'LG' },
  { value: 'mann', label: 'Mann' },
  { value: 'maxcom', label: 'Maxcom' },
  { value: 'meizu', label: 'MEIZU' },
  { value: 'motorola', label: 'Motorola' },
  { value: 'neffos', label: 'Neffos' },
  { value: 'noa', label: 'Noa' },
  { value: 'nubia', label: 'Nubia' },
  { value: 'olmio', label: 'Olmio' },
  { value: 'omlook', label: 'Omlook' },
  { value: 'oneplus', label: 'OnePlus' },
  { value: 'onext', label: 'ONEXT' },
  { value: 'oppo', label: 'Oppo' },
  { value: 'panasonic', label: 'Panasonic' },
  { value: 'philips', label: 'Philips' },
  { value: 'poco', label: 'POCO' },
  { value: 'qumo', label: 'QUMO' },
  { value: 'realme', label: 'Realme' },
  { value: 'ritzviva', label: 'RitzViva' },
  { value: 'roverphone', label: 'RoverPhone' },
  { value: 'senseit', label: 'Senseit' },
  { value: 'sony', label: 'Sony' },
  { value: 'sunwind', label: 'SunWind' },
  { value: 'tecno', label: 'Tecno' },
  { value: 'thl', label: 'ThL' },
  { value: 'vivo', label: 'Vivo' },
  { value: 'vsmart', label: 'Vsmart' },
  { value: 'wigor', label: 'Wigor' },
  { value: 'zoji', label: 'Zoji' },
  { value: 'zopo', label: 'Zopo' },
  { value: 'zte', label: 'ZTE' },
];
