import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';
import ProjectRequestsService from '../services/projectRequestsService';



const initialState = {
  requestsList: [],
  requestsPagination: {},
  errors: {},
  loading: false,
};

const projectRequestsService = new ProjectRequestsService(Api);

export const getRequestsListData = createAsyncThunk(
  'requests/getRequestsList',
  async ({ params }, { rejectWithValue }) => {
    try {
      const response = await projectRequestsService.getRequestsList({ params });

      if (!isEmpty(params)) {
        setUrlParams(params);
      }

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);


export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    resetRequestsListData: (state) => {
      state.requestsList = [];
      state.requestsPagination = {};
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequestsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRequestsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.requestsList = action.payload.data;
        state.requestsPagination = action.payload.meta.pagination;
      })
      .addCase(getRequestsListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
      });
  },
});

// actions
export const { resetRequestsListData } = requestsSlice.actions;

// selectors
export const selectRequestsLoading = (state) => state.requests.loading;
export const selectRequestsList = (state) => state.requests.requestsList;
export const selectRequestsPagination = (state) => state.requests.requestsPagination;
export const selectRequestsFilterErrors = (state) => state.requests.errors;


// reducer
export default requestsSlice.reducer;
