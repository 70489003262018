import React from 'react';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Col, Row } from 'antd';
import Yup from '../../../../vendor/yup';



const FormCreateEditSpaceButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditSpaceButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Min 3 symbol required')
    .max(100, 'Max 100 symbols required')
    .required('Name field is required'),
  key: Yup.string()
    .min(2, 'Min 2 symbol required')
    .max(10, 'Max 10 symbols required')
    .matches(/^[A-Z][A-Z-0-9]+$/, 'Value should start with Latin letter. Only uppercase Latin letters, dash and numbers are required')
    .required('URL key field is required'),
  config: Yup.string()
    .required('Config field is required')
    .test(
      'is-valid-json',
      'Please enter valid JSON schema',
      (value) => {
        try {
          JSON.parse(value);
          return true;
          // eslint-disable-next-line no-unused-vars
        } catch (error) {
          return false;
        }
      },
    ),
});

const FormCreateEditSpace = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
  onCancel,
  isEditSpace,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        const { config } = values;

        onSubmit({ ...values, config: JSON.parse(config) });
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, dirty } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Row gutter={[ 16, 16 ]}>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col'
                  name="name"
                  label="Name:"
                  required
                >
                  <Input
                    name='name'
                    placeholder='Specify name'
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col'
                  name="key"
                  label="URL key:"
                  required
                >
                  <Input
                    name='key'
                    disabled={isEditSpace}
                    placeholder='Specify space url key'
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col'
                  name="config"
                  label="Config:"
                  required
                >
                  <Input.TextArea
                    name='config'
                    rows={13}
                    placeholder='Specify config'
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]}>
              <FormCreateEditSpaceButtonsRow>
                <FormCreateEditSpaceButtonWr>
                  <Button onClick={onCancel}>
                    Cancel
                  </Button>
                </FormCreateEditSpaceButtonWr>
                <FormCreateEditSpaceButtonWr>
                  <SubmitButton
                    style={{ width: '100%' }}
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    {isEditSpace ? 'Update' : 'Create'}
                  </SubmitButton>
                </FormCreateEditSpaceButtonWr>
              </FormCreateEditSpaceButtonsRow>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditSpace.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isEditSpace: PropTypes.bool,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  values: PropTypes.shape({}),
};

FormCreateEditSpace.defaultProps = {
  formErrors: {},
  isEditSpace: false,
  isSubmitting: false,
};

export default FormCreateEditSpace;
