import { Breadcrumb, Spin } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import urlPageConfigurations from '../../../../urls/urlPageConfigurations';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';
import FormCreateEditConfigTargeting from '../../forms/FormCreateEditConfigTargeting';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import HasRights from '../../../../components/HasRights';
import { useApp } from '../../../../app/context/AppContext';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { Permissions } from '../../../../const/permissions';
import {
  createConfigTargeting,
  getAbTestsListData,
  getConfigTargetDetails,
  resetConfigTargetDetailsData,
  selectConfigTargetDetails,
  resetAbTestsListData,
  selectConfigTargetDetailsLoading,
  selectFormConfigTargetingAbTests,
  selectFormConfigTargetingAbTestsLoading,
  updateConfigTargeting,
  selectConfigTargetingFormErrors,
  selectConfigTargetingFormLoading,
} from '../../feature/configurationDetailsSlice';
import {
  getPreparedFormData,
  predefinedFormCreateInitialValues,
  preparedToRequestFormData,
} from './PageCreateEditConfigTargeting.const';



const PageCreateEditConfigTargeting = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { projectSettings, userName } = useApp();
  const navigate = useNavigate();
  const { pathname } = location;
  const { configurationId, configurationName, targetingId } = useParams();
  const isCreateConfigTargeting = pathname.endsWith('create');

  const isLoadingCreateUpdateTarget = useSelector(selectConfigTargetingFormLoading);
  const configTargetingFormErrors = useSelector(selectConfigTargetingFormErrors);
  const configTargetingDetails = useSelector(selectConfigTargetDetails);
  const isLoadingConfigTargeting = useSelector(selectConfigTargetDetailsLoading);

  const abTestsList = useSelector(selectFormConfigTargetingAbTests);
  const isLoadingAbTestsList = useSelector(selectFormConfigTargetingAbTestsLoading);


  useEffect(() => {
    dispatch(getAbTestsListData());
    if (!isEmpty(targetingId)) {
      dispatch(getConfigTargetDetails(configurationId));
    }

    return () => {
      dispatch(resetConfigTargetDetailsData());
      dispatch(resetAbTestsListData());
    };
  }, []);

  const handleRedirectToConfigDetails = (response) => {
    if (isFulfilledRequestStatus(response)) {
      navigate(urlPageConfigurationDetails({ configurationId }));
    }
  };

  const handleSubmitFormData = async (values) => {
    window.scrollTo(0, 0);
    const data = preparedToRequestFormData(values);

    if (isCreateConfigTargeting) {
      await dispatch(createConfigTargeting({ configurationId, data })).then((response) => {
        handleRedirectToConfigDetails(response);
      });
    } else {
      await dispatch(updateConfigTargeting({ configurationId, targetingId, data })).then((response) => {
        handleRedirectToConfigDetails(response);
      });
    }
  };

  const initialValues = !isCreateConfigTargeting ?
    getPreparedFormData(configTargetingDetails, configurationName) :
    predefinedFormCreateInitialValues({ configName: configurationName, authorName: userName });

  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIGURATIONS.TARGETING.CREATE, Permissions.CONFIGS.CONFIGURATIONS.TARGETING.UPDATE ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageConfigurations()}>
            Configurations list
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageConfigurationDetails({ configurationId })}>
            Configuration details
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {`${isCreateConfigTargeting ? 'Create' : 'Edit'} targeting`}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin
        spinning={isLoadingCreateUpdateTarget || isLoadingConfigTargeting || isLoadingAbTestsList}
        tip="Loading configuration targeting form..."
      >
        <FormCreateEditConfigTargeting
          initialValues={initialValues}
          isEditForm={!isCreateConfigTargeting}
          formErrors={configTargetingFormErrors}
          abTestsList={abTestsList}
          isLoadingAbTestsList={isLoadingAbTestsList}
          onCancel={() => {
            navigate(-1);
          }}
          isSubmitting={isLoadingCreateUpdateTarget || isLoadingConfigTargeting}
          onSubmit={handleSubmitFormData}
        />
      </Spin>
    </HasRights>
  );
};

export default PageCreateEditConfigTargeting;

