import React from 'react';
import { Badge, Button, Popconfirm, Row, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import { hasRights, Permissions } from '../../../../const/permissions';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';



export const CONFIGURATIONS_STATUS_COLOR = (status) => {
  switch (status) {
    case 'enabled': {
      return 'green';
    }
    case 'on checking': {
      return 'yellow';
    }
    default: {
      return 'red';
    }
  }
};

export const getConfigurationsListColumns = (handleDeleteConfiguration) => [
  {
    title: 'Name',
    align: 'center',
    render: (item) => item.name || EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (value) => {
      return (
        <Badge color={CONFIGURATIONS_STATUS_COLOR(value)} text={capitalize(value)} />
      );
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('updated_at'),
  },
  ...(hasRights([
    Permissions.CONFIGS.CONFIGURATIONS.DELETE,
    Permissions.CONFIGS.CONFIGURATIONS.UPDATE,
    Permissions.CONFIGS.CONFIGURATIONS.VIEW_DETAILS,
  ]) ?
    [ {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '20%',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.DELETE ]) && (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteConfiguration(item.id)}>
                <Tooltip placement="top" title='Delete configuration'>
                  <Button
                    size="small"
                    type='primary'
                    danger
                    className='btn-with-side-margin'
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.VIEW_DETAILS ]) && (
              <Tooltip placement="top" title='Configuration details'>
                <Button size="small" type='primary' className='btn-with-side-margin'>
                  <Link to={urlPageConfigurationDetails({ configurationId: item.id })}>
                    <EyeOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
