import { Breadcrumb, Card, Col, Empty, Layout, Row, Spin, Table, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import HasRights from '../../../../components/HasRights';
import { Permissions } from '../../../../const/permissions';
import urlPageConsentProvider from '../../../../urls/urlPageConsentProvider';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import ProjectUserDetailsCard from '../../components/ProjectUserDetailsCard/index';
import FormProjectUserFilter from '../../forms/FormProjectUserFilter';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import { GDPR_USER_HISTORY_TABLE_COLUMNS, GDPR_USER_REQUESTS_TABLE_COLUMNS } from './PageProjectUserDetails.const';
import { getQueryParams } from '../../../../lib/getQueryParams';
import {
  getProjectUserDetails,
  resetProjectUserDetailsData,
  selectFormErrors,
  selectProjectUserDetailsData,
  selectProjectUserDetailsLoading,
} from '../../feature/projectUserDetailsSlice';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Content } = Layout;
const { TabPane } = Tabs;


const PageProjectUserDetails = () => {
  const { projectSettings } = useApp();
  const dispatch = useDispatch();
  const queryParams = getQueryParams(window.location.search);
  const initialValues = queryParams ?? {};
  const [ activeTabKey, setActiveTabKey ] = useState(null);

  const userDetails = useSelector(selectProjectUserDetailsData);
  const isLoading = useSelector(selectProjectUserDetailsLoading);
  const searchErrors = useSelector(selectFormErrors);

  const handleSearchUser = (values) => {
    dispatch(getProjectUserDetails({ values })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        setActiveTabKey(response.payload[0].ids.coreId);
      }
    });
  };


  useEffect(() => {
    if (!isEmpty(initialValues)) {
      handleSearchUser(initialValues);
    }
    return () => {
      dispatch(resetProjectUserDetailsData());
    };
  }, []);


  return (
    <HasRights allowedPermissions={[ Permissions.REQUESTS.VIEW_DETAILS ]}>
      <Col>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={urlPageConsentProvider()}>
                Requests list
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>User details</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Col>

      <Spin spinning={isLoading} tip={LOADING_TIP_PLACEHOLDER}>
        <Content className='layout-content'>
          <CollapsibleFilterWr
            title='Search user'
            keyValue='gdpr-user-search'
            maxDeviceWidth={992}
          >
            <FormProjectUserFilter
              initialValues={initialValues}
              isSubmitting={isLoading}
              formErrors={searchErrors}
              onSubmit={handleSearchUser}
            />
          </CollapsibleFilterWr>
        </Content>

        <Card title='User details'>
          {!isEmpty(userDetails) ? (
            <Tabs
              type="card"
              defaultActiveKey={activeTabKey}
              activeKey={activeTabKey}
              onChange={(key) => setActiveTabKey(key)}
            >
              {userDetails.map((data) => (
                <TabPane tab={`Core ID: ${data.ids.coreId}`} key={data.ids.coreId}>
                  <ProjectUserDetailsCard data={data} />

                  <Row align="middle" justify="space-between" className='da-pt-42'>
                    <Col span={24}>
                      <h5>Requests</h5>
                    </Col>
                    <Content className='layout-content da-overflow-scroll padding-unset' id='userDevicesTopCmp'>
                      <Card className='card-has-table' style={{ marginBottom: '20px' }}>
                        <Table
                          sticky
                          pagination={false}
                          loading={isLoading}
                          rowKey="id"
                          columns={GDPR_USER_REQUESTS_TABLE_COLUMNS}
                          dataSource={data?.requests}
                          scroll={{ y: 250 }}
                        />
                      </Card>
                    </Content>
                  </Row>

                  <Row align="middle" justify="space-between" className='da-pt-24'>
                    <Col span={24}>
                      <h5>History</h5>
                    </Col>
                    <Content className='layout-content da-overflow-scroll padding-unset' id='userDevicesTopCmp'>
                      <Card className='card-has-table' style={{ margin: 'unset' }}>
                        <Table
                          sticky
                          pagination={false}
                          loading={isLoading}
                          rowKey="date"
                          columns={GDPR_USER_HISTORY_TABLE_COLUMNS}
                          dataSource={data?.history}
                          scroll={{ y: 250 }}
                        />
                      </Card>
                    </Content>
                  </Row>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <Empty description="No users found" />
          )}
        </Card>
      </Spin>
    </HasRights>
  );
};


export default PageProjectUserDetails;
