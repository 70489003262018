import moment from 'moment';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const mkPreparedEditFormValues = (obj) => {
  const app_versions = [];

  // eslint-disable-next-line fp/no-loops,no-restricted-syntax
  for (const key in obj) {
    if (key.match('app_versions')) {
      app_versions.push(obj[key]);

      // eslint-disable-next-line fp/no-delete
      delete obj[key];
    }
  }
  return Object.assign(obj, { app_versions });
};


export const preparedEvSequenceFormValues = (values) => {
  const preparedRequestValues =  {
    date_start: values.date_start ? moment(values.date_start).format('YYYYMMDD') : '',
    date_end: values.date_end ? moment(values.date_end).format('YYYYMMDD') : '',
    app_versions: values.app_versions ? values.app_versions : null,
    user_platform: values.user_platform ? values.user_platform : null,
    event_first: values.event_first ? values.event_first : null,
    event_second: values.event_second ? values.event_second : null,
    parameter_1_name: !isEmpty(values.parameter_1_name) ? values.parameter_1_name : null,
    parameter_2_name: values.parameter_2_name ? values.parameter_2_name : null,
    parameter_1_val: values.parameter_1_val ? values.parameter_1_val : null,
    parameter_2_val: values.parameter_2_val ? values.parameter_2_val : null,
    operator: values.operator ? values.operator : null,
    time_diff: values.time_diff ? values.time_diff : null,
    value_1: values.value_1 ? values.value_1 : null,
    value_2: values.value_2 ? values.value_2 : null,
  };

  return getNotEmptyObjectValues(preparedRequestValues);
};

export const EVENTS_SEQUENCE_TABLE_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'datetime',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.datetime) >= moment(_b.datetime) ? 1 : -1),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'User count',
    dataIndex: 'user_count',
    align: 'center',
    sorter: (_a, _b) => Number(_a.user_count) - Number(_b.user_count),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Version',
    dataIndex: 'version',
    align: 'center',
    sorter: (_a, _b) => Number(_a.version) - Number(_b.version),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Sequence count',
    dataIndex: 'sequence_count',
    align: 'center',
    sorter: (_a, _b) => Number(_a.sequence_count) - Number(_b.sequence_count),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];
