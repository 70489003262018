import React from 'react';
import { Button, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import { hasRights, Permissions } from '../../../../../const/permissions';
import urlPageRoleDetails from '../../../../../urls/urlPageRoleDetails';
import urlPageSettingsRoleDetails from '../../../../../urls/urlPageSettingsRoleDetails';
import { getDefaultTableSorting } from '../../../../../lib/getDefaultTableSorting';
import { APP_ENVIRONMENTS, EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';



export const ENVIRONMENT_TAG_COLOR = (value) => {
  switch (value) {
    case APP_ENVIRONMENTS.GLOBAL: {
      return 'purple';
    }
    case APP_ENVIRONMENTS.SPACE: {
      return 'geekblue';
    }
    case APP_ENVIRONMENTS.PROJECT: {
      return 'blue';
    }
    default: {
      return 'blue';
    }
  }
};

export const getRolesTableColumns = (handleDeleteRole, isGlobalScope, currentSpace) => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '10%',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Role',
    width: '20%',
    render: (item) => {
      return (
        <>
          {hasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW ]) && !isEmpty(item.name) ? (
            <Link
              to={isGlobalScope
                ? urlPageRoleDetails({ roleId: item.id })
                : urlPageSettingsRoleDetails({ roleId: item.id })}
            >
              {!item.space_id && !isGlobalScope
                ? `[DEFAULT] ${item.name}`
                : item.name}
            </Link>
          ) : (<div>{item.name || EMPTY_VALUE_PLACEHOLDER}</div>)}
        </>
      );
    },
  },
  {
    title: 'Environment',
    dataIndex: 'environment',
    align: 'center',
    width: '20%',
    render: (value) => {
      return (
        <div style={{ display: 'inline-grid' }}>
          {value ? value.map((item) => <Tag color={ENVIRONMENT_TAG_COLOR(item)} key={item}>{capitalize(item) || EMPTY_VALUE_PLACEHOLDER}</Tag>) : EMPTY_VALUE_PLACEHOLDER}
        </div>);
    },
  },
  {
    title: 'Level',
    dataIndex: 'level',
    align: 'center',
    width: '10%',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('level'),
    render: (value) => value ? <Tag color="volcano">{value}</Tag> : EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Display name',
    dataIndex: 'display_name',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
    width: '30%',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '30%',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  ...(hasRights([
    Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.EDIT_ROLE_DETAILS,
    Permissions.ADMINISTRATION.ROLES.ROLES_LIST.DELETE_ROLE,
  ]) ?
    [ {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '20%',
      render: (item) => {
        const spaceId = item?.space_id;
        const canEditRole = !!(isGlobalScope || (!isGlobalScope && spaceId));
        const canDeleteRole = !!(isGlobalScope || (!isGlobalScope && spaceId && spaceId === currentSpace?.id));

        return (
          <Row className="da-h-100" align="middle" justify="center">
            {hasRights([ Permissions.ADMINISTRATION.ROLES.ROLES_LIST.DELETE_ROLE ]) &&
            !item.is_secured && canDeleteRole && (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteRole(item.id)}>
                <Tooltip placement="top" title='Delete role'>
                  <Button
                    size="small"
                    type='primary'
                    danger
                    className='btn-with-side-margin'
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            {hasRights([
              Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.EDIT_ROLE_DETAILS,
              Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW,
            ], true) && canEditRole && (
              <Tooltip placement="top" title='Edit role'>
                <Button
                  size="small"
                  type='primary'
                  className='btn-with-side-margin warning-btn'
                >
                  <Link
                    to={isGlobalScope
                      ? urlPageRoleDetails({ roleId: item.id })
                      : urlPageSettingsRoleDetails({ roleId: item.id })}
                  >
                    <EditOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
