import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RoleDetailsService from '../services/roleDetailsService';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';



const initialState = {
  rolesDetails: {},
  allowedPermissions: [],
  rolePermissions: [],
  errors: [],
  loading: false,
  formCreateEditRoleLoading: false,
  formCreateEditRoleErrors: [],
  rolePermissionsLoading: false,
  rolePermissionsFormErrors: [],
};

const roleDetailsService = new RoleDetailsService(Api);

export const getRolesDetailsData = createAsyncThunk(
  'roleDetails/getRoleDetails',
  async (roleId) => {
    return roleDetailsService.getRoleDetails(roleId);
  },
);


export const createRole = createAsyncThunk(
  'roleDetails/createRole',
  async (values, { rejectWithValue }) => {
    try {
      const response = await roleDetailsService.createRole(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRoleDetailsData = createAsyncThunk(
  'roleDetails/updateRoleDetails',
  async ({ roleId, data }, { rejectWithValue }) => {
    try {
      return await roleDetailsService.updateRoleDetails({ roleId, data });
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getRolePermissions = createAsyncThunk(
  'roleDetails/getRolePermissions',
  async (roleId) => {
    const response = await roleDetailsService.getRolePermissions(roleId);

    return response.data;
  },
);

export const updateRolePermissions = createAsyncThunk(
  'roleDetails/updateRolePermissions',
  async ({ roleId, permissions }, { rejectWithValue }) => {
    try {
      const response = await roleDetailsService.updateRolePermissions({ roleId, permissions });

      return response.data.permissions.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const roleDetailsSlice = createSlice({
  name: 'roleDetails',
  initialState,
  reducers: {
    resetRoleDetailsData: (state) => {
      state.rolesDetails = {};
      state.allowedPermissions = [];
      state.rolePermissions = [];
    },
    resetCreateEditRoleErrors: (state) => {
      state.formCreateEditRoleErrors = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRolesDetailsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRolesDetailsData.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesDetails = action.payload.data;
        state.allowedPermissions = action.payload.meta.custom.permissions;
      })
      .addCase(getRolesDetailsData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
      });

    builder
      .addCase(createRole.pending, (state) => {
        state.formCreateEditRoleLoading = true;
        state.formCreateEditRoleErrors = [];
      })
      .addCase(createRole.fulfilled, (state) => {
        state.formCreateEditRoleLoading = false;
        mkNotification('success', 'Role is successfully created.');
      })
      .addCase(createRole.rejected, (state, action) => {
        state.formCreateEditRoleLoading = false;
        state.formCreateEditRoleErrors = action.payload.errors;
      });

    builder
      .addCase(updateRoleDetailsData.pending, (state) => {
        state.formCreateEditRoleLoading = true;
        state.formCreateEditRoleErrors = [];
      })
      .addCase(updateRoleDetailsData.fulfilled, (state, action) => {
        state.formCreateEditRoleLoading = false;
        state.rolesDetails = action.payload.data;
        state.allowedPermissions = action.payload.meta.custom.permissions;
        mkNotification('success', 'Role details is successfully updated.');
      })
      .addCase(updateRoleDetailsData.rejected, (state, action) => {
        state.formCreateEditRoleLoading = false;
        state.formCreateEditRoleErrors = action.payload.errors;
      });

    builder
      .addCase(getRolePermissions.pending, (state) => {
        state.rolePermissionsLoading = true;
      })
      .addCase(getRolePermissions.fulfilled, (state, action) => {
        state.rolePermissionsLoading = false;
        state.rolePermissions = action.payload;
      })
      .addCase(getRolePermissions.rejected, (state, action) => {
        state.rolePermissionsLoading = false;
        state.rolePermissionsFormErrors = action.payload.errors;
      });

    builder
      .addCase(updateRolePermissions.pending, (state) => {
        state.rolePermissionsLoading = true;
      })
      .addCase(updateRolePermissions.fulfilled, (state, action) => {
        state.rolePermissionsLoading = false;
        state.rolePermissions = action.payload;
        mkNotification('success', 'Role permissions is successfully updated.');
      })
      .addCase(updateRolePermissions.rejected, (state, action) => {
        state.rolePermissionsLoading = false;
        state.rolePermissionsFormErrors = action.payload.errors;
      });
  },
});

// actions
export const { resetRoleDetailsData, resetCreateEditRoleErrors } = roleDetailsSlice.actions;

// selectors
export const selectRoleDetailsLoading = (state) => state.roleDetails.loading;
export const selectRoleDetails = (state) => state.roleDetails.rolesDetails;
export const selectErrors = (state) => state.roleDetails.errors;
export const selectAllowedPermissions = (state) => state.roleDetails.allowedPermissions;

export const selectFormEditRoleLoading = (state) => state.roleDetails.formCreateEditRoleLoading;
export const selectFormEditRoleErrors = (state) => state.roleDetails.formCreateEditRoleErrors;

export const selectRolePermissions = (state) => state.roleDetails.rolePermissions;
export const selectUpdatePermissionsLoading = (state) => state.roleDetails.rolePermissionsLoading;
export const selectUpdatePermissionsErrors = (state) => state.roleDetails.rolePermissionsFormErrors;

// reducer
export default roleDetailsSlice.reducer;
