import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';
import HintsMonitoringService from '../services/hintsMonitorService';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  hintsData: [],
  loading: false,
};

const hintsMonitoringService = new HintsMonitoringService(Api);

export const getHintsMonitoringData = createAsyncThunk(
  'hintsMonitoringEvents/getMonitoring',
  async (values, { rejectWithValue }) => {
    try {
      const response = await hintsMonitoringService.getHintsMonitoring(values);

      if (values) {
        setUrlParams(values);
      }

      if (isEmpty(response)) {
        mkNotification('info', 'No hints monitoring data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const hintsMonitorSlice = createSlice({
  name: 'hintsMonitoringEvents',
  initialState,
  reducers: {
    resetHintsMonitoringData: (state) => {
      state.hintsData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHintsMonitoringData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHintsMonitoringData.fulfilled, (state, action) => {
        state.loading = false;
        state.hintsData = action.payload;
      })
      .addCase(getHintsMonitoringData.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetHintsMonitoringData } = hintsMonitorSlice.actions;

// selectors
export const selectHintsLoading = (state) => state.hintsMonitoring.loading;
export const selectHintsData = (state) => state.hintsMonitoring.hintsData;

// reducer
export default hintsMonitorSlice.reducer;
