import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Layout, Row, Spin, Table } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import urlPageCreateAbTest from '../../../../urls/urlPageCreateAbTest';
import urlPageAbTests from '../../../../urls/urlPageAbTests';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import FormAbTestsFilter from '../../forms/FormAbTestsFilter';
import { getAbTestTableColumns } from './PageAbTests.const';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { getPreparedFilterData, mkPreparedFilterData } from '../../../../lib/getPreparedFilterData';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { hasRights, Permissions } from '../../../../const/permissions';
import { useApp } from '../../../../app/context/AppContext';
import { useLocalStorage } from '../../../../app/hooks';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  getAbTestsListData,
  deleteAbTest,
  resetAbTestsData,
  selectAbTestsErrors,
  selectAbTestsPagination,
  selectAbTestsData,
  selectAbTestsLoading,
  selectAbTestsOptions,
} from '../../feature/abTestsSlice';



const { Content } = Layout;

const PageAbTests = () => {
  const { projectSettings } = useApp();
  const { pathname, search } = useLocation();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ filterValues, setFilterValues ] = useLocalStorage('abtests_filter', {});

  const dispatch = useDispatch();
  const data = useSelector(selectAbTestsData);
  const pagination = useSelector(selectAbTestsPagination);
  const abTestsOptions = useSelector(selectAbTestsOptions);
  const isLoading = useSelector(selectAbTestsLoading);
  const formErrors = useSelector(selectAbTestsErrors);

  const initialFilterValues = getObjectValuesByUrlParams(queryParams);


  const handleSetFilter = (values) => {
    window.scrollTo(0, 0);
    setFilterValues(values);
    dispatch(getAbTestsListData({ ...mkPreparedFilterData(values), ...mkQueryParams(1, 10, currentOrder, currentSorting) }));
  };


  useEffect(() => {
    if (pathname === urlPageAbTests()) {
      dispatch(getAbTestsListData({ ...mkPreparedFilterData(filterValues), ...mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting) }));
    }
    return () => {
      dispatch(resetAbTestsData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting ]);


  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  const handleDeleteAbTest = async (abTestId) => {
    await dispatch(deleteAbTest({ abTestId })).then(() => {
      dispatch(getAbTestsListData({ ...mkPreparedFilterData(filterValues), ...mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting) }));
    });
  };


  return (
    <>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Ab-tests list</Breadcrumb.Item>
          </Breadcrumb>
          {hasRights([ Permissions.AB_TESTS.CREATE ]) && (
            <Button
              style={{ margin: '10px 0' }}
              size='middle'
              type="primary"
            >
              <Link to={urlPageCreateAbTest()}>
                Create ab-test
              </Link>
            </Button>
          )}
        </Row>
      </Col>

      <Content className='layout-content'>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} md={24} lg={6}>
            <CollapsibleFilterWr
              title='Filter'
              keyValue='mixin-visualize-filter'
              maxDeviceWidth={992}
            >
              <Spin spinning={isLoading} tip="Loading options...">
                <FormAbTestsFilter
                  initialValues={getPreparedFilterData(initialFilterValues)}
                  isSubmitting={isLoading}
                  optionsData={abTestsOptions}
                  formErrors={formErrors}
                  onSubmit={handleSetFilter}
                />
              </Spin>
            </CollapsibleFilterWr>
          </Col>
          <Col xs={24} md={24} lg={18}>
            <Card>
              <Table
                sticky
                columns={getAbTestTableColumns(handleDeleteAbTest)}
                dataSource={data}
                rowKey="id"
                loading={isLoading}
                onChange={handleChangeTableParams}
                pagination={{
                  size: 'small',
                  current: pagination?.current_page,
                  pageSize: pagination?.per_page,
                  total: pagination?.total,
                  position: [ 'bottomCenter' ],
                  pageSizeOptions: [ '10', '25', '50' ],
                  showSizeChanger: true,
                }}
                scroll={{ y: 550 }}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageAbTests;
