import React from 'react';
import { Form, Input, DatePicker, SubmitButton, FormItem, Select } from 'formik-antd';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Col, Row, Tooltip } from 'antd';
import { RiInformationLine } from 'react-icons/ri';
import Yup from '../../../../vendor/yup';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';
import { mkSelectFilterOption } from '../../../../lib/mkSelectFilterOption';
import { COUNTRIES_LIST, SEMVER_REGEXP } from '../../../../const/system';
import {
  AB_TESTS_LANGUAGES_OPTIONS,
  DEVICE_TYPE_OPTIONS, LAT_TYPES_OPTIONS, LAW_TYPES_OPTIONS, PLATFORM_OPTIONS, USERS_TYPES_OPTIONS,
} from '../../../AbTests/forms/FormCreateEditAbTest/FormCreateEditAbTest.const';
import { SPECIAL_INSTALL_DATES_OPTIONS } from './FormMixinVisualizeFilter.const';
import { FEATURE_SHOW_TEST_STATUS } from '../../../../const/features';



const { Option } = Select;


const validationSchema  = Yup.lazy(() => {
  const semverField = Yup.string().nullable().matches(SEMVER_REGEXP, 'Value is doesn`t matches with semver regular expression').max(100, 'Max 100 symbols required');

  return (
    Yup.object().shape({
      config: Yup.string().required('Required to select configuration'),
      mixin: Yup.string().nullable(),
      country: Yup.string().nullable(),
      language: Yup.string().nullable(),
      device_type: Yup.string().nullable(),
      manufactures: Yup.string()
        .min(2, 'Min 2 symbols required')
        .max(100, 'Max 100 symbols required'),
      model: Yup.string()
        .min(2, 'Min 2 symbols required')
        .max(100, 'Max 100 symbols required')
        .matches(/^[a-zA-Z0-9 :_!-]*$/, 'Only latin letters, digits, spaces and :_!- is required'),
      performance: Yup.number().integer()
        .typeError('Specify integer value')
        .min(0, 'Should be equal or more than 0')
        .max(65000, 'Should be less or equal 65000')
        .nullable(),
      ram: Yup.number().integer().typeError('Specify integer value')
        .min(0, 'Should be equal or more than 0')
        .max(65000, 'Should be less or equal 65000')
        .nullable(),
      os_version: semverField,
      app_version: semverField,
      platform: Yup.string().nullable(),
      law: Yup.string().nullable(),
      lat: Yup.string().nullable(),
      install_date: Yup.string().nullable(),
      special_install_date: Yup.boolean().nullable(),
      user_type: Yup.string().nullable(),
      user_id: Yup.string()
        .min(12, 'Min 12 symbols required')
        .max(255, 'Max 255 symbols required'),
    })
  );
});

const FormMixinVisualizeFilter = ({ onSubmit, initialValues, isSubmitting, configOptions, formErrors }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(getNotEmptyObjectValues(values));
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setFieldValue, setValues } = props;
        const { config, ...restValues } = values;

        return (
          <Form layout="vertical">
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="config"
                label="Configuration:"
                required
              >
                <Select
                  showSearch
                  name="config"
                  value={values?.config !== null ? Number(values.config) : null}
                  placeholder="Select config"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {configOptions.map((option) => {
                    return (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="mixin"
                label="Mixin:"
              >
                <Input name='mixin' placeholder='Search by mixin name, text or code...' />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="mixin_status"
                label="Mixin status:"
              >
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  name="mixin_status"
                  value={values.mixin_status}
                  placeholder="Select mixin status"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  <Option value="enabled">Enabled</Option>
                  <Option value="paused">Paused</Option>
                </Select>
              </FormItem>
            </Col>

            {FEATURE_SHOW_TEST_STATUS && (
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="mixin_publish_status"
                  label="Test status:"
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    name="mixin_publish_status"
                    value={values.mixin_publish_status}
                    placeholder="Select test status"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    <Option value="publish">Published</Option>
                    <Option value="approved">Approved</Option>
                  </Select>
                </FormItem>
              </Col>
            )}

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="country"
                label="Country:"
              >
                <Select
                  showSearch
                  allowClear
                  name="country"
                  placeholder="Select country"
                  value={values.country}
                  optionFilterProp="children"
                  filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {COUNTRIES_LIST.map((option) => {
                    return (
                      <Option key={option.code} value={option.code}>
                        {`${option.code} - ${option.name}`}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='language'
                label="Language:"
              >
                <Select
                  allowClear
                  showSearch
                  name='language'
                  placeholder="Select language"
                  value={values.language}
                  optionFilterProp="children"
                  filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {AB_TESTS_LANGUAGES_OPTIONS.map((option) => {
                    return (
                      <Option key={option.key} value={option.key}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='device_type'
                label="Device type:"
              >
                <Select
                  allowClear
                  showSearch
                  name='device_type'
                  placeholder="Select device type"
                  value={values.device_type}
                  optionFilterProp="children"
                  filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {DEVICE_TYPE_OPTIONS.map((option) => {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='manufactures'
                label="Device manufacturer:"
              >
                <Input
                  name="manufactures"
                  placeholder='Specify device manufacturer'
                />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="model"
                label="Device model:"
              >
                <Input name='model' placeholder='Specify device model' />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="performance"
                label="Device performance:"
              >
                <Input
                  type='number'
                  name='performance'
                  placeholder='Specify device performance'
                />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="ram"
                label="Ram:"
              >
                <Input
                  type="number"
                  name='ram'
                  placeholder='Specify ram'
                />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="os_version"
                label="OS version:"
              >
                <Input name='os_version' placeholder='Specify OS version' />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="app_version"
                label="App version"
              >
                <Input name='app_version' placeholder='Specify app version' />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="platform"
                label="Platform:"
              >
                <Select
                  allowClear
                  showSearch
                  name='platform'
                  placeholder="Select platform"
                  value={values.platform}
                  optionFilterProp="children"
                  filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {PLATFORM_OPTIONS.map((option) => {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='law'
                label="Law:"
              >
                <Select
                  allowClear
                  showSearch
                  name='law'
                  placeholder="Specify law type"
                  value={values.law}
                  optionFilterProp="children"
                  filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {LAW_TYPES_OPTIONS.map((option) => {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='lat'
                label="Lat:"
              >
                <Select
                  name='lat'
                  placeholder="Select lat"
                  value={values.lat}
                  optionFilterProp="children"
                  filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {LAT_TYPES_OPTIONS.map((option) => {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='install_date'
                label={
                  <div className='content-right'>
                    Install date:
                    <Tooltip placement="top" title="Time zone - UTC +0">
                      <RiInformationLine size={18} />
                    </Tooltip>
                  </div>
                }
              >
                <DatePicker
                  showTime
                  format='YYYY-MM-DD HH:mm:ss'
                  placeholder='Select date'
                  name='install_date'
                  disabledDate={(date) => date.isSameOrAfter(moment(new Date()).utc(false))}
                  onChange={(value) => {
                    if (!isEmpty(value)) {
                      setFieldValue('install_date', moment(value).format('YYYY-MM-DD HH:mm:ss'));
                    }
                  }}
                />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="special_install_date"
                label="Special install date:"
              >
                <Select
                  showSearch
                  allowClear
                  name="special_install_date"
                  value={values.special_install_date}
                  placeholder="Select special install date"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {Object.keys(SPECIAL_INSTALL_DATES_OPTIONS).map((key) => (
                    <Select.Option key={key} value={SPECIAL_INSTALL_DATES_OPTIONS[key]}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name='user_type'
                label="User type:"
              >
                <Select
                  allowClear
                  showSearch
                  name='user_type'
                  placeholder="Select users type"
                  value={values.user_type}
                  optionFilterProp="children"
                  filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {USERS_TYPES_OPTIONS.map((option) => {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="user_id"
                label="User Id"
              >
                <Input name='user_id' placeholder='Specify user Id' />
              </FormItem>
            </Col>

            <Row style={{ justifyContent: 'center', padding: '10px 0' }} gutter={[ 16, 16 ]}>
              <Col>
                <Button
                  type='secondary'
                  disabled={isEmpty(restValues)}
                  onClick={() => {
                    setValues({ config: values.config });
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || (isEqual(getObjectValuesByUrlParams(), values))}
                >
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormMixinVisualizeFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    config: PropTypes.string,
    mixin: PropTypes.string,
    mixin_status: PropTypes.string,
    mixin_publish_status: PropTypes.string,
    country: PropTypes.string,
    language: PropTypes.string,
    device_type: PropTypes.string,
    manufactures: PropTypes.string,
    model: PropTypes.string,
    performance: PropTypes.string,
    ram: PropTypes.string,
    os_version: PropTypes.string,
    app_version: PropTypes.string,
    platform: PropTypes.string,
    law: PropTypes.string,
    lat: PropTypes.string,
    install_date: PropTypes.string,
    special_install_date: PropTypes.string,
    user_type: PropTypes.string,
    user_id: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  configOptions: PropTypes.array,
};

FormMixinVisualizeFilter.defaultProps = {
  configOptions: [],
  formErrors: {},
};

export default FormMixinVisualizeFilter;
