import { Empty } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { Area, AreaChart, Brush, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CHARTS_COLORS_MAP } from '../../../Monitor/components/MonitorCharts/MonitorCharts.const';
import { getIndexByGroup, getUniqueDateStatisticsFields } from '../../pages/PageAbTestDetails/PageAbTestDetails.const';



const AbTestDetailsDateStatisticsChart = ({ data }) => {
  const uniqFields = getUniqueDateStatisticsFields(data);
  const groups = getIndexByGroup(uniqFields);
  const colorByGroup = [];

  return (
    <>
      {!isEmpty(data) ? (
        <div id="column-card" className='abtest-charts-column-container'>
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <AreaChart
              width={500}
              height={400}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Brush dataKey="date" height={30} stroke='#666666' />
              <Legend verticalAlign="top" wrapperStyle={{ top: '0' }} />
              {uniqFields?.map((field, index) => {
                groups.map((item, ind) => field.includes(item) ? colorByGroup.push(CHARTS_COLORS_MAP[ind]) : null);

                return (
                  <Area
                    key={field}
                    type="monotone"
                    dataKey={field}
                    stroke={colorByGroup[index]}
                    strokeWidth={1.5}
                    fill={field.includes('total') ? 'none' : colorByGroup[index]}
                    fillOpacity={field.includes('total') ? 0.1 : 0.3}
                    activeDot={field.includes('unique_users') ? { r: 8 } : { r: 5 }}
                    strokeDasharray={field.includes('total') ? '10 10' : '0'}
                  />
                );
              })}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

AbTestDetailsDateStatisticsChart.propTypes = {
  data: PropTypes.array.isRequired,
};


export default AbTestDetailsDateStatisticsChart;
