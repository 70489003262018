import { setRequestParams } from '../../../lib/setRequestParams';



export default class RequestsService {
  constructor (Api) {
    this.api = Api;
  }

  getRequestsList ({ params }) {
    return this.api.get(`gdpr/requests${setRequestParams(params)}`);
  }
}
