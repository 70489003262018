import React, { useEffect } from 'react';
import { BackTop, Breadcrumb, Card, Col, Layout, Row, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import UserEventsTable from './UserEventsTable';
import UserEventsFilter from './UserEventsFilter';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { getUserEventsData, resetUserEventsData, selectData, selectLoading } from '../../feature/userEventsSlice';
import CollapsibleFilterWr from '../../../../components/CollapsibleFilterWr';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Content } = Layout;

const PageUserEvents = () => {
  const { projectSettings } = useApp();
  const dispatch = useDispatch();
  const userEvents = useSelector(selectData);
  const isLoading = useSelector(selectLoading);

  const handleSetFilter = (values) => {
    dispatch(getUserEventsData(values));
  };

  useEffect(() => {
    if (!isEmpty(window.location.search)) {
      handleSetFilter(getObjectValuesByUrlParams());
    }
    return () => {
      dispatch(resetUserEventsData());
    };
  }, []);

  const initialFilterValues = isEmpty(window.location.search) ? {
    parameter: 'network',
    platform: '',
    uId: '',
    date_start: '',
    date_end: '',
  } : getObjectValuesByUrlParams();

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>User events</Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} lg={6}>
            <div className="layout-content">
              <CollapsibleFilterWr
                title='Filter'
                keyValue='user-events-filter'
                maxDeviceWidth={992}
              >
                <UserEventsFilter
                  onSubmit={handleSetFilter}
                  isSubmitting={isLoading}
                  initialValues={initialFilterValues}
                />
              </CollapsibleFilterWr>
            </div>
          </Col>
          <Col xs={24} lg={18}>
            <Content className='layout-content' id='userEventsTopCmp'>
              <Card className='card-has-table'>
                <Spin spinning={!!isLoading} tip="Loading...">
                  <UserEventsTable events={userEvents} />
                </Spin>
              </Card>
            </Content>
            <BackTop
              visibilityHeight={150}
              target={() => document.getElementById('userEventsTopCmp')}
            >
              <div className='back-top-button'>
                <VerticalAlignTopOutlined />
              </div>
            </BackTop>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageUserEvents;
