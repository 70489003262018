import { Col, Collapse, Divider, Row, Spin, Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useCheckMobileScreen } from '../../../../app/hooks';
import { USER_PROFILES_PLATFORMS_ICONS } from '../../pages/PageUserProfiles/PageUserProfiles.const';
import DescriptionValueRow from '../../../../components/DescriptionValueRow';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const { Panel } = Collapse;
const listResult = 'da-mt-sm-4 da-p1-body da-text-color-black-100 da-text-color-dark-0';

const DeviceInformation = ({ deviceData, deviceDataLoading }) => {
  const dividerClass = 'da-border-color-black-40 da-border-color-dark-80';
  const isMobile = useCheckMobileScreen();

  return (
    <Spin spinning={deviceDataLoading} tip="Loading device information...">
      <Collapse>
        <Panel header={<h5 style={{ margin: 'unset' }}>Device information</h5>} key="device_info">
          <Row justify={isMobile ? 'inherit' : 'space-around'}>
            <Col
              xs={24} sm={24} lg={12} md={12} xl={8}
              className="da-profile-content-list da-mt-8 da-pb-sm-0 da-pb-24"
            >
              <Divider orientation="left" className={dividerClass}>
                Main information
              </Divider>
              <ul>
                <DescriptionValueRow
                  firstRow
                  title='Device id'
                  value={deviceData.id}
                />
                <DescriptionValueRow
                  title='Install date'
                  value={renderDateField(deviceData?.installedAt)}
                />
                {/*  <DescriptionValueRow*/}
                {/*    title='Model'*/}
                {/*    value={deviceData?.info?.model}*/}
                {/*  />*/}
                {/*  <DescriptionValueRow*/}
                {/*    title='Form factor'*/}
                {/*    value={deviceData?.info?.formFactor}*/}
                {/*  />*/}
                {/*  <DescriptionValueRow*/}
                {/*    title='Manufacturer'*/}
                {/*    value={deviceData?.info?.manufacturer}*/}
                {/*  />*/}
              </ul>

              <Divider orientation="left" className={dividerClass}>
                Application
              </Divider>
              <ul>
                <DescriptionValueRow
                  firstRow
                  title='Package name'
                  value={deviceData?.app?.packageName}
                />
                <DescriptionValueRow
                  title='Version'
                  value={deviceData?.app?.version}
                />
                <DescriptionValueRow
                  title='Install version'
                  value={deviceData?.app?.versionInstall}
                />
              </ul>

              <Divider orientation="left" className={dividerClass}>
                Platform
              </Divider>
              <ul>
                <li>
                  <span className='da-p1-body'>Platform</span>
                  <span className={listResult}>
                    <Tooltip placement="top" title={deviceData?.platform?.platform || EMPTY_VALUE_PLACEHOLDER}>
                      {USER_PROFILES_PLATFORMS_ICONS(deviceData?.platform?.platform)}
                    </Tooltip>
                  </span>
                </li>
                <DescriptionValueRow
                  title='Version'
                  value={deviceData?.platform?.version}
                />
                <DescriptionValueRow
                  title='Install version'
                  value={deviceData?.platform?.versionInstall}
                />
              </ul>
            </Col>

            <Col
              xs={24} sm={24} lg={12} md={12} xl={8}
              className="da-profile-content-list da-mt-8 da-pb-sm-10 da-pb-24"
            >
              {/*<Divider orientation="left" className={dividerClass}>*/}
              {/*  Configuration*/}
              {/*</Divider>*/}
              {/*<ul>*/}
              {/*  <DescriptionValueRow*/}
              {/*    firstRow*/}
              {/*    title='Date'*/}
              {/*    value={moment(deviceData?.config?.date).format('DD.MM.YYYY HH:mm')}*/}
              {/*  />*/}
              {/*  <DescriptionValueRow*/}
              {/*    title='Hash'*/}
              {/*    value={deviceData?.config?.hash}*/}
              {/*  />*/}
              {/*</ul>*/}

              <Divider orientation="left" className={dividerClass}>
                Session
              </Divider>
              <ul>
                <DescriptionValueRow
                  firstRow
                  title='Sessions count'
                  value={deviceData?.session?.count}
                />
                <DescriptionValueRow
                  title='First session'
                  value={renderDateField(deviceData?.session?.dateFirst)}
                />
                <DescriptionValueRow
                  title='Last session'
                  value={renderDateField(deviceData?.session?.dateLast)}
                />
              </ul>
              {/*<Divider orientation="left" className={dividerClass}>*/}
              {/*  Law*/}
              {/*</Divider>*/}
              {/*<ul>*/}
              {/*  <DescriptionValueRow*/}
              {/*    firstRow*/}
              {/*    title='Date'*/}
              {/*    value={moment(deviceData?.law?.date).format('DD.MM.YYYY HH:mm')}*/}
              {/*  />*/}
              {/*  <DescriptionValueRow*/}
              {/*    title='Name'*/}
              {/*    value={deviceData?.law?.name}*/}
              {/*  />*/}
              {/*</ul>*/}

              <Divider orientation="left" className={dividerClass}>
                Geolocation
              </Divider>
              <ul>
                <DescriptionValueRow
                  firstRow
                  title='Country'
                  value={deviceData?.geo?.country}
                />
                <DescriptionValueRow
                  title='Region'
                  value={deviceData?.geo?.region}
                />
              </ul>

              <Divider orientation="left" className={dividerClass}>
                Localization
              </Divider>
              <ul>
                <DescriptionValueRow
                  firstRow
                  title='Language'
                  value={deviceData?.loc?.lang}
                />
              </ul>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Spin>
  );
};

DeviceInformation.propTypes = {
  deviceData: PropTypes.object,
  deviceDataLoading: PropTypes.bool,
};

DeviceInformation.defaultProps = {
  deviceData: {},
  deviceDataLoading: false,
};

export default DeviceInformation;
