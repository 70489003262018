import { ExportToCsv } from 'export-to-csv';



export const exportToCsv = (data, fileName = 'generated_file', customOptions) => {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useKeysAsHeaders: true,
    filename: fileName,
  };

  const csvExporter = new ExportToCsv(customOptions || options);

  csvExporter.generateCsv(data);
};
