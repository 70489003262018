import { setRequestParams } from '../../../../lib/setRequestParams';



export default class LogsService {
  constructor (Api) {
    this.api = Api;
  }

  getLogsList (params) {
    return this.api.get(`logs${setRequestParams(params)}`);
  }

  getLogDetails (logId) {
    return this.api.get(`logs/${logId}`);
  }
}
