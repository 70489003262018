import React from 'react';
import { Card, Row, Col, Table } from 'antd';
import PropTypes from 'prop-types';



const WidgetTableCard = ({ title, data, rowKey, columns, isLoading, customClassname }) => {
  const preparedTableData = data.length > 5 ? data.slice(0, 5) : data;

  return (
    <Card className="da-border-color-black-40 da-card-6 da-project-table-card">
      <Row>
        <Col span={24}>
          <Row justify="space-between" className="da-mb-16">
            <h5>{title}</h5>
          </Row>

          <Table
            size="small"
            className={`responsive-width ${customClassname}`}
            columns={columns}
            loading={isLoading}
            dataSource={preparedTableData}
            rowKey={rowKey}
            pagination={false}
            scroll={{ x: 700, y: 350 }}
          />
        </Col>
      </Row>
    </Card>
  );
};

WidgetTableCard.propTypes = {
  title: PropTypes.string.isRequired,
  rowKey: PropTypes.string.isRequired,
  columns: PropTypes.oneOfType([ PropTypes.func, PropTypes.array ]),
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  customClassname: PropTypes.string,
};

WidgetTableCard.defaultProps = {
  data: [],
  customClassname: '',
  isLoading: false,
  columns: [],
};

export default WidgetTableCard;
