// eslint-disable-next-line lodash/import-scope
import { isEmpty, isNumber, isBoolean } from 'lodash';



export const getNotEmptyObjectValues = (values) => {
  return Object.keys(values).reduce((object, key) => {
    if (!isEmpty(values[key]) || isNumber(values[key]) || isBoolean(values[key])) {
      object[key] = values[key];
    }
    return object;
  }, {});
};
