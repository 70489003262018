import { setRequestParams } from '../../../../lib/setRequestParams';



export default class ProjectsService {
  constructor (Api) {
    this.api = Api;
  }

  getProjectsList (params) {
    return this.api.get(`projects${setRequestParams(params)}`);
  }

  deleteProject (projectId) {
    return this.api.delete(`projects/${projectId}`);
  }
}
