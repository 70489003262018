import moment from 'moment';
import React from 'react';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const preparedTableValues = (data, field) => data.map((item) => {
  const calculatedCoefficient =  Number(item[field]) / Number(item.active_users);

  return {
    date: item.date,
    [field]: item[field],
    active_users: item.active_users,
    coefficient: isFinite(calculatedCoefficient) ? calculatedCoefficient.toFixed(2) : EMPTY_VALUE_PLACEHOLDER,
  };
});

export const GAME_FINISHED_TABLE_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.date, 'DD.MM.YYYY HH:mm') >= moment(_b.date, 'DD.MM.YYYY HH:mm') ? 1 : -1),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Game finished',
    dataIndex: 'game_finished',
    align: 'center',
    sorter: (_a, _b) => Number(_a.game_finished) - Number(_b.game_finished),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Active users',
    dataIndex: 'active_users',
    align: 'center',
    sorter: (_a, _b) => Number(_a.active_users) - Number(_b.active_users),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Coefficient',
    dataIndex: 'coefficient',
    align: 'center',
    sorter: (_a, _b) => _a.coefficient - _b.coefficient,
    render: (value) => value,
  },
];

export const APP_OPEN_TABLE_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.date, 'DD.MM.YYYY HH:mm') >= moment(_b.date, 'DD.MM.YYYY HH:mm') ? -1 : 1),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'App open',
    dataIndex: 'app_open',
    align: 'center',
    sorter: (_a, _b) => Number(_a.app_open) - Number(_b.app_open),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Active users',
    dataIndex: 'active_users',
    align: 'center',
    sorter: (_a, _b) => Number(_a.active_users) - Number(_b.active_users),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Coefficient',
    dataIndex: 'coefficient',
    align: 'center',
    sorter: (_a, _b) => _a.coefficient - _b.coefficient,
    render: (value) => value,
  },
];

