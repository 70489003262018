import { RiCloseFill } from 'react-icons/ri';
import { Modal } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import FormReturnExperiment from '../../forms/FormReturnExperiment';



const ModalReturnExperiment = ({
  title,
  isActiveModal,
  hideModal,
  initialValues,
  formErrors,
  handleSubmitForm,
  isLoading,
}) => {
  return (
    <Modal
      title={title}
      width={416}
      centered
      destroyOnClose
      visible={isActiveModal}
      onCancel={hideModal}
      footer={null}
      closeIcon={
        <RiCloseFill className="remix-icon text-color-black-100" size={24} />
      }
    >
      <FormReturnExperiment
        initialValues={initialValues}
        formErrors={formErrors}
        onSubmit={handleSubmitForm}
        isSubmitting={isLoading}
      />
    </Modal>
  );
};


ModalReturnExperiment.propTypes = {
  title: PropTypes.string.isRequired,
  isActiveModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  formErrors: PropTypes.object,
};

ModalReturnExperiment.defaultProps = {
  initialValues: {},
  formErrors: {},
  isLoading: false,
};

export default ModalReturnExperiment;
