import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { useApp } from '../../../../../app/context/AppContext';
import AppLogo from '../../../../../assets/images/logo/logo.svg';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';



const MenuLogoEnvLabel = styled.span`
  font-size: 18px;
  color: red;
  font-weight: bold;
  margin-right: 5px;
`;

const currentEnvLabel = () => {
  switch (document.location.hostname) {
    case 'uprocedures.dev': return 'dev';
    case 'qa.uprocedures.com': return 'qa';
    case 'localhost': return 'local';

    default: return '';
  }
};

const MenuLogo = ({ onClose }) => {
  const { projectSettings } = useApp();

  return (
    <Link
      to={!isEmpty(projectSettings?.spaceKey) ? mkRedirectToDashboardPath(projectSettings) : '/'}
      className="da-header-logo da-d-flex da-align-items-end"
      onClick={onClose}
    >
      <img className="da-logo" style={{ minWidth: '120px' }} src={AppLogo} alt="logo" />
      <span
        className="da-p1-body da-font-weight-500 da-text-color-black-40 da-mb-16 da-ml-4"
        style={{ letterSpacing: -1.5 }}
      >
        <MenuLogoEnvLabel>{currentEnvLabel()}</MenuLogoEnvLabel>
      </span>
    </Link>
  );
};

MenuLogo.propTypes = {
  onClose: PropTypes.func,
};

MenuLogo.defaultProps = {
  onClose: () => {},
};

export default MenuLogo;
