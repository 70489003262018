import React from 'react';
import { Breadcrumb, Layout, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useApp } from '../../../app/context/AppContext';
import { getProjectsOptions } from '../../../lib/getProjectsOptions';
import { LOADING_TIP_PLACEHOLDER } from '../../../const/system';
import ProjectsList from '../../../components/ProjectsList';
import mkRedirectToDashboard from '../../../lib/mkRedirectToDashboard';
import { authLoading } from '../../../app/auth/feature/AuthSlice';



const { Content } = Layout;


const PageSelectProject = () => {
  const { setProjectSettings, projectSettings, userSpaces } = useApp();
  const navigate = useNavigate();
  const isLoading = useSelector(authLoading);

  const handleChangeSettings = (value) => {
    setProjectSettings(value);
    mkRedirectToDashboard(navigate, value);
  };

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <Content className='layout-content'>
        <Spin spinning={isLoading} tip={LOADING_TIP_PLACEHOLDER}>
          <ProjectsList
            projectSettings={projectSettings}
            data={getProjectsOptions(userSpaces)}
            handleSetSettings={handleChangeSettings}
          />
        </Spin>
      </Content>
    </>
  );
};

export default PageSelectProject;
