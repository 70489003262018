import isEmpty from 'lodash/isEmpty';



export const getProjectsOptions = (data) => {
  return !isEmpty(data) ? data.map((item) => {
    const label = item?.name;
    const id = item?.id;
    const key = item?.key;
    const projects = !isEmpty(item?.projects) ? item.projects.data.map((project) => ({
      label: project?.name,
      key: project?.key,
      id: project.id,
    })) : [];

    return { label, id, key, projects };
  }) : [];
};
