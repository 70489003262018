import { Card, Col, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import YAML from 'js-yaml';



const LogDetailsDiffCard = ({ data, isLoading, hasDiff }) => {
  return (
    <Card
      title='Difference'
      loading={isLoading}
    >
      <Row gutter={[ 16, 16 ]} >
        {hasDiff ? (
          <>
            <Col xs={24} md={12}>
              <Card title='Before:'>
                <ReactJson
                  src={YAML.load(data?.before)}
                  theme="summerfruit:inverted"
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card title='After:'>
                <ReactJson
                  src={YAML.load(data?.after)}
                  theme="summerfruit:inverted"
                />
              </Card>
            </Col>
          </>
        ) : (
          <Col span={24}>
            <ReactJson
              src={YAML.load(data)}
              theme="summerfruit:inverted"
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

LogDetailsDiffCard.propTypes = {
  data: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]).isRequired,
  hasDiff: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default LogDetailsDiffCard;
