import { setRequestParams } from '../../../lib/setRequestParams';



export default class UserEventsService {
  constructor (Api) {
    this.api = Api;
  }

  getEventsSequence (params) {
    return this.api.get(`events/sequence${setRequestParams(params)}`);
  }

  getEvents () {
    return this.api.get('events/user/events-map');
  }

  getParameters () {
    return this.api.get('events/user/parameters-map');
  }
}
