import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import UsersService from '../services/usersService';
import { mkNotification } from '../../../../lib/mkNotification';
import { setUrlParams } from '../../../../lib/setUrlParams';



const initialState = {
  usersList: [],
  usersPagination: {},
  errors: {},
  loadingUsersList: false,
  formCreateEditUserLoading: false,
  formCreateEditUserErrors: {},
};

const usersService = new UsersService(Api);

export const getUsersListData = createAsyncThunk(
  'users/getUsersList',
  async (values) => {
    const response = await usersService.getUsersList(values);

    if (!isEmpty(values)) {
      setUrlParams(values);
    }

    return response;
  },
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (values, { rejectWithValue }) => {
    try {
      const response = await usersService.createUser(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (userId) => {
    const response = await usersService.deleteUser(userId);

    return response.data;
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsersListData: (state) => {
      state.usersList = [];
      state.usersPagination = {};
    },
    resetFormCreateEditUserErrors: (state) => {
      state.formCreateEditUserErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersListData.pending, (state) => {
        state.loadingUsersList = true;
      })
      .addCase(getUsersListData.fulfilled, (state, action) => {
        state.loadingUsersList = false;
        state.usersList = action.payload.data;
        state.usersPagination = action.payload.meta.pagination;
      })
      .addCase(getUsersListData.rejected, (state, action) => {
        state.loadingUsersList = false;
        state.errors = action.payload.data;
      });

    builder
      .addCase(createUser.pending, (state) => {
        state.formCreateEditUserLoading = true;
        state.formCreateEditUserErrors = {};
      })
      .addCase(createUser.fulfilled, (state) => {
        state.formCreateEditUserLoading = false;
        mkNotification('success', 'User is successfully created.');
      })
      .addCase(createUser.rejected, (state, action) => {
        state.formCreateEditUserLoading = false;
        state.formCreateEditUserErrors = action.payload.errors;
      });

    builder
      .addCase(deleteUser.pending, (state) => {
        state.loadingUsersList = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.loadingUsersList = false;
        mkNotification('success', 'User is successfully deleted.');
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loadingUsersList = false;
        state.errors = action.payload;
      });
  },
});

// actions
export const { resetUsersListData, resetFormCreateEditUserErrors } = usersSlice.actions;

// selectors
export const selectUsersLoading = (state) => state.users.loadingUsersList;
export const selectUsersList = (state) => state.users.usersList;
export const selectUsersPagination = (state) => state.users.usersPagination;
export const selectErrors = (state) => state.users.errors;
export const selectFormCreateEditUserLoading = (state) => state.users.formCreateEditUserLoading;
export const selectFormCreateEditUserErrors = (state) => state.users.formCreateEditUserErrors;

// reducer
export default usersSlice.reducer;
