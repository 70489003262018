import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../services/Api/Api';
import { mkNotification } from '../../../lib/mkNotification';
import ProjectDetailsService from '../services/projectDetailsService';



const initialState = {
  loading: false,
  projectDetails: {},
  createProjectLoading: false,
  updateProjectLoading: false,
  formCreateEditProjectErrors: {},
};

const projectDetailsService = new ProjectDetailsService(Api);

export const getProjectDetails = createAsyncThunk(
  'projectDetails/getProject',
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await projectDetailsService.getDetails(projectId);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createProject = createAsyncThunk(
  'projectDetails/createProject',
  async (values, { rejectWithValue }) => {
    try {
      const response = await projectDetailsService.createProject(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProjectDetails = createAsyncThunk(
  'projectDetails/updateProject',
  async ({ projectId, values }, { rejectWithValue }) => {
    try {
      const response = await projectDetailsService.updateProject({ projectId, values });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const projectDetailsSlice = createSlice({
  name: 'projectDetails',
  initialState,
  reducers: {
    resetProjectDetailsData: (state) => {
      state.projectDetails = {};
      state.formCreateEditProjectErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.projectDetails = action.payload;
      })
      .addCase(getProjectDetails.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(createProject.pending, (state) => {
        state.createProjectLoading = true;
        state.formCreateEditProjectErrors = {};
      })
      .addCase(createProject.fulfilled, (state) => {
        state.createProjectLoading = false;
        mkNotification('success', 'Project is successfully created.');
      })
      .addCase(createProject.rejected, (state, action) => {
        state.createProjectLoading = false;
        state.formCreateEditProjectErrors = action.payload.errors;
      });

    builder
      .addCase(updateProjectDetails.pending, (state) => {
        state.updateProjectLoading = true;
        state.formCreateEditProjectErrors = {};
      })
      .addCase(updateProjectDetails.fulfilled, (state) => {
        state.updateProjectLoading = false;
        mkNotification('success', 'Project is successfully updated.');
      })
      .addCase(updateProjectDetails.rejected, (state, action) => {
        state.updateProjectLoading = false;
        state.formCreateEditProjectErrors = action.payload.errors;
      });
  },
});

// actions
export const { resetProjectDetailsData } = projectDetailsSlice.actions;

// selectors
export const selectProjectDetailsLoading = (state) => state.projectDetails.loading;
export const selectProjectDetailsData = (state) => state.projectDetails.projectDetails;
export const selectFormCreateProjectLoading = (state) => state.projectDetails.createProjectLoading;
export const selectFormEditProjectLoading = (state) => state.projectDetails.updateProjectLoading;
export const selectFormCreateEditProjectErrors = (state) => state.projectDetails.formCreateEditProjectErrors;


// reducer
export default projectDetailsSlice.reducer;
