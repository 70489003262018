import { checkPermissions } from '../components/HasRights/HasRights';
import { getUrlProjectSettings } from '../lib/getUrlProjectSettings';
import { getAllUserPolicies } from '../lib/getAllUserPolicies';



export const Permissions = {
  USER_PROFILES: {
    VIEW_ALL: 'get-list-profiles',
    USER_PROFILES_LIST: {
      VIEW_PAGE: 'get-list-profiles',
      VIEW_DEVICES: 'get-profiles-devices',
      VIEW_DEVICE_DETAILS: 'get-profiles-device',
      VIEW_DETAILS: 'get-user-profile',
      VIEW_AB_TESTS: 'get-device-tests',
      VIEW_PURCHASES: 'get-device-purchases',
      VIEW_PICTURES: 'get-device-pictures',
    },
  },
  AB_TESTS: {
    VIEW_PAGE: 'get-list-of-ab-tests',
    CREATE: 'create-ab-test',
    UPDATE: 'update-ab-test',
    DELETE: 'delete-specific-ab-test',
    GET_STATISTIC: 'get-ab-test-statistics',
    VIEW_DETAILS: 'get-specific-ab-test',
    PUBLISH_TEST: 'publish-specific-ab-test',
  },
  SUPPORT: {
    VIEW_ALL: [
      'get-specific-ticket',
      'get-user-events',
      'get-event-sequence',
    ],
    TICKET: {
      VIEW_PAGE: 'get-specific-ticket',
    },
    USER_EVENTS: {
      VIEW_PAGE: 'get-user-events',
    },
    EVENTS_SEQUENCE: {
      VIEW_PAGE: 'get-event-sequence',
    },
  },
  MONITORING: {
    VIEW_ALL: [
      'get-game-monitoring',
      'get-ad-impression-monitoring',
      'get-application-monitoring',
    ],
    GAMES_MONITOR: {
      VIEW_PAGE: 'get-game-monitoring',
    },
    APPLICATION_MONITOR: {
      VIEW_PAGE: 'get-application-monitoring',
    },
    ADS_MONITOR: {
      VIEW_PAGE: 'get-ad-impression-monitoring',
    },
    HINTS: {
      VIEW_PAGE: 'get-hint-monitoring',
    },
    CONTACT_US: {
      VIEW_PAGE: 'get-contact-monitoring',
    },
    BEE_FREELANCER: {
      VIEW_ALL: [
        'bee-finished-projects',
        'bee-reopened-projects',
        'bee-average-time',
        'bee-most-slow-type',
        'bee-average-taps',
        'bee-active-projects',
        'bee-time-spending',
        'bee-active-users',
      ],
      VIEW_PAGE: 'bee-finished-projects',
      ACTIVE_USERS: 'bee-active-users',
      FINISHED_PROJECTS: 'bee-finished-projects',
      REOPENED_PROJECTS: 'bee-reopened-projects',
      AVERAGE_TIME: 'bee-average-time',
      MOST_SLOW_TYPE: 'bee-most-slow-type',
      AVERAGE_TAPS: 'bee-average-taps',
      ACTIVE_PROJECTS: 'bee-active-projects',
      TIME_SPENDING: 'bee-time-spending',
    },
  },
  CONFIGS: {
    STRUCTURES: {
      VIEW_LIST: 'get-list-of-structures',
      CREATE: 'create-ad-structure',
      UPDATE: 'update-specific-structure',
      DELETE: 'delete-specific-ad-structure',
      VIEW_DETAILS: 'get-specific-ad-structure',
    },
    MIXIN_VISUALIZE: {
      VIEW_ALL: [
        'visualize-mixin-get-mixins-by-row',
        'visualize-mixin-get-config',
        'visualize-mixin-get-mixins',
      ],
      VIEW_MIXINS_LIST: 'visualize-mixin-get-mixins',
      VIEW_CONFIG: 'visualize-mixin-get-config',
      VIEW_CONFIG_ROW_MIXINS: 'visualize-mixin-get-mixins-by-row',
    },
    CONFIG_VISUALIZE: {
      VIEW_DEV_FIELDS_ON_CREATE: 'visualize-config-edit-dev',
      VIEW_LIST_AND_DETAILS: 'view-request-visualize-config',
      VIEW_CORE_DETAILS: 'visualize-config',
      SAVE: 'save-request-visualize-config',
      DELETE: 'delete-request-visualize-config',
      CREATE: 'create-request-visualize-config',
    },
    CONFIGURATIONS: {
      PUBLISH: 'publish-specific-ad-config',
      VIEW_LIST: 'get-all-ad-configs',
      CREATE: 'create-ad-config',
      UPDATE: 'update-specific-ad-config',
      DELETE: 'delete-specific-ad-config',
      VIEW_DETAILS: 'get-specific-ad-config',
      MIXINS: {
        VIEW_LIST: 'get-all-ad-mixins',
        VIEW_DETAILS: 'get-specific-ad-mixin',
        CREATE: 'create-ad-mixin',
        UPDATE: 'update-specific-ad-mixin',
        DELETE: 'delete-specific-ad-mixin',
        TARGETING: {
          VIEW_DETAILS: 'get-specific-ad-mixin-target',
          CREATE: 'create-ad-mixin-target',
          UPDATE: 'update-specific-ad-mixin-target',
          DELETE: 'delete-specific-ad-mixin-target',
        },
      },
      TARGETING: {
        VIEW_DETAILS: 'get-specific-ad-config-target',
        CREATE: 'create-ad-config-target',
        UPDATE: 'update-specific-ad-config-target',
        DELETE: 'delete-specific-ad-config-target',
      },
    },
  },
  MIXINS_EXPERIMENTS: {
    VIEW_ALL_LIST: 'get-all-experiments-mixins',
    VIEW_OWN_LIST: 'get-own-experiments-mixins',
    VIEW_FREE_LIST: 'get-free-experiments-mixins',
    CREATE: 'create-experiments-mixin',
    UPDATE: 'update-experiments-mixin',
    ARCHIVE: 'archive-experiments-mixin',
    VIEW_DETAILS: 'find-specific-experiments-mixin',
    TOGGLE_IN_SANDBOX: 'publish-experiments-mixin',
    CHECK_EXPERIMENT: 'check-experiments-mixin',
    REVIEW_EXPERIMENT: 'review-experiments-mixin',
    CREATE_TARGET: 'create-experiments-mixin-target',
    BLOCK_SANDBOX: 'block-sandbox',
  },
  REQUESTS: {
    VIEW_LIST: 'get-all-gdpr-requests',
    VIEW_DETAILS: 'get-specific-gdpr-request',
    USER_DETAILS: 'search-user-gdpr-data',
  },
  ADMINISTRATION: {
    GLOBAL_ADMIN_MENU: 'show-global-admin-menu',
    SPACE_ADMIN_MENU: 'show-space-admin-menu',
    VIEW_ALL: [
      'show-global-admin-menu',
      'show-space-admin-menu',
      'remove-role-from-user',
      'get-all-roles',
      'get-all-permissions',
      'get-specific-role',
      'get-specific-permission',
      'detach-permission',
      'attach-permission-to-role',
      'assign-user-to-role',
      'delete-specific-user',
      'get-specific-user',
      'create-user',
      'get-all-users',
      'update-specific-user',
      'get-all-spaces',
      'create-space',
      'update-specific-space',
      'delete-specific-space',
      'get-all-projects',
      'get-specific-project',
      'create-project',
      'update-specific-project',
      'delete-specific-project',
    ],
    USERS: {
      USERS_LIST: {
        VIEW_PAGE: 'get-all-users',
        DELETE_USER: 'delete-specific-user',
        CREATE_USER: 'create-user',
      },
      USER_DETAILS: {
        VIEW: 'get-specific-user',
        UPDATE_USER: 'update-specific-user',
        UPDATE_POLICIES: 'update-specific-user-policies',
      },
    },
    LOGS: {
      VIEW_PAGE: 'get-all-logs',
      VIEW_DETAILS: 'get-specific-log',
    },
    PERMISSIONS: {
      VIEW_ALL: [
        'get-all-permissions',
        'get-specific-permission',
      ],
      PERMISSIONS_LIST: {
        VIEW_PAGE: 'get-all-permissions',
        SPECIFIC_USER: 'get-specific-user-permissions',
      },
      PERMISSION_DETAILS: {
        VIEW: 'get-specific-permission',
      },
    },
    ROLES: {
      VIEW_ALL: [
        'get-all-roles',
        'delete-specific-role',
        'create-role',
        'get-specific-role',
        'get-specific-role-permissions',
        'update-specific-role',
        'update-specific-role-permissions',
      ],
      ALL_ROLES: 'get-all-roles',
      SPECIFIC_ROLE: 'get-specific-user-roles',
      ROLES_LIST: {
        VIEW_PAGE: 'get-all-roles',
        DELETE_ROLE: 'delete-specific-role',
        CREATE_ROLE: 'create-role',
      },
      ROLE_DETAILS: {
        VIEW: 'get-specific-role',
        EDIT_ROLE_DETAILS: 'update-specific-role',
        EDIT_PERMISSIONS: 'update-specific-role-permissions',
        GET_ROLE_PERMISSIONS: 'get-specific-role-permissions',
      },
    },
    SPACES: {
      VIEW_LIST: 'get-all-spaces',
      CREATE: 'create-space',
      UPDATE: 'update-specific-space',
      DELETE: 'delete-specific-space',
    },
    PROJECTS: {
      VIEW_LIST: 'get-all-projects',
      VIEW_DETAILS: 'get-specific-project',
      CREATE: 'create-project',
      UPDATE: 'update-specific-project',
      DELETE: 'delete-specific-project',
    },
  },
};

export const hasRights = (allowedPermission, isMultipleAllowance) => {
  const userData = JSON.parse(localStorage.getItem('user'));

  if (userData) {
    const projectSettings = getUrlProjectSettings();
    const userPermissions = getAllUserPolicies(userData?.policies, projectSettings, 'permissions');

    return checkPermissions(userPermissions, allowedPermission, isMultipleAllowance);
  }

  return false;
};
