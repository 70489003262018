import { Breadcrumb, Button, Card, Col, Modal, Row, Table } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiCloseFill } from 'react-icons/ri';
import { useApp } from '../../../../app/context/AppContext';
import urlPageStructures from '../../../../urls/urlPageStructures';
import FormCreateEditStructure from '../../forms/FormCreateEditStructure';
import { getStructuresTableColumns } from './PageStructures.const';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import { getQueryParams } from '../../../../lib/getQueryParams';
import {
  createStructure,
  deleteStructure,
  getStructuresListData,
  resetStructuresListData,
  selectFormEditStructureErrors,
  selectFormEditStructureLoading,
  selectStructuresList,
  selectStructuresLoading,
  selectStructuresPagination,
} from '../../feature/structuresSlice';
import { hasRights, Permissions } from '../../../../const/permissions';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const PageStructures = () => {
  const { pathname, search } = useLocation();
  const queryParams = getQueryParams(search);
  const { projectSettings } = useApp();
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ isActiveStructureModal, setToggleCreateStructureModal ] = useState(false);

  const dispatch = useDispatch();
  const structuresList = useSelector(selectStructuresList);
  const isLoading = useSelector(selectStructuresLoading);
  const formCreateStructureErrors = useSelector(selectFormEditStructureErrors);
  const isFormCreateStructureLoading = useSelector(selectFormEditStructureLoading);
  const pagination = useSelector(selectStructuresPagination);

  useEffect(() => {
    if (pathname === urlPageStructures()) {
      dispatch(getStructuresListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
    }
    return () => {
      dispatch(resetStructuresListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting ]);

  const handleDeleteStructure = async (structureId) => {
    await dispatch(deleteStructure(structureId));
    dispatch(getStructuresListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  const showCreateStructureModal = () => {
    setToggleCreateStructureModal(true);
  };

  const hideCreateStructureModal = () => {
    setToggleCreateStructureModal(false);
  };

  const handleSubmitEditStructureForm = async (values) => {
    const preparedValues = {
      name: values.name,
      version: values.version,
      structure_file: values.structure_file,
    };

    await dispatch(createStructure(preparedValues))
      .then((response) => {
        if (isFulfilledRequestStatus(response)) {
          hideCreateStructureModal();
          dispatch(getStructuresListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
        }
      });
  };

  return (
    <>
      <Modal
        title="Create structure"
        width={470}
        centered
        destroyOnClose
        visible={isActiveStructureModal}
        onCancel={hideCreateStructureModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <FormCreateEditStructure
          initialValues={{}}
          formErrors={formCreateStructureErrors}
          handleCancel={hideCreateStructureModal}
          onSubmit={handleSubmitEditStructureForm}
          isSubmitting={isFormCreateStructureLoading}
        />
      </Modal>

      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Structures list</Breadcrumb.Item>
          </Breadcrumb>
          {hasRights([ Permissions.CONFIGS.STRUCTURES.CREATE ]) && (
            <Button
              style={{ margin: '10px 0' }}
              size='middle'
              type="primary"
              onClick={showCreateStructureModal}
            >
              Create structure
            </Button>
          )}
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Table
            sticky
            onChange={handleChangeTableParams}
            columns={getStructuresTableColumns(handleDeleteStructure)}
            dataSource={structuresList}
            rowKey="id"
            loading={isLoading}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 550 }}
          />
        </Card>
      </div>
    </>
  );
};


export default PageStructures;
