import React from 'react';
import { Tag } from 'antd';
import capitalize from 'lodash/capitalize';
import { ENVIRONMENT_TAG_COLOR } from '../../../Roles/pages/PageRoles/PageRoles.const';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';



export const PERMISSIONS_TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '10%',
    defaultSortOrder: 'ascend',
    sorter: (_a, _b) => Number(_a.id) - Number(_b.id),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: '30%',
  },
  {
    title: 'Environment',
    dataIndex: 'environment',
    align: 'center',
    width: '10%',
    render: (value) => {
      return (
        <div style={{ display: 'inline-grid' }}>
          {value ? value.map((item) => <Tag color={ENVIRONMENT_TAG_COLOR(item)} key={item}>{capitalize(item) || EMPTY_VALUE_PLACEHOLDER}</Tag>) : EMPTY_VALUE_PLACEHOLDER}
        </div>);
    },
  },
  {
    title: 'Display name',
    dataIndex: 'display_name',
    width: '25%',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '25%',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];
