import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { setUrlParams } from '../../../lib/setUrlParams';
import AppMonitoringService from '../services/applicationMonitorService';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  appData: [],
  loading: false,
};

const appMonitoringService = new AppMonitoringService(Api);

export const getAppMonitoringData = createAsyncThunk(
  'appMonitoringEvents/getMonitoring',
  async (values, { rejectWithValue }) => {
    try {
      const response = await appMonitoringService.getAppMonitoring(values);

      if (values) {
        setUrlParams(values);
      }

      if (isEmpty(response)) {
        mkNotification('info', 'No application monitoring data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const applicationMonitorSlice = createSlice({
  name: 'appMonitoringEvents',
  initialState,
  reducers: {
    resetAppsMonitoringData: (state) => {
      state.appData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppMonitoringData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAppMonitoringData.fulfilled, (state, action) => {
        state.loading = false;
        state.appData = action.payload;
      })
      .addCase(getAppMonitoringData.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetAppsMonitoringData } = applicationMonitorSlice.actions;

// selectors
export const selectAppsLoading = (state) => state.appMonitoring.loading;
export const selectAppsData = (state) => state.appMonitoring.appData;

// reducer
export default applicationMonitorSlice.reducer;
