import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Typography } from 'antd';
import { EMPTY_VALUE_PLACEHOLDER } from '../../const/system';



const { Paragraph } = Typography;

const DescriptionValueRow = ({ firstRow, marginStyle, copyable, additionalStyle, title, value }) => {
  const listResult = 'da-mt-sm-4 da-p1-body da-text-color-black-100 da-text-color-dark-0';

  return (
    <li className={firstRow ? '' : marginStyle}>
      <span className='da-p1-body'>{title}</span>
      <div className={listResult} style={additionalStyle}>
        <Paragraph copyable={copyable}>{!isEmpty(value) ? value : EMPTY_VALUE_PLACEHOLDER}</Paragraph>
      </div>
    </li>
  );
};

DescriptionValueRow.propTypes = {
  firstRow: PropTypes.bool,
  title: PropTypes.string.isRequired,
  marginStyle: PropTypes.string,
  copyable: PropTypes.bool,
  additionalStyle: PropTypes.object,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};

DescriptionValueRow.defaultProps = {
  firstRow: false,
  marginStyle: 'da-mt-18',
  additionalStyle: {},
  copyable: false,
  value: null,
};

export default DescriptionValueRow;
