import moment from 'moment';
import isEmpty from 'lodash/isEmpty';



const getDateFormat = (date) => {
  if (moment(date)._f === 'YYYY-MM-DD HH:mm:ss') {
    return 'DD.MM.YYYY HH:mm';
  }
  return 'DD.MM.YYYY';
};

export const getPreparedChartsData = (data, defaultFields, customDateFormat, isPrevType = false) => {
  const preparedData = !isEmpty(data) ? data.map(({ count, prev_count, type, ...item }) => {
    const preparedDate = `${moment(item.date).format(customDateFormat || getDateFormat(item.date))}`;

    return isPrevType ? {
      [type]: Number(count),
      [`prev_${type}`]: Number(prev_count),
      date: preparedDate,
    } : {
      [type]: Number(count),
      date: preparedDate,
    };
  }) : [];

  return preparedData.reduce((result, item) => {
    const i = result.findIndex((resultItem) => resultItem.date === item.date);

    //Fill object values by date field
    if ((i === -1) && moment(item.date, 'DD.MM.YYYY').isSameOrBefore()) {
      result.push(item);
    } else {
      result[i] = { ...result[i], ...item };
    }

    if (!isEmpty(defaultFields)) {
    //Set 0 on empty obj fields
      return result.map((item) => ({
        ...defaultFields,
        ...item,
      }));
    }
    return result;
  }, []);
};
