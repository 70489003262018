import moment from 'moment';



export const firstStepDefaultValues = {
  ad_in_app: 0,
  ad_distribution: 1,
  ad_banner_size: 'phone',
  ad_version: '2.2.0',
  adv_id: '0000-0000-0000-0000',
  app_install_date: moment(new Date()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
  device_type: 'phone',
  alt_id: '0000-0000-0000-0000',
  sync_id: '0000-0000-0000-0000',
  user_id: '0000-0000-0000-0000',
  ad_id: '0000000000',
  utc: 3,
  language: 'English',
  locale: 'GB_en',
  limit_tracking: 0,
  limit_ad_tracking: 0,
  is_new_user: false,
  ab_name: 'none',
  ab_group: 'none',
  ab_affiliation: 'none',
  dev_ab_name: '',
  dev_ab_group: '',
  dev_ab_affiliation: null,
};
