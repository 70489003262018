import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import TicketDetailsService from '../services/ticketDetailsService';



const initialState = {
  data: {},
  loading: false,
  eventsData: [],
  eventsLoading: false,
  getEventsPolling: false,
};

const ticketDetailsService = new TicketDetailsService(Api);

export const getTicketDetailsData = createAsyncThunk(
  'ticket/getData',
  async (tickedId, { rejectWithValue }) => {
    try {
      const response = await ticketDetailsService.getData(tickedId);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTicketDetailsEventsData = createAsyncThunk(
  'ticket/getTicketEvents',
  async (tickedId, { rejectWithValue }) => {
    try {
      return ticketDetailsService.getTicketEvents(tickedId);
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const ticketDetailsSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    resetTicketDetailsData: (state) => {
      state.data = {};
    },
    resetTicketDetailsEventsData: (state) => {
      state.eventsData = [];
      state.getEventsPolling = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketDetailsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTicketDetailsData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getTicketDetailsData.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getTicketDetailsEventsData.pending, (state) => {
        state.eventsLoading = true;
      })
      .addCase(getTicketDetailsEventsData.fulfilled, (state, action) => {
        state.eventsLoading = false;
        state.eventsData = action.payload.data;
        state.getEventsPolling = isEmpty(action.payload.data) && action.payload.status === 202;
      })
      .addCase(getTicketDetailsEventsData.rejected, (state) => {
        state.eventsLoading = false;
      });
  },
});


// actions
export const { resetTicketDetailsData, resetTicketDetailsEventsData } = ticketDetailsSlice.actions;

// selectors
export const selectLoading = (state) => state.ticket.loading;
export const selectData = (state) => state.ticket.data;

export const selectTicketEventsLoading = (state) => state.ticket.eventsLoading;
export const selectTicketEventsData = (state) => state.ticket.eventsData;
export const selectTicketEventsPolling = (state) => state.ticket.getEventsPolling;

// reducer
export default ticketDetailsSlice.reducer;
