import { Breadcrumb, Button, Modal, Spin } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircle } from 'react-iconly';
import isEmpty from 'lodash/isEmpty';
import HasRights from '../../../../components/HasRights';
import { hasRights, Permissions } from '../../../../const/permissions';
import urlPageMixinsExperiments from '../../../../urls/urlPageMixinsExperiments';
import urlPageMixinsExperimentDetails from '../../../../urls/urlPageMixinsExperimentDetails';
import urlPageCreateMixinsExperimentMixinTargeting from '../../../../urls/urlPageCreateMixinsExperimentMixinTargeting';
import FormCreateEditExperimentMixin from '../../forms/FormCreateEditExperimentMixin';
import { useApp } from '../../../../app/context/AppContext';
import { useLocalStorage } from '../../../../app/hooks';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  getPreparedFormData,
  predefinedFormCreateInitialValues,
  preparedToRequestFormData,
} from './PageCreateEditMixinsExperimentMixin.const';
import {
  createExperimentMixin,
  resetCreateExperimentMixinErrors,
  selectFormCreateExperimentMixinErrors,
  selectFormCreateExperimentMixinLoading,
  selectExperimentDetailsLoading,
  selectExperimentDetailsData,
  getExperimentDetails,
} from '../../feature/mixinsExperimentDetailsSlice';
import {
  getExperimentMixinDetails,
  resetUpdateExperimentMixinErrors,
  selectExperimentMixinDetails,
  selectExperimentMixinDetailsLoading,
  selectFormUpdateExperimentMixinErrors,
  selectFormUpdateExperimentMixinLoading,
  updateExperimentMixinDetails,
} from '../../feature/mixinExperimentMixinDetailsSlice';



const PageCreateEditMixinsExperimentMixin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { projectSettings, userName } = useApp();
  const { pathname } = location;
  const { testId, testName, mixinId } = useParams();

  const isExperimentCreate = pathname.endsWith('create');
  const [ storedUserIP, _ ] = useLocalStorage('user_ip', null);
  const [ isActiveNotificationModal, setToggleNotificationModal ] = useState(false);
  const [ savedMixinId, setMixinId ] = useState('');
  const [ serverErrors, setServerErrors ] = useState({});

  const isLoadingCreateMixin = useSelector(selectFormCreateExperimentMixinLoading);
  const createMixinErrors = useSelector(selectFormCreateExperimentMixinErrors);
  const mixinDetails = useSelector(selectExperimentMixinDetails);
  const isLoadingMixinDetails = useSelector(selectExperimentMixinDetailsLoading);
  const isLoadingUpdateMixin = useSelector(selectFormUpdateExperimentMixinLoading);
  const updateMixinErrors = useSelector(selectFormUpdateExperimentMixinErrors);
  const testDetails = useSelector(selectExperimentDetailsData);
  const isLoadingTestDetails = useSelector(selectExperimentDetailsLoading);

  const configId = !isEmpty(testDetails) ? testDetails.config.data.id : null;
  const configName = !isEmpty(testDetails) ? testDetails.config.data.name : null;


  useEffect(() => {
    dispatch(getExperimentDetails(testId)).then(() => {
      if (!isExperimentCreate) {
        dispatch(getExperimentMixinDetails({ testId, mixinId }));
      }
    });
  }, [ isExperimentCreate ]);


  useEffect(() => {
    const errors = { ...createMixinErrors, ...updateMixinErrors };

    if (!isEmpty(errors)) {
      setServerErrors(errors);
    }
  }, [ createMixinErrors, updateMixinErrors ]);


  const handleRedirectToTestDetails = () => {
    setToggleNotificationModal(false);
    navigate(urlPageMixinsExperimentDetails({ testId, testName }));
  };


  const handleSubmitFormData = async (values) => {
    const data = preparedToRequestFormData({
      ...values,
      experiment_mixin_id: testDetails?.id,
      target_ip: storedUserIP,
    });

    if (isExperimentCreate) {
      await dispatch(createExperimentMixin({ testId, data })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          resetCreateExperimentMixinErrors();
          if (hasRights([ Permissions.MIXINS_EXPERIMENTS.CREATE_TARGET, Permissions.MIXINS_EXPERIMENTS.CHECK_EXPERIMENT ], true)) {
            setToggleNotificationModal(true);
            setMixinId(response.payload.id);
          } else {
            handleRedirectToTestDetails();
          }
        }
      });
    } else {
      await dispatch(updateExperimentMixinDetails({ testId, mixinId, data })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          resetUpdateExperimentMixinErrors();
          handleRedirectToTestDetails();
        }
      });
    }
  };

  const configData = !isEmpty(testDetails) ? JSON.parse(testDetails.config.data.config) : {};
  const initialValues = !isExperimentCreate ?
    getPreparedFormData(mixinDetails, configId, configName) :
    predefinedFormCreateInitialValues({ configId, configName, authorName: userName });

  return (
    <HasRights allowedPermissions={[ Permissions.MIXINS_EXPERIMENTS.CREATE, Permissions.MIXINS_EXPERIMENTS.UPDATE ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageMixinsExperiments()}>
            Mixins experiments
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageMixinsExperimentDetails({ testId, testName })}>
            Details
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {isExperimentCreate ? 'Create mixin' : 'Edit mixin'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Modal
        title={
          <div className='da-d-flex-center'>
            <InfoCircle set="curved" />
            &nbsp;Notification
          </div>
        }
        width={500}
        centered
        closable
        destroyOnClose
        visible={isActiveNotificationModal}
        onCancel={handleRedirectToTestDetails}
        footer={[
          <Button
            key="cancel"
            onClick={handleRedirectToTestDetails}
          >
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setToggleNotificationModal(false);
              navigate({
                pathname: urlPageCreateMixinsExperimentMixinTargeting({
                  testId,
                  testName,
                  configId,
                  configName,
                  mixinId: savedMixinId,
                }),
              }, { replace: true });
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div>Do you want to create target for this mixin?</div>
      </Modal>

      <Spin
        spinning={isLoadingTestDetails || isLoadingMixinDetails || isLoadingCreateMixin || isLoadingUpdateMixin}
        tip={isLoadingCreateMixin || isLoadingUpdateMixin ? 'Please wait...' : 'Loading experiment mixin form...'}
      >
        <FormCreateEditExperimentMixin
          initialValues={initialValues}
          configData={configData}
          isEditForm={!isExperimentCreate}
          serverErrors={serverErrors}
          setServerErrors={setServerErrors}
          formErrors={serverErrors}
          onCancel={() => {
            navigate(-1);
          }}
          isSubmitting={isLoadingCreateMixin || isLoadingUpdateMixin}
          onSubmit={handleSubmitFormData}
        />
      </Spin>
    </HasRights>
  );
};

export default PageCreateEditMixinsExperimentMixin;
