import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUrlProjectSettings } from '../../lib/getUrlProjectSettings';
import urlPageTicketDetailsPublic from '../../urls/urlPageTicketDetailsPublic';
import { MAIN_PROJECT_KEY, MAIN_SPACE_KEY } from '../../const/system';
import { getAllUserPolicies } from '../../lib/getAllUserPolicies';
import { useLocalStorage } from '../hooks';



export const UserContext = React.createContext({});


const AppContextProvider = ({ children }) => {
  const [ storedUserData ] = useLocalStorage('user');
  const [ storedTestModeData ] = useLocalStorage('test_mode_params');
  const [ userData, setUserData ] = useState(storedUserData);
  const [ testParams ] = useState(storedTestModeData);
  const [ projectSettings, setProjectSettings ] = useState(getUrlProjectSettings());
  const [ prevProjectSettings, setPrevProjectSettings ] = useState({ spaceKey: null, projectKey: null });

  const userPolicies = userData?.policies ?? [];

  const memoizedValue = useMemo(() => ({
    userData,
    setUserData,
    isAuthenticated: userData?.isAuthenticated,
    userId: userData?.user?.id,
    userName: userData?.user?.name,
    userPolicies,
    userSpaces: userData?.spaces?.data ?? [],
    userPermissions: getAllUserPolicies(userPolicies, projectSettings, 'permissions'),
    projectSettings,
    setProjectSettings,
    prevProjectSettings,
    setPrevProjectSettings,
    testParams,
  }), [ userData, projectSettings ]);


  const url = window.location.pathname;
  const parsedUrl = url.split('/');
  const ticketId = parsedUrl[parsedUrl.length - 1];


  useEffect(() => {
    if (!url.includes(MAIN_SPACE_KEY) && !url.includes(MAIN_PROJECT_KEY) && url.includes('/tickets/')) {
      window.location.replace(`/${MAIN_SPACE_KEY}/${MAIN_PROJECT_KEY}${urlPageTicketDetailsPublic({ ticketId })}`);
    }
  }, [ url ]);


  return (
    <UserContext.Provider value={memoizedValue}>
      {children}
    </UserContext.Provider>
  );
};


AppContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useApp = () => useContext(UserContext);

export default AppContextProvider;
