export default class RolesService {
  constructor (Api) {
    this.api = Api;
  }

  getRoleDetails (roleId) {
    return this.api.get(`roles/${roleId}`);
  }

  createRole (values) {
    return this.api.post('roles', values);
  }

  updateRoleDetails ({ roleId, data }) {
    return this.api.put(`roles/${roleId}`, data);
  }

  getRolePermissions (roleId) {
    return this.api.get(`roles/${roleId}/permissions`);
  }

  updateRolePermissions ({ roleId, permissions }) {
    return this.api.put(`roles/${roleId}/permissions`, { permissions });
  }
}
