import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';



const Error403 = () => {
  const navigate = useNavigate();

  return (
    <Result
      status='403'
      title="403"
      subTitle="Sorry, you have no rights to access this page."
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Go back
        </Button>
      }
    />
  );
};

export default Error403;
