import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import LogsService from '../services/logsService';
import { setUrlParams } from '../../../../lib/setUrlParams';



const initialState = {
  logsList: [],
  logsOptions: {},
  logsPagination: {},
  errors: {},
  loadingLogsList: false,
  loadingDetails: false,
  logDetails: {},
};

const logsService = new LogsService(Api);

export const getLogsListData = createAsyncThunk(
  'logs/getLogsList',
  async (values, { rejectWithValue }) => {
    try {
      const response = await logsService.getLogsList(values);

      if (!isEmpty(values)) {
        setUrlParams(values);
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getLogDetailsData = createAsyncThunk(
  'logs/getLogDetails',
  async (logId) => {
    const response = await logsService.getLogDetails(logId);

    return response.data;
  },
);


export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    resetLogsListData: (state) => {
      state.logsList = [];
      state.logsPagination = {};
      state.logsOptions = {};
    },
    resetLogDetailsData: (state) => {
      state.logDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogsListData.pending, (state) => {
        state.loadingLogsList = true;
      })
      .addCase(getLogsListData.fulfilled, (state, action) => {
        state.loadingLogsList = false;
        state.logsList = action.payload.data;
        state.logsPagination = action.payload.meta.pagination;
        state.logsOptions = action.payload.meta.custom;
      })
      .addCase(getLogsListData.rejected, (state, action) => {
        state.loadingLogsList = false;
        state.errors = action.payload.errors;
      });

    builder
      .addCase(getLogDetailsData.pending, (state) => {
        state.loadingDetails = true;
      })
      .addCase(getLogDetailsData.fulfilled, (state, action) => {
        state.loadingDetails = false;
        state.logDetails = action.payload;
      })
      .addCase(getLogDetailsData.rejected, (state, action) => {
        state.loadingDetails = false;
        state.errors = action.payload;
      });
  },
});

// actions
export const { resetLogsListData, resetLogDetailsData } = logsSlice.actions;

// selectors
export const selectLogsLoading = (state) => state.logs.loadingLogsList;
export const selectLogsList = (state) => state.logs.logsList;
export const selectLogsOptions = (state) => state.logs.logsOptions;
export const selectLogsPagination = (state) => state.logs.logsPagination;
export const selectLogsErrors = (state) => state.logs.errors;
export const selectLogDetailsLoading = (state) => state.logs.loadingDetails;
export const selectLogDetails = (state) => state.logs.logDetails;


// reducer
export default logsSlice.reducer;
