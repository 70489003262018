import React from 'react';
import { Form, Input, DatePicker, SubmitButton, FormItem, Select } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Button, Col, Divider, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import Yup from '../../../../../vendor/yup';
import { getObjectValuesByUrlParams } from '../../../../../lib/getObjectValuesByUrlParams';
import { SEMVER_REGEXP } from '../../../../../const/system';
import { mkSelectFilterOption } from '../../../../../lib/mkSelectFilterOption';



const validationSchema = Yup.object().shape({
  date_start: Yup.string().nullable().required('Start date is required'),
  date_end: Yup.string().nullable().required('End date is required'),
  input_app_versions: Yup.string().nullable()
    .matches(SEMVER_REGEXP, 'Value is doesn`t matches with semver regular expression')
    .max(100, 'Max 100 symbols required'),
  app_versions: Yup.array().of(Yup.string().min(3, 'Min 3 symbols required').required('Required to input app version(s)')),
  user_platform: Yup.string().nullable().required('Required to select user platform'),
  event_first: Yup.string().nullable().required('First param name is required'),
  event_second: Yup.string().nullable().required('Second param name is required'),
  parameter_1_name: Yup.string().nullable(),
  parameter_1_val: Yup.string().nullable(),
  operator: Yup.string().nullable(),
  time_diff: Yup.string().nullable().matches(/^\d+$/, 'Only positive digits is required'),
  parameter_2_name: Yup.string().nullable(),
  parameter_2_val: Yup.string().nullable(),
});


const EventsSequenceFilter = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
  parametersOptions,
  eventsOptions,
}) => {
  return (
    <div className='filter-wr'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        isSubmitting={isSubmitting}
        initialErrors={formErrors}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {(props) => {
          // eslint-disable-next-line react/prop-types
          const { values, isValid, setFieldValue, errors } = props;

          const disabledDate = (current) => {
            return current && current > moment().endOf('day');
          };

          const endDisabledDate = (current) => {
            // eslint-disable-next-line react/prop-types
            const start = moment(values.date_start);
            const endDate = moment(new Date());

            return !(start.isSameOrBefore(current) && endDate.isAfter(current));
          };

          return (
            <Form layout="vertical">
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="date_start"
                  label="Start date"
                  required
                >
                  <DatePicker
                    name='date_start'
                    disabledDate={disabledDate}
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="date_end"
                  label="End date"
                  required
                >
                  <DatePicker
                    name='date_end'
                    disabledDate={endDisabledDate}
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name='app_versions'
                  label='App versions'
                  required
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch={false}
                    name='app_versions'
                    value={values.app_versions}
                    placeholder="Specify app version(s)"
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <FormItem
                            className='ant-form-item-col ant-form-item-custom-option'
                            name='input_app_versions'
                          >
                            <Input
                              fast
                              size='small'
                              style={{ flex: 'auto' }}
                              name='input_app_versions'
                              onKeyDown={(event) => event.stopPropagation()}
                              value={values.input_app_versions}
                              onChange={(event) => {
                                setFieldValue('input_app_versions', event.target.value, true);
                              }}
                            />
                          </FormItem>
                          <Button
                            type="primary"
                            size='small'
                            disabled={
                              (!isEmpty(values.input_app_versions) ?
                                values.app_versions.includes(values.input_app_versions) : false)
                                || (errors ? !isEmpty(errors?.input_app_versions) : false)
                                || isEmpty(values.input_app_versions)
                            }
                            className='ant-form-item-custom-option-btn'
                            onClick={() => {
                              setFieldValue('app_versions', [ ...values.app_versions, values.input_app_versions ], false);
                              setFieldValue('input_app_versions', '', true);
                            }}
                          >
                            <PlusOutlined />
                            App version
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="user_platform"
                  label="Platform"
                  required
                >
                  <Select
                    name="user_platform"
                    placeholder="Select platform"
                  >
                    <Select.Option value="ios">iOS</Select.Option>
                    <Select.Option value="android">Android</Select.Option>
                  </Select>
                </FormItem>
              </Col>

              <Divider orientation="left" style={{ margin: '8px 0', fontSize: '13px', color: 'gray' }}>First event</Divider>
              <Col >
                <FormItem
                  className='ant-form-item-col'
                  name="parameter_1_name"
                  label="Event name"
                  required
                >
                  <Select
                    name="event_first"
                    placeholder="Select event name"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input
                            size='small'
                            style={{ flex: 'auto' }}
                            name='input_event_first'
                            value={values.input_event_first}
                            onChange={(event) => {
                              setFieldValue('event_first', event.target.value, false);
                              setFieldValue('input_event_first', event.target.value, false);
                            }}
                          />
                          <Button
                            type="primary"
                            size='small'
                            style={{ flex: 'none', margin: '4px', display: 'block', cursor: 'pointer' }}
                            onKeyDown={(event) => event.stopPropagation()}
                            onClick={() => {
                              setFieldValue('customEventsOptions', [ ...values.customEventsOptions, values.parameter_1_name ], false);
                              setFieldValue('input_event_first', '', false);
                            }}
                          >
                            <PlusOutlined />
                            Add item
                          </Button>
                        </div>
                      </div>
                    )}
                  >
                    {eventsOptions.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={index} value={option}>
                          {capitalize(option)}
                        </Select.Option>
                      );
                    })}
                    {!isEmpty(values.customEventsOptions) && values.customEventsOptions.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={index} value={option}>
                          {option}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col >
                <FormItem
                  className='ant-form-item-col'
                  name="parameter_1_name"
                  label="Event params"
                  required
                >
                  <Select
                    name="parameter_1_name"
                    placeholder="Select event params"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input
                            style={{ flex: 'auto' }}
                            name='input_parameter_1_name'
                            value={values.input_parameter_1_name}
                            onKeyDown={(event) => event.stopPropagation()}
                            onChange={(event) => {
                              setFieldValue('parameter_1_name', event.target.value, false);
                              setFieldValue('input_parameter_1_name', event.target.value, false);
                            }}
                          />
                          <Button
                            type="primary"
                            size='small'
                            style={{ flex: 'none', margin: '4px', display: 'block', cursor: 'pointer' }}
                            onClick={() => {
                              setFieldValue('customParametersOptions', [ ...values.customParametersOptions, values.parameter_1_name ], false);
                              setFieldValue('input_parameter_1_name', '', false);
                            }}
                          >
                            <PlusOutlined />
                            Add item
                          </Button>
                        </div>
                      </div>
                    )}
                  >
                    {parametersOptions.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={index} value={option}>
                          {capitalize(option)}
                        </Select.Option>
                      );
                    })}
                    {!isEmpty(values.customParametersOptions) && values.customParametersOptions.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={index} value={option}>
                          {option}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col >
                <FormItem
                  className='ant-form-item-col'
                  name="parameter_1_val"
                  label="Value"
                  required
                >
                  <Input
                    name="parameter_1_val"
                    style={{ minWidth: '120px' }}
                    placeholder="Event value"
                  />
                </FormItem>
              </Col>

              <Divider orientation="left" style={{ margin: '8px 0', fontSize: '13px', color: 'gray' }}>Events difference</Divider>
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="operator"
                  label="Operator"
                  required
                >
                  <Select
                    name="operator"
                    placeholder="Select operator"
                  >
                    <Select.Option value="<">Lower than</Select.Option>
                    <Select.Option value=">">Greater than</Select.Option>
                    <Select.Option value="=">Equal</Select.Option>
                    <Select.Option value=">=">Greater or equal than</Select.Option>
                    <Select.Option value="<=">Lower or equal than</Select.Option>
                    <Select.Option value="<>">Not equal</Select.Option>
                  </Select>
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="time_diff"
                  label="Time in seconds"
                >
                  <Input
                    name="time_diff"
                    placeholder="Difference value"
                  />
                </FormItem>
              </Col>

              <Divider orientation="left" style={{ margin: '8px 0', fontSize: '13px', color: 'gray' }}>Second event</Divider>
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="event_second"
                  label="Event name"
                  required
                >
                  <Select
                    name="event_second"
                    placeholder="Select event name"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input
                            style={{ flex: 'auto' }}
                            name='input_event_second'
                            value={values.input_event_second}
                            onKeyDown={(event) => event.stopPropagation()}
                            onChange={(event) => {
                              setFieldValue('event_second', event.target.value, false);
                              setFieldValue('input_event_second', event.target.value, false);
                            }}
                          />
                          <Button
                            type="primary"
                            size='small'
                            style={{ flex: 'none', margin: '4px', display: 'block', cursor: 'pointer' }}
                            onClick={() => {
                              setFieldValue('customEventsOptions', [ ...values.customEventsOptions, values.event_second ], false);
                              setFieldValue('input_event_second', '', false);
                            }}
                          >
                            <PlusOutlined />
                            Add item
                          </Button>
                        </div>
                      </div>
                    )}
                  >
                    {eventsOptions.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={index} value={option}>
                          {capitalize(option)}
                        </Select.Option>
                      );
                    })}
                    {!isEmpty(values.customEventsOptions) && values.customEventsOptions.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={index} value={option}>
                          {option}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="parameter_2_name"
                  label="Event params"
                  required
                >
                  <Select
                    name="parameter_2_name"
                    placeholder="Select event params"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input
                            style={{ flex: 'auto' }}
                            name='input_parameter_2_name'
                            value={values.input_parameter_2_name}
                            onChange={(event) => {
                              setFieldValue('parameter_2_name', event.target.value, false);
                              setFieldValue('input_parameter_2_name', event.target.value, false);
                            }}
                          />
                          <Button
                            type="primary"
                            size='small'
                            style={{ flex: 'none', margin: '4px', display: 'block', cursor: 'pointer' }}
                            onKeyDown={(event) => event.stopPropagation()}
                            onClick={() => {
                              setFieldValue('customParametersOptions', [ ...values.customParametersOptions, values.parameter_2_name ], false);
                              setFieldValue('input_parameter_2_name', '', false);
                            }}
                          >
                            <PlusOutlined />
                            Add item
                          </Button>
                        </div>
                      </div>
                    )}
                  >
                    {parametersOptions.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={index} value={option}>
                          {capitalize(option)}
                        </Select.Option>
                      );
                    })}
                    {!isEmpty(values.customParametersOptions) && values.customParametersOptions.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={index} value={option}>
                          {option}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="parameter_2_val"
                  label="Value"
                  required
                >
                  <Input
                    name="parameter_2_val"
                    placeholder="Event value"
                  />
                </FormItem>
              </Col>

              <Row style={{ textAlign: 'center' }}>
                <Col span={24}>
                  <SubmitButton
                    style={{ marginTop: '10px' }}
                    loading={isSubmitting}
                    disabled={!isValid || (isEqual(getObjectValuesByUrlParams(), values))}
                  >
                    Search
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

EventsSequenceFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  eventsOptions: PropTypes.array,
  parametersOptions: PropTypes.array,
  formErrors: PropTypes.object,
  errors: PropTypes.shape({
    date_start: PropTypes.string,
    date_end: PropTypes.string,
    app_versions: PropTypes.string,
    user_platform: PropTypes.string,
    event_first: PropTypes.string,
    parameter_1_name: PropTypes.string,
    parameter_1_val: PropTypes.string,
    event_second: PropTypes.string,
    parameter_2_name: PropTypes.string,
    parameter_2_val: PropTypes.string,
    operator: PropTypes.string,
    time_diff: PropTypes.string,
    input_app_versions: PropTypes.array,
  }),
  values: PropTypes.shape({
    customEventsOptions: PropTypes.array,
    input_event_second: PropTypes.string,
    input_event_first: PropTypes.string,
    event_second: PropTypes.string,
    parameter_1_name: PropTypes.string,
    parameter_2_name: PropTypes.string,
    customParametersOptions: PropTypes.array,
    app_versions: PropTypes.array,
    input_app_versions: PropTypes.array,
    input_parameter_1_name: PropTypes.string,
    input_parameter_2_name: PropTypes.string,
    parameter_1_val: PropTypes.string,
  }),
  touched: PropTypes.shape({
    date_start: PropTypes.string,
    date_end: PropTypes.string,
    app_versions: PropTypes.string,
    user_platform: PropTypes.string,
    parameter_1_name: PropTypes.string,
    event_first: PropTypes.string,
    parameter_1_val: PropTypes.string,
    event_second: PropTypes.string,
    parameter_2_name: PropTypes.string,
    parameter_2_val: PropTypes.string,
    time_diff: PropTypes.string,
    operator: PropTypes.string,
  }),
};

EventsSequenceFilter.defaultProps = {
  formErrors: {},
  eventsOptions: [],
  parametersOptions: [],
};

export default EventsSequenceFilter;
