import { setRequestParams } from '../../../lib/setRequestParams';



export default class AppSettingsService {
  constructor (Api) {
    this.api = Api;
  }

  getTestModeUsersList (params) {
    return this.api.getTest(`users${setRequestParams(params)}`);
  }
}
