import { setRequestParams } from '../../../lib/setRequestParams';



export default class ConfigurationDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  getDetails (configurationId) {
    return this.api.get(`configs/${configurationId}`);
  }

  updateDetails ({ configurationId, data }) {
    return this.api.put(`configs/${configurationId}`, data);
  }

  getConfigTargetDetails (configurationId) {
    return this.api.get(`configs/${configurationId}/targets`);
  }

  createConfigTargeting ({ configurationId, data }) {
    return this.api.post(`configs/${configurationId}/targets`, data);
  }

  updateConfigTargeting ({ configurationId, targetingId, data }) {
    return this.api.put(`configs/${configurationId}/targets/${targetingId}`, data);
  }

  deleteConfigTargeting ({ configurationId, targetingId }) {
    return this.api.delete(`configs/${configurationId}/targets/${targetingId}`);
  }

  createConfigMixin ({ data }) {
    return this.api.post('configs/mixins', data);
  }

  getConfigMixinsList ({ configurationId, params }) {
    return this.api.get(`configs/${configurationId}/mixins${setRequestParams(params)}`);
  }

  deleteConfigMixin ({ mixinId }) {
    return this.api.delete(`configs/mixins/${mixinId}`);
  }

  getAbTests () {
    return this.api.get('ab-tests?limit=-1&status[0]=draft&status[1]=active&status[2]=paused');
  }

  publishConfiguration (configurationId) {
    return this.api.put(`configs/${configurationId}/publish`, {});
  }
}
