import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Row, Spin, Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import HasRights from '../../../../components/HasRights';
import { Permissions } from '../../../../const/permissions';
import urlPageConfigVisualize from '../../../../urls/urlPageConfigVisualize';
import { firstStepDefaultValues } from './PageCreateConfigVisualize.const';
import FormCreateConfigVisualize from '../../forms/FormCreateConfigVisualize';
import FormCreateConfigVisualizeStep2 from '../../forms/FormCreateConfigVisualizeStep2';
import FormCreateConfigVisualizeStep3 from '../../forms/FormCreateConfigVisualizeStep3';
import {
  createConfigRequest,
  getRequestConfigPreview,
  getVisualizedServerConfigRequest,
  saveCreatedConfigRequest,
  selectConfigRequestPreview,
  selectConfigRequestPreviewLoading,
  selectCreateRequestErrors,
  selectCreateRequestLoading,
  selectSaveCreatedRequestLoading,
  selectVisualizedServerData,
  selectVisualizedServerDataLoading,
} from '../../feature/configurationVisualizeSlice';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Step } = Steps;

const PageCreateConfigVisualize = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { projectSettings } = useApp();
  const { requestId } = useParams();
  const navigate = useNavigate();
  const isViewDetails = pathname.endsWith('details');
  const [ currentStep, setCurrentStep ] = useState(isViewDetails ? 1 : 0);
  const [ itemId, setItemId ] = useState(null);
  const [ firstStepValues, setFirstStepValues ] = useState({});

  const isLoadingFirstStep = useSelector(selectCreateRequestLoading);
  const firstStepFormErrors = useSelector(selectCreateRequestErrors);
  const isLoadingPreviewJSON = useSelector(selectConfigRequestPreviewLoading);
  const previewJSON = useSelector(selectConfigRequestPreview);
  const isLoadingSaveRequest = useSelector(selectSaveCreatedRequestLoading);
  const isLoadingServerConfigData = useSelector(selectVisualizedServerDataLoading);
  const serverConfigData = useSelector(selectVisualizedServerData);


  useEffect(() => {
    if (isViewDetails) {
      setItemId(requestId);
      dispatch(getRequestConfigPreview(requestId));
    }
  }, [ requestId ]);

  const handleGoSecondStep = async (values) => {
    const preparedValues = getNotEmptyObjectValues(values);

    await dispatch(createConfigRequest(preparedValues)).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        dispatch(getRequestConfigPreview(response.payload.data?.uuid));
        setItemId(response.payload.data?.uuid);
        setCurrentStep(currentStep + 1);
      }
    });

    setFirstStepValues(values);
  };

  const handleGoThirdStep = async (values) => {
    if (values.is_saved) {
      await dispatch(saveCreatedConfigRequest({ name: values.name, uuid: itemId })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          setItemId(response.payload?.id);
        }
      });
    }
    setCurrentStep(currentStep + 1);
  };

  const handleGetServerConfig = (server) => {
    if (server !== null) {
      dispatch(getVisualizedServerConfigRequest({ server, id: itemId }));
    }
  };

  const handleGoPrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCancel = () => {
    setFirstStepValues({});
    setItemId(null);
    navigate(-1);
  };

  const handleViewRequestsList = () => {
    navigate(urlPageConfigVisualize());
  };


  const steps = [
    {
      ...!isViewDetails && {
        key: 1,
        title: 'Create request form',
        content: (
          <Spin spinning={isLoadingFirstStep} tip="Loading form. Please wait...">
            <FormCreateConfigVisualize
              initialValues={!isEmpty(firstStepValues) ? firstStepValues : firstStepDefaultValues}
              formErrors={firstStepFormErrors}
              isSubmitting={isLoadingFirstStep}
              onCancel={handleCancel}
              onSubmit={handleGoSecondStep}
            />
          </Spin>
        ),
      },
    },
    {
      key: 2,
      title: 'Check',
      content: (
        <Spin spinning={isLoadingPreviewJSON} tip={LOADING_TIP_PLACEHOLDER}>
          <FormCreateConfigVisualizeStep2
            initialValues={{ is_saved: false }}
            isDetails={isViewDetails}
            isSubmitting={isLoadingSaveRequest}
            previewJSON={previewJSON}
            onCancel={isViewDetails ? handleViewRequestsList : handleGoPrevStep}
            onSubmit={handleGoThirdStep}
          />
        </Spin>
      ),
    },
    {
      key: 3,
      title: 'View Config',
      content: (
        <Spin spinning={isLoadingSaveRequest} tip={LOADING_TIP_PLACEHOLDER}>
          <FormCreateConfigVisualizeStep3
            initialValues={{ server: null }}
            isLoading={isLoadingServerConfigData}
            serverConfigData={serverConfigData}
            handleGetServerConfig={handleGetServerConfig}
            onCancel={handleGoPrevStep}
          />
        </Spin>
      ),
    },
  ];


  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIG_VISUALIZE.CREATE, Permissions.CONFIGS.CONFIG_VISUALIZE.VIEW_LIST_AND_DETAILS ]}>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={urlPageConfigVisualize()}>Request list</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{isViewDetails ? 'Details' : 'Create'}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Steps current={currentStep}>
            {steps.map((item) => (
              <Step key={item.key} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {steps[currentStep].content}
          </div>
        </Card>
      </div>
    </HasRights>
  );
};

export default PageCreateConfigVisualize;
