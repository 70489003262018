import { Breadcrumb, Spin } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import urlPageMixinsExperimentDetails from '../../../../urls/urlPageMixinsExperimentDetails';
import urlPageMixinsExperiments from '../../../../urls/urlPageMixinsExperiments';
import FormCreateEditExperimentTargeting from '../../forms/FormCreateEditExperimentTargeting';
import HasRights from '../../../../components/HasRights';
import { Permissions } from '../../../../const/permissions';
import { useApp } from '../../../../app/context/AppContext';
import getUserIP from '../../../../lib/getUserIP';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  getPreparedFormData,
  predefinedFormCreateInitialValues,
  preparedToRequestFormData,
} from './PageCreateEditMixinsExperimentTargeting.const';
import {
  getAbTestsListData,
  resetAbTestsListData,
  selectFormConfigTargetingAbTests,
  selectFormConfigTargetingAbTestsLoading,
} from '../../../Configurations/feature/configurationDetailsSlice';
import {
  getExperimentDetails,
  resetExperimentDetailsData,
  selectExperimentDetailsData,
  selectExperimentDetailsLoading,
} from '../../feature/mixinsExperimentDetailsSlice';
import {
  createExperimentMixinTargeting,
  getExperimentMixinTargetDetails,
  updateExperimentMixinTargeting,
  resetExperimentMixinTargetDetailsData,
  resetExperimentMixinDetailsData,
  selectCreateExperimentMixinTargetingErrors,
  selectCreateExperimentMixinTargetingLoading,
  selectExperimentMixinTargetDetails,
  selectExperimentMixinTargetDetailsLoading,
  selectUpdateExperimentMixinTargetingErrors,
  selectUpdateExperimentMixinTargetingLoading,
} from '../../feature/mixinExperimentMixinDetailsSlice';



const PageCreateEditMixinsExperimentTargeting = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { projectSettings, userName } = useApp();
  const navigate = useNavigate();
  const { pathname } = location;
  const { testId, testName, configName, targetingId } = useParams();
  const [ mixinId, setMixinId ] = useState(null);
  const storedUserIP = getUserIP();
  const isCreateConfigTargeting = pathname.endsWith('create');

  const createTargetingFormLoading = useSelector(selectCreateExperimentMixinTargetingLoading);
  const createTargetingFormErrors = useSelector(selectCreateExperimentMixinTargetingErrors);
  const updateTargetingFormLoading = useSelector(selectUpdateExperimentMixinTargetingLoading);
  const updateTargetingFormErrors = useSelector(selectUpdateExperimentMixinTargetingErrors);
  const configTargetingDetails = useSelector(selectExperimentMixinTargetDetails);
  const isLoadingConfigTargeting = useSelector(selectExperimentMixinTargetDetailsLoading);

  const testDetails = useSelector(selectExperimentDetailsData);
  const isLoadingTestDetails = useSelector(selectExperimentDetailsLoading);
  const abTestsList = useSelector(selectFormConfigTargetingAbTests);
  const isLoadingAbTestsList = useSelector(selectFormConfigTargetingAbTestsLoading);


  useEffect(() => {
    dispatch(getAbTestsListData());
    dispatch(getExperimentDetails(testId)).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        setMixinId(response.payload.mixin_id);
      }
    });

    return () => {
      dispatch(resetExperimentMixinDetailsData());
      dispatch(resetAbTestsListData());
      dispatch(resetExperimentDetailsData());
      dispatch(resetExperimentMixinTargetDetailsData());
    };
  }, []);


  useEffect(() => {
    if (mixinId !== null) {
      dispatch(getExperimentMixinTargetDetails({ testId, mixinId }));
    }
    return () => {
      dispatch(resetExperimentMixinTargetDetailsData());
    };
  }, [ mixinId, testId ]);


  const handleRedirectToConfigDetails = (response) => {
    if (isFulfilledRequestStatus(response)) {
      navigate(urlPageMixinsExperimentDetails({ testId, testName }));
    }
  };

  const handleSubmitFormData = async (values) => {
    window.scrollTo(0, 0);
    const data = preparedToRequestFormData({ ...values, experiment_mixin_id: testId });

    if (isCreateConfigTargeting) {
      await dispatch(createExperimentMixinTargeting({ testId, mixinId, data })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          handleRedirectToConfigDetails(response);
        }
      });
    } else {
      await dispatch(updateExperimentMixinTargeting({ testId, mixinId, targetingId, data })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          handleRedirectToConfigDetails(response);
        }
      });
    }
  };

  const initialValues = !isCreateConfigTargeting ?
    getPreparedFormData(configTargetingDetails, testName, testDetails) :
    predefinedFormCreateInitialValues({ configName, authorName: userName });

  return (
    <HasRights allowedPermissions={[ Permissions.MIXINS_EXPERIMENTS.CREATE_TARGET, Permissions.MIXINS_EXPERIMENTS.UPDATE ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageMixinsExperiments()}>
            Mixins experiments
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageMixinsExperimentDetails({ testId, testName })}>
            Details
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {`${isCreateConfigTargeting ? 'Create' : 'Edit'} targeting`}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin
        spinning={(createTargetingFormLoading || updateTargetingFormLoading || isLoadingConfigTargeting || isLoadingAbTestsList || isLoadingTestDetails) && !isEmpty(storedUserIP)}
        tip="Loading experiment targeting form..."
      >
        <FormCreateEditExperimentTargeting
          initialValues={initialValues}
          isEditForm={!isCreateConfigTargeting}
          formErrors={createTargetingFormErrors || updateTargetingFormErrors}
          abTestsList={abTestsList}
          userIP={storedUserIP}
          onCancel={() => {
            navigate(-1);
          }}
          isSubmitting={createTargetingFormLoading || updateTargetingFormLoading || isLoadingConfigTargeting}
          onSubmit={handleSubmitFormData}
        />
      </Spin>
    </HasRights>
  );
};

export default PageCreateEditMixinsExperimentTargeting;

