export default class MixinExperimentMixinDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  updateExperimentMixinDetails ({ testId, mixinId, data }) {
    return this.api.put(`experiments-mixins/${testId}/mixins/${mixinId}`, data);
  }

  getExperimentMixinDetails ({ testId, mixinId }) {
    return this.api.get(`experiments-mixins/${testId}/mixins/${mixinId}?include=test`);
  }

  getExperimentMixinTargetDetails ({ testId, mixinId }) {
    return this.api.get(`experiments-mixins/${testId}/mixins/${mixinId}/targets`);
  }

  createExperimentMixinTargeting ({ testId, mixinId, data }) {
    return this.api.post(`experiments-mixins/${testId}/mixins/${mixinId}/targets`, data);
  }

  updateExperimentMixinTargeting ({ testId, mixinId, targetingId, data }) {
    return this.api.put(`experiments-mixins/${testId}/mixins/${mixinId}/targets/${targetingId}`, data);
  }
}
