export default class MixinsExperimentDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  getExperimentDetails (testId) {
    return this.api.get(`experiments-mixins/${testId}?include=mixin,config,user,history`);
  }

  updateExperimentDetails ({ testId, data }) {
    return this.api.put(`experiments-mixins/${testId}`, data);
  }

  getExperimentTargetDetails (testId) {
    return this.api.get(`configs/${testId}/targets`);
  }

  createExperimentMixin ({ testId, data }) {
    return this.api.post(`experiments-mixins/${testId}/mixins`, data);
  }

  publishToSandbox ({ testId, data }) {
    return this.api.post(`experiments-mixins/${testId}/publish`, data);
  }

  toggleExperimentStatus ({ testId, data }) {
    return this.api.post(`experiments-mixins/${testId}/status`, data);
  }

  toggleAssignExperiment ({ testId, data }) {
    return this.api.post(`experiments-mixins/${testId}/assign`, data);
  }

  togglePauseExperiment ({ testId, data }) {
    return this.api.post(`experiments-mixins/${testId}/pause`, data);
  }
}
