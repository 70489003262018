import moment from 'moment';



export const CREATED_AT = 'created_at';
export const UPDATED_AT = 'updated_at';

export const getColumnSorting = (date1, date2, fieldName) => {
  return moment(date1[fieldName]) >= moment(date2[fieldName]) ? 1 : -1;
};

