import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined } from '@ant-design/icons';



const TErrorFieldNotice = styled.div`
  display: flex;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: #ff5636; 
  margin: 6px 0 0 2px;
`;

const TErrorFieldNoticeIconWr = styled.div`
  padding: 0 5px 0 0;
`;

const ErrorBoundary = ({ message, defaultMessage, disabled }) => {
  message = Array.isArray(message) ? message[0] : message;

  message = !message || isEmpty(message) ? defaultMessage : message;

  if (disabled || !message) {
    return null;
  }

  return (
    <TErrorFieldNotice>
      <TErrorFieldNoticeIconWr>
        <ExclamationCircleOutlined />
      </TErrorFieldNoticeIconWr>
      {message}
    </TErrorFieldNotice>
  );
};

ErrorBoundary.propTypes = {
  message: PropTypes.oneOfType([ PropTypes.shape({}), PropTypes.array, PropTypes.string ]),
  defaultMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  message: null,
  defaultMessage: null,
  disabled: false,
};

export default ErrorBoundary;
