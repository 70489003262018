import { setRequestParams } from '../../../lib/setRequestParams';



export default class MixinsExperimentsService {
  constructor (Api) {
    this.api = Api;
  }

  getExperimentsList (params) {
    return this.api.get(`experiments-mixins${setRequestParams(params)}&include=config`);
  }

  createExperiment (values) {
    return this.api.post('experiments-mixins', values);
  }

  archiveExperiment ({ testId }) {
    return this.api.post(`experiments-mixins/${testId}/archive`);
  }
}
