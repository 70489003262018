import React from 'react';
import { Button, Popconfirm, Row, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { getDefaultTableSorting } from '../../../../../lib/getDefaultTableSorting';
import { hasRights, Permissions } from '../../../../../const/permissions';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../../const/system';
import urlPageSpaceProjects from '../../../../../urls/urlPageSpaceProjects';



export const defaultModulePack = '{\n' +
  '    "modules": [\n' +
  '        "config",\n' +
  '        "ab-tests",\n' +
  '        "experiments"\n' +
  '    ],\n' +
  '    "sandbox": {\n' +
  '        "time_block_sandbox": 30,\n' +
  '        "time_to_check_experiment_before_checking": 30,\n' +
  '        "time_to_check_experiment_before_review": 30,\n' +
  '        "time_to_check_own_experiment_before_review": 30\n' +
  '    }\n' +
  '}';

export const geSpacesTableColumns = (handleDeleteSpace, showCreateEditSpacesModal) => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '10%',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
    render: (item) => item,
  },
  {
    title: 'Key',
    align: 'center',
    width: '10%',
    render: (item) => item.key,
  },
  {
    title: 'Name',
    align: 'center',
    render: (item) => item.name,
  },
  {
    title: 'Author',
    align: 'center',
    render: (item) => <div>{item?.user?.name || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('updated_at'),
  },
  ...(hasRights([ Permissions.AB_TESTS.VIEW_PAGE, Permissions.ADMINISTRATION.SPACES.UPDATE, Permissions.ADMINISTRATION.SPACES.DELETE ]) ? [
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '15%',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            {item.is_removable && hasRights([ Permissions.ADMINISTRATION.SPACES.DELETE ]) && (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteSpace(item.id)}>
                <Tooltip placement="top" title='Delete space'>
                  <Button
                    size="small"
                    type='primary'
                    danger
                    className='btn-with-side-margin'
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            {hasRights([ Permissions.ADMINISTRATION.SPACES.UPDATE ]) && (
              <Tooltip placement="top" title='Edit space'>
                <Button
                  size="small"
                  type='primary'
                  className='btn-with-side-margin warning-btn'
                  onClick={() => {
                    showCreateEditSpacesModal(item);
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}
            {hasRights([ Permissions.ADMINISTRATION.PROJECTS.VIEW_LIST ]) && (
              <Tooltip placement="top" title='View projects list'>
                <Button size="small" type='primary' className='btn-with-side-margin'>
                  <Link to={urlPageSpaceProjects({ spaceId: item.id })}>
                    <OrderedListOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];


export const transpileModuleErrors = (error) => {
  if (!isEmpty(error)) {
    const errors = Object.values(error?.config?.modules || {})
      .flatMap((moduleErrors) => Array.isArray(moduleErrors) ? moduleErrors : []);

    return { config: errors };
  }
  return undefined;
};
