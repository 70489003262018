export const getQueryParams = (query) => {
  const params = {};

  new URLSearchParams(query).forEach((value, key) => {
    let decodedKey = decodeURIComponent(key);

    const decodedValue = decodeURIComponent(value);

    if (decodedKey.endsWith('[]')) {
      decodedKey = decodedKey.replace('[]', '');
      // eslint-disable-next-line no-unused-expressions
      params[decodedKey] || (params[decodedKey] = []);
      params[decodedKey].push(decodedValue);
    } else {
      params[decodedKey] = decodedValue;
    }
  });

  return params;
};
