import { setRequestParams } from '../../../../lib/setRequestParams';



export default class RolesService {
  constructor (Api) {
    this.api = Api;
  }

  getRolesList (params) {
    return this.api.get(`roles${setRequestParams(params)}`);
  }

  deleteRole (roleId) {
    return this.api.delete(`roles/${roleId}`);
  }
}
