import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';
import { AB_TEST_STATUS_MAP } from '../PageAbTests/PageAbTests.const';
import { mapToArrayOfDates } from '../../../../lib/mapToArrayOfDates';



export const AB_TEST_INITIAL_CONDITION_FIELD_VALUES = {
  condition_type: null,
  device_models: [],
  platforms: [],
  os_versions_min: null,
  os_versions_max: null,
  os_versions_list: [],
  app_versions_min: null,
  app_versions_max: null,
  app_versions_list: [],
  ram_min: null,
  ram_max: null,
  ram_list: [],
  device_performance_min: null,
  device_performance_max: null,
  device_performance_list: [],
  install_dates_min: null,
  install_dates_max: null,
  install_dates_special: { seconds: '', statement: '' },
  install_dates_list: [],
  law: [],
  lat: null,
  users: { types: [], list: [] },
};

export const CREATE_AB_TEST_INITIAL_VALUES = {
  status: AB_TEST_STATUS_MAP.DRAFT,
  priority: 1,
  percent: 100,
  groups: [
    { name: '0', percent: 50 },
    { name: '1', percent: 50 },
  ],
  conditions: [ AB_TEST_INITIAL_CONDITION_FIELD_VALUES ],
};

export const getPreparedFormData = (values) => {
  return {
    ...values,
    created_at: values?.created_at ? moment(values.created_at).format('YYYY-MM-DD HH:mm:ss') : null,
    started_at: values?.started_at ? moment(values.started_at).format('YYYY-MM-DD HH:mm:ss') : null,
    finished_at: values?.finished_at ? moment(values.finished_at).format('YYYY-MM-DD HH:mm:ss') : null,
    conditions: !isEmpty(values?.conditions) ? values.conditions.map((condition) => {
      return {
        ...condition,
        lat: condition?.lat ?? null,
        countries: condition.countries ? condition.countries.map((country) => country.toUpperCase()) : [],
        os_versions_min: condition?.os_versions?.min ?? '',
        os_versions_max: condition?.os_versions?.max ?? '',
        os_versions_list: condition?.os_versions?.list ?? [],
        ram_min: condition?.ram?.min ?? null,
        ram_max: condition?.ram?.max ?? null,
        ram_list: condition?.ram?.list ?? [],
        device_performance_min: condition?.device_performance?.min ?? null,
        device_performance_max: condition?.device_performance?.max ?? null,
        device_performance_list: condition?.device_performance?.list ?? [],
        app_versions_min: condition?.app_versions?.min ?? '',
        app_versions_max: condition?.app_versions?.max ?? '',
        app_versions_list: condition?.app_versions?.list ?? [],
        install_dates_min: condition.install_dates?.min ? moment.utc(condition.install_dates.min).local() : null,
        install_dates_max: condition.install_dates?.max ? moment.utc(condition.install_dates.max).local() : null,
        install_dates_list: condition.install_dates?.list ? mapToArrayOfDates(condition.install_dates.list) : [],
        install_dates_special: condition.install_dates?.special ? {
          seconds: condition.install_dates?.special ? condition.install_dates.special[0] : '',
          statement: condition.install_dates?.special ? condition.install_dates.special[1] : '',
        } : { seconds: '', statement: '' },
      };
    }) : [],
  };
};

export const preparedToRequestFormData = (values) => {
  return {
    affiliation: values.affiliation,
    description: values.description,
    name: values.name,
    status: values.status,
    groups: values.groups.map((group) => {
      return {
        name: group.name,
        percent: Number(group.percent),
      };
    }),
    link: values?.link ?? null,
    created_at: null,
    started_at: null,
    finished_at: null,
    percent: Number(values.percent),
    priority: Number(values.priority),
    conditions: !isEmpty(values.conditions) ? values.conditions.map((condition) => {
      const installDatesFilledValues = condition.install_dates_list ? Object.values(getNotEmptyObjectValues(condition.install_dates_list)) : [];

      return {
        condition_type: condition.condition_type,
        custom_target: !isEmpty(condition?.custom_target) ? condition.custom_target : null,
        device_types: condition?.device_types ?? [],
        device_manufactures: condition?.device_manufactures ?? [],
        device_models: condition?.device_models ?? [],
        countries: condition.countries ? condition.countries.map((country) => country.toLowerCase()) : [],
        languages: condition?.languages ?? [],
        platforms: condition?.platforms ?? [],
        ram: getNotEmptyObjectValues({
          min: condition?.ram_min ?? null,
          max: condition?.ram_max ?? null,
          list: condition?.ram_list ?? null,
        }),
        device_performance: getNotEmptyObjectValues({
          min: condition?.device_performance_min ?? null,
          max: condition?.device_performance_max ?? null,
          list: condition?.device_performance_list ?? null,
        }),
        os_versions: getNotEmptyObjectValues({
          min: condition?.os_versions_min ?? null,
          max: condition?.os_versions_max ?? null,
          list: condition?.os_versions_list ?? null,
        }),
        app_versions: getNotEmptyObjectValues({
          min: condition?.app_versions_min ?? null,
          max: condition?.app_versions_max ?? null,
          list: condition?.app_versions_list ?? null,
        }),
        install_dates: getNotEmptyObjectValues({
          min: condition.install_dates_min ? moment(condition.install_dates_min).utc().format('YYYY-MM-DDTHH:mm:ssZ') : null,
          max: condition.install_dates_max ? moment(condition.install_dates_max).utc().format('YYYY-MM-DDTHH:mm:ssZ') : null,
          special: condition?.install_dates_special.seconds || condition?.install_dates_special.statement ? [ condition.install_dates_special.seconds || '', condition.install_dates_special.statement || '' ] : [],
          list: installDatesFilledValues.map((item) => {
            return {
              from: item[0] ? moment(item[0]).utc().format('YYYY-MM-DDTHH:mm:ssZ') : null,
              to: item[1] ? moment(item[1]).utc().format('YYYY-MM-DDTHH:mm:ssZ') : null,
            };
          }),
        }),
        law: condition?.law ?? [],
        lat: condition?.lat ?? null,
        users: getNotEmptyObjectValues({
          types: condition?.users?.types ?? [],
          list: condition?.users?.list ?? [],
        }),
      };
    }) : [],
  };
};
