import { Button, Popconfirm, Row, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { hasRights, Permissions } from '../../../../../const/permissions';
import urlPageEditProject from '../../../../../urls/urlPageEditProject';
import urlPageSpaceEditProject from '../../../../../urls/urlPageSpaceEditProject';
import urlPageSettingsEditProject from '../../../../../urls/urlPageSettingsEditProject';
import { getDefaultTableSorting } from '../../../../../lib/getDefaultTableSorting';
import { EMPTY_VALUE_PLACEHOLDER, GLOBAL_SETTINGS_PREFIX, renderDateField } from '../../../../../const/system';



export const getProjectsListColumns = (handleDeleteConfiguration, spaceId, settingsScope) => [
  {
    title: 'ID',
    align: 'center',
    width: '7%',
    dataIndex: 'id',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('id'),
  },
  {
    title: 'Name',
    align: 'center',
    render: (item) => item.name || EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Key',
    align: 'center',
    render: (item) => item.key || EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Package name',
    align: 'center',
    render: (item) => item.package_name || EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: 'Author',
    align: 'center',
    render: (item) => <div>{item?.created_by?.name || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('updated_at'),
  },
  ...(hasRights([
    Permissions.ADMINISTRATION.PROJECTS.DELETE,
    Permissions.ADMINISTRATION.PROJECTS.UPDATE,
    Permissions.ADMINISTRATION.PROJECTS.VIEW_DETAILS,
  ]) ?
    [ {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '15%',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            {item.is_removable && hasRights([ Permissions.ADMINISTRATION.PROJECTS.DELETE ]) && (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteConfiguration(item.id)}>
                <Tooltip placement="top" title='Delete project'>
                  <Button
                    size="small"
                    type='primary'
                    danger
                    className='btn-with-side-margin'
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            {hasRights([
              Permissions.ADMINISTRATION.PROJECTS.VIEW_DETAILS,
              Permissions.ADMINISTRATION.PROJECTS.UPDATE,
            ], true) && (
              <Tooltip placement="top" title='Edit project'>
                <Button
                  size="small"
                  type='primary'
                  className='btn-with-side-margin warning-btn'
                >
                  <Link
                    to={!isEmpty(spaceId) ? urlPageSpaceEditProject({ spaceId, projectId: item.id }) : settingsScope === GLOBAL_SETTINGS_PREFIX
                      ? urlPageEditProject({ projectId: item.id })
                      : urlPageSettingsEditProject({ projectId: item.id })}
                  >
                    <EditOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
