import { setRequestParams } from '../../../lib/setRequestParams';



export default class TicketsService {
  constructor (Api) {
    this.api = Api;
  }

  getTicketsList (params) {
    return this.api.get(`tickets${setRequestParams(params)}`);
  }
}
