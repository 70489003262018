import React from 'react';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Col, Row, Tooltip } from 'antd';
import capitalize from 'lodash/capitalize';
import { RiInformationLine } from 'react-icons/ri';
import Yup from '../../../../../../vendor/yup';
import { hasRights, Permissions } from '../../../../../../const/permissions';
import { APP_ENVIRONMENTS } from '../../../../../../const/system';



const { Option } = Select;

const FormCreateEditRoleButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditRoleButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Min 2 symbols required')
    .max(20, 'Max 20 symbols required')
    .required('Name field is required'),
  display_name: Yup.string()
    .min(2, 'Min 2 symbols required')
    .max(100, 'Max 100 symbols required')
    .required('Display name field is required'),
  description: Yup.string()
    .max(191, 'Max 191 symbols required')
    .required('Description field is required'),
  level: Yup.number().typeError('Specify a number')
    .min(1, 'Should be positive number')
    .max(99, 'Max value is 99')
    .required('Level field is required'),
  environment: Yup.array().nullable().required('Environment field is required'),
});

const FormCreateEditRole = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
  isEditDisabled,
  handleSetFormEdit,
  isRoleEdit,
  isGlobalScope,
  handleResetEditFormErrors,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, values, resetForm, dirty } = props;
        // eslint-disable-next-line react/prop-types
        const canEditRole = !!(isGlobalScope || (!isGlobalScope && initialValues?.space_id));

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Row gutter={!isRoleEdit ? [ 16, 16 ] : []}>
              <Col span={isRoleEdit ? 24 : 12}>
                <FormItem
                  className='ant-form-item-col'
                  name="name"
                  label="Name:"
                  required
                >
                  <Input
                    name='name'
                    placeholder='Specify role name'
                    disabled={isEditDisabled || values.is_secured}
                    onChange={(event) => {
                      setFieldValue('name', event.target.value.replace(/\s/g, ''));
                    }}
                  />
                </FormItem>

                <FormItem
                  className='ant-form-item-col'
                  name="display_name"
                  label="Display name:"
                  required
                >
                  <Input
                    name='display_name'
                    placeholder='Specify role display name'
                    disabled={isEditDisabled}
                  />
                </FormItem>
              </Col>

              <Col span={isRoleEdit ? 24 : 12}>
                <FormItem
                  className='ant-form-item-col'
                  name="level"
                  label={
                    <>
                      <div>Level:</div>
                      <div className='content-right'>
                        <Tooltip
                          placement="top"
                          title="Role access level. Level 1 is the highest, level 99 is the lowest.
                            You cannot create users with roles less than yours."
                        >
                          <RiInformationLine size={16} />
                        </Tooltip>
                      </div>
                    </>
                  }
                  required
                >
                  <Input
                    type='number'
                    name='level'
                    placeholder='Specify role level'
                    disabled={isEditDisabled}
                  />
                </FormItem>

                <FormItem
                  className='ant-form-item-col'
                  name="environment"
                  label="Environment:"
                  required
                >
                  <Select
                    mode='multiple'
                    allowClear
                    showSearch
                    name="environment"
                    value={values?.environment}
                    disabled={isEditDisabled}
                    placeholder="Select environments"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  >
                    {Object.values(APP_ENVIRONMENTS).filter((item) => {
                      if (isGlobalScope) {
                        return item;
                      }

                      return item !== APP_ENVIRONMENTS.GLOBAL;
                    })
                      .map((item) => {
                        return (
                          <Option key={item} value={item}>{capitalize(item)}</Option>
                        );
                      })}
                  </Select>
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col'
                  name="description"
                  label="Description:"
                  required
                >
                  <Input.TextArea
                    name='description'
                    disabled={isEditDisabled}
                    placeholder='Specify role description'
                    rows={5}
                    showCount
                    maxLength={191}
                  />
                </FormItem>
              </Col>
            </Row>

            {hasRights([ isRoleEdit ?
              Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.EDIT_ROLE_DETAILS :
              Permissions.ADMINISTRATION.ROLES.ROLES_LIST.CREATE_ROLE ]) && (
              <FormCreateEditRoleButtonsRow>
                {isRoleEdit && canEditRole && (
                  <FormCreateEditRoleButtonWr>
                    <Button
                      style={{ width: '100%' }}
                      type='secondary'
                      onClick={() => {
                        resetForm();
                        handleSetFormEdit(!isEditDisabled);

                        if (isRoleEdit) {
                          handleResetEditFormErrors();
                        }
                      }}
                    >
                      {isEditDisabled ? 'Edit' : 'Cancel'}
                    </Button>
                  </FormCreateEditRoleButtonWr>
                )}
                {!isEditDisabled && (
                  <FormCreateEditRoleButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      loading={isSubmitting}
                      disabled={!isValid || !dirty}
                    >
                      {isRoleEdit ? 'Update' : 'Create'}
                    </SubmitButton>
                  </FormCreateEditRoleButtonWr>
                )}
              </FormCreateEditRoleButtonsRow>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditRole.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSetFormEdit: PropTypes.func,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.array,
  values: PropTypes.shape({
    is_secured: PropTypes.bool.isRequired,
    environment: PropTypes.array.isRequired,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  isEditDisabled: PropTypes.bool,
  isRoleEdit: PropTypes.bool,
  isGlobalScope: PropTypes.bool,
  handleResetEditFormErrors: PropTypes.func,
};

FormCreateEditRole.defaultProps = {
  formErrors: [],
  isEditDisabled: false,
  isRoleEdit: false,
  isGlobalScope: false,
  handleSetFormEdit: () => {},
  handleResetEditFormErrors: () => {},
};

export default FormCreateEditRole;
