import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import RolesService from '../services/rolesService';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';
import { setUrlParams } from '../../../../lib/setUrlParams';



const initialState = {
  rolesList: [],
  rolesPagination: {},
  errors: [],
  loading: false,
};

const rolesService = new RolesService(Api);

export const getRolesListData = createAsyncThunk(
  'roles/getRolesList',
  async (values) => {
    const response = await rolesService.getRolesList(values);

    if (!isEmpty(values)) {
      setUrlParams(values);
    }

    return response;
  },
);

export const deleteRole = createAsyncThunk(
  'roles/deleteRole',
  async (roleId) => {
    const response = await rolesService.deleteRole(roleId);

    return response.data;
  },
);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetRolesListData: (state) => {
      state.rolesList = [];
      state.rolesPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRolesListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRolesListData.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesList = action.payload.data;
        state.rolesPagination = action.payload.meta.pagination;
      })
      .addCase(getRolesListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.data;
      });
    builder
      .addCase(deleteRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRole.fulfilled, (state) => {
        state.loading = false;
        mkNotification('success', 'Role is successfully deleted.');
      })
      .addCase(deleteRole.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetRolesListData } = rolesSlice.actions;

// selectors
export const selectRolesLoading = (state) => state.roles.loading;
export const selectRolesList = (state) => state.roles.rolesList;
export const selectRolesPagination = (state) => state.roles.rolesPagination;
export const selectErrors = (state) => state.roles.errors;

// reducer
export default rolesSlice.reducer;
