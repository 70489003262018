import isEmpty from 'lodash/isEmpty';



export const getObjectAverageValues = (arr) => !isEmpty(arr) ?
  arr.reduce((acc, item) => {
    Object.keys(item).forEach((itemField) => {
      if (!acc[itemField] && itemField !== 'date') {
        acc[itemField] = Number(item[itemField]);
      } else acc[itemField] = Number(acc[itemField]) + Number(item[itemField]);
    });
    // eslint-disable-next-line fp/no-delete
    delete acc.date;

    return acc;
  }) : [];

