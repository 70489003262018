import moment from 'moment';
import React from 'react';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const preparedTableValues = (data) => data.map((item) => {
  const calculatedCoefficient = Number(item.app_install) / Number(item.app_confirmation);

  return {
    date: item.date,
    app_install: item.app_install,
    app_confirmation: item.app_confirmation,
    coefficient: isFinite(calculatedCoefficient) ? calculatedCoefficient.toFixed(2) : EMPTY_VALUE_PLACEHOLDER,
  };
});

export const TABLE_APP_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.date, 'DD.MM.YYYY HH:mm') >= moment(_b.date, 'DD.MM.YYYY HH:mm') ? 1 : -1),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'App install',
    dataIndex: 'app_install',
    align: 'center',
    sorter: (_a, _b) => Number(_a.app_install) - Number(_b.app_install),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'App confirm',
    dataIndex: 'app_confirmation',
    align: 'center',
    sorter: (_a, _b) => Number(_a.app_confirmation) - Number(_b.app_confirmation),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Coefficient',
    dataIndex: 'coefficient',
    align: 'center',
    sorter: (_a, _b) => _a.coefficient - _b.coefficient,
    render: (value) => value,
  },
];
