import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Table, Card } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { USER_PROFILE_DEVICES_TABLE_COLUMNS } from './PageUserProfileListDevices.const';
import {
  getUserProfilesDevicesListData,
  selectDevicesList,
  selectDevicesLoading,
  selectDevicesPagination,
} from '../../feature/userProfileDevicesSlice';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { Permissions } from '../../../../const/permissions';
import HasRights from '../../../../components/HasRights';



const { Content } = Layout;

const PageUserProfileListDevices = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const queryParams = getQueryParams(window.location.search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const devicesListData = useSelector(selectDevicesList);
  const devicesListDataLoading = useSelector(selectDevicesLoading);
  const pagination = useSelector(selectDevicesPagination);
  const showPublicQuery = true;

  useEffect(() => {
    const params = mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting);

    dispatch(getUserProfilesDevicesListData({ userId, params, showPublicQuery }));
  }, [ currentPage, currentLimit, currentOrder, currentSorting ]);

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <HasRights allowedPermissions={[ Permissions.USER_PROFILES.VIEW_ALL ]}>
      <Row align="middle" justify="space-between" className='da-pt-24'>
        <Col span={24}>
          <h4>User devices</h4>
        </Col>
        <Content className='layout-content da-overflow-scroll padding-unset' id='userDevicesTopCmp'>
          <Card className='card-has-table' style={{ margin: 'unset' }}>
            <Table
              loading={devicesListDataLoading}
              columns={USER_PROFILE_DEVICES_TABLE_COLUMNS(userId, devicesListDataLoading)}
              rowKey="id"
              dataSource={devicesListData}
              onChange={handleChangeTableParams}
              pagination={{
                current: pagination?.current_page,
                pageSize: pagination?.per_page,
                total: pagination?.total,
                position: [ 'bottomCenter' ],
                pageSizeOptions: [ '10', '25', '50' ],
                size: 'small',
                showSizeChanger: true,
              }}
            />
          </Card>
        </Content>
      </Row>
    </HasRights>
  );
};

PageUserProfileListDevices.propTypes = {
  userData: PropTypes.object,
  userDataLoading: PropTypes.bool,
};

PageUserProfileListDevices.defaultProps = {
  userData: {},
  userDataLoading: true,
};

export default PageUserProfileListDevices;
