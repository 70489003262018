import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PermissionsService from '../services/permissionsService';
import Api from '../../../../services/Api/Api';



const initialState = {
  permissionsList: [],
  errors: [],
  loading: false,
};

const permissionsService = new PermissionsService(Api);

export const getPermissionsListData = createAsyncThunk(
  'permissions/getPermissionsList',
  async () => {
    const response = await permissionsService.getPermissionsList();

    return response.data;
  },
);

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    resetPermissionsListData: (state) => {
      state.permissionsList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissionsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPermissionsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.permissionsList = action.payload;
      })
      .addCase(getPermissionsListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

// actions
export const { resetPermissionsListData } = permissionsSlice.actions;

// selectors
export const selectPermissionsLoading = (state) => state.permissions.loading;
export const selectPermissionsList = (state) => state.permissions.permissionsList;
export const selectErrors = (state) => state.permissions.permissionsList;

// reducer
export default permissionsSlice.reducer;
