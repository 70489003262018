import { Breadcrumb, Card, Col, Row, Steps } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import YAML from 'js-yaml';
import urlPageConfigurations from '../../../../urls/urlPageConfigurations';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';
import { Permissions } from '../../../../const/permissions';
import HasRights from '../../../../components/HasRights';
import FormSelectStructure from '../../forms/FormSelectStructure';
import FormCreateEditConfiguration from '../../forms/FormCreateEditConfiguration';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import { getStructuresListData, selectStructuresList, selectStructuresLoading } from '../../feature/structuresSlice';
import { createConfiguration, selectConfigurationsCreateLoading } from '../../feature/configurationsSlice';
import {
  getConfigurationDetails, resetConfigurationDetailsData,
  selectConfigurationDetails, selectConfigurationDetailsLoading, selectFormEditConfigDetailsLoading,
  updateConfigurationDetails,
} from '../../feature/configurationDetailsSlice';



const { Step } = Steps;


const PageCreateEditConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { projectSettings } = useApp();
  const { configurationId } = useParams();

  const [ currentStep, setCurrentStep ] = useState(0);
  const [ firstStepValues, setFirstStepValues ] = useState({});
  const [ configMapper, setConfigMapper ] = useState({});

  const isEditConfig = pathname.endsWith('edit');
  const isTestConfig = pathname.includes('mixins-experiments');
  const configurationDetails = useSelector(selectConfigurationDetails);
  const isLoadingConfigDetails = useSelector(selectConfigurationDetailsLoading);

  const structuresList = useSelector(selectStructuresList);
  const isLoadingStructuresList = useSelector(selectStructuresLoading);
  const isLoadingCreateConfiguration = useSelector(selectConfigurationsCreateLoading);
  const isLoadingUpdateConfiguration = useSelector(selectFormEditConfigDetailsLoading);

  useEffect(() => {
    dispatch(getStructuresListData());
    if (isEditConfig) {
      dispatch(getConfigurationDetails(configurationId));
    }
    return () => {
      resetConfigurationDetailsData();
    };
  }, []);

  const handleGoNextStep = (values) => {
    setCurrentStep(currentStep + 1);
    setFirstStepValues(values);
    if (values?.structure_id) {
      const validationConfig = structuresList.filter((structure) => structure.id === values.structure_id)[0].structure;

      setConfigMapper(YAML.load(validationConfig));
    }
  };

  const handleGoPrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCancel = () => {
    setFirstStepValues({});
    navigate(-1);
  };

  const handleRedirectToConfigurations = (response) => {
    if (isEmpty(response.payload.errors)) {
      setFirstStepValues({});
      navigate(urlPageConfigurations());
    }
  };

  const handleCreateConfiguration = async (values) => {
    window.scrollTo(0, 0);
    const data = { ...values, ...firstStepValues };

    if (isEditConfig) {
      await dispatch(updateConfigurationDetails({ configurationId, data })).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          if (!isTestConfig) {
            handleRedirectToConfigurations(response);
          } else {
            navigate(-1);
          }
        }
      });
    } else {
      await dispatch(createConfiguration(data)).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          handleRedirectToConfigurations(response);
        }
      });
    }
  };

  const configMapperInitialValue = !isEmpty(configMapper) ? configMapper.content.schema.properties : {};

  const steps = [
    {
      key: 1,
      title: `${isEditConfig ? 'Update' : 'Select'} structure`,
      content: (
        <FormSelectStructure
          initialValues={!isEmpty(firstStepValues) ? firstStepValues : {
            name: isEditConfig ? configurationDetails.name : '',
            status: isEditConfig ? configurationDetails.status : null,
            structure_id: isEditConfig ? configurationDetails.structure_id : null,
            priority: isEditConfig ? configurationDetails.priority : null,
          }}
          isLoading={isLoadingStructuresList || isLoadingConfigDetails}
          isEditConfig={isEditConfig}
          structuresOptionsList={structuresList}
          onCancel={handleCancel}
          onSubmit={handleGoNextStep}
        />
      ),
    },
    {
      key: 2,
      title: `${isEditConfig ? 'Update' : 'Fill'} configuration form`,
      content: (
        <FormCreateEditConfiguration
          initialValues={
            isEditConfig && !isEmpty(configurationDetails) ?
              JSON.parse(configurationDetails.config) : configMapperInitialValue
          }
          configMapper={configMapper}
          isLoading={isLoadingCreateConfiguration || isLoadingUpdateConfiguration}
          onCancel={handleGoPrevStep}
          isEditConfig={isEditConfig}
          onSubmit={handleCreateConfiguration}
        />
      ),
    },
  ];

  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIGURATIONS.CREATE, Permissions.CONFIGS.CONFIGURATIONS.UPDATE ]}>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={urlPageConfigurations()}>
                Configurations list
              </Link>
            </Breadcrumb.Item>
            {!isEditConfig ? (
              <Breadcrumb.Item>Create</Breadcrumb.Item>
            ) : (
              <>
                <Breadcrumb.Item>
                  <Link to={urlPageConfigurationDetails({ configurationId })}>
                    Configuration details
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Update</Breadcrumb.Item>
              </>
            )}
          </Breadcrumb>
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Steps current={currentStep}>
            {steps.map((item) => (
              <Step key={item.key} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {steps[currentStep].content}
          </div>
        </Card>
      </div>
    </HasRights>
  );
};

export default PageCreateEditConfiguration;
