import React from 'react';
import {
  CartesianGrid,
  Legend,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  Brush,
} from 'recharts';
import PropTypes from 'prop-types';
import { Empty, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import abbreviate from 'number-abbreviate';
import { CHARTS_COLORS_MAP, CHARTS_FILL_COLORS_MAP } from './MonitorCharts.const';



const MonitorCharts = ({ mainData, axisData, isLoading }) => {
  return (
    <Spin spinning={!!isLoading} tip="Loading charts...">
      {isEmpty(mainData) ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>No charts data available</span>
          }
        />
      ) : (
        <div className='charts-graphics-container'>
          <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <ComposedChart
              width={730}
              height={250}
              data={mainData}
              syncId="anyId"
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" scale="auto" />
              <YAxis yAxisId="left" tickFormatter={abbreviate} />
              <YAxis yAxisId="right" tickFormatter={abbreviate} orientation="right" stroke="#eb0c0c" />
              <Tooltip />
              <Legend verticalAlign="top" wrapperStyle={{ top: '0' }} />
              <Brush dataKey="date" height={30} stroke='#666666' />
              <Area
                yAxisId="right"
                connectNulls
                type="monotone"
                dataKey={axisData.rightAxis}
                stroke="#eb0c0c"
                fill="#f5c1c1"
                activeDot={{ r: 8 }}
              />
              {axisData.leftAxis.map((axis, index) => (
                <Area
                  key={axis[index]}
                  yAxisId="left"
                  connectNulls
                  type="monotone"
                  dataKey={axis}
                  stroke={CHARTS_COLORS_MAP[index]}
                  fill={CHARTS_FILL_COLORS_MAP[index]}
                />
              ))}
              {!isEmpty(axisData.rightPrevAxis) && (
                <Line
                  yAxisId="right"
                  connectNulls
                  type="monotone"
                  dataKey={axisData.rightPrevAxis}
                  dot={false}
                  stroke="#eb0c0c"
                  strokeDasharray="10 10"
                  strokeWidth={2}
                />
              )}
              {axisData.leftPrevAxis && axisData.leftPrevAxis.map((axis, index) => (
                <Line
                  key={axis}
                  yAxisId="left"
                  connectNulls
                  type="monotone"
                  dataKey={axis}
                  dot={false}
                  stroke={CHARTS_COLORS_MAP[index]}
                  strokeDasharray="10 10"
                  strokeWidth={2}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </Spin>
  );
};

MonitorCharts.propTypes = {
  mainData: PropTypes.array.isRequired,
  axisData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MonitorCharts;
