import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Button, Checkbox, Col, Collapse, Row } from 'antd';
import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import ReactJson from 'react-json-view';
import Yup from '../../../../vendor/yup';
import { hasRights, Permissions } from '../../../../const/permissions';
import { FormCreateConfigVisualizeButtonWr } from '../FormCreateConfigVisualize/FormCreateConfigVisualize';



const { Panel } = Collapse;


const validationSchema = Yup.object().shape({
  is_saved: Yup.boolean(),
  name: Yup.string()
    .when('is_saved', {
      is: (is_saved) => is_saved === true,
      then: () => Yup.string()
        .min(4, 'Min 4 symbols required')
        .max(150, 'Max 150 symbols required')
        .required('Name field is required'),
    }),
});


const FormCreateConfigVisualizeStep2 = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
  previewJSON,
  isDetails,
}) => {
  return (
    <Formik
      enableReinitialize
      isSubmitting={isSubmitting}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        const { values, setFieldValue, isValid } = props;

        return (
          <Form layout="horizontal">
            {!isEmpty(previewJSON) && (
              <Collapse className='da-mb-24'>
                <Panel header="Preview JSON" key='preview-json'>
                  <ReactJson
                    src={previewJSON}
                    theme="summerfruit:inverted"
                  />
                </Panel>
              </Collapse>
            )}
            {hasRights([ Permissions.CONFIGS.CONFIG_VISUALIZE.SAVE ]) && (
              <div className="centered-item">
                <Col>
                  <FormItem name="is_saved" label="Save request:">
                    <Checkbox
                      name="is_saved"
                      disabled={isDetails}
                      onChange={(event) => {
                        setFieldValue('is_saved', event.target.checked);
                      }}
                    />
                  </FormItem>
                  {values?.is_saved && (
                    <FormItem name="name" label="Request Name:" required>
                      <Input name="name" placeholder='Specify request name' />
                    </FormItem>
                  )}
                </Col>
              </div>
            )}

            <Row gutter={[ 16, 16 ]}>
              <div className="centered-item">
                <FormCreateConfigVisualizeButtonWr>
                  <Button onClick={onCancel}>
                    Go back
                  </Button>
                </FormCreateConfigVisualizeButtonWr>
                <FormCreateConfigVisualizeButtonWr>
                  <SubmitButton
                    style={{ width: '100%' }}
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Next
                  </SubmitButton>
                </FormCreateConfigVisualizeButtonWr>
              </div>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateConfigVisualizeStep2.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.array,
  values: PropTypes.shape({
    is_saved: PropTypes.bool,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  previewJSON: PropTypes.object,
  isValid: PropTypes.bool,
  isDetails: PropTypes.bool,
};

FormCreateConfigVisualizeStep2.defaultProps = {
  isDetails: false,
  setFieldValue: () => {},
  formErrors: [],
  previewJSON: {},
  isValid: false,
  values: {
    is_saved: false,
  },
};

export default FormCreateConfigVisualizeStep2;
