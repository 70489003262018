import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Divider, Spin } from 'antd';
import { withTheme } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import {
  removeEmptyValues,
  getUiSchema,
  removeDefaultOnChange,
} from './FormCreateEditConfiguration.const';
import './customFormStyles.css';



const MyForm = withTheme(AntDTheme);
const dividerClass = 'da-border-color-black-40 da-border-color-dark-80';

const FormCreateEditConfigurationButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditConfigurationButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;



const FormCreateEditConfiguration = ({
  onSubmit,
  onCancel,
  isLoading,
  configMapper,
  initialValues,
  isEditConfig,
}) => {
  let prevKey = '';

  let prevObj = {};

  const replaceRefs = (obj) => {
    const result = obj;

    // eslint-disable-next-line fp/no-loops,no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line lodash/prefer-lodash-typecheck
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        prevKey = key;
        prevObj = obj;
        replaceRefs(obj[key]);
      } else {
        if (obj.hasOwnProperty('$ref')) {
          const prop = obj[key].split('/').pop();

          prevObj[prevKey] = { ...configMapper.components.schemas[prop] };
          prevKey = key;
          prevObj = obj;
        }
        if (obj.hasOwnProperty('format') && obj[key] === 'binary') {
          prevObj[prevKey] = Object.assign(obj, { format: 'data-url' });
          prevKey = key;
          prevObj = obj;
        }
        if (isEditConfig && obj.hasOwnProperty('default') && !obj.hasOwnProperty('enum')) {
          // eslint-disable-next-line fp/no-delete
          delete obj.default;

          prevObj[prevKey] = obj;
          prevKey = key;
          prevObj = obj;
        }
        // eslint-disable-next-line lodash/prefer-lodash-typecheck
        if (typeof obj[key] === 'string' && obj.hasOwnProperty('enum') && !obj.hasOwnProperty('anyOf')) {
          prevObj[prevKey] = {
            anyOf: [
              { type: 'null', title: 'Empty', nullable: true },
              ...obj.enum.map((item) => {
                return { type: obj.type, title: `${item}`, enum: [ item ], default: item };
              }),
            ],
            ...obj.hasOwnProperty('default') ? { default: obj.default } : {},
          };
        }
      }
    }
    return result;
  };

  const preparedInitialValues = replaceRefs(initialValues);
  const replacedRefsConfigMapper = replaceRefs(configMapper);
  const { schema } = replacedRefsConfigMapper?.content;
  const formSchema = isEditConfig ? removeDefaultOnChange(schema) : schema;
  const initValuesCopy = cloneDeep(preparedInitialValues);
  const schemaProps = cloneDeep(schema.properties);

  const customFieldTemplate = (props) => {
    const { id, label, required, children, description, errors, help } = props;

    const dividerFields = !isEmpty(formSchema?.properties) ? Object.keys(formSchema.properties) : [];
    const preparedLabel = !isEmpty(label) ? `${capitalize(label.replace(/([a-z])([A-Z])/g, '$1 $2'))}:` : '';
    const hasTitle = dividerFields.includes(id.substring(5));

    return (
      <div className="custom-form-field">
        {hasTitle ? (
          <Divider orientation="left" className={dividerClass}>
            <div className='da-d-flex'>
              <div style={{ color: 'red', paddingRight: 7 }}>{required ? '*' : null}</div>
              <div>{preparedLabel}</div>
            </div>
          </Divider>
        ) : (
          <label htmlFor={id} className='da-d-flex'>
            <div style={{ color: 'red' }}>{required && !isEmpty(label) ? '* ' : null}</div>
            &nbsp;
            <div>{preparedLabel}</div>
          </label>
        )}
        <div className='da-mb-10'>
          {children}
          <div style={{ color: ' #00000073', marginTop: '4px' }}>
            {description}
          </div>
          {errors}
          {help}
        </div>
      </div>
    );
  };


  return (
    <>
      <Spin
        spinning={isLoading}
        tip={`${!isEditConfig ? 'Creating' : 'Updating'} configuration. Please wait...`}
      >
        <MyForm
          layout="vertical"
          className="row"
          FieldTemplate={customFieldTemplate}
          formData={isEditConfig ? preparedInitialValues : {}}
          schema={formSchema}
          uiSchema={!isEditConfig ? getUiSchema(initValuesCopy) : getUiSchema(schemaProps)}
          onSubmit={(values) => {
            const submitData = { config: JSON.stringify(values.formData) };

            onSubmit(submitData);
          }}
          onError={() => {
            window.scrollTo(0, 0);
          }}
          onChange={(ev) => {
            removeDefaultOnChange(ev.schema);
            removeEmptyValues(ev.formData);
          }}
        >
          <FormCreateEditConfigurationButtonsRow>
            <FormCreateEditConfigurationButtonWr>
              <Button onClick={onCancel}>
                Previous
              </Button>
            </FormCreateEditConfigurationButtonWr>
            <FormCreateEditConfigurationButtonWr>
              <Button
                htmlType='submit'
                size='middle'
                type="primary"
                style={{ width: '100%' }}
                loading={isLoading}
              >
                {!isEditConfig ? 'Create' : 'Update'}
              </Button>
            </FormCreateEditConfigurationButtonWr>
          </FormCreateEditConfigurationButtonsRow>
        </MyForm>
      </Spin>
    </>
  );
};

FormCreateEditConfiguration.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isEditConfig: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
  onCancel: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  configMapper: PropTypes.object.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
  description: PropTypes.node,
  errors: PropTypes.node,
  help: PropTypes.node,
};

FormCreateEditConfiguration.defaultProps = {
  formErrors: {},
  initialValues: {},
};

export default FormCreateEditConfiguration;
