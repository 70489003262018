import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import { EMPTY_VALUE_PLACEHOLDER } from '../const/system';



export const isEmptyNumber = (value) => {
  if (isNumber(value) && !isUndefined(value)) {
    return value;
  }
  return EMPTY_VALUE_PLACEHOLDER;
};
