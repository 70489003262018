import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import { mapToArrayOfDates } from '../../../../lib/mapToArrayOfDates';



export const getPreparedFormData = (values, configName) => {
  return {
    config_name: configName,
    author_name: !isEmpty(values?.author) ? values.author.name : '',
    ...values,
    conditions: !isEmpty(values.conditions) ? values.conditions.map((condition) => {
      return {
        ...condition,
        lat: condition.lat !== null ? condition.lat : null,
        countries: condition.countries ? condition.countries.map((country) => country.toUpperCase()) : [],
        os_versions_min: condition.os_versions?.min ? condition.os_versions?.min : '',
        os_versions_max: condition.os_versions?.max ? condition.os_versions?.max : '',
        os_versions_list: condition.os_versions?.list ? condition.os_versions?.list : [],
        ram_min: condition?.ram?.min ?? null,
        ram_max: condition?.ram?.max ?? null,
        ram_list: condition?.ram?.list ?? [],
        device_performance_min: condition?.device_performance?.min ?? null,
        device_performance_max: condition?.device_performance?.max ?? null,
        device_performance_list: condition?.device_performance?.list ?? [],
        app_versions_min: condition.app_versions?.min ? condition.app_versions?.min : '',
        app_versions_max: condition.app_versions?.max ? condition.app_versions?.max : '',
        app_versions_list: condition.app_versions?.list ? condition.app_versions?.list : [],
        install_dates_min: condition.install_dates?.min ? moment.utc(condition.install_dates.min).local() : null,
        install_dates_max: condition.install_dates?.max ? moment.utc(condition.install_dates.max).local() : null,
        install_dates_list: condition.install_dates?.list ? mapToArrayOfDates(condition.install_dates.list) : [],
        ab_test: condition.ab_test ? {
          name: condition?.ab_test?.name ?? null,
          group: condition?.ab_test?.group ?? null,
        } : null,
        users: !isEmpty(condition.users) ? condition.users : {},
        install_dates_special: condition.install_dates?.special ? {
          seconds: condition.install_dates?.special ? condition.install_dates.special[0] : '',
          statement: condition.install_dates?.special ? condition.install_dates.special[1] : '',
        } : { seconds: '', statement: '' },
      };
    }) : [],
  };
};
