import { Card, Collapse, Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useCheckMobileScreen } from '../../app/hooks';



const { Panel } = Collapse;

const CollapsibleCardWr = ({ children, title, keyValue, isLoading, maxDeviceWidth, isCollapsible, defaultActiveKey }) => {
  return (
    <Spin spinning={isLoading}>
      {useCheckMobileScreen(maxDeviceWidth) || isCollapsible ? (
        <Collapse defaultActiveKey={defaultActiveKey}>
          <Panel header={title} key={keyValue} isActive>
            {children}
          </Panel>
        </Collapse>
      ) : (
        <Card title={title} loading={isLoading}>
          {children}
        </Card>
      )}
    </Spin>
  );
};

CollapsibleCardWr.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  keyValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  maxDeviceWidth: PropTypes.number,
  isCollapsible: PropTypes.bool,
  defaultActiveKey: PropTypes.string,
};

CollapsibleCardWr.defaultProps = {
  isLoading: false,
  isCollapsible: false,
  defaultActiveKey: '',
  maxDeviceWidth: 768,
};

export default CollapsibleCardWr;
