import React from 'react';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import OpenAPISchemaValidator from '@configapi/configapi-validator/packages/openapi-schema-validator';
import YAML from 'js-yaml';
import isEmpty from 'lodash/isEmpty';
import { RiErrorWarningFill } from 'react-icons/ri';
import capitalize from 'lodash/capitalize';
import Yup from '../../../../vendor/yup';
import { hasRights, Permissions } from '../../../../const/permissions';
import { SEMVER_REGEXP } from '../../../../const/system';
import { customizeYamlStructure } from './FormCreateEditStructure.const';



const FormCreateEditStructureButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditStructureButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;


const validator = new OpenAPISchemaValidator({
  version: 3, //configApi schema
});

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Min 2 symbols required')
    .max(100, 'Max 100 symbols required')
    .required('Name field is required'),
  version: Yup.string().nullable()
    .matches(SEMVER_REGEXP, 'Value is doesn`t matches with semver regular expression')
    .max(100, 'Max 100 symbols required')
    .required('Version field is required'),
  file: Yup.mixed().required('Structure file is required'),
  structureDetailsError: Yup.string().nullable(),
});

const FormCreateEditStructure = ({
  onSubmit,
  initialValues,
  isSubmitting,
  handleCancel,
  formErrors,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, resetForm, dirty, values } = props;
        const structureErrors = values?.structure_errors;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <FormItem
              className='ant-form-item-col'
              name="name"
              label="Name:"
              required
            >
              <Input
                name='name'
                placeholder='Specify structure name'
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="version"
              label="Version:"
              required
            >
              <Input
                name='version'
                placeholder='Specify structure version'
              />
            </FormItem>

            <Form.Item
              label="Structure:"
              name='structure_file'
              valuePropName="file"
              required
            >
              <Upload.Dragger
                name="structure_file"
                accept='.yml, .yaml'
                multiple={false}
                maxCount={1}
                onChange={(info) => {
                  setFieldValue('file', info.file);
                  setFieldValue('structureDetailsError', null);
                }}
                beforeUpload={(file) => {
                  const reader = new FileReader();

                  reader.onload = (ev) => {
                    try {
                      const fileData = YAML.load(ev.target.result);

                      setFieldValue('structure_file', YAML.dump(customizeYamlStructure(fileData)));
                      setFieldValue('structure_errors', validator.validate(fileData));
                      return YAML.load(ev.target.result);
                    } catch (err) {
                      setFieldValue('structureDetailsError', `There was a problem with file parsing. Ensure it is valid YAML!\n ${err}`);
                      return false;
                    }
                  };
                  reader.readAsText(file);

                  return false;
                }}
                onRemove={() => {
                  setFieldValue('file', null);
                  setFieldValue('structure_errors', null);
                  setFieldValue('structureDetailsError', null);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined style={{ fontSize: '30px', color: '#7a7b7c' }} />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Only yml or yaml file format is valid.</p>
              </Upload.Dragger>

              {!isEmpty(structureErrors) && structureErrors?.errors.length !== 0 && (
                <div
                  style={{
                    border: '2px solid #f0f3f5',
                    borderRadius: '4px',
                    marginTop: '8px',
                    padding: '5px',
                  }}
                >
                  {structureErrors.errors.map((err, index) => (
                    <div
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <RiErrorWarningFill style={{ color: '#FF0022', margin: '2px 5px 0 0' }} />
                      <div className='da-text-color-danger-1'>
                        {`${!isEmpty(err.instancePath) ? `${err.instancePath} - ` : ''} 
                            ${!isEmpty(err.params?.additionalProperty) ? `"${err.params.additionalProperty}": ` : !isEmpty(err.params?.type) ? `"${err.params.type}": ` : ''}
                            ${capitalize(err.message)}.`}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!isEmpty(values.structureDetailsError) && (
                <Input.TextArea
                  style={{ marginTop: '10px', borderColor: 'red' }}
                  rows={7}
                  name='structureDetailsError'
                  disabled
                />
              )}
            </Form.Item>

            {hasRights([ Permissions.CONFIGS.STRUCTURES.CREATE ]) && (
              <FormCreateEditStructureButtonsRow>
                <FormCreateEditStructureButtonWr>
                  <Button
                    style={{ width: '100%' }}
                    type='secondary'
                    onClick={() => {
                      resetForm();
                      handleCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </FormCreateEditStructureButtonWr>

                <FormCreateEditStructureButtonWr>
                  <SubmitButton
                    style={{ width: '100%' }}
                    loading={isSubmitting}
                    disabled={
                      !isValid ||
                      !dirty ||
                      structureErrors?.errors.length !== 0 ||
                      !isEmpty(values.structureDetailsError)
                    }
                  >
                    Create
                  </SubmitButton>
                </FormCreateEditStructureButtonWr>
              </FormCreateEditStructureButtonsRow>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditStructure.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    structure_errors: PropTypes.object,
    structureDetailsError: PropTypes.string,
  }),
  formErrors: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
};

FormCreateEditStructure.defaultProps = {
  formErrors: [],
  values: {
    structure_errors: {},
    structureDetailsError: '',
  },
};

export default FormCreateEditStructure;
