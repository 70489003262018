import { configureStore } from '@reduxjs/toolkit';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { interceptor } from '../services/Api/Api';
import authReducer from './auth/feature/AuthSlice';
import appSettingsReducer from './auth/feature/AppSettingsSlice';
import projectsListReducer from '../domains/Public/PageSelectProject/feature/projectsOptionsSlice';
import dashboardReducer from '../domains/Public/PageDashboard/feature/dashboardSlice';
import gamesMonitorReducer from '../domains/Monitor/feature/gamesMonitorSlice';
import spacesReducer from '../domains/Administration/Spaces/feature/spacesSlice';
import abTestsReducer from '../domains/AbTests/feature/abTestsSlice';
import abTestDetailsReducer from '../domains/AbTests/feature/abTestDetailsSlice';
import adsMonitorReducer from '../domains/Monitor/feature/adsMonitorSlice';
import appMonitorReducer from '../domains/Monitor/feature/applicationMonitorSlice';
import hintsMonitorReducer from '../domains/Monitor/feature/hintsMonitorSlice';
import contactUsMonitorReducer from '../domains/Monitor/feature/contactUsMonitorSlice';
import userEventsReducer from '../domains/Support/feature/userEventsSlice';
import eventsSequenceReducer from '../domains/Support/feature/eventsSequenceSlice';
import ticketsListReducer from '../domains/Support/feature/ticketsSlice';
import ticketReducer from '../domains/Support/feature/ticketDetailsSlice';
import usersReducer from '../domains/Administration/Users/feature/usersSlice';
import permissionsReducer from '../domains/Administration/Permissions/feature/permissionsSlice';
import rolesReducer from '../domains/Administration/Roles/feature/rolesSlice';
import configurationsReducer from '../domains/Configurations/feature/configurationsSlice';
import mixinsExperimentsReducer from '../domains/Experiments/feature/mixinsExperimentsSlice';
import mixinsExperimentDetailsReducer from '../domains/Experiments/feature/mixinsExperimentDetailsSlice';
import mixinsExperimentsMixinDetailsReducer from '../domains/Experiments/feature/mixinExperimentMixinDetailsSlice';
import structuresReducer from '../domains/Configurations/feature/structuresSlice';
import structureDetailsReducer from '../domains/Configurations/feature/structureDetailsSlice';
import configurationDetailsReducer from '../domains/Configurations/feature/configurationDetailsSlice';
import configurationMixinDetailsReducer from '../domains/Configurations/feature/configurationMixinDetailsSlice';
import configurationVisualizeReducer from '../domains/Configurations/feature/configurationVisualizeSlice';
import mixinsVisualizeReducer from '../domains/Configurations/feature/mixinsVisualizeSlice';
import userDetailsReducer from '../domains/Administration/Users/feature/userDetailsSlice';
import roleDetailsReducer from '../domains/Administration/Roles/feature/roleDetailsSlice';
import logsReducer from '../domains/Administration/Logs/feature/logsSlice';
import userProfilesReducer from '../domains/UserProfiles/feature/userProfilesSlice';
import userProfileDetailsReducer from '../domains/UserProfiles/feature/userProfileDetailsSlice';
import userProfileDeviceDetailsReducer from '../domains/UserProfiles/feature/userProfileDeviceDetailsSlice';
import userProfileDevicesReducer from '../domains/UserProfiles/feature/userProfileDevicesSlice';
import projectsReducer from '../domains/Administration/Projects/feature/projectsSlice';
import projectDetailsReducer from '../domains/ConsentProvider/feature/projectDetailsSlice';
import projectUserDetailsReducer from '../domains/ConsentProvider/feature/projectUserDetailsSlice';
import projectRequestsReducer from '../domains/ConsentProvider/feature/projectRequestsSlice';



const reducer = {
  auth: authReducer,
  appSettings: appSettingsReducer,
  projectsOptions: projectsListReducer,
  dashboard: dashboardReducer,
  userEvents: userEventsReducer,
  eventsSequence: eventsSequenceReducer,
  tickets: ticketsListReducer,
  ticket: ticketReducer,
  gameMonitoring: gamesMonitorReducer,
  adsMonitoring: adsMonitorReducer,
  appMonitoring: appMonitorReducer,
  hintsMonitoring: hintsMonitorReducer,
  contactUsMonitoring: contactUsMonitorReducer,
  spaces: spacesReducer,
  abTests: abTestsReducer,
  abTestDetails: abTestDetailsReducer,
  structures: structuresReducer,
  structureDetails: structureDetailsReducer,
  configurationVisualize: configurationVisualizeReducer,
  mixinsVisualize: mixinsVisualizeReducer,
  configurations: configurationsReducer,
  configurationDetails: configurationDetailsReducer,
  configurationMixinDetails: configurationMixinDetailsReducer,
  mixinsExperiments: mixinsExperimentsReducer,
  mixinsExperimentDetails: mixinsExperimentDetailsReducer,
  mixinsExperimentMixinDetails: mixinsExperimentsMixinDetailsReducer,
  users: usersReducer,
  userDetails: userDetailsReducer,
  permissions: permissionsReducer,
  roles: rolesReducer,
  roleDetails: roleDetailsReducer,
  logs: logsReducer,
  userProfiles: userProfilesReducer,
  userProfileDetails: userProfileDetailsReducer,
  userProfileDeviceDetails: userProfileDeviceDetailsReducer,
  userProfileDevices: userProfileDevicesReducer,
  projects: projectsReducer,
  projectDetails: projectDetailsReducer,
  projectUserDetails: projectUserDetailsReducer,
  requests: projectRequestsReducer,
};


const config = {
  whitelist: [ 'setAuth/logoutUser' ],
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware(config)),
});

interceptor(store);
initStateWithPrevTab(store);

export default store;
