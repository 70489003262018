import { Descriptions, Divider } from 'antd';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmptyNumber } from '../../../../lib/isEmptyNumber';
import { LAT_TYPES_TRANSLATIONS } from '../../forms/FormCreateEditAbTest/FormCreateEditAbTest.const';
import CollapsibleCardWr from '../../../../components/CollapsibleCardWr';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const preparedArrayMap = (arrValues) => {
  return (
    !isEmpty(arrValues) ? arrValues.map((item) => {
      return (
        <div key={item}>{item}</div>
      );
    }) : EMPTY_VALUE_PLACEHOLDER
  );
};

const AbTestDetailsConditionsCard = ({ index, condition, isLoading }) => {
  return (
    <CollapsibleCardWr
      title={`Condition #${index + 1}`}
      isLoading={isLoading}
      keyValue={`Condition #${index + 1}`}
      isCollapsible
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Condition type:">
          {capitalize(condition.condition_type) || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="Countries:">
          {condition?.countries ? condition.countries.join(', ').toUpperCase() : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="Device types:">
          {!isEmpty(condition.device_types) ? condition.device_types.map((item) => capitalize(item)).join(', ') : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="Device manufactures:">
          {!isEmpty(condition.device_manufactures) ? condition.device_manufactures.map((item) => capitalize(item)).join(', ') : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="Device models:">
          {preparedArrayMap(condition.device_models)}
        </Descriptions.Item>
        <Descriptions.Item label="Ram (Mb):">
          {isEmpty(condition.ram) ? EMPTY_VALUE_PLACEHOLDER : (
            <>
              <div>{`Min: ${isEmptyNumber(condition.ram?.min)}`}</div>
              <div>{`Max: ${isEmptyNumber(condition.ram?.max)}`}</div>
              <div>{`List: ${condition.ram?.list ? condition.ram.list.map((item) => item).join(', ') : EMPTY_VALUE_PLACEHOLDER}`}</div>
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Device performance:">
          {isEmpty(condition.device_performance) ? EMPTY_VALUE_PLACEHOLDER : (
            <>
              <div>{`Min: ${isEmptyNumber(condition.device_performance?.min)}`}</div>
              <div>{`Max: ${isEmptyNumber(condition.device_performance?.max)}`}</div>
              <div>{`List: ${condition.device_performance?.list ? condition.device_performance.list.map((item) => item).join(', ') : EMPTY_VALUE_PLACEHOLDER}`}</div>
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Languages:">
          {!isEmpty(condition.languages) ? condition.languages.map((item) => capitalize(item)).join(', ') : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="Lat:">
          {LAT_TYPES_TRANSLATIONS(condition?.lat)}
        </Descriptions.Item>
        <Descriptions.Item label="Law:">
          {!isEmpty(condition.law) ? condition.law.map((item) => item.toUpperCase()).join(', ') : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="App versions:">
          {isEmpty(condition.app_versions) ? EMPTY_VALUE_PLACEHOLDER : (
            <>
              <div>{`Min: ${condition.app_versions?.min || EMPTY_VALUE_PLACEHOLDER}`}</div>
              <div>{`Max: ${condition.app_versions?.max || EMPTY_VALUE_PLACEHOLDER}`}</div>
              <div>{`List: ${condition.app_versions?.list ? condition.app_versions.list.map((item) => item).join(', ') : EMPTY_VALUE_PLACEHOLDER}`}</div>
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Platforms:">
          {!isEmpty(condition.platforms) ? condition.platforms.map((item) => capitalize(item)).join(', ') : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="OS versions:">
          {isEmpty(condition.os_versions) ? EMPTY_VALUE_PLACEHOLDER : (
            <>
              <div>{`Min: ${condition.os_versions?.min || EMPTY_VALUE_PLACEHOLDER}`}</div>
              <div>{`Max: ${condition.os_versions?.max || EMPTY_VALUE_PLACEHOLDER}`}</div>
              <div>
                {`List: ${!isEmpty(condition.os_versions?.list) ? condition.os_versions.list.map((item) => item).join(', ') : EMPTY_VALUE_PLACEHOLDER}`}
              </div>
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Users types:">
          {!isEmpty(condition.users?.types) ? condition.users.types.map((item) => capitalize(item)).join(', ') : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label="Users IDs:">
          {!isEmpty(condition.users?.list) ? condition.users.list.map((item, index) => (
            <div key={item[index]} className='custom-tag'>{item}</div>
          )) : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>

        <Descriptions.Item label="Install dates:">
          {isEmpty(condition.install_dates) ? EMPTY_VALUE_PLACEHOLDER : (
            <>
              <div>
                Min:
                <div>{condition.install_dates?.min ? moment.utc(condition.install_dates.min).local().format('DD.MM.YYYY HH:mm:ss') : EMPTY_VALUE_PLACEHOLDER}</div>
                <Divider style={{ display: 'block' }} className='da-border-color-black-40 da-border-color-dark-80' />
              </div>
              <div>
                Max:
                <div>{condition.install_dates?.max ? moment.utc(condition.install_dates.max).local().format('DD.MM.YYYY HH:mm:ss') : EMPTY_VALUE_PLACEHOLDER}</div>
                <Divider style={{ display: 'block' }} className='da-border-color-black-40 da-border-color-dark-80' />
              </div>
              <div>
                {`Special: ${condition.install_dates?.special ? condition.install_dates.special.map((item) => item).join(', ') : EMPTY_VALUE_PLACEHOLDER}`}
                <Divider style={{ display: 'block' }} className='da-border-color-black-40 da-border-color-dark-80' />
              </div>
              <div>
                List:
                <div>
                  {condition.install_dates?.list ? condition.install_dates.list.map((datesArr, index) => {
                    return (
                      <div key={datesArr[index]}>
                        {index !== 0 && (
                          <Divider style={{ display: 'block' }} className='da-border-color-black-40 da-border-color-dark-80' />
                        )}
                        <div>{`From: ${!isEmpty(datesArr?.from) ? moment.utc(datesArr.from).local().format('DD.MM.YYYY HH:mm:ss') : EMPTY_VALUE_PLACEHOLDER}`}</div>
                        <div>{`To: ${!isEmpty(datesArr?.to) ? moment.utc(datesArr.to).local().format('DD.MM.YYYY HH:mm:ss') : EMPTY_VALUE_PLACEHOLDER}`}</div>
                      </div>
                    );
                  }) : EMPTY_VALUE_PLACEHOLDER}
                </div>
              </div>
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Custom target:">
          {condition?.custom_target ?? EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
      </Descriptions>
    </CollapsibleCardWr>
  );
};

AbTestDetailsConditionsCard.propTypes = {
  index: PropTypes.number.isRequired,
  condition: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default AbTestDetailsConditionsCard;
