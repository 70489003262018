import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Input, Modal, Row, Spin, Table } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { SearchOutlined } from '@ant-design/icons';
// eslint-disable-next-line lodash/import-scope
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import urlPageSpaces from '../../../../../urls/urlPageSpaces';
import { defaultModulePack, geSpacesTableColumns, transpileModuleErrors } from './PageSpaces.const';
import { getQueryParams } from '../../../../../lib/getQueryParams';
import { mkQueryParams } from '../../../../../lib/mkQueryParams';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { useApp } from '../../../../../app/context/AppContext';
import FormCreateEditSpace from '../../../../AbTests/forms/FormCreateEditSpace';
import { isFulfilledRequestStatus } from '../../../../../lib/isRequestSuccess';
import { hasRights, Permissions } from '../../../../../const/permissions';
import { LOADING_TIP_PLACEHOLDER } from '../../../../../const/system';
import {
  createSpace,
  updateSpace,
  deleteSpace,
  getSpacesListData,
  resetSpacesListData,
  resetErrors,
  selectCreateUpdateSpaceErrors,
  selectCreateUpdateSpaceLoading,
  selectSpacesList,
  selectSpacesLoading,
  selectSpacesPagination,
} from '../../feature/spacesSlice';



const PageSpaces = () => {
  const { pathname, search } = useLocation();
  const { projectSettings } = useApp();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ searchText, setSearchText ] = useState('');
  const [ isActiveModal, setToggleActiveModal ] = useState(false);
  const [ editSpaceItem, setEditSpace ] = useState(null);
  const isEditSpace = !isEmpty(editSpaceItem);
  const formInitialValues = isEditSpace && !isEmpty(editSpaceItem) ? {
    name: editSpaceItem?.name,
    key: editSpaceItem?.key,
    config: editSpaceItem?.config ? JSON.stringify(editSpaceItem.config) : '',
  } : { config: defaultModulePack };

  const dispatch = useDispatch();
  const spacesList = useSelector(selectSpacesList);
  const isLoading = useSelector(selectSpacesLoading);
  const pagination = useSelector(selectSpacesPagination);
  const isLoadingCreateUpdate = useSelector(selectCreateUpdateSpaceLoading);
  const createUpdateSpaceErrors = useSelector(selectCreateUpdateSpaceErrors);


  const handleGetSpaces = () => {
    dispatch(getSpacesListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting, '', [ { 'filterKey': 'name', 'filterValue': searchText } ])));
  };

  useEffect(() => {
    if (pathname === urlPageSpaces()) {
      handleGetSpaces();
    }
    return () => {
      dispatch(resetSpacesListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting, searchText ]);


  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);


  const showCreateEditSpacesModal = (editSpaceItem) => {
    setToggleActiveModal(true);
    if (!isEditSpace) {
      setEditSpace(editSpaceItem);
    }
  };

  const hideCreateEditSpacesModal = () => {
    dispatch(resetErrors());
    setToggleActiveModal(false);
    setEditSpace(null);
  };

  const handleSubmit = async (values) => {
    if (isEditSpace) {
      await dispatch(updateSpace({ spaceId: editSpaceItem.id, data: values }))
        .then((response) => {
          if (isFulfilledRequestStatus(response)) {
            hideCreateEditSpacesModal();
            handleGetSpaces();
          }
        });
    } else {
      await dispatch(createSpace(values))
        .then((response) => {
          if (isFulfilledRequestStatus(response)) {
            hideCreateEditSpacesModal();
            handleGetSpaces();
          }
        });
    }
  };

  const handleDeleteSpace = async (spaceId) => {
    await dispatch(deleteSpace(spaceId)).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        handleGetSpaces();
      }
    });
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <>
      <Modal
        title={!editSpaceItem ? 'Create space' : `Edit ${editSpaceItem.name} space`}
        width={650}
        centered
        destroyOnClose
        visible={isActiveModal}
        onCancel={hideCreateEditSpacesModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <Spin spinning={isLoadingCreateUpdate} tip={LOADING_TIP_PLACEHOLDER}>
          <FormCreateEditSpace
            initialValues={formInitialValues}
            isEditSpace={isEditSpace}
            isSubmitting={isLoadingCreateUpdate}
            formErrors={{ ...createUpdateSpaceErrors, ...transpileModuleErrors(createUpdateSpaceErrors) }}
            onSubmit={handleSubmit}
            onCancel={hideCreateEditSpacesModal}
          />
        </Spin>
      </Modal>

      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Spaces list</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Input
              placeholder="Search..."
              allowClear={!isEmpty(searchText)}
              style={{ width: 230, margin: '10px 0' }}
              onChange={debouncedSearch}
              prefix={<SearchOutlined fill='#9393ab' />}
            />
            {hasRights([ Permissions.ADMINISTRATION.SPACES.CREATE ]) && (
              <Button
                style={{ margin: '10px 0 10px 15px' }}
                size='middle'
                type="primary"
                onClick={() => {
                  showCreateEditSpacesModal();
                }}
              >
                Create space
              </Button>
            )}
          </div>
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Table
            sticky
            onChange={handleChangeTableParams}
            columns={geSpacesTableColumns(handleDeleteSpace, showCreateEditSpacesModal)}
            dataSource={spacesList}
            rowKey="id"
            loading={isLoading}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 550 }}
          />
        </Card>
      </div>
    </>
  );
};

export default PageSpaces;
