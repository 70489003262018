import { setRequestParams } from '../../../../lib/setRequestParams';



export default class UsersService {
  constructor (Api) {
    this.api = Api;
  }

  getUsersList (params) {
    return this.api.get(`users${setRequestParams(params)}&include=spaces`);
  }

  createUser (values) {
    return this.api.post('register', values);
  }

  deleteUser (userId) {
    return this.api.delete(`users/${userId}`);
  }
}
