import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { mkNotification } from '../../../lib/mkNotification';
import { setUrlParams } from '../../../lib/setUrlParams';
import MixinsExperimentsService from '../services/mixinsExperimentsService';



const initialState = {
  experimentsList: [],
  experimentsPagination: {},
  experimentsStatuses: [],
  experimentsListLoading: false,
  createExperimentData: [],
  createExperimentErrors: {},
  createExperimentLoading: false,
};

const mixinsExperimentsService = new MixinsExperimentsService(Api);

export const getExperimentsListData = createAsyncThunk(
  'mixinsExperiments/getExperimentsList',
  async (values) => {
    const response = await mixinsExperimentsService.getExperimentsList(values);

    if (!isEmpty(values)) {
      setUrlParams(values);
    }

    return response;
  },
);

export const createExperiment = createAsyncThunk(
  'mixinsExperiments/createExperiment',
  async (values, { rejectWithValue }) => {
    try {
      const response = await mixinsExperimentsService.createExperiment(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const archiveExperiment = createAsyncThunk(
  'mixinsExperiments/archiveExperiment',
  async ({ testId }, { rejectWithValue }) => {
    try {
      const response = await mixinsExperimentsService.archiveExperiment({ testId });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const mixinsExperimentsSlice = createSlice({
  name: 'mixinsExperiments',
  initialState,
  reducers: {
    resetTestsListData: (state) => {
      state.experimentsList = [];
      state.experimentsStatuses = [];
      state.experimentsPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExperimentsListData.pending, (state) => {
        state.experimentsListLoading = true;
      })
      .addCase(getExperimentsListData.fulfilled, (state, action) => {
        state.experimentsListLoading = false;
        state.experimentsList = action.payload.data;
        state.experimentsPagination = action.payload.meta.pagination;
        state.experimentsStatuses = action.payload.meta.custom.statuses;
      })
      .addCase(getExperimentsListData.rejected, (state, action) => {
        state.experimentsListLoading = false;
        state.errors = action.payload.data;
      });

    builder
      .addCase(createExperiment.pending, (state) => {
        state.createExperimentLoading = true;
        state.createExperimentErrors = {};
      })
      .addCase(createExperiment.fulfilled, (state, action) => {
        state.createExperimentLoading = false;
        state.createExperimentData = action.payload;
        mkNotification('success', 'Experiment is successfully created.');
      })
      .addCase(createExperiment.rejected, (state, action) => {
        state.createExperimentLoading = false;
        state.createExperimentErrors = action.payload.errors;
      });

    builder
      .addCase(archiveExperiment.pending, (state) => {
        state.experimentsListLoading = true;
      })
      .addCase(archiveExperiment.fulfilled, (state) => {
        state.experimentsListLoading = false;
        mkNotification('success', 'Experiment is successfully archived.');
      })
      .addCase(archiveExperiment.rejected, (state) => {
        state.experimentsListLoading = false;
      });
  },
});

// actions
export const { resetTestsListData, resetTestDetails } = mixinsExperimentsSlice.actions;

// selectors
export const selectExperimentsListLoading = (state) => state.mixinsExperiments.experimentsListLoading;
export const selectExperimentsList = (state) => state.mixinsExperiments.experimentsList;
export const selectExperimentsPagination = (state) => state.mixinsExperiments.experimentsPagination;
export const selectExperimentsStatuses = (state) => state.mixinsExperiments.experimentsStatuses;

export const selectCreateExperimentLoading = (state) => state.mixinsExperiments.createExperimentLoading;
export const selectCreateExperimentData = (state) => state.mixinsExperiments.createExperimentData;
export const selectCreateExperimentErrors = (state) => state.mixinsExperiments.createExperimentErrors;

// reducer
export default mixinsExperimentsSlice.reducer;
