import React from 'react';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Col, Popconfirm, Row } from 'antd';
import Yup from '../../../../../vendor/yup';
import { mkSelectFilterOption } from '../../../../../lib/mkSelectFilterOption';



const { Option } = Select;

const FormCreateEditProjectButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditProjectButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;


const FormCreateEditProject = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
  onCancel,
  spacesList,
  isEditProject,
  isGlobalAdmin,
  disableSpaceField,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={
        Yup.object().shape({
          space_id: isGlobalAdmin ? Yup.string().required('Required to select space') : Yup.string().nullable(),
          name: Yup.string()
            .min(3, 'Min 3 symbol required')
            .max(100, 'Max 100 symbols required')
            .required('Name field is required'),
          package_name: Yup.string()
            .min(1, 'Min 1 symbol required')
            .max(100, 'Max 100 symbols required')
            .matches(/^[a-z][a-z0-9.]+$/, 'Only lower case latin letters and integer values sliced by dot is required')
            .required('Package name field is required'),
          key: Yup.string()
            .min(2, 'Min 2 symbols required')
            .max(10, 'Max 10 symbols required')
            .matches(/^[A-Z]+$/, 'Only upper case latin letters is required')
            .required('URL key field is required'),
        })
      }
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, dirty, values } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Row gutter={[ 16, 16 ]}>
              {isGlobalAdmin && (
                <Col span={12}>
                  <FormItem
                    className='ant-form-item-col'
                    name="space_id"
                    label="Space:"
                    required
                  >
                    <Select
                      showSearch
                      allowClear
                      name="space_id"
                      disabled={isEditProject || disableSpaceField}
                      placeholder="Select space"
                      value={values?.space_id}
                      optionFilterProp="children"
                      filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    >
                      {spacesList?.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>{item.name}</Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
              )}

              <Col span={12}>
                <FormItem
                  className='ant-form-item-col'
                  name="name"
                  label="Name:"
                  required
                >
                  <Input
                    name='name'
                    placeholder='Specify name'
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem
                  className='ant-form-item-col'
                  name="package_name"
                  label="Package name:"
                  required
                >
                  <Input
                    name='package_name'
                    disabled={isEditProject}
                    placeholder='Specify package name'
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem
                  className='ant-form-item-col'
                  name="key"
                  label="URL key:"
                  required
                >
                  <Input
                    name='key'
                    disabled={isEditProject}
                    placeholder='Specify project url key'
                  />
                </FormItem>
              </Col>
            </Row>

            {!isEditProject && (
              <Row gutter={[ 16, 16 ]}>
                <Col>
                  <div className="da-mb-0 da-text-color-black-80 da-text-color-dark-30 da-d-flex">
                    {'NOTE: You can\'t edit project details instead field "Name" after creating the project.'}
                  </div>
                </Col>
              </Row>
            )}

            <Row gutter={[ 16, 16 ]}>
              <FormCreateEditProjectButtonsRow>
                <FormCreateEditProjectButtonWr>
                  <Button onClick={onCancel}>
                    Cancel
                  </Button>
                </FormCreateEditProjectButtonWr>
                <FormCreateEditProjectButtonWr>
                  {isEditProject ? (
                    <SubmitButton
                      style={{ width: '100%' }}
                      loading={isSubmitting}
                      disabled={!isValid || !dirty}
                    >
                      Update
                    </SubmitButton>
                  ) : (
                    <Popconfirm
                      title="Sure to create the project?"
                      disabled={!isValid || !dirty}
                      onConfirm={() => onSubmit(values)}
                    >
                      <Button
                        style={{ width: '100%' }}
                        type="primary"
                        loading={isSubmitting}
                        disabled={!isValid || !dirty}
                      >
                        Create
                      </Button>
                    </Popconfirm>
                  )}
                </FormCreateEditProjectButtonWr>
              </FormCreateEditProjectButtonsRow>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditProject.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditProject: PropTypes.bool,
  isGlobalAdmin: PropTypes.bool,
  disableSpaceField: PropTypes.bool,
  spacesList: PropTypes.array,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  values: PropTypes.shape({
    space_id: PropTypes.string,
    status: PropTypes.string,
  }),
};

FormCreateEditProject.defaultProps = {
  formErrors: {},
  isEditProject: false,
  isGlobalAdmin: false,
  disableSpaceField: false,
  spacesList: [],
};

export default FormCreateEditProject;
