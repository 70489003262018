import { getUrlProjectSettings } from '../lib/getUrlProjectSettings';



export default (builder) => (...paramObjects) => {
  const { spaceKey, projectKey } = getUrlProjectSettings();
  const prefix = `${spaceKey ? `/${spaceKey}` : ''}${projectKey ? `/${projectKey}` : ''}`;
  const url = builder(Object.assign({}, ...paramObjects));
  const urlHasSlash = String(url).startsWith('/');

  if (!urlHasSlash) {
    throw new ReferenceError(`url (${url}) not start with slash`);
  }
  return `${prefix}${url}`;
};
