import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../../../services/Api/Api';
import SelectProjectsService from '../services/projectsOptionsService';



const initialState = {
  data: [],
  errors: [],
  loading: false,
};

const selectProjectsService = new SelectProjectsService(Api);

export const getSpacesAndProjectsListData = createAsyncThunk(
  'projects/getSpacesAndProjectsList',
  async () => {
    const response = await selectProjectsService.getSpacesAndProjects();

    return response.data;
  },
);

export const spacesAndProjectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpacesAndProjectsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSpacesAndProjectsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getSpacesAndProjectsListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

// actions
export const { resetData } = spacesAndProjectsSlice.actions;

// selectors
export const selectProjectsLoading = (state) => state.projectsOptions.loading;
export const selectProjectsList = (state) => state.projectsOptions.data;
export const selectErrors = (state) => state.projectsOptions.errors;

// reducer
export default spacesAndProjectsSlice.reducer;
