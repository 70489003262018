export const mkClearFormTableParams = (obj) => {
  const disabledFields = [ 'page', 'limit', 'orderBy', 'sortedBy' ];

  disabledFields.forEach((field) => {
    if (obj.hasOwnProperty(field)) {
      // eslint-disable-next-line fp/no-delete
      delete obj[field];
    }
  });

  return obj;
};
