import isEmpty from 'lodash/isEmpty';
import addUrlParam from './addUrlParam';



export const setRequestParams = (params) => {
  if (!isEmpty(params)) {
    return `?${addUrlParam(params)}`;
  }
  return '';
};
