import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Layout, Row, Table } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import {
  USER_PROFILE_PICTURES_TABLE_COLUMNS,
  USER_PROFILE_PURCHASES_TABLE_COLUMNS,
} from './PageUserProfileDetailsDeviceDetails.const';
import DeviceInformation from '../../components/DeviceInformation';
import DeviceAbTestsDrawer from '../../components/DeviceAbTestsDrawer';
import DeviceCurrentAbTestCard from '../../components/DeviceCurrentAbTestCard';
import {
  getUserProfileDeviceAbTestsData,
  getUserProfileDevicePicturesData,
  getUserProfileDevicePurchasesData,
  selectUserDeviceAbTests,
  selectUserDeviceAbTestsLoading,
  selectUserDevicePictures,
  selectUserDevicePicturesLoading,
  selectUserDevicePurchases,
  selectUserDevicePurchasesLoading,
} from '../../feature/userProfileDeviceDetailsSlice';
import { hasRights, Permissions } from '../../../../const/permissions';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import { getQueryParams } from '../../../../lib/getQueryParams';
import HasRights from '../../../../components/HasRights';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const { Content } = Layout;

const PageUserProfileDetailsDeviceDetails = () => {
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const { deviceId } = useParams();
  const [ isDrawerVisible, setDrawerVisible ] = useState(false);

  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);

  const splitLocation = pathname.split('/');
  const isAbTestsPage = splitLocation[splitLocation.length - 1] === 'ab-tests';
  const isPurchasesPage = splitLocation[splitLocation.length - 1] === 'purchases';
  const isPicsPage = splitLocation[splitLocation.length - 1] === 'pictures';

  const deviceAbTests = useSelector(selectUserDeviceAbTests);
  const deviceAbTestsLoading = useSelector(selectUserDeviceAbTestsLoading);
  const devicePurchases = useSelector(selectUserDevicePurchases);
  const devicePurchasesLoading = useSelector(selectUserDevicePurchasesLoading);
  const devicePictures = useSelector(selectUserDevicePictures);
  const devicePicturesLoading = useSelector(selectUserDevicePicturesLoading);

  const deviceData = state?.deviceData ?? {};
  const deviceDataLoading = state?.isLoading ?? false;

  useEffect(() => {
    const params = mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting);

    if (isAbTestsPage && hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_AB_TESTS ])) {
      dispatch(getUserProfileDeviceAbTestsData({ deviceId, params }));
    }
    if (isPurchasesPage && hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PURCHASES ])) {
      dispatch(getUserProfileDevicePurchasesData({ deviceId, params }));
    }
    if (isPicsPage && hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PICTURES ])) {
      dispatch(getUserProfileDevicePicturesData({ deviceId, params }));
    }
  }, [ currentPage, currentLimit, currentOrder, currentSorting ]);

  const handleShowDrawer = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <HasRights allowedPermissions={[ Permissions.USER_PROFILES.VIEW_ALL ]}>
      {hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_DEVICE_DETAILS ]) && (
        <DeviceInformation
          deviceData={deviceData}
          deviceDataLoading={deviceDataLoading}
        />
      )}

      {hasRights([
        Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_AB_TESTS,
        Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PURCHASES,
        Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PICTURES,
      ]) && (
        <Row align="middle" justify="space-between" className='da-pt-24'>
          <Col span={24}>
            <h3>{`Device ${splitLocation[splitLocation.length - 1]}`}</h3>
          </Col>
          <Content className='layout-content da-overflow-scroll padding-unset' id='userDevicesTopCmp'>
            {isAbTestsPage && (
              <div className='table-title-content-wr da-mb-10'>
                <div className='table-title'>
                  Current Ab-test
                </div>
                <Button
                  className='da-mb-10'
                  size='small'
                  type="primary"
                  disabled={isEmpty(deviceAbTests.history)}
                  onClick={handleShowDrawer}
                >
                  Show history
                </Button>
              </div>
            )}
            <Card className='card-has-table' style={{ margin: 'unset' }}>
              {isAbTestsPage && hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_AB_TESTS ]) && (
                <>
                  <Card style={{ margin: 'unset' }}>
                    <DeviceCurrentAbTestCard
                      abTestData={deviceAbTests.current}
                      deviceAbTestsLoading={deviceAbTestsLoading}
                    />
                  </Card>
                  <DeviceAbTestsDrawer
                    handleShowDrawer={handleShowDrawer}
                    handleOnClose={handleCloseDrawer}
                    visible={isDrawerVisible}
                    // deviceName={deviceData?.info?.model || EMPTY_VALUE_PLACEHOLDER}
                    deviceName={String(deviceData?.id) || EMPTY_VALUE_PLACEHOLDER}
                    deviceHistory={deviceAbTests.history}
                  />
                </>
              )}
              {isPurchasesPage && hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PURCHASES ]) && (
                <Table
                  loading={devicePurchasesLoading}
                  columns={USER_PROFILE_PURCHASES_TABLE_COLUMNS}
                  rowKey="id"
                  dataSource={devicePurchases}
                  onChange={handleChangeTableParams}
                  pagination={{
                    position: [ 'bottomCenter' ],
                    pageSizeOptions: [ '10', '25', '50' ],
                    size: 'small',
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                  }}
                />
              )}
              {isPicsPage && hasRights([ Permissions.USER_PROFILES.USER_PROFILES_LIST.VIEW_PICTURES ]) && (
                <Table
                  loading={devicePicturesLoading}
                  columns={USER_PROFILE_PICTURES_TABLE_COLUMNS}
                  rowKey="id"
                  dataSource={devicePictures}
                  onChange={handleChangeTableParams}
                  pagination={{
                    position: [ 'bottomCenter' ],
                    pageSizeOptions: [ '10', '25', '50' ],
                    size: 'small',
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                  }}
                />
              )}
            </Card>
          </Content>
        </Row>
      )}
    </HasRights>
  );
};

export default PageUserProfileDetailsDeviceDetails;
