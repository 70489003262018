import { setRequestParams } from '../../../lib/setRequestParams';



export default class ConfigurationRequestService {
  constructor (Api) {
    this.api = Api;
  }

  getRequestList (params) {
    return this.api.get(`visualize/config/requests${setRequestParams(params)}`);
  }

  createRequest (values) {
    return this.api.post('visualize/config/request', values);
  }

  getRequestConfigPreviewData (id) {
    return this.api.get(`visualize/config/request/${id}`);
  }

  saveCreatedRequest (values) {
    return this.api.post('visualize/config/save', values);
  }

  visualizeServerConfigRequest (values) {
    return this.api.post('visualize/config', values);
  }

  deleteRequest (requestId) {
    return this.api.delete(`visualize/config/request/${requestId}`);
  }
}
