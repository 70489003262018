import React, { useEffect } from 'react';
import { Breadcrumb, Col, Divider, Layout, Row } from 'antd';
import {
  RiBarChartLine,
  RiCheckLine,
  RiFundsBoxLine,
  RiGamepadLine,
  RiRestartLine,
  RiTimeLine,
  RiUserFollowLine,
  RiUserHeartLine,
  RiUserReceivedLine,
} from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import DashboardPlaceholder from './components/DashboardPlaceholder';
import WidgetItemStatistic from '../../Support/widgets/WidgetItemStatistic';
import WidgetTableCard from '../../Support/widgets/WidgetTableCard';
import WidgetColumnCard from '../../Support/widgets/WidgetColumnCard';
import WidgetItemMetric from '../../Support/widgets/WidgetItemMetric';
import WidgetMetricCharts from '../../Support/widgets/WidgetMetricCharts';
import WidgetMetricPieChart from '../../Support/widgets/WidgetMetricPieChart';
import { useApp } from '../../../app/context/AppContext';
import { hasRights, Permissions } from '../../../const/permissions';
import { getPreparedChartsData } from '../../../lib/getPreparedChartsData';
import { getGameMonitoringData, selectGameData, selectGameLoading } from '../../Monitor/feature/gamesMonitorSlice';
import { getAppMonitoringData, selectAppsData, selectAppsLoading } from '../../Monitor/feature/applicationMonitorSlice';
import { getAdsMonitoringData, selectAdsData, selectAdsLoading } from '../../Monitor/feature/adsMonitorSlice';
import {
  getActiveProjectsWidgetData,
  getAvgTapsWidgetData,
  getAvgTimeWidgetData,
  getBeeActiveUsersWidgetData,
  getFinishedProjectsWidgetData,
  getGameSessionsWidgetData,
  getImpressionsUsersWidgetData,
  getNewTicketsWidgetData,
  getNewUsersWidgetData,
  getPlatformOverviewWidgetData,
  getProblemsOverviewWidgetData,
  getReopenedProjectsWidgetData,
  getSessionUsersWidgetData,
  getTicketsByAppVersionWidgetData,
  getTicketsByPlatformWidgetData,
  getTicketsChannelsWidgetData,
  getTicketsPerDayWidgetData,
  getTimeSpendingWidgetData,
  selectActiveProjectsData,
  selectActiveProjectsLoading,
  selectAvgTapsData,
  selectAvgTapsLoading,
  selectAvgTimeData,
  selectAvgTimeLoading,
  selectBeeActiveUsersData,
  selectBeeActiveUsersLoading,
  selectFinishedProjectsData,
  selectFinishedProjectsLoading,
  selectGameSessionsData,
  selectGameSessionsLoading,
  selectImpressionsUsersData,
  selectImpressionsUsersLoading,
  selectNewTicketsData,
  selectNewTicketsLoading,
  selectNewUsersData,
  selectNewUsersLoading,
  selectPlatformOverviewData,
  selectPlatformOverviewLoading,
  selectProblemsOverviewData,
  selectProblemsOverviewLoading,
  selectReopenedProjectsData,
  selectReopenedProjectsLoading,
  selectSessionUsersData,
  selectSessionUsersLoading,
  selectTicketsByAppVersionData,
  selectTicketsByAppVersionLoading,
  selectTicketsByPlatformData,
  selectTicketsByPlatformLoading,
  selectTicketsChannelsData,
  selectTicketsChannelsLoading,
  selectTicketsPerDayData,
  selectTicketsPerDayLoading,
  selectTimeSpendingData,
  selectTimeSpendingLoading,
} from './feature/dashboardSlice';
import {
  PLATFORM_OVERVIEW_WIDGET_COLUMNS, TICKETS_BY_APP_VERSION_WIDGET_COLUMNS,
  TICKETS_BY_PLATFORM_WIDGET_COLUMNS,
  TICKETS_CHANNELS_WIDGET_COLUMNS,
} from './PageDashboard.const';
import illustration from '../../../assets/images/illustrations/hello-image.svg';
import illustration2 from '../../../assets/images/illustrations/newsletter-1.svg';
import { MAIN_PROJECT_KEY } from '../../../const/system';



const { Content } = Layout;


const PageDashboard = () => {
  const dispatch = useDispatch();
  const { projectSettings, userSpaces } = useApp();
  const gameChartsData = useSelector(selectGameData);
  const isGameLoading = useSelector(selectGameLoading);
  const appChartsData = useSelector(selectAppsData);
  const isAppsLoading = useSelector(selectAppsLoading);
  const adsChartsData = useSelector(selectAdsData);
  const isAdsLoading = useSelector(selectAdsLoading);

  const newUsersData = useSelector(selectNewUsersData);
  const newUsersLoading = useSelector(selectNewUsersLoading);
  const gameSessionsData = useSelector(selectGameSessionsData);
  const gameSessionsLoading = useSelector(selectGameSessionsLoading);
  const sessionUsersData = useSelector(selectSessionUsersData);
  const sessionUsersLoading = useSelector(selectSessionUsersLoading);
  const impressionsUsersData = useSelector(selectImpressionsUsersData);
  const impressionsUsersLoading = useSelector(selectImpressionsUsersLoading);

  const platformOverviewData = useSelector(selectPlatformOverviewData);
  const platformOverviewLoading = useSelector(selectPlatformOverviewLoading);
  const newTicketsData = useSelector(selectNewTicketsData);
  const newTicketsLoading = useSelector(selectNewTicketsLoading);
  const ticketsPerDayData = useSelector(selectTicketsPerDayData);
  const ticketsPerDayLoading = useSelector(selectTicketsPerDayLoading);
  const ticketsByPlatformData = useSelector(selectTicketsByPlatformData);
  const ticketsByPlatformLoading = useSelector(selectTicketsByPlatformLoading);
  const ticketsChannelsData = useSelector(selectTicketsChannelsData);
  const ticketsChannelsLoading = useSelector(selectTicketsChannelsLoading);
  const ticketsByAppVersionData = useSelector(selectTicketsByAppVersionData);
  const ticketsByAppVersionLoading = useSelector(selectTicketsByAppVersionLoading);
  const problemsOverviewData = useSelector(selectProblemsOverviewData);
  const problemsOverviewLoading = useSelector(selectProblemsOverviewLoading);
  const beeActiveUsersData = useSelector(selectBeeActiveUsersData);
  const beeActiveUsersLoading = useSelector(selectBeeActiveUsersLoading);
  const finishedProjectsData = useSelector(selectFinishedProjectsData);
  const finishedProjectsLoading = useSelector(selectFinishedProjectsLoading);
  const reopenedProjectsData = useSelector(selectReopenedProjectsData);
  const reopenedProjectsLoading = useSelector(selectReopenedProjectsLoading);
  const avgTimeData = useSelector(selectAvgTimeData);
  const avgTimeLoading = useSelector(selectAvgTimeLoading);
  const avgTapsData = useSelector(selectAvgTapsData);
  const avgTapsLoading = useSelector(selectAvgTapsLoading);
  const activeProjectsData = useSelector(selectActiveProjectsData);
  const activeProjectsLoading = useSelector(selectActiveProjectsLoading);
  const timeSpendingData = useSelector(selectTimeSpendingData);
  const timeSpendingLoading = useSelector(selectTimeSpendingLoading);

  const gameMonitoringChartsData = getPreparedChartsData(gameChartsData, { game_finished: 0, active_users: 0, app_open: 0 });
  const appMonitoringChartsData = getPreparedChartsData(appChartsData, { app_install: 0, app_confirmation: 0 });
  const adsMonitoringChartsData = getPreparedChartsData(adsChartsData, { impressions_per_user: 0, ecpm: 0 });
  const timeSpendingChartsData = getPreparedChartsData(timeSpendingData, {}, 'DD.MM');

  const pollingInterval = 300000; //5min

  useEffect(() => {
    if (projectSettings.projectKey === MAIN_PROJECT_KEY) {
      if (hasRights([
        Permissions.MONITORING.ADS_MONITOR.VIEW_PAGE,
        Permissions.MONITORING.APPLICATION_MONITOR.VIEW_PAGE,
        Permissions.MONITORING.GAMES_MONITOR.VIEW_PAGE,
      ], true)) {
        dispatch(getPlatformOverviewWidgetData());
      }
      if (hasRights([ Permissions.SUPPORT.TICKET.VIEW_PAGE ])) {
        dispatch(getNewTicketsWidgetData());
        dispatch(getTicketsPerDayWidgetData());
        dispatch(getTicketsByPlatformWidgetData());
        dispatch(getTicketsChannelsWidgetData());
        dispatch(getTicketsByAppVersionWidgetData());
        dispatch(getProblemsOverviewWidgetData());
      }
      if (hasRights([ Permissions.MONITORING.GAMES_MONITOR.VIEW_PAGE ])) {
        dispatch(getGameMonitoringData());
        dispatch(getGameSessionsWidgetData());
        dispatch(getSessionUsersWidgetData());
      }
      if (hasRights([ Permissions.MONITORING.APPLICATION_MONITOR.VIEW_PAGE ])) {
        dispatch(getAppMonitoringData());
        dispatch(getNewUsersWidgetData());
      }
      if (hasRights([ Permissions.MONITORING.ADS_MONITOR.VIEW_PAGE ])) {
        dispatch(getAdsMonitoringData());
        dispatch(getImpressionsUsersWidgetData());
      }
      if (hasRights([ Permissions.MONITORING.BEE_FREELANCER.VIEW_PAGE ])) {
        dispatch(getBeeActiveUsersWidgetData());
        dispatch(getActiveProjectsWidgetData());
        dispatch(getFinishedProjectsWidgetData());
        dispatch(getReopenedProjectsWidgetData());
        dispatch(getAvgTimeWidgetData());
        dispatch(getAvgTapsWidgetData());
        dispatch(getTimeSpendingWidgetData());
      }
    }

    const applicationWidgetsIntervalId = setInterval(() => {
      const allowPolling = hasRights([ Permissions.MONITORING.APPLICATION_MONITOR.VIEW_PAGE ]);

      return allowPolling &&
        dispatch(getNewUsersWidgetData()) &&
        dispatch(getAppMonitoringData());
    }, pollingInterval);

    const gamesWidgetsIntervalId = setInterval(() => {
      const allowPolling = hasRights([ Permissions.MONITORING.GAMES_MONITOR.VIEW_PAGE ]);

      return allowPolling &&
        dispatch(getSessionUsersWidgetData()) &&
        dispatch(getGameMonitoringData()) &&
        dispatch(getGameSessionsWidgetData());
    }, pollingInterval);

    const adsWidgetsIntervalId = setInterval(() => {
      const allowPolling = hasRights([ Permissions.MONITORING.ADS_MONITOR.VIEW_PAGE ]);

      return allowPolling &&
        dispatch(getImpressionsUsersWidgetData()) &&
        dispatch(getAdsMonitoringData());
    }, pollingInterval);

    const platformOverviewIntervalId = setInterval(() => {
      const allowPolling = hasRights([
        Permissions.MONITORING.ADS_MONITOR.VIEW_PAGE,
        Permissions.MONITORING.APPLICATION_MONITOR.VIEW_PAGE,
        Permissions.MONITORING.GAMES_MONITOR.VIEW_PAGE,
      ], true);

      return allowPolling && dispatch(getPlatformOverviewWidgetData());
    }, pollingInterval);

    const supportWidgetsIntervalId = setInterval(() => {
      const allowPolling = hasRights([ Permissions.SUPPORT.TICKET.VIEW_PAGE ]);

      return allowPolling &&
        dispatch(getNewTicketsWidgetData()) &&
        dispatch(getTicketsByPlatformWidgetData()) &&
        dispatch(getTicketsChannelsWidgetData()) &&
        dispatch(getTicketsByAppVersionWidgetData()) &&
        dispatch(getProblemsOverviewWidgetData()) &&
        dispatch(getTicketsPerDayWidgetData());
    }, pollingInterval);

    const beeFreelancerWidgetsIntervalId = setInterval(() => {
      const allowPolling = hasRights([ Permissions.MONITORING.BEE_FREELANCER.VIEW_PAGE ]);

      return allowPolling &&
      dispatch(getBeeActiveUsersWidgetData()) &&
      dispatch(getActiveProjectsWidgetData()) &&
      dispatch(getFinishedProjectsWidgetData()) &&
      dispatch(getReopenedProjectsWidgetData()) &&
      dispatch(getAvgTimeWidgetData()) &&
      dispatch(getAvgTapsWidgetData()) &&
      dispatch(getTimeSpendingWidgetData());
    }, pollingInterval);

    return () => {
      clearInterval(applicationWidgetsIntervalId);
      clearInterval(gamesWidgetsIntervalId);
      clearInterval(adsWidgetsIntervalId);
      clearInterval(supportWidgetsIntervalId);
      clearInterval(platformOverviewIntervalId);
      clearInterval(beeFreelancerWidgetsIntervalId);
    };
  }, [ projectSettings, userSpaces ]);

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <Content className='layout-content'>
        <div className="site-layout-background site-layout-container">
          {projectSettings.projectKey === MAIN_PROJECT_KEY ? (
            <>
              {hasRights([ Permissions.MONITORING.GAMES_MONITOR.VIEW_PAGE ]) && (
                <>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Games
                  </Divider>
                  <Row gutter={8}>
                    <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                      <Row gutter={[ 16, 16 ]}>
                        <Col xs={24} sm={12} md={12} lg={24} xl={24}>
                          <WidgetItemMetric
                            title='Game / Sessions'
                            data={gameSessionsData}
                            isLoading={gameSessionsLoading}
                            icon={
                              <RiGamepadLine
                                className="da-text-color-dark-0"
                                fill='#4887df'
                                size={24}
                              />
                            }
                          />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={24} xl={24}>
                          <WidgetItemMetric
                            title='Session / Users'
                            data={sessionUsersData}
                            isLoading={sessionUsersLoading}
                            icon={
                              <RiUserHeartLine
                                className="da-text-color-dark-0"
                                fill='#4887df'
                                size={24}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                      <WidgetMetricCharts
                        title='Game monitoring'
                        data={gameMonitoringChartsData}
                        isLoading={isGameLoading}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {hasRights([ Permissions.MONITORING.APPLICATION_MONITOR.VIEW_PAGE ]) && (
                <>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Applications
                  </Divider>
                  <Row gutter={8}>
                    <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                      <WidgetMetricCharts
                        title='App monitoring'
                        data={appMonitoringChartsData}
                        isLoading={isAppsLoading}
                      />
                    </Col>
                    <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <WidgetItemMetric
                            image={
                              <img src={illustration} alt="img" style={{ height: '11.5rem' }} />
                            }
                            title='New users'
                            data={newUsersData}
                            isLoading={newUsersLoading}
                            icon={
                              <RiUserReceivedLine
                                className="da-text-color-dark-0"
                                fill='#4887df'
                                size={24}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}

              {hasRights([ Permissions.MONITORING.ADS_MONITOR.VIEW_PAGE ]) && (
                <>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Advertise
                  </Divider>
                  <Row gutter={8}>
                    <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <WidgetItemMetric
                            image={
                              <img src={illustration2} alt="img2" style={{ height: '11.5rem' }} />
                            }
                            title='Impressions / Active user'
                            data={impressionsUsersData}
                            isLoading={impressionsUsersLoading}
                            icon={
                              <RiFundsBoxLine
                                className="da-text-color-dark-0"
                                fill='#4887df'
                                size={24}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                      <WidgetMetricCharts
                        title='ADs monitoring'
                        data={adsMonitoringChartsData}
                        isLoading={isAdsLoading}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {hasRights([ Permissions.MONITORING.BEE_FREELANCER.VIEW_PAGE ]) && (
                <>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Bee freelancer
                  </Divider>
                  <Row gutter={8}>
                    <Col xs={24} sm={12} md={8} xxl={4}>
                      <WidgetItemMetric
                        title='Bee active users'
                        data={beeActiveUsersData}
                        isLoading={beeActiveUsersLoading}
                        icon={
                          <RiUserFollowLine
                            className="da-text-color-dark-0"
                            fill='#4887df'
                            size={24}
                          />
                        }
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} xxl={5}>
                      <WidgetItemMetric
                        title='Finished projects'
                        data={finishedProjectsData}
                        isLoading={finishedProjectsLoading}
                        icon={
                          <RiCheckLine
                            className="da-text-color-dark-0"
                            fill='#4887df'
                            size={24}
                          />
                        }
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} xxl={5}>
                      <WidgetItemMetric
                        title='Reopened projects'
                        data={reopenedProjectsData}
                        isLoading={reopenedProjectsLoading}
                        icon={
                          <RiRestartLine
                            className="da-text-color-dark-0"
                            fill='#4887df'
                            size={24}
                          />
                        }
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} xxl={5}>
                      <WidgetItemMetric
                        hasDate
                        title='Avg time on task'
                        data={avgTimeData}
                        isLoading={avgTimeLoading}
                        icon={
                          <RiTimeLine
                            className="da-text-color-dark-0"
                            fill='#4887df'
                            size={24}
                          />
                        }
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} xxl={5}>
                      <WidgetItemMetric
                        title='Avg taps'
                        data={avgTapsData}
                        isLoading={avgTapsLoading}
                        icon={
                          <RiBarChartLine
                            className="da-text-color-dark-0"
                            fill='#4887df'
                            size={24}
                          />
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col xs={24} sm={24} md={8} xxl={8}>
                      <WidgetMetricPieChart
                        title='Active projects'
                        data={activeProjectsData}
                        isLoading={activeProjectsLoading}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={16} xxl={16}>
                      <WidgetMetricCharts
                        hasTooltipDate
                        title='Time spending'
                        sinceTitle='Last 30 days'
                        data={timeSpendingChartsData}
                        XLabel={{ value: 'Days', position: 'insideBottomRight', offset: -10 }}
                        YLabel={{ value: 'Time (sec)', angle: -90, position: 'insideLeft', offset: 25 }}
                        isLoading={timeSpendingLoading}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {hasRights([
                Permissions.MONITORING.ADS_MONITOR.VIEW_PAGE,
                Permissions.MONITORING.APPLICATION_MONITOR.VIEW_PAGE,
                Permissions.MONITORING.GAMES_MONITOR.VIEW_PAGE,
              ], true) && (
                <>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Monitoring general
                  </Divider>
                  <Row gutter={8}>
                    <Col span={24}>
                      <WidgetTableCard
                        title='Platform overview'
                        data={platformOverviewData}
                        columns={PLATFORM_OVERVIEW_WIDGET_COLUMNS}
                        isLoading={platformOverviewLoading}
                        rowKey="app_version"
                      />
                    </Col>
                  </Row>
                </>
              )}

              {hasRights([ Permissions.SUPPORT.TICKET.VIEW_PAGE ]) && (
                <>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Support
                  </Divider>
                  <Row gutter={8}>
                    <Col span={24} xl={24}>
                      <WidgetColumnCard
                        data={problemsOverviewData}
                        isLoading={problemsOverviewLoading}
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                      <Row gutter={[ 16, 16 ]}>
                        <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                          <WidgetItemStatistic
                            title='New tickets'
                            info='Count of new tickets'
                            data={newTicketsData}
                            isLoading={newTicketsLoading}
                            footerText='Since last month'
                          />
                        </Col>
                        <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                          <WidgetItemStatistic
                            title='Tickets per day'
                            info='Count of tickets per day'
                            data={ticketsPerDayData}
                            isLoading={ticketsPerDayLoading}
                            footerText='Since previous week'
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={18} lg={16} md={16} sm={24} xs={24}>
                      <WidgetTableCard
                        title='Channels'
                        data={ticketsChannelsData}
                        isLoading={ticketsChannelsLoading}
                        columns={TICKETS_CHANNELS_WIDGET_COLUMNS}
                        customClassname='disabled-width'
                        rowKey="channel"
                      />
                    </Col>
                  </Row>

                  <Row gutter={[ 16, 16 ]}>
                    <Col xl={12} md={12} sm={12} xs={24}>
                      <WidgetTableCard
                        title='Tickets by platform'
                        data={ticketsByPlatformData}
                        isLoading={ticketsByPlatformLoading}
                        columns={TICKETS_BY_PLATFORM_WIDGET_COLUMNS}
                        customClassname='disabled-width'
                        rowKey="platform"
                      />
                    </Col>
                    <Col xl={12} md={12} sm={12} xs={24}>
                      <WidgetTableCard
                        title='Tickets by app version'
                        data={ticketsByAppVersionData}
                        isLoading={ticketsByAppVersionLoading}
                        columns={TICKETS_BY_APP_VERSION_WIDGET_COLUMNS}
                        customClassname='disabled-width'
                        rowKey="version"
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <DashboardPlaceholder />
          )}
        </div>
      </Content>
    </>
  );
};

export default PageDashboard;
