import moment from 'moment';
import React from 'react';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const preparedExportValues = (data) => data.map((item) => {
  return {
    date: item.date,
    contact_us_click: item.contact_us_click,
  };
});

export const TABLE_CONTACT_US_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.date, 'DD.MM.YYYY HH:mm') >= moment(_b.date, 'DD.MM.YYYY HH:mm') ? 1 : -1),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Сontact us click',
    dataIndex: 'contact_us_click',
    align: 'center',
    sorter: (_a, _b) => Number(_a.contact_us_click) - Number(_b.contact_us_click),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
];
