import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Input, Layout, Modal, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RiCloseFill } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { SearchOutlined } from '@ant-design/icons';
// eslint-disable-next-line lodash/import-scope
import { debounce } from 'lodash';
import { useApp } from '../../../../../app/context/AppContext';
import { getUsersListTableColumns } from './PageUsers.const';
import { hasRights, Permissions } from '../../../../../const/permissions';
import FormCreateEditUser from './FormCreateEditUser';
import { getQueryParams } from '../../../../../lib/getQueryParams';
import { mkQueryParams } from '../../../../../lib/mkQueryParams';
import { isFulfilledRequestStatus } from '../../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../../lib/mkRedirectToDashboard';
import { useCurrentSettingsScope } from '../../../../../app/hooks';
import {
  createUser,
  deleteUser,
  getUsersListData,
  resetFormCreateEditUserErrors,
  resetUsersListData,
  selectFormCreateEditUserErrors,
  selectFormCreateEditUserLoading,
  selectUsersList,
  selectUsersLoading,
  selectUsersPagination,
} from '../../feature/usersSlice';



const { Content } = Layout;

const PageUsers = () => {
  const { pathname, search } = useLocation();
  const { projectSettings, userSpaces } = useApp();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ isActiveUserModal, setToggleCreateUserModal ] = useState(false);
  const [ searchText, setSearchText ] = useState('');
  const settingsScope = useCurrentSettingsScope();

  const dispatch = useDispatch();
  const usersList = useSelector(selectUsersList);
  const pagination = useSelector(selectUsersPagination);
  const isLoadingUsers = useSelector(selectUsersLoading);
  const formCreateUserErrors = useSelector(selectFormCreateEditUserErrors);
  const isFormCreateUserLoading = useSelector(selectFormCreateEditUserLoading);


  const handleGetUsers = () => {
    dispatch(getUsersListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting, searchText)));
  };

  useEffect(() => {
    handleGetUsers();

    return () => {
      dispatch(resetUsersListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting, searchText ]);


  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);


  const handleDeleteUser = async (userId) => {
    await dispatch(deleteUser(userId)).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        handleGetUsers();
      }
    });
  };

  const showCreateUserModal = () => {
    setToggleCreateUserModal(true);
  };

  const hideCreateUserModal = () => {
    setToggleCreateUserModal(false);
    dispatch(resetFormCreateEditUserErrors());
  };

  const handleSubmitCreateUserForm = async (values) => {
    await dispatch(createUser(values))
      .then((response) => {
        if (isFulfilledRequestStatus(response)) {
          hideCreateUserModal();
          handleGetUsers();
        }
      });
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <>
      <Modal
        title="Create user"
        width={416}
        centered
        destroyOnClose
        visible={isActiveUserModal}
        onCancel={hideCreateUserModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <FormCreateEditUser
          initialValues={{}}
          spacesOptions={userSpaces}
          formErrors={formCreateUserErrors}
          onSubmit={handleSubmitCreateUserForm}
          isSubmitting={isFormCreateUserLoading}
          spaceKey={projectSettings?.spaceKey}
        />
      </Modal>

      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Users list</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Input
              placeholder="Search..."
              allowClear={!isEmpty(searchText)}
              style={{ width: 230, margin: '10px 0' }}
              onChange={debouncedSearch}
              prefix={<SearchOutlined fill='#9393ab' />}
            />
            {hasRights([ Permissions.ADMINISTRATION.USERS.USERS_LIST.CREATE_USER ]) && (
              <Button
                style={{ margin: '10px 0 10px 15px' }}
                size='middle'
                type="primary"
                onClick={showCreateUserModal}
              >
                Create user
              </Button>
            )}
          </div>
        </Row>
      </Col>

      <Content className='layout-content'>
        <Card className='da-mb-24'>
          <Table
            sticky
            loading={isLoadingUsers}
            rowKey="id"
            columns={getUsersListTableColumns(handleDeleteUser, settingsScope)}
            dataSource={usersList}
            onChange={handleChangeTableParams}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 550 }}
          />
        </Card>
      </Content>
    </>
  );
};


export default PageUsers;
