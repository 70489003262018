import { Card, Empty, Timeline } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { CommentOutlined, MessageOutlined } from '@ant-design/icons';
import { renderDateField } from '../../../../const/system';



const MixinsExperimentHistoryCard = ({ data, isLoading }) => {
  return (
    <Card className='card-has-table' loading={isLoading}>
      {!isEmpty(data) ? (
        <div className='da-mt-16'>
          <Timeline>
            {data.map((item) => (
              <div key={item.id}>
                <Timeline.Item><b>{renderDateField(item.created_at, 'DD-MM-YYYY HH:mm:ss')}</b></Timeline.Item>
                {!isEmpty(item?.message) && (
                  <Timeline.Item color='gray' dot={<MessageOutlined />}>
                    {item.message}
                  </Timeline.Item>
                )}
                {!isEmpty(item?.comment) && (
                  <Timeline.Item color='red' dot={<CommentOutlined />}>
                    {item.comment}
                  </Timeline.Item>
                )}
              </div>
            ))}
          </Timeline>
        </div>
      ) : <Empty />}
    </Card>
  );
};


MixinsExperimentHistoryCard.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
};

MixinsExperimentHistoryCard.defaultProps = {
  isLoading: false,
  data: [],
};

export default MixinsExperimentHistoryCard;
