export default class ConfigurationMixinDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  updateConfigMixinDetails ({ mixinId, data }) {
    return this.api.put(`configs/mixins/${mixinId}`, data);
  }

  getConfigMixinDetails (mixinId) {
    return this.api.get(`configs/mixins/${mixinId}?include=test`);
  }

  getConfigMixinTargetDetails (mixinId) {
    return this.api.get(`configs/mixins/${mixinId}/targets`);
  }

  createConfigMixinTargeting ({ mixinId, data }) {
    return this.api.post(`configs/mixins/${mixinId}/targets`, data);
  }

  updateConfigMixinTargeting ({ mixinId, targetingId, data }) {
    return this.api.put(`configs/mixins/${mixinId}/targets/${targetingId}`, data);
  }

  deleteConfigMixinTargeting ({ mixinId, targetingId }) {
    return this.api.delete(`configs/mixins/${mixinId}/targets/${targetingId}`);
  }

}
