import React from 'react';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Col, Row, Spin } from 'antd';
import Yup from '../../../../vendor/yup';
import { mkSelectFilterOption } from '../../../../lib/mkSelectFilterOption';



const { Option } = Select;

const FormSelectStructureButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormSelectStructureButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Min 2 symbols required')
    .max(100, 'Max 100 symbols required')
    .required('Name field is required'),
  status: Yup.string().nullable().required('Required to select status'),
  structure_id: Yup.string().nullable().required('Required to select structure'),
  priority: Yup.number().typeError('Specify a number').min(1, 'Should be positive number').max(10000, 'Should be less or equal 10000').required('Required field'),
});


const FormSelectStructure = ({
  onSubmit,
  onCancel,
  isEditConfig,
  isLoading,
  structuresOptionsList,
  initialValues,
  formErrors,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnMount
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, values, setFieldValue } = props;

        return (
          <Form layout="vertical" style={{ width: '100%' }}>
            <Spin spinning={isLoading} tip="Loading structures. Please wait...">
              <Row gutter={[ 16, 16 ]}>
                <Col span={24}>
                  <Row gutter={[ 16, 16 ]}>
                    <Col xs={24} sm={12} lg={6}>
                      <FormItem
                        className='ant-form-item-col'
                        name="name"
                        label="Name:"
                        required
                      >
                        <Input
                          name='name'
                          placeholder='Specify configuration name'
                        />
                      </FormItem>
                    </Col>

                    <Col xs={24} sm={12} lg={6}>
                      <FormItem
                        className='ant-form-item-col'
                        name="status"
                        label="Status:"
                        required
                      >
                        <Select
                          showSearch
                          name="status"
                          placeholder="Select status"
                          value={values.status}
                          optionFilterProp="children"
                          filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                        >
                          <Option value='enabled'>Enabled</Option>
                          <Option value='paused'>Paused</Option>
                        </Select>
                      </FormItem>
                    </Col>

                    <Col xs={24} sm={12} lg={6}>
                      <FormItem
                        className='ant-form-item-col'
                        name="structure_id"
                        label="Structure:"
                        required={!isEditConfig}
                      >
                        <Select
                          showSearch
                          name="structure_id"
                          placeholder="Select structure"
                          disabled={isEditConfig}
                          value={values.structure_id}
                          optionFilterProp="children"
                          filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                        >
                          {structuresOptionsList.map((option) => {
                            return (
                              <Option key={option.id} value={option.id}>
                                {`${option.name} - ${option.version}`}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>

                    <Col xs={24} sm={12} lg={6}>
                      <FormItem
                        className='ant-form-item-col'
                        name="priority"
                        label="Priority:"
                        required
                      >
                        <Input
                          name='priority'
                          placeholder='Specify priority'
                          onChange={(event) => {
                            setFieldValue('priority', Number(event.target.value.replace(/\D/g, '')));
                          }}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]}>
                <FormSelectStructureButtonsRow>
                  <FormSelectStructureButtonWr>
                    <Button onClick={onCancel}>
                      Cancel
                    </Button>
                  </FormSelectStructureButtonWr>
                  <FormSelectStructureButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      disabled={!isValid}
                    >
                      Next
                    </SubmitButton>
                  </FormSelectStructureButtonWr>
                </FormSelectStructureButtonsRow>
              </Row>
            </Spin>
          </Form>
        );
      }}
    </Formik>
  );
};

FormSelectStructure.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  values: PropTypes.shape({
    structure_id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
  }),
  structuresOptionsList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isEditConfig: PropTypes.bool,
};

FormSelectStructure.defaultProps = {
  formErrors: {},
  isEditConfig: false,
  structuresOptionsList: [],
};

export default FormSelectStructure;
