import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import { mkNotification } from '../../../lib/mkNotification';
import { setUrlParams } from '../../../lib/setUrlParams';
import ConfigurationsService from '../services/configurationsService';



const initialState = {
  configurationsList: [],
  allConfigurationsList: [],
  configurationsPagination: {},
  errors: [],
  loading: false,
  formCreateConfigurationLoading: false,
};

const configurationsService = new ConfigurationsService(Api);

export const getConfigurationsListData = createAsyncThunk(
  'configurations/getConfigurationsList',
  async (values) => {
    const response = await configurationsService.getConfigurationsList(values);

    if (!isEmpty(values)) {
      setUrlParams(values);
    }

    return response;
  },
);

export const getAllConfigurationsListData = createAsyncThunk(
  'configurations/getAllConfigurationsList',
  async (values) => {

    return configurationsService.getConfigurationsList(values);
  },
);

export const createConfiguration = createAsyncThunk(
  'configurations/createConfiguration',
  async (values, { rejectWithValue }) => {
    try {
      const response = await configurationsService.createConfiguration(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteConfiguration = createAsyncThunk(
  'configurations/deleteConfiguration',
  async (configurationId) => {
    const response = await configurationsService.deleteConfiguration(configurationId);

    return response.data;
  },
);

export const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    resetConfigurationsListData: (state) => {
      state.configurationsList = [];
      state.allConfigurationsList = [];
      state.configurationsPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfigurationsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getConfigurationsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.configurationsList = action.payload.data;
        state.configurationsPagination = action.payload.meta.pagination;
      })
      .addCase(getConfigurationsListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.data;
      });

    builder
      .addCase(getAllConfigurationsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllConfigurationsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.allConfigurationsList = action.payload.data;
      })
      .addCase(getAllConfigurationsListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.data;
      });

    builder
      .addCase(createConfiguration.pending, (state) => {
        state.formCreateConfigurationLoading = true;
      })
      .addCase(createConfiguration.fulfilled, (state) => {
        state.formCreateConfigurationLoading = false;
        mkNotification('success', 'Configuration is successfully created.');
      })
      .addCase(createConfiguration.rejected, (state) => {
        state.formCreateConfigurationLoading = false;
      });

    builder
      .addCase(deleteConfiguration.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteConfiguration.fulfilled, (state) => {
        state.loading = false;
        mkNotification('success', 'Configuration is successfully deleted.');
      })
      .addCase(deleteConfiguration.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetConfigurationsListData } = configurationsSlice.actions;

// selectors
export const selectConfigurationsCreateLoading = (state) => state.configurations.formCreateConfigurationLoading;
export const selectConfigurationsLoading = (state) => state.configurations.loading;
export const selectConfigurationsList = (state) => state.configurations.configurationsList;
export const selectConfigurationsPagination = (state) => state.configurations.configurationsPagination;
export const selectErrors = (state) => state.configurations.errors;
export const selectAllConfigurationsList = (state) => state.configurations.allConfigurationsList;



// reducer
export default configurationsSlice.reducer;
