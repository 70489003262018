import React from 'react';
import { Form, Input, SubmitButton, FormItem } from 'formik-antd';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { UUID_REGEXP } from '../../../../const/system';
import Yup from '../../../../vendor/yup';
import { getObjectValuesByUrlParams } from '../../../../lib/getObjectValuesByUrlParams';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';



const validationSchema  = Yup.object().shape({
  request_id: Yup.string().matches(UUID_REGEXP, 'Should be UUID value').nullable(),
  core_id: Yup.string().matches(UUID_REGEXP, 'Should be UUID value').nullable(),
  player_id: Yup.string().matches(UUID_REGEXP, 'Should be UUID value').nullable(),
  alt_id: Yup.string().matches(UUID_REGEXP, 'Should be UUID value').nullable(),
  adv_id: Yup.string().matches(UUID_REGEXP, 'Should be UUID value').nullable(),
});


const FormProjectUserFilter = ({ onSubmit, initialValues, isSubmitting, formErrors }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(getNotEmptyObjectValues(values));
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setValues } = props;

        return (
          <Form layout="vertical">
            <Row gutter={[ 16, 16 ]}>
              <Col xs={24} sm={12} md={12} lg={6}>
                <FormItem
                  className='ant-form-item-col'
                  name="request_id"
                  label="Request ID:"
                >
                  <Input name='request_id' placeholder='Specify request id (UUID)' />
                </FormItem>
              </Col>

              <Col xs={24} sm={12} md={12} lg={5}>
                <FormItem
                  className='ant-form-item-col'
                  name="core_id"
                  label="Core ID:"
                >
                  <Input name='core_id' placeholder='Specify core id' />
                </FormItem>
              </Col>

              <Col xs={24} sm={12} md={8} lg={5}>
                <FormItem
                  className='ant-form-item-col'
                  name="player_id"
                  label="Player ID:"
                >
                  <Input name='player_id' placeholder='Specify player id' />
                </FormItem>
              </Col>

              <Col xs={24} sm={12} md={8} lg={4}>
                <FormItem
                  className='ant-form-item-col'
                  name="adv_id"
                  label="Adv ID:"
                >
                  <Input name='adv_id' placeholder='Specify advertise id' />
                </FormItem>
              </Col>

              <Col xs={24} sm={12} md={8} lg={4}>
                <FormItem
                  className='ant-form-item-col'
                  name="alt_id"
                  label="Alt ID:"
                >
                  <Input name='alt_id' placeholder='Specify alternative id' />
                </FormItem>
              </Col>

            </Row>


            <Row style={{ justifyContent: 'center', padding: '10px 0' }} gutter={[ 16, 16 ]}>
              <Col>
                <Button
                  type='secondary'
                  disabled={isEmpty(values)}
                  onClick={() => {
                    setValues({});
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || (isEqual(getObjectValuesByUrlParams(), values))}
                >
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormProjectUserFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  values: PropTypes.shape({
    request_id: PropTypes.string,
    core_id: PropTypes.string,
    player_id: PropTypes.string,
    alt_id: PropTypes.string,
    adv_id: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool.isRequired,
};

FormProjectUserFilter.defaultProps = {
  formErrors: {},
};

export default FormProjectUserFilter;
