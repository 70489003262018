import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import UserProfileDevicesService from '../services/userProfileDevicesService';
import { setUrlParams } from '../../../lib/setUrlParams';



const initialState = {
  devicesList: [],
  errors: [],
  devicesPagination: {},
  loadingDevicesList: false,
};

const devicesService = new UserProfileDevicesService(Api);

export const getUserProfilesDevicesListData = createAsyncThunk(
  'devices/getUserProfilesDevicesList',
  async ({ userId, params, showPublicQuery }) => {
    const response = await devicesService.getUserProfilesDevicesList({ userId, params });

    if (!isEmpty(params) && showPublicQuery) {
      setUrlParams(params);
    }

    return response;
  },
);

export const userProfileDevicesSlice = createSlice({
  name: 'devicesList',
  initialState,
  reducers: {
    resetDevicesListData: (state) => {
      state.devicesList = [];
      state.devicesPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfilesDevicesListData.pending, (state) => {
        state.loadingDevicesList = true;
      })
      .addCase(getUserProfilesDevicesListData.fulfilled, (state, action) => {
        state.loadingDevicesList = false;
        state.devicesList = action.payload.data;
        state.devicesPagination = action.payload.meta.pagination;
      })
      .addCase(getUserProfilesDevicesListData.rejected, (state, action) => {
        state.loadingDevicesList = false;
        state.errors = action.payload;
      });
  },
});

// actions
export const { resetDevicesListData } = userProfileDevicesSlice.actions;

// selectors
export const selectDevicesLoading = (state) => state.userProfileDevices.loadingDevicesList;
export const selectDevicesList = (state) => state.userProfileDevices.devicesList;
export const selectErrors = (state) => state.userProfileDevices.devicesList;
export const selectDevicesPagination = (state) => state.userProfileDevices.devicesPagination;

// reducer
export default userProfileDevicesSlice.reducer;
