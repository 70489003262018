import { Breadcrumb, Button, Card, Col, Layout, Row, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactJson from 'react-json-view';
import isEmpty from 'lodash/isEmpty';
import urlPageSpaceDashboard from '../../../../urls/urlPageSpaceDashboard';
import urlPageConfigurations from '../../../../urls/urlPageConfigurations';
import urlPageConfigurationDetails from '../../../../urls/urlPageConfigurationDetails';
import urlPageEditConfigMixin from '../../../../urls/urlPageEditConfigMixin';
import urlPageCreateConfigMixinTargeting from '../../../../urls/urlPageCreateConfigMixinTargeting';
import ConfigurationMixinTargetDetailsCard from '../../components/ConfigurationMixinTargetDetailsCard';
import ConfigurationTargetDetailsConditionsCard from '../../components/ConfigurationTargetDetailsConditionsCard';
import ConfigurationDetailsCard from '../../components/ConfigurationDetailsCard';
import { BTN_SIDE_MARGIN } from '../../../../const/system';
import { hasRights, Permissions } from '../../../../const/permissions';
import HasRights from '../../../../components/HasRights';
import { useApp } from '../../../../app/context/AppContext';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import {
  deleteConfigMixinTargeting,
  getConfigMixinDetails, getConfigMixinTargetDetails,
  resetConfigMixinDetailsData, resetConfigMixinTargetDetailsData,
  selectConfigMixinDetails,
  selectConfigMixinDetailsLoading, selectConfigMixinTargetDetails, selectConfigMixinTargetDetailsLoading,
} from '../../feature/configurationMixinDetailsSlice';



const { Content } = Layout;

const PageConfigurationMixinDetails = () => {
  const dispatch = useDispatch();
  const { projectSettings } = useApp();
  const { configurationId, configurationName, mixinId } = useParams();

  const configMixinDetails = useSelector(selectConfigMixinDetails);
  const isLoading = useSelector(selectConfigMixinDetailsLoading);
  const mixinTargetDetails = useSelector(selectConfigMixinTargetDetails);
  const isLoadingMixinTargetDetails = useSelector(selectConfigMixinTargetDetailsLoading);

  useEffect(() => {
    dispatch(getConfigMixinDetails(mixinId));
    dispatch(getConfigMixinTargetDetails(mixinId));
    return () => {
      dispatch(resetConfigMixinDetailsData());
      dispatch(resetConfigMixinTargetDetailsData());
    };
  }, []);


  const handleDeleteConfigMixinTargeting = async ({ mixinId, targetingId }) => {
    await dispatch(deleteConfigMixinTargeting({ mixinId, targetingId })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        dispatch(getConfigMixinTargetDetails(mixinId));
      }
    });
  };

  const mixinData = !isEmpty(configMixinDetails?.config) ? configMixinDetails.config : null;

  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.VIEW_DETAILS ]}>
      <Spin spinning={isLoading} tip="Loading details. Please wait...">
        <Col span={24}>
          <Row justify="space-between">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={urlPageSpaceDashboard(projectSettings)}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={urlPageConfigurations()}>
                  Configurations list
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={urlPageConfigurationDetails({ configurationId })}>
                  Configuration details
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                Mixin details
              </Breadcrumb.Item>
            </Breadcrumb>

            {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.UPDATE ]) && (
              <Col>
                <Button
                  style={BTN_SIDE_MARGIN}
                  disabled={isLoading}
                  size='middle'
                  type="primary"
                >
                  <Link
                    to={urlPageEditConfigMixin({
                      configurationId,
                      configurationName,
                      mixinId,
                    })}
                  >
                    Edit mixin
                  </Link>
                </Button>
              </Col>
            )}
          </Row>
        </Col>

        <Content className='layout-content'>
          <Row>
            <Col xs={24} md={24} lg={8} style={{ padding: '0 5px' }}>
              {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.VIEW_DETAILS ]) && (
                <ConfigurationDetailsCard
                  isMixin
                  data={configMixinDetails}
                  isLoading={isLoading}
                />
              )}

              {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.TARGETING.VIEW_DETAILS ]) && !isEmpty(mixinTargetDetails) ? (
                <>
                  <ConfigurationMixinTargetDetailsCard
                    data={mixinTargetDetails}
                    isLoading={isLoadingMixinTargetDetails}
                    configurationId={configurationId}
                    configurationName={configurationName}
                    handleDeleteConfigMixinTargeting={handleDeleteConfigMixinTargeting}
                  />
                  {mixinTargetDetails.conditions.map((condition, index) => (
                    <div className='card-table' key={condition[index]}>
                      <ConfigurationTargetDetailsConditionsCard
                        index={index}
                        condition={condition}
                        isLoading={isLoading}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.TARGETING.CREATE ]) && isEmpty(mixinTargetDetails) && (
                    <Button
                      style={{ margin: '10px 0' }}
                      className='hovered-btn'
                      disabled={isLoading}
                    >
                      <Link to={urlPageCreateConfigMixinTargeting({ configurationId, configurationName, mixinId })}>
                        + Create mixin target
                      </Link>
                    </Button>
                  )}
                </>
              )}

            </Col>
            <Col xs={24} md={24} lg={16} style={{ padding: '0 5px' }}>
              <div className='card-table'>
                <Card
                  title='Mixin config'
                  loading={isLoading}
                  style={{ marginBottom: '10px' }}
                >
                  <ReactJson
                    src={mixinData}
                    theme="summerfruit:inverted"
                  />
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </Spin>
    </HasRights>
  );
};

export default PageConfigurationMixinDetails;
