export default class AbTestDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  getDetails (abTestName) {
    return this.api.get(`ab-tests/${abTestName}`);
  }

  getStatistic (name) {
    return this.api.getWithStatus(`ab-tests/${name}/statistics`); //used custom GET method
  }

  publishTest ({ abTestId }) {
    return this.api.post(`ab-tests/${abTestId}/publish`);
  }

  createAbTest (values) {
    return this.api.post('ab-tests', values);
  }

  updateAbTest ({ abTestName, data }) {
    return this.api.put(`ab-tests/${abTestName}`, data);
  }
}
