import React from 'react';
import { Badge, Button, Tag, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import capitalize from 'lodash/capitalize';
import urlPageTicketDetails from '../../../../urls/urlPageTicketDetails';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



const { Paragraph } = Typography;

const TICKETS_STATUS_COLOR = (status) => {
  switch (status) {
    case 'Closed': {
      return 'black';
    }
    case 'Solved': {
      return 'green';
    }
    case 'Waiting': {
      return 'orange';
    }
    case 'On-hold': {
      return 'yellow';
    }
    default: {
      return 'red';
    }
  }
};

export const TICKETS_LIST_TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'ticket_id',
    align: 'center',
    width: '100px',
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('ticket_id') || 'descend',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    align: 'center',
    render: (value) => {
      return (
        <Tooltip placement="top" title={value || EMPTY_VALUE_PLACEHOLDER}>
          <Paragraph
            className={value ? 'paragraph' : ''}
            ellipsis={{ rows: 2, symbol: '...' }}
          >
            {value || EMPTY_VALUE_PLACEHOLDER}
          </Paragraph>
        </Tooltip>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (value) => {
      return (
        <Badge color={TICKETS_STATUS_COLOR(value)} text={capitalize(value)} />
      );
    },
  },
  {
    title: 'Link',
    dataIndex: 'link',
    align: 'center',
    render: (value) => {
      return (
        <a
          href={value}
          target='_blank'
          rel="noreferrer"
        >
          Zendesk
        </a>
      );
    },
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    align: 'center',
    render: (value) => {
      return value.map((tag, index) => (
        <Tag key={tag && index}>{tag}</Tag>
      ));
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => <div>{renderDateField(value)}</div>,
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    width: '100px',
    render: (item) => {
      return (
        <>
          <Tooltip placement="top" title='Ticket details'>
            <Button size="small" type='primary' className='btn-with-side-margin'>
              <Link to={urlPageTicketDetails({ ticketId: item.ticket_id })}>
                <EyeOutlined />
              </Link>
            </Button>
          </Tooltip>
        </>
      );
    },
  },
];
