export default class TicketDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  getData (ticketId) {
    return this.api.get(`tickets/${ticketId}`);
  }

  getTicketEvents (ticketId) {
    return this.api.getWithStatus(`tickets/${ticketId}/events`); //used custom GET method
  }
}
