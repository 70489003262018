import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Row, Table, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import urlPageConfigVisualize from '../../../../urls/urlPageConfigVisualize';
import urlPageConfigVisualizeCreate from '../../../../urls/urlPageConfigVisualizeCreate';
import { getQueryParams } from '../../../../lib/getQueryParams';
import {
  selectConfigVisualizeRequestsList,
  selectConfigVisualizeRequestsPagination,
  selectConfigVisualizeRequestsLoading,
  getConfigVisualizeRequestListData,
  resetConfigVisualizeListData,
  deleteRequest,
} from '../../feature/configurationVisualizeSlice';
import { getRequestListColumns } from './PageConfigVisualize.const';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import { hasRights, Permissions } from '../../../../const/permissions';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const PageConfigVisualize = () => {
  const dispatch = useDispatch();
  const { projectSettings } = useApp();
  const { pathname, search } = useLocation();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);

  const isLoading = useSelector(selectConfigVisualizeRequestsLoading);
  const pagination = useSelector(selectConfigVisualizeRequestsPagination);
  const requestsList = useSelector(selectConfigVisualizeRequestsList);


  useEffect(() => {
    if (pathname === urlPageConfigVisualize()) {
      dispatch(getConfigVisualizeRequestListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
    }
    return () => {
      dispatch(resetConfigVisualizeListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting ]);


  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  const handleDeleteConfiguration = async (requestId) => {
    await dispatch(deleteRequest(requestId));
    dispatch(getConfigVisualizeRequestListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
  };


  return (
    <>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Requests list</Breadcrumb.Item>
          </Breadcrumb>

          <Col>
            {hasRights([ Permissions.CONFIGS.CONFIG_VISUALIZE.CREATE ]) && (
              <Button
                style={{ margin: '10px 0' }}
                size='middle'
                type="primary"
              >
                <Link to={urlPageConfigVisualizeCreate()}>
                  Create request
                </Link>
              </Button>
            )}
          </Col>
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Table
            sticky
            columns={getRequestListColumns(handleDeleteConfiguration)}
            onChange={handleChangeTableParams}
            dataSource={requestsList}
            rowKey="id"
            loading={isLoading}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 550 }}
          />
        </Card>
      </div>
    </>
  );
};

export default PageConfigVisualize;
