import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/authService';
import Api from '../../../services/Api/Api';



const clearLocalStorage = () => {
  const clearableFields = [
    'token',
    'user',
    'user_ip',
    'ab_tests_list_status_filter',
    'experiments_status_filter',
    'test_mode_params',
  ];

  clearableFields.map((item) => localStorage.removeItem(item));
};

const userData = JSON.parse(localStorage.getItem('user'));

const initialState = {
  data: {
    isAuthenticated: !!JSON.parse(localStorage.getItem('token'))?.access_token,
    policies: userData?.user?.policies || [],
    spaces: userData?.user?.spaces || [],
    user: userData?.user || {},
  },
  loading: false,
  errors: null,
};

const authService = new AuthService(Api);

export const getAuthUser = createAsyncThunk(
  'setAuth/getAuth',
  async (data) => {
    const res = await authService.getAuth(data.code);

    if (res.hasOwnProperty('token')) {
      localStorage.setItem('token', JSON.stringify(res.token));
    }

    return res?.user;
  },
);

export const refreshUser = createAsyncThunk(
  'setAuth/refreshUser',
  (params) => {
    return authService.refreshUser(params);
  },
);

export const authSlice = createSlice({
  name: 'setAuth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.data = {
        isAuthenticated: false,
        user: {},
      };
      clearLocalStorage();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthUser.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(getAuthUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.data.isAuthenticated = false;
      })
      .addCase(getAuthUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.error.message;
        state.data.isAuthenticated = false;
        clearLocalStorage();
      });

    builder
      .addCase(refreshUser.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(refreshUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.data.isAuthenticated = true;

        const user = action.payload.data;
        const policies = action.payload?.data?.policies ?? [];
        const spaces = action.payload?.data?.spaces?.data ?? [];

        localStorage.setItem('user', JSON.stringify({ user, policies, spaces, isAuthenticated: true }));
      })
      .addCase(refreshUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.error.message;
        state.data.isAuthenticated = false;
        clearLocalStorage();
      });
  },
});

export const { logoutUser } = authSlice.actions;

// selectors
export const authLoading = (state) => state.auth.loading;
export const authData = (state) => state.auth.data;

// reducer
export default authSlice.reducer;
