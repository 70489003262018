const Config = {
  VITE_API_URL: process.env.REACT_APP_SUPPORTPANEL_API_URL,
  VITE_API_URL_TEST: process.env.REACT_APP_SUPPORTPANEL_API_URL_TEST,
  VITE_AUTH_GOOGLE_CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  VITE_SENTRY_DSN: process.env.SENTRY_DSN,
  VITE_SENTRY_ENV: process.env.SENTRY_ENV,
  VITE_GOOGLE_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  VITE_ALLOWED_EMAIL_DOMAINS: process.env.ALLOWED_EMAIL_DOMAINS,
  LAST_COMMIT_DATA: process.env.LAST_COMMIT,
  LAST_COMMIT_HASH: process.env.COMMIT_HASH,
};

export default Config;
