import moment from 'moment';
import React from 'react';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const preparedTableValues = (data) => data.map((item) => {
  const calculatedCoefficient = Number(item.impressions_per_user) / Number(item.ecpm);

  return {
    date: item.date,
    impressions_per_user: item.impressions_per_user,
    ecpm: item.ecpm,
    coefficient: isFinite(calculatedCoefficient) ? calculatedCoefficient.toFixed(2) : EMPTY_VALUE_PLACEHOLDER,
  };
});

export const ADS_TABLE_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    sorter: (_a, _b) => (moment(_a.date, 'DD.MM.YYYY HH:mm') >= moment(_b.date, 'DD.MM.YYYY HH:mm') ? 1 : -1),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Impressions per user',
    dataIndex: 'impressions_per_user',
    align: 'center',
    sorter: (_a, _b) => Number(_a.impressions_per_user) - Number(_b.impressions_per_user),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'ECPM per user',
    dataIndex: 'ecpm',
    align: 'center',
    sorter: (_a, _b) => Number(_a.ecpm) - Number(_b.ecpm),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Coefficient',
    dataIndex: 'coefficient',
    align: 'center',
    sorter: (_a, _b) => _a.coefficient - _b.coefficient,
    render: (value) => value,
  },
];
