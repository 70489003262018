import { Descriptions } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import CollapsibleCardWr from '../../../../components/CollapsibleCardWr';



const AbTestDetailsGroupsCard = ({ index, group, isLoading }) => {
  return (
    <CollapsibleCardWr
      title={`Group #${index + 1}`}
      isLoading={isLoading}
      keyValue={`Group #${index + 1}`}
      isCollapsible
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Name:">
          {group.name}
        </Descriptions.Item>
        <Descriptions.Item label="Percent:">
          {group.percent}
        </Descriptions.Item>
        {!isEmpty(group?.counter) && (
          <Descriptions.Item label="Count:">
            {group.counter}
          </Descriptions.Item>
        )}
      </Descriptions>
    </CollapsibleCardWr>
  );
};

AbTestDetailsGroupsCard.propTypes = {
  index: PropTypes.number.isRequired,
  group: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default AbTestDetailsGroupsCard;
