import isEmpty from 'lodash/isEmpty';



export const predefinedFormCreateInitialValues = ({ configId, configName, authorName }) => {
  return {
    config_name: configName,
    config_id: configId,
    author_name: authorName,
    status: 'paused',
    config: [],
  };
};

export const getPreparedFormData = (values, configId, configName) => {
  return {
    ...values,
    config_name: configName,
    author_name: !isEmpty(values?.author) ? values.author.name : '',
    config_id: configId,
    config: !isEmpty(values?.config) ? values.config.map((item) => {
      return {
        type: item.type,
        path: item.path,
        value: JSON.stringify(item.value),
        priority: String(item.priority),
      };
    }) : [],
  };
};


export const preparedToRequestFormData = (values) => {
  return {
    config_name: values.config_name,
    name: values.name,
    status: values.status,
    config_id: values.config_id,
    experiment_mixin_id: values?.experiment_mixin_id ?? null,
    priority: Number(values.priority),
    config: values.config.map((item) => {
      return {
        type: item.type,
        path: `#${item.path}`,
        value: item.value,
        priority: Number(item.priority),
      };
    }),
  };
};
