import { setRequestParams } from '../../../lib/setRequestParams';



export default class AppMonitoringService {
  constructor (Api) {
    this.api = Api;
  }

  getAppMonitoring (params) {
    return this.api.get(`monitoring/app${setRequestParams(params)}`);
  }
}
