import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import SpacesService from '../services/spacesService';
import { setUrlParams } from '../../../../lib/setUrlParams';
import { mkNotification } from '../../../../lib/mkNotification';



const initialState = {
  spacesList: [],
  spacesPagination: {},
  loading: false,
  createUpdateSpaceErrors: {},
  createUpdateSpaceLoading: false,
  createUpdateSpaceData: [],
  spaceDetailsData: {},
  spaceDetailsLoading: false,
};

const spacesService = new SpacesService(Api);

export const getSpacesListData = createAsyncThunk(
  'spaces/getSpacesList',
  async (values) => {
    const response = await spacesService.getSpacesList(values);

    if (!isEmpty(values)) {
      setUrlParams(values);
    }

    return response;
  },
);

export const createSpace = createAsyncThunk(
  'spaces/createSpace',
  async (values, { rejectWithValue }) => {
    try {
      const response = await spacesService.createSpace(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSpace = createAsyncThunk(
  'spaces/updateSpace',
  async ({ spaceId, data }, { rejectWithValue }) => {
    try {
      const response = await spacesService.updateSpace({ spaceId, data });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getSpaceDetails = createAsyncThunk(
  'spaces/getDetails',
  async ({ spaceId }, { rejectWithValue }) => {
    try {
      const response = await spacesService.getDetails({ spaceId });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteSpace = createAsyncThunk(
  'spaces/deleteSpace',
  async (projectId) => {
    const response = await spacesService.deleteSpace(projectId);

    return response.data;
  },
);

export const spacesSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    resetSpacesListData: (state) => {
      state.spacesList = [];
      state.spacesPagination = {};
    },
    resetErrors: (state) => {
      state.createUpdateSpaceErrors = [];
    },
    resetSpaceDetailsData: (state) => {
      state.spaceDetailsData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpacesListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSpacesListData.fulfilled, (state, action) => {
        state.loading = false;
        state.spacesList = action.payload.data;
        state.spacesPagination = action.payload.meta.pagination;
      })
      .addCase(getSpacesListData.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getSpaceDetails.pending, (state) => {
        state.spaceDetailsloading = true;
      })
      .addCase(getSpaceDetails.fulfilled, (state, action) => {
        state.spaceDetailsloading = false;
        state.spaceDetailsData = action.payload;
      })
      .addCase(getSpaceDetails.rejected, (state) => {
        state.spaceDetailsloading = false;
      });

    builder
      .addCase(createSpace.pending, (state) => {
        state.createUpdateSpaceLoading = true;
        state.createUpdateSpaceErrors = {};
      })
      .addCase(createSpace.fulfilled, (state, action) => {
        state.createUpdateSpaceLoading = false;
        state.createUpdateSpaceData = action.payload;
        mkNotification('success', 'Space is successfully created.');
      })
      .addCase(createSpace.rejected, (state, action) => {
        state.createUpdateSpaceLoading = false;
        state.createUpdateSpaceErrors = action.payload.errors;
      });

    builder
      .addCase(updateSpace.pending, (state) => {
        state.createUpdateSpaceLoading = true;
        state.createUpdateSpaceErrors = {};
      })
      .addCase(updateSpace.fulfilled, (state, action) => {
        state.createUpdateSpaceLoading = false;
        state.updateSpaceData = action.payload;
        mkNotification('success', 'Space is successfully updated.');
      })
      .addCase(updateSpace.rejected, (state, action) => {
        state.createUpdateSpaceLoading = false;
        state.createUpdateSpaceErrors = action.payload.errors;
      });

    builder
      .addCase(deleteSpace.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSpace.fulfilled, (state) => {
        state.loading = false;
        mkNotification('success', 'Space is successfully deleted.');
      })
      .addCase(deleteSpace.rejected, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { resetSpacesListData, resetErrors, resetSpaceDetailsData } = spacesSlice.actions;

// selectors
export const selectSpacesLoading = (state) => state.spaces.loading;
export const selectSpacesList = (state) => state.spaces.spacesList;
export const selectSpaceDetailsLoading = (state) => state.spaces.spaceDetailsloading;
export const selectSpacesDetailsData = (state) => state.spaces.spaceDetailsData;
export const selectSpacesPagination = (state) => state.spaces.spacesPagination;
export const selectCreateUpdateSpaceLoading = (state) => state.spaces.createUpdateSpaceLoading;
export const selectCreateUpdateSpaceData = (state) => state.spaces.createUpdateSpaceData;
export const selectCreateUpdateSpaceErrors = (state) => state.spaces.createUpdateSpaceErrors;

// reducer
export default spacesSlice.reducer;
