import { Breadcrumb, Button, Card, Col, Row, Table } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import urlPageConfigurations from '../../../../urls/urlPageConfigurations';
import urlPageConfigurationsCreate from '../../../../urls/urlPageConfigurationsCreate';
import { getConfigurationsListColumns } from './PageConfigurations.const';
import { hasRights, Permissions } from '../../../../const/permissions';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import {
  deleteConfiguration,
  getConfigurationsListData, resetConfigurationsListData,
  selectConfigurationsList,
  selectConfigurationsLoading,
  selectConfigurationsPagination,
} from '../../feature/configurationsSlice';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const PageConfigurations = () => {
  const { pathname, search } = useLocation();
  const { projectSettings } = useApp();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);

  const dispatch = useDispatch();
  const configurationsList = useSelector(selectConfigurationsList);
  const isLoading = useSelector(selectConfigurationsLoading);
  const pagination = useSelector(selectConfigurationsPagination);

  useEffect(() => {
    if (pathname === urlPageConfigurations()) {
      dispatch(getConfigurationsListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
    }
    return () => {
      dispatch(resetConfigurationsListData());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting ]);

  const handleDeleteConfiguration = async (configurationId) => {
    await dispatch(deleteConfiguration(configurationId));
    dispatch(getConfigurationsListData(mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting)));
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  return (
    <>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Configurations list</Breadcrumb.Item>
          </Breadcrumb>

          <Col>
            {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.CREATE ]) && (
              <Button
                style={{ margin: '10px 0' }}
                size='middle'
                type="primary"
              >
                <Link to={urlPageConfigurationsCreate()}>
                  Create configuration
                </Link>
              </Button>
            )}
          </Col>
        </Row>
      </Col>

      <div className="site-layout-container">
        <Card className='da-mb-24'>
          <Table
            sticky
            onChange={handleChangeTableParams}
            columns={getConfigurationsListColumns(handleDeleteConfiguration)}
            dataSource={configurationsList}
            rowKey="id"
            loading={isLoading}
            pagination={{
              size: 'small',
              current: pagination?.current_page,
              pageSize: pagination?.per_page,
              total: pagination?.total,
              position: [ 'bottomCenter' ],
              pageSizeOptions: [ '10', '25', '50' ],
              showSizeChanger: true,
            }}
            scroll={{ y: 550 }}
          />
        </Card>
      </div>
    </>
  );
};

export default PageConfigurations;
