import isEmpty from 'lodash/isEmpty';
import urlPageLogin from '../urls/urlPageLogin';
import urlPageAdministration from '../urls/urlPageAdministration';
import urlPageSettings from '../urls/urlPageSettings';



export const setRoutesPrefix = (routes, prevProjectKey, newPrefix) => {
  const excludePath = [ urlPageAdministration(), urlPageLogin(), urlPageSettings(), '/', '*' ];

  const modifyPath = (route, oldPrefix, newPrefix) => {
    if (!isEmpty(oldPrefix.spaceKey) && route.path.includes(oldPrefix.spaceKey)) {
      route.path = `/${newPrefix.spaceKey}${isEmpty(newPrefix.projectKey) ? '' : `/${newPrefix.projectKey}`}${route.path.slice(oldPrefix.spaceKey.length + 1)}`;
    } else if (
      !isEmpty(oldPrefix.spaceKey) &&
      !excludePath.includes(route.path) &&
      !route.path.includes(oldPrefix.spaceKey)
    ) {
      route.path = `/${newPrefix.spaceKey}${isEmpty(newPrefix.projectKey) ? '' : `/${newPrefix.projectKey}`}${route.path}`;
    }

    if (route.children && route.children.length > 0) {
      route.children.forEach((child) => {
        if (!excludePath.includes(child.path)) {
          modifyPath(child, oldPrefix, newPrefix);
        }
      });
    }
  };

  const oldPrefix = { spaceKey: prevProjectKey.spaceKey, projectKey: prevProjectKey.projectKey };

  routes.forEach((route) => {
    if (!excludePath.includes(route.path) && !isEmpty(oldPrefix.spaceKey)) {
      // eslint-disable-next-line prefer-destructuring
      oldPrefix.spaceKey = route.path.split('/')[0];
      oldPrefix.projectKey = null;
    }
    modifyPath(route, oldPrefix, newPrefix);
  });

  return routes;
};
