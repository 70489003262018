import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import cloneDeep from 'lodash/cloneDeep';



const deleteEmptyFields = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && isObject(obj[key])) {
      deleteEmptyFields(obj[key]);
      if (Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
        // eslint-disable-next-line fp/no-delete
        delete obj[key];
      } else if (isArray(obj[key]) && obj[key].length === 0) {
        // eslint-disable-next-line fp/no-delete
        delete obj[key];
      }
    }
  });
  return obj;
};


export const mkValidateFormFields = (spaceValues) => {
  const values = deleteEmptyFields(cloneDeep(spaceValues));

  if (!isEmpty(values)) {
    return values.some((space) => {
      if (Object.keys(space).length === 1 && space.hasOwnProperty('id')) {
        return true;
      }
      return space.projects?.some((project) => Object.keys(project).length === 1 && project.hasOwnProperty('id'));
    });
  }
  return false;
};
