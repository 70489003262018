import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';



export const mkPreparedRequestData = (values, isGlobal) => {
  if (!isGlobal) {
    const { projects_ids, ...rest } = values;

    return rest;
  }

  if (Array.isArray(values)) {
    values.map((value) => mkPreparedRequestData(value, isGlobal));
  } else if (isObject(values) && values !== null) {
    if (values.hasOwnProperty('space_data')) {
      values.spaces = values.space_data;
      // eslint-disable-next-line fp/no-delete
      delete values.space_data;
    }
    if (values.hasOwnProperty('space_projects_ids')) {
      // eslint-disable-next-line fp/no-delete
      delete values.space_projects_ids;
    }
    // eslint-disable-next-line fp/no-loops,guard-for-in,no-restricted-syntax
    for (const key in values) {
      values[key] = mkPreparedRequestData(values[key], isGlobal);
    }
  }
  return values;
};


export const getGlobalPoliciesInitialValues = (obj) => {
  const result = {
    roles: [],
    permissions: [],
    spaces: [],
    space_data: [],
  };

  if (obj) {
    result.roles = obj.roles;
    result.permissions = obj.permissions;

    const mkSpaces = (spaces) => {
      return spaces.map((space) => {
        const spaceData = {
          id: space.id,
          roles: [],
          permissions: [],
          space_projects_ids: [],
          projects: [],
        };

        spaceData.roles = concat(spaceData.roles, space.roles);
        spaceData.permissions = concat(spaceData.permissions, space.permissions);

        if (space.projects) {
          spaceData.projects = space.projects.map((project) => {
            const projectData = {
              id: project.id,
              roles: [],
              permissions: [],
            };

            projectData.roles = concat(projectData.roles, project.roles);
            projectData.permissions = concat(projectData.permissions, project.permissions);
            return projectData;
          });
          spaceData.space_projects_ids = spaceData.space_projects_ids.concat(space.projects.map((project) => project.id));
        }

        result.roles = obj.roles;
        result.permissions = obj.permissions;
        result.spaces.push(space.id);
        result.space_data.push(spaceData);
        return spaceData.id;
      });
    };

    mkSpaces(obj.spaces);
    return result;
  }
  return result;
};


export const mkLocalPoliciesOptions = (data, projectSettings) => {
  if (!isEmpty(data)) {
    const { permissions, roles } = data;

    return {
      permissions,
      roles,
      projects: data?.spaces.filter((space) => space.key === projectSettings.spaceKey)[0].projects?.data,
    };
  }
  return { permissions: [], roles: [], projects: [] };
};


export const getLocalPoliciesInitialValues = (data) => {
  if (!isEmpty(data)) {
    return { ...data, projects_ids: !isEmpty(data?.projects) ? data?.projects.map((item) => item.id) : [] };
  }
  return { permissions: [], roles: [], projects: [], projects_ids: [] };
};
