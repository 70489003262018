import url from './url';



export default url(({
  testId = ':testId',
  testName = ':testName',
  configId = ':configId',
  configName = ':configName',
  mixinId = ':mixinId',
}) => `/mixins-experiments/${testId}/${testName}/config/${configId}/${configName}/mixin/${mixinId}/targeting/create`);
