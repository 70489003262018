import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { LOCAL_SETTINGS_PREFIX, GLOBAL_SETTINGS_PREFIX } from '../const/system';



export const useCheckMobileScreen = (maxDeviceWidth = 768) => {
  const [ width, setWidth ] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (width <= maxDeviceWidth);
};


export const useMediaQuery = (query) => {
  const [ matches, setMatches ] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);

    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [ matches, query ]);

  return matches;
};


export const useCurrentSettingsScope = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');

  let scope = null;

  if (splitPathname.includes(GLOBAL_SETTINGS_PREFIX)) {
    scope = GLOBAL_SETTINGS_PREFIX;
  } else if (splitPathname.includes(LOCAL_SETTINGS_PREFIX)) {
    scope = LOCAL_SETTINGS_PREFIX;
  }

  return scope;
};


export const useLocalStorage = (key, defaultValue) => {
  const [ value, setValue ] = useState(!isEmpty(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : defaultValue);

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [ value, key ]);

  return [ value, setValue ];
};
