import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';
import DashboardService from '../services/dashboardService';



const initialState = {
  newUsersWidgetData: {},
  loadingNewUsersWidget: false,
  gameSessionsWidgetData: {},
  loadingGameSessionsWidget: false,
  sessionUsersWidgetData: {},
  loadingSessionUsersWidget: false,
  impressionsUsersWidgetData: {},
  loadingImpressionsUsersWidget: false,
  platformOverviewWidgetData: [],
  loadingPlatformOverviewWidget: false,
  newTicketsWidgetData: {},
  loadingNewTicketsWidget: false,
  ticketsPerDayWidgetData: {},
  loadingTicketsPerDayWidget: false,
  ticketsByPlatformWidgetData: [],
  loadingTicketsByPlatformWidget: false,
  ticketsChannelsWidgetData: [],
  loadingTicketsChannelsWidget: false,
  ticketsByAppVersionWidgetData: [],
  loadingTicketsByAppVersionWidget: false,
  problemsOverviewWidgetData: [],
  loadingProblemsOverviewWidget: false,
  beeActiveUsersWidgetData: {},
  loadingBeeActiveUsersWidget: false,
  finishedProjectsWidgetData: {},
  loadingFinishedProjectsWidget: false,
  reopenedProjectsWidgetData: {},
  loadingReopenedProjectsWidget: false,
  avgTimeWidgetData: {},
  loadingAvgTimeWidget: false,
  avgTapsWidgetData: {},
  loadingAvgTapsWidget: false,
  activeProjectsWidgetData: [],
  loadingActiveProjectsWidget: false,
  timeSpendingWidgetData: [],
  loadingTimeSpendingWidget: false,
};

const dashboardService = new DashboardService(Api);

export const getNewUsersWidgetData = createAsyncThunk(
  'dashboardWidgets/getNewUsersData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getNewUsersData();

      if (isEmpty(response)) {
        mkNotification('info', 'No users widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getGameSessionsWidgetData = createAsyncThunk(
  'gameSessionsWidget/getGameSessionsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getGameSessionsData();

      if (isEmpty(response)) {
        mkNotification('info', 'No game/sessions widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getSessionUsersWidgetData = createAsyncThunk(
  'sessionUsersWidget/getSessionUsersData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getSessionUsersData();

      if (isEmpty(response)) {
        mkNotification('info', 'No session/users widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getImpressionsUsersWidgetData = createAsyncThunk(
  'impressionsUsersWidget/getImpressionsUsersData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getImpressionsUsersData();

      if (isEmpty(response)) {
        mkNotification('info', 'No impressions/users widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getPlatformOverviewWidgetData = createAsyncThunk(
  'platformOverviewWidget/getPlatformOverviewData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getPlatformOverviewData();

      if (isEmpty(response)) {
        mkNotification('info', 'No platform overview data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getNewTicketsWidgetData = createAsyncThunk(
  'newTicketsWidget/getNewTicketsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getNewTicketsData();

      if (isEmpty(response)) {
        mkNotification('info', 'No new tickets data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTicketsPerDayWidgetData = createAsyncThunk(
  'ticketsPerDayWidget/getTicketsPerDayData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getTicketsPerDayData();

      if (isEmpty(response)) {
        mkNotification('info', 'No tickets per day data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTicketsByPlatformWidgetData = createAsyncThunk(
  'ticketsByPlatformWidget/getTicketsByPlatformData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getTicketsByPlatformData();

      if (isEmpty(response)) {
        mkNotification('info', 'No tickets by platform data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTicketsChannelsWidgetData = createAsyncThunk(
  'ticketsChannelsWidget/getTicketsChannelsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getTicketsChannelsData();

      if (isEmpty(response)) {
        mkNotification('info', 'No tickets channels data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTicketsByAppVersionWidgetData = createAsyncThunk(
  'ticketsByAppVersionWidget/getTicketsByAppVersionData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getTicketsByAppVersionData();

      if (isEmpty(response)) {
        mkNotification('info', 'No tickets by app version data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getProblemsOverviewWidgetData = createAsyncThunk(
  'problemsOverviewWidget/getProblemsOverviewData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getProblemsOverviewData();

      if (isEmpty(response)) {
        mkNotification('info', 'No problems overview data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getBeeActiveUsersWidgetData = createAsyncThunk(
  'beeFreelancer/getBeeActiveUsersData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getBeeActiveUsersData();

      if (isEmpty(response)) {
        mkNotification('info', 'No bee active users widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getFinishedProjectsWidgetData = createAsyncThunk(
  'beeFreelancer/getFinishedProjectsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getFinishedProjectsData();

      if (isEmpty(response)) {
        mkNotification('info', 'No bee finished projects widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getReopenedProjectsWidgetData = createAsyncThunk(
  'beeFreelancer/getReopenedProjectsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getReopenedProjectsData();

      if (isEmpty(response)) {
        mkNotification('info', 'No bee reopened projects widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getAvgTimeWidgetData = createAsyncThunk(
  'beeFreelancer/getAvgTimeData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getAvgTimeData();

      if (isEmpty(response)) {
        mkNotification('info', 'No bee average time widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getAvgTapsWidgetData = createAsyncThunk(
  'beeFreelancer/getAverageTapsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getAvgTapsData();

      if (isEmpty(response)) {
        mkNotification('info', 'No bee average taps widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getActiveProjectsWidgetData = createAsyncThunk(
  'beeFreelancer/getActiveProjectsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getActiveProjectsData();

      if (isEmpty(response)) {
        mkNotification('info', 'No bee active projects widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTimeSpendingWidgetData = createAsyncThunk(
  'beeFreelancer/getTimeSpendingData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getTimeSpendingData();

      if (isEmpty(response)) {
        mkNotification('info', 'No bee atime spending widget data available.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const dashboardSlice = createSlice({
  name: 'dashboardWidgets',
  initialState,
  reducers: {
    resetUsersWidgetData: (state) => {
      state.newUsersWidgetData = {};
    },
    resetGameSessionsWidgetData: (state) => {
      state.gameSessionsWidgetData = {};
    },
    resetSessionUsersWidgetData: (state) => {
      state.sessionUsersWidgetData = {};
    },
    resetImpressionsUsersWidgetData: (state) => {
      state.impressionsUsersWidgetData = {};
    },
    resetPlatformOverviewWidgetData: (state) => {
      state.platformOverviewWidgetData = [];
    },
    resetNewTicketsWidgetData: (state) => {
      state.newTicketsWidgetData = {};
    },
    resetTicketsPerDayWidgetData: (state) => {
      state.ticketsPerDayWidgetData = {};
    },
    resetTicketsByPlatformWidgetData: (state) => {
      state.ticketsByPlatformWidgetData = [];
    },
    resetTicketsChannelsWidgetData: (state) => {
      state.ticketsChannelsWidgetData = [];
    },
    resetTicketsByAppVersionWidgetData: (state) => {
      state.ticketsByAppVersionWidgetData = [];
    },
    resetProblemsOverviewWidgetData: (state) => {
      state.problemsOverviewWidgetData = [];
    },
    resetBeeActiveUsersWidgetData: (state) => {
      state.beeActiveUsersWidgetData = {};
    },
    resetFinishedProjectsWidgetData: (state) => {
      state.finishedProjectsWidgetData = {};
    },
    resetReopenedProjectsWidgetData: (state) => {
      state.reopenedProjectsWidgetData = {};
    },
    resetAvgTimeWidgetData: (state) => {
      state.avgTimeWidgetData = {};
    },
    resetAvgTapsWidgetData: (state) => {
      state.avgTapsWidgetData = {};
    },
    resetActiveProjectsWidgetData: (state) => {
      state.activeProjectsWidgetData = [];
    },
    resetTimeSpendingWidgetData: (state) => {
      state.timeSpendingWidgetData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewUsersWidgetData.pending, (state) => {
        state.loadingNewUsersWidget = true;
      })
      .addCase(getNewUsersWidgetData.fulfilled, (state, action) => {
        state.loadingNewUsersWidget = false;
        state.newUsersWidgetData = action.payload;
      })
      .addCase(getNewUsersWidgetData.rejected, (state) => {
        state.loadingNewUsersWidget = false;
      });

    builder
      .addCase(getGameSessionsWidgetData.pending, (state) => {
        state.loadingGameSessionsWidget = true;
      })
      .addCase(getGameSessionsWidgetData.fulfilled, (state, action) => {
        state.loadingGameSessionsWidget = false;
        state.gameSessionsWidgetData = action.payload;
      })
      .addCase(getGameSessionsWidgetData.rejected, (state) => {
        state.loadingGameSessionsWidget = false;
      });

    builder
      .addCase(getSessionUsersWidgetData.pending, (state) => {
        state.loadingSessionUsersWidget = true;
      })
      .addCase(getSessionUsersWidgetData.fulfilled, (state, action) => {
        state.loadingSessionUsersWidget = false;
        state.sessionUsersWidgetData = action.payload;
      })
      .addCase(getSessionUsersWidgetData.rejected, (state) => {
        state.loadingSessionUsersWidget = false;
      });

    builder
      .addCase(getImpressionsUsersWidgetData.pending, (state) => {
        state.loadingImpressionsUsersWidget = true;
      })
      .addCase(getImpressionsUsersWidgetData.fulfilled, (state, action) => {
        state.loadingImpressionsUsersWidget = false;
        state.impressionsUsersWidgetData = action.payload;
      })
      .addCase(getImpressionsUsersWidgetData.rejected, (state) => {
        state.loadingImpressionsUsersWidget = false;
      });

    builder
      .addCase(getPlatformOverviewWidgetData.pending, (state) => {
        state.loadingPlatformOverviewWidget = true;
      })
      .addCase(getPlatformOverviewWidgetData.fulfilled, (state, action) => {
        state.loadingPlatformOverviewWidget = false;
        state.platformOverviewWidgetData = action.payload;
      })
      .addCase(getPlatformOverviewWidgetData.rejected, (state) => {
        state.loadingPlatformOverviewWidget = false;
      });

    builder
      .addCase(getNewTicketsWidgetData.pending, (state) => {
        state.loadingNewTicketsWidget = true;
      })
      .addCase(getNewTicketsWidgetData.fulfilled, (state, action) => {
        state.loadingNewTicketsWidget = false;
        state.newTicketsWidgetData = action.payload;
      })
      .addCase(getNewTicketsWidgetData.rejected, (state) => {
        state.loadingNewTicketsWidget = false;
      });

    builder
      .addCase(getTicketsPerDayWidgetData.pending, (state) => {
        state.loadingTicketsPerDayWidget = true;
      })
      .addCase(getTicketsPerDayWidgetData.fulfilled, (state, action) => {
        state.loadingTicketsPerDayWidget = false;
        state.ticketsPerDayWidgetData = action.payload;
      })
      .addCase(getTicketsPerDayWidgetData.rejected, (state) => {
        state.loadingTicketsPerDayWidget = false;
      });

    builder
      .addCase(getTicketsByPlatformWidgetData.pending, (state) => {
        state.loadingTicketsByPlatformWidget = true;
      })
      .addCase(getTicketsByPlatformWidgetData.fulfilled, (state, action) => {
        state.loadingTicketsByPlatformWidget = false;
        state.ticketsByPlatformWidgetData = action.payload;
      })
      .addCase(getTicketsByPlatformWidgetData.rejected, (state) => {
        state.loadingTicketsByPlatformWidget = false;
      });

    builder
      .addCase(getTicketsChannelsWidgetData.pending, (state) => {
        state.loadingTicketsChannelsWidget = true;
      })
      .addCase(getTicketsChannelsWidgetData.fulfilled, (state, action) => {
        state.loadingTicketsChannelsWidget = false;
        state.ticketsChannelsWidgetData = action.payload;
      })
      .addCase(getTicketsChannelsWidgetData.rejected, (state) => {
        state.loadingTicketsChannelsWidget = false;
      });

    builder
      .addCase(getTicketsByAppVersionWidgetData.pending, (state) => {
        state.loadingTicketsByAppVersionWidget = true;
      })
      .addCase(getTicketsByAppVersionWidgetData.fulfilled, (state, action) => {
        state.loadingTicketsByAppVersionWidget = false;
        state.ticketsByAppVersionWidgetData = action.payload;
      })
      .addCase(getTicketsByAppVersionWidgetData.rejected, (state) => {
        state.loadingTicketsByAppVersionWidget = false;
      });
    builder
      .addCase(getProblemsOverviewWidgetData.pending, (state) => {
        state.loadingProblemsOverviewWidget = true;
      })
      .addCase(getProblemsOverviewWidgetData.fulfilled, (state, action) => {
        state.loadingProblemsOverviewWidget = false;
        state.problemsOverviewWidgetData = action.payload;
      })
      .addCase(getProblemsOverviewWidgetData.rejected, (state) => {
        state.loadingProblemsOverviewWidget = false;
      });
    builder
      .addCase(getBeeActiveUsersWidgetData.pending, (state) => {
        state.loadingBeeActiveUsersWidget = true;
      })
      .addCase(getBeeActiveUsersWidgetData.fulfilled, (state, action) => {
        state.loadingBeeActiveUsersWidget = false;
        state.beeActiveUsersWidgetData = action.payload;
      })
      .addCase(getBeeActiveUsersWidgetData.rejected, (state) => {
        state.loadingBeeActiveUsersWidget = false;
      });
    builder
      .addCase(getFinishedProjectsWidgetData.pending, (state) => {
        state.loadingFinishedProjectsWidget = true;
      })
      .addCase(getFinishedProjectsWidgetData.fulfilled, (state, action) => {
        state.loadingFinishedProjectsWidget = false;
        state.finishedProjectsWidgetData = action.payload;
      })
      .addCase(getFinishedProjectsWidgetData.rejected, (state) => {
        state.loadingFinishedProjectsWidget = false;
      });
    builder
      .addCase(getReopenedProjectsWidgetData.pending, (state) => {
        state.loadingReopenedProjectsWidget = true;
      })
      .addCase(getReopenedProjectsWidgetData.fulfilled, (state, action) => {
        state.loadingReopenedProjectsWidget = false;
        state.reopenedProjectsWidgetData = action.payload;
      })
      .addCase(getReopenedProjectsWidgetData.rejected, (state) => {
        state.loadingReopenedProjectsWidget = false;
      });
    builder
      .addCase(getAvgTimeWidgetData.pending, (state) => {
        state.loadingAvgTimeWidget = true;
      })
      .addCase(getAvgTimeWidgetData.fulfilled, (state, action) => {
        state.loadingAvgTimeWidget = false;
        state.avgTimeWidgetData = action.payload;
      })
      .addCase(getAvgTimeWidgetData.rejected, (state) => {
        state.loadingAvgTimeWidget = false;
      });
    builder
      .addCase(getAvgTapsWidgetData.pending, (state) => {
        state.loadingAvgTapsWidget = true;
      })
      .addCase(getAvgTapsWidgetData.fulfilled, (state, action) => {
        state.loadingAvgTapsWidget = false;
        state.avgTapsWidgetData = action.payload;
      })
      .addCase(getAvgTapsWidgetData.rejected, (state) => {
        state.loadingAvgTapsWidget = false;
      });
    builder
      .addCase(getActiveProjectsWidgetData.pending, (state) => {
        state.loadingActiveProjectsWidget = true;
      })
      .addCase(getActiveProjectsWidgetData.fulfilled, (state, action) => {
        state.loadingActiveProjectsWidget = false;
        state.activeProjectsWidgetData = action.payload;
      })
      .addCase(getActiveProjectsWidgetData.rejected, (state) => {
        state.loadingActiveProjectsWidget = false;
      });
    builder
      .addCase(getTimeSpendingWidgetData.pending, (state) => {
        state.loadingTimeSpendingWidget = true;
      })
      .addCase(getTimeSpendingWidgetData.fulfilled, (state, action) => {
        state.loadingTimeSpendingWidget = false;
        state.timeSpendingWidgetData = action.payload;
      })
      .addCase(getTimeSpendingWidgetData.rejected, (state) => {
        state.loadingTimeSpendingWidget = false;
      });
  },
});

// actions
export const {
  resetUsersWidgetData,
  resetGameSessionsWidgetData,
  resetSessionUsersWidgetData,
  resetImpressionsUsersWidgetData,
  resetPlatformOverviewWidgetData,
  resetNewTicketsWidgetData,
  resetTicketsPerDayWidgetData,
  resetTicketsByPlatformWidgetData,
  resetTicketsChannelsWidgetData,
  resetTicketsByAppVersionWidgetData,
  resetProblemsOverviewWidgetData,
  resetBeeActiveUsersWidgetData,
  resetFinishedProjectsWidgetData,
  resetReopenedProjectsWidgetData,
  resetAvgTimeWidgetData,
  resetAvgTapsWidgetData,
  resetActiveProjectsWidgetData,
  resetTimeSpendingWidgetData,
} = dashboardSlice.actions;

// selectors
export const selectNewUsersLoading = (state) => state.dashboard.loadingNewUsersWidget;
export const selectNewUsersData = (state) => state.dashboard.newUsersWidgetData;
export const selectGameSessionsLoading = (state) => state.dashboard.loadingGameSessionsWidget;
export const selectGameSessionsData = (state) => state.dashboard.gameSessionsWidgetData;
export const selectSessionUsersLoading = (state) => state.dashboard.loadingSessionUsersWidget;
export const selectSessionUsersData = (state) => state.dashboard.sessionUsersWidgetData;
export const selectImpressionsUsersLoading = (state) => state.dashboard.loadingImpressionsUsersWidget;
export const selectImpressionsUsersData = (state) => state.dashboard.impressionsUsersWidgetData;
export const selectPlatformOverviewLoading = (state) => state.dashboard.loadingPlatformOverviewWidget;
export const selectPlatformOverviewData = (state) => state.dashboard.platformOverviewWidgetData;
export const selectNewTicketsLoading = (state) => state.dashboard.loadingNewTicketsWidget;
export const selectNewTicketsData = (state) => state.dashboard.newTicketsWidgetData;
export const selectTicketsPerDayLoading = (state) => state.dashboard.loadingTicketsPerDayWidget;
export const selectTicketsPerDayData = (state) => state.dashboard.ticketsPerDayWidgetData;
export const selectTicketsByPlatformLoading = (state) => state.dashboard.loadingTicketsByPlatformWidget;
export const selectTicketsByPlatformData = (state) => state.dashboard.ticketsByPlatformWidgetData;
export const selectTicketsChannelsLoading = (state) => state.dashboard.loadingTicketsChannelsWidget;
export const selectTicketsChannelsData = (state) => state.dashboard.ticketsChannelsWidgetData;
export const selectTicketsByAppVersionLoading = (state) => state.dashboard.loadingTicketsByAppVersionWidget;
export const selectTicketsByAppVersionData = (state) => state.dashboard.ticketsByAppVersionWidgetData;
export const selectProblemsOverviewLoading = (state) => state.dashboard.loadingProblemsOverviewWidget;
export const selectProblemsOverviewData = (state) => state.dashboard.problemsOverviewWidgetData;
export const selectBeeActiveUsersLoading = (state) => state.dashboard.loadingBeeActiveUsersWidget;
export const selectBeeActiveUsersData = (state) => state.dashboard.beeActiveUsersWidgetData;
export const selectFinishedProjectsLoading = (state) => state.dashboard.loadingFinishedProjectsWidget;
export const selectFinishedProjectsData = (state) => state.dashboard.finishedProjectsWidgetData;
export const selectReopenedProjectsLoading = (state) => state.dashboard.loadingReopenedProjectsWidget;
export const selectReopenedProjectsData = (state) => state.dashboard.reopenedProjectsWidgetData;
export const selectAvgTimeLoading = (state) => state.dashboard.loadingAvgTimeWidget;
export const selectAvgTimeData = (state) => state.dashboard.avgTimeWidgetData;
export const selectAvgTapsLoading = (state) => state.dashboard.loadingAvgTapsWidget;
export const selectAvgTapsData = (state) => state.dashboard.avgTapsWidgetData;
export const selectActiveProjectsLoading = (state) => state.dashboard.loadingActiveProjectsWidget;
export const selectActiveProjectsData = (state) => state.dashboard.activeProjectsWidgetData;
export const selectTimeSpendingLoading = (state) => state.dashboard.loadingTimeSpendingWidget;
export const selectTimeSpendingData = (state) => state.dashboard.timeSpendingWidgetData;

// reducer
export default dashboardSlice.reducer;
