import { Breadcrumb, Button, Card, Col, Layout, Popconfirm, Row, Spin, Tabs, Tooltip } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import ReactJson from 'react-json-view';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getUniqueData } from '../../../../lib/getUniqueData';
import { hasRights, Permissions } from '../../../../const/permissions';
import HasRights from '../../../../components/HasRights';
import urlPageMixinsExperiments from '../../../../urls/urlPageMixinsExperiments';
import urlPageCreateMixinsExperimentMixinTargeting from '../../../../urls/urlPageCreateMixinsExperimentMixinTargeting';
import urlPageEditMixinsExperiment from '../../../../urls/urlPageEditMixinsExperiment';
import urlPageCreateMixinsExperimentsMixin from '../../../../urls/urlPageCreateMixinsExperimentsMixin';
import urlPageEditMixinsExperimentMixin from '../../../../urls/urlPageEditMixinsExperimentMixin';
import urlPageEditMixinsExperimentTargeting from '../../../../urls/urlPageEditMixinsExperimentTargeting';
import ModalConfirmPublishExperiment from '../../components/ModalConfirmPublishExperiment';
import MixinsExperimentDetailsCard from '../../components/MixinsExperimentDetailsCard';
import MixinsExperimentTargetDetailsCard from '../../components/MixinsExperimentTargetDetailsCard';
import ConfigurationTargetDetailsConditionsCard from '../../../Configurations/components/ConfigurationTargetDetailsConditionsCard';
import ModalReturnExperiment from '../../components/ModalReturnExperiment';
import MixinsExperimentHistoryCard from '../../components/MixinsExperimentHistoryCard';
import AlertNotification from '../../../../components/AlertNotification';
import { EXPERIMENT_STATUSES_MAP } from '../PageMixinsExperiments/PageMixinsExperiments.const';
import { BTN_SIDE_MARGIN } from '../../../../const/system';
import { useApp } from '../../../../app/context/AppContext';
import getUserIP from '../../../../lib/getUserIP';
import mkTimeFromSeconds from '../../../../lib/mkTimeFromSeconds';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  getExperimentDetails,
  publishExperimentToSandbox,
  resetExperimentDetailsData,
  toggleAssigneeExperiment,
  sendExperimentWithStatus,
  togglePauseExperiment,
  resetReturnExperimentErrors,
  selectPublishSandboxLoading,
  selectExperimentDetailsData,
  selectExperimentDetailsLoading,
  selectUpdateExperimentDetailsLoading,
  selectToggleAssigneeLoading,
  selectSendExperimentStatusLoading,
  selectReturnExperimentFormErrors,
  selectTogglePauseLoading,
} from '../../feature/mixinsExperimentDetailsSlice';
import {
  getExperimentMixinTargetDetails,
  resetExperimentMixinTargetDetailsData,
  selectExperimentMixinTargetDetails,
  selectExperimentMixinTargetDetailsLoading,
} from '../../feature/mixinExperimentMixinDetailsSlice';



const { Content } = Layout;
const { TabPane } = Tabs;


const PageMixinsExperimentDetails = () => {
  const { testId, testName } = useParams();
  const { projectSettings } = useApp();
  const userId = JSON.parse(localStorage.getItem('user'))?.user?.id;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedUserIP = getUserIP();
  const [ isActiveNotificationModal, setToggleNotificationModal ] = useState(false);
  const [ isBlockSandbox, setToggleBlockSandbox ] = useState(false);
  const [ isActiveReturnModal, setToggleReturnModal ] = useState(false);
  const [ isUnassignUser, setUnassignUser ] = useState(false);

  const experimentDetails = useSelector(selectExperimentDetailsData);
  const isLoadingExperimentDetails = useSelector(selectExperimentDetailsLoading);
  const experimentTargetDetails = useSelector(selectExperimentMixinTargetDetails);
  const isLoadingExperimentTargeting = useSelector(selectExperimentMixinTargetDetailsLoading);
  const isLoadingSendForVerification = useSelector(selectUpdateExperimentDetailsLoading);
  const isLoadingPublishExperimentToSandbox = useSelector(selectPublishSandboxLoading);
  const isLoadingToggleAssignee = useSelector(selectToggleAssigneeLoading);
  const isLoadingTogglePause = useSelector(selectTogglePauseLoading);
  const returnExperimentErrors = useSelector(selectReturnExperimentFormErrors);
  const isLoadingSendExperimentWithStatus = useSelector(selectSendExperimentStatusLoading);

  const mixinData = !isEmpty(experimentDetails) ? experimentDetails?.mixin?.data : {};

  const isAuthor = experimentDetails?.author?.id === userId;
  const isAssigned = experimentDetails?.assigned?.id === userId;
  const isUnassigned = experimentDetails?.assigned === null;
  const isPaused = experimentDetails?.is_paused ?? false;
  const isDraft = experimentDetails?.is_draft ?? false;
  const isBlocked = experimentDetails?.config?.data?.is_blocked ?? false;
  const unblockAt = experimentDetails?.config?.data?.unblock_at;
  const isExperimentInSandbox = experimentDetails?.in_sandbox ?? false;
  const hasMixin = experimentDetails?.mixin_id ?? false;
  const experimentStatus = experimentDetails?.status;
  const canSendForReview = experimentDetails.time_to_continue === 0;
  const canSendAndBlock = !isExperimentInSandbox && !isBlocked && experimentStatus === EXPERIMENT_STATUSES_MAP.ON_CHECKING;
  const testTargetsMap = !isEmpty(experimentTargetDetails) ? experimentTargetDetails.conditions : [];
  const canChangeAssignee = ((hasRights([ Permissions.MIXINS_EXPERIMENTS.CREATE ]) && [ EXPERIMENT_STATUSES_MAP.CREATED, EXPERIMENT_STATUSES_MAP.FREE ].includes(experimentStatus)) ||
    (hasRights([ Permissions.MIXINS_EXPERIMENTS.CHECK_EXPERIMENT ]) && [ EXPERIMENT_STATUSES_MAP.ON_CHECKING, EXPERIMENT_STATUSES_MAP.FREE ].includes(experimentStatus)) ||
    (hasRights([ Permissions.MIXINS_EXPERIMENTS.REVIEW_EXPERIMENT ]) && [ EXPERIMENT_STATUSES_MAP.REVIEW, EXPERIMENT_STATUSES_MAP.FREE ].includes(experimentStatus))) &&
    ![ EXPERIMENT_STATUSES_MAP.APPROVED, EXPERIMENT_STATUSES_MAP.RELEASED ].includes(experimentStatus);
  const canEditExperiment = [ EXPERIMENT_STATUSES_MAP.CREATED, EXPERIMENT_STATUSES_MAP.ON_CHECKING ].includes(experimentStatus) &&
        !isPaused && isAssigned && !isExperimentInSandbox;
  const canPause = [
    EXPERIMENT_STATUSES_MAP.CREATED,
    EXPERIMENT_STATUSES_MAP.ON_CHECKING,
    EXPERIMENT_STATUSES_MAP.REVIEW,
  ].includes(experimentStatus) && !isDraft && isAssigned && !isExperimentInSandbox;
  const canSendExperiment = [ EXPERIMENT_STATUSES_MAP.CREATED, EXPERIMENT_STATUSES_MAP.ON_CHECKING ].includes(experimentStatus) && hasMixin && !isPaused && isAssigned;
  const canPublishExperiment = canSendExperiment && !isBlocked;
  const canChangeStatus = !isDraft && !isPaused && !isExperimentInSandbox && hasMixin;


  useEffect(() => {
    dispatch(getExperimentDetails(testId)).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        if (response.payload.status !== EXPERIMENT_STATUSES_MAP.OBSOLETE && response.payload.mixin_id) {
          dispatch(getExperimentMixinTargetDetails({ testId, mixinId: response.payload.mixin_id }));
        }
      }
    });
    return () => {
      dispatch(resetExperimentDetailsData());
      dispatch(resetExperimentMixinTargetDetailsData());
    };
  }, []);


  const handleGetExperimentDetails = (getTarget = false) => {
    dispatch(getExperimentDetails(testId)).then((response) => {
      if (isFulfilledRequestStatus(response) && getTarget) {
        dispatch(getExperimentMixinTargetDetails({ testId, mixinId: response.payload.mixin_id }));
      }
    });
  };

  const handlePublishExperiment = () => {
    const data = {
      block_sandbox: isBlockSandbox,
      exclude_from_sandbox: isExperimentInSandbox,
    };

    dispatch(publishExperimentToSandbox({ testId, data })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        handleGetExperimentDetails();
      }
    });
  };

  const handleToggleAssignExperiment = ({ testId, values = {} }) => {
    const data = { is_assigned: !isAssigned, target_ip: storedUserIP, ...values };

    dispatch(toggleAssigneeExperiment({ testId, data })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        handleGetExperimentDetails(true);
        setUnassignUser(false);
        setToggleReturnModal(false);
      }
    });
  };

  const handleTogglePauseExperiment = () => {
    dispatch(togglePauseExperiment({ testId, data: { is_paused: !isPaused } })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        handleGetExperimentDetails();
      }
    });
  };

  const handleSubmitWithStatus = (data) => {
    dispatch(sendExperimentWithStatus({ testId, data })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        navigate(urlPageMixinsExperiments());
      }
    });
  };

  const handleCloseReturnModal = () => {
    setToggleReturnModal(false);
    dispatch(resetReturnExperimentErrors());
  };


  return (
    <HasRights allowedPermissions={[ Permissions.MIXINS_EXPERIMENTS.VIEW_DETAILS ]}>
      <Spin
        spinning={isLoadingExperimentDetails || isLoadingExperimentTargeting || isLoadingSendForVerification}
        tip="Loading details. Please wait..."
      >
        <Col span={24}>
          <Row justify="space-between">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={mkRedirectToDashboardPath(projectSettings)}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={urlPageMixinsExperiments()}>
                  Mixins experiments
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Details</Breadcrumb.Item>
            </Breadcrumb>

            <ModalConfirmPublishExperiment
              configurationId={testId}
              isActiveModal={isActiveNotificationModal}
              hasIpCheck={!hasRights([ Permissions.MIXINS_EXPERIMENTS.CHECK_EXPERIMENT ])}
              testTargetIPs={getUniqueData(testTargetsMap, 'ip_addresses')}
              targetLink={urlPageEditMixinsExperimentTargeting({
                testId: experimentDetails?.id,
                testName: experimentDetails?.name,
                configId: experimentDetails?.config?.data?.id,
                configName: experimentDetails?.config?.data?.name,
                targetingId: experimentTargetDetails?.id,
              })}
              userIP={storedUserIP}
              isBlockSandbox={isBlockSandbox}
              isRevertFromSandbox={isExperimentInSandbox}
              toggleModal={setToggleNotificationModal}
              handleSubmit={handlePublishExperiment}
            />

            <ModalReturnExperiment
              title={isUnassignUser ? 'Unassign reason' : 'Return for testing reason'}
              isActiveModal={isActiveReturnModal}
              hideModal={handleCloseReturnModal}
              initialValues={{}}
              formErrors={returnExperimentErrors}
              isLoading={isLoadingSendExperimentWithStatus || isLoadingToggleAssignee}
              handleSubmitForm={(values) => {
                if (!isUnassignUser) {
                  handleSubmitWithStatus({ comment: values.comment, revert_action: true });
                } else {
                  handleToggleAssignExperiment({ testId, values });
                }
              }}
            />

            <Col>
              <>
                {![ EXPERIMENT_STATUSES_MAP.OBSOLETE, EXPERIMENT_STATUSES_MAP.ARCHIVED ].includes(experimentStatus) && (
                  <>
                    {!hasMixin ? (
                      <>
                        {hasRights([ Permissions.MIXINS_EXPERIMENTS.CREATE ]) && (isAssigned || isAuthor) && (
                          <Button
                            style={BTN_SIDE_MARGIN}
                            className='hovered-btn'
                          >
                            <Link to={urlPageCreateMixinsExperimentsMixin({ testId, testName })}>
                              + Create mixin
                            </Link>
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {hasRights([ Permissions.MIXINS_EXPERIMENTS.UPDATE ]) && canEditExperiment && (
                          <Button
                            style={BTN_SIDE_MARGIN}
                            className='hovered-btn'
                          >
                            <Link to={urlPageEditMixinsExperimentMixin({ testId, testName, mixinId: experimentDetails.mixin_id })}>
                              Edit mixin
                            </Link>
                          </Button>
                        )}
                      </>
                    )}

                    {!isDraft && !isPaused && !isExperimentInSandbox && canChangeAssignee && (
                      <>
                        {isAssigned && (
                          <Tooltip placement="top" title='Are you sure you want to be unassigned for this experiment?'>
                            <Button
                              style={BTN_SIDE_MARGIN}
                              className='bordered-violet-btn'
                              loading={isLoadingToggleAssignee}
                              onClick={() => {
                                setUnassignUser(true);
                                setToggleReturnModal(true);
                              }}
                            >
                              Unassign
                            </Button>
                          </Tooltip>
                        )}
                        {isUnassigned && (
                          <Button
                            style={BTN_SIDE_MARGIN}
                            className='bordered-violet-btn'
                            loading={isLoadingToggleAssignee}
                            onClick={() => {
                              handleToggleAssignExperiment({ testId });
                            }}
                          >
                            Assign
                          </Button>
                        )}
                      </>
                    )}

                    {(hasRights([ Permissions.MIXINS_EXPERIMENTS.TOGGLE_IN_SANDBOX ]) ||
                      hasRights([
                        Permissions.MIXINS_EXPERIMENTS.TOGGLE_IN_SANDBOX,
                        Permissions.MIXINS_EXPERIMENTS.CHECK_EXPERIMENT,
                        Permissions.MIXINS_EXPERIMENTS.BLOCK_SANDBOX,
                      ], true)) && canPublishExperiment && (
                      <Tooltip placement="top" title={isExperimentInSandbox ? 'Exclude from sandbox' : 'Send to sandbox'}>
                        <Button
                          className='bordered-violet-btn'
                          style={BTN_SIDE_MARGIN}
                          loading={isLoadingPublishExperimentToSandbox}
                          onClick={() => {
                            setToggleBlockSandbox(false);
                            setToggleNotificationModal(true);
                          }}
                        >
                          {isExperimentInSandbox ? 'Exclude' : 'Send'}
                        </Button>
                      </Tooltip>
                    )}

                    {hasRights([
                      Permissions.MIXINS_EXPERIMENTS.TOGGLE_IN_SANDBOX,
                      Permissions.MIXINS_EXPERIMENTS.CHECK_EXPERIMENT,
                      Permissions.MIXINS_EXPERIMENTS.BLOCK_SANDBOX,
                    ], true) && canSendExperiment && ((isBlocked && isExperimentInSandbox) || canSendAndBlock) && (
                      <Tooltip placement="top" title={isBlocked ? 'Exclude from sandbox and unblock' : 'Send to sandbox and block'}>
                        <Button
                          className='bordered-violet-btn'
                          style={BTN_SIDE_MARGIN}
                          loading={isLoadingPublishExperimentToSandbox}
                          onClick={() => {
                            setToggleBlockSandbox(!isBlocked);
                            setToggleNotificationModal(true);
                          }}
                        >
                          {isBlocked ? 'Exclude and unblock' : 'Send and block'}
                        </Button>
                      </Tooltip>
                    )}

                    {((experimentStatus === EXPERIMENT_STATUSES_MAP.CREATED && !hasRights([ Permissions.MIXINS_EXPERIMENTS.CHECK_EXPERIMENT ])) ||
                        (experimentStatus === EXPERIMENT_STATUSES_MAP.ON_CHECKING && hasRights([ Permissions.MIXINS_EXPERIMENTS.CHECK_EXPERIMENT ]))) &&
                      canChangeStatus && !isBlocked && isAssigned && (
                      <Popconfirm
                        title='Sure to send this experiment for verification?'
                        disabled={isLoadingSendExperimentWithStatus || !canSendForReview}
                        onConfirm={() => {
                          handleSubmitWithStatus({ comment: null, revert_action: false });
                        }}
                      >
                        <Button
                          danger
                          disabled={!canSendForReview}
                          style={BTN_SIDE_MARGIN}
                          loading={isLoadingSendExperimentWithStatus}
                        >
                          Send for verification
                        </Button>
                      </Popconfirm>
                    )}
                  </>
                )}

                {canPause && (
                  <Button
                    style={BTN_SIDE_MARGIN}
                    className='hovered-btn'
                    loading={isLoadingTogglePause}
                    onClick={handleTogglePauseExperiment}
                  >
                    {isPaused ? 'Start' : 'Pause'}
                  </Button>
                )}

                {([ EXPERIMENT_STATUSES_MAP.ON_CHECKING, EXPERIMENT_STATUSES_MAP.REVIEW ].includes(experimentStatus)) &&
                  hasMixin && !isBlocked && canChangeStatus && isAssigned && !isAuthor &&
                  hasRights([ Permissions.MIXINS_EXPERIMENTS.CHECK_EXPERIMENT, Permissions.MIXINS_EXPERIMENTS.REVIEW_EXPERIMENT ]) && (
                  <Button
                    style={BTN_SIDE_MARGIN}
                    className='hovered-btn'
                    onClick={() => {
                      setToggleReturnModal(true);
                    }}
                  >
                    Return for testing
                  </Button>
                )}

                {isAssigned && canChangeStatus && hasRights([ Permissions.MIXINS_EXPERIMENTS.REVIEW_EXPERIMENT ]) &&
                  (EXPERIMENT_STATUSES_MAP.REVIEW === experimentStatus) && (
                  <Button
                    className='bordered-violet-btn'
                    style={BTN_SIDE_MARGIN}
                    loading={isLoadingSendExperimentWithStatus}
                    onClick={() => {
                      handleSubmitWithStatus({ comment: null, revert_action: false });
                    }}
                  >
                    Approve
                  </Button>
                )}
              </>
            </Col>

            {isPaused && (
              <AlertNotification
                closable
                type="info"
                message="Notification: Unable to edit or publish experiment cause experiment is paused."
              />
            )}

            {!canSendForReview && hasMixin && isAssigned && (
              <AlertNotification
                type="info"
                message={isExperimentInSandbox ?
                  `Notification: You need to wait ${mkTimeFromSeconds(experimentDetails.time_to_continue)} before sending experiment for review.`
                  : `Notification: Experiment should be ${mkTimeFromSeconds(experimentDetails.time_to_continue)} in sandbox before submitting for verification.`}
              />
            )}

            {isBlocked && ![
              EXPERIMENT_STATUSES_MAP.APPROVED,
              EXPERIMENT_STATUSES_MAP.RELEASED,
              EXPERIMENT_STATUSES_MAP.ARCHIVED,
              EXPERIMENT_STATUSES_MAP.OBSOLETE,
            ].includes(experimentStatus) && (
              <AlertNotification
                type="error"
                message={`Warning: Sandbox is blocked for every experiment in the config till ${moment(unblockAt).local().format('DD.MM.YYYY HH:mm')}`}
              />
            )}
          </Row>
        </Col>

        <Content className='layout-content'>
          <Row className='da-mb-10'>
            <Col xs={24} md={24} lg={8} style={{ padding: '0 5px' }}>
              <MixinsExperimentDetailsCard
                customTitle='Experiment details'
                canEdit={canEditExperiment}
                handleEdit={() => {
                  navigate(urlPageEditMixinsExperiment({ testId }), { replace: true });
                }}
                data={experimentDetails}
                isLoading={isLoadingExperimentDetails}
              />
              {!isEmpty(experimentTargetDetails) ? (
                <>
                  <MixinsExperimentTargetDetailsCard
                    data={experimentTargetDetails}
                    testDetails={experimentDetails}
                    canEdit={canEditExperiment}
                    isLoading={isLoadingExperimentTargeting}
                  />
                  {testTargetsMap.map((condition, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className='card-table' key={index}>
                      <Col>
                        <ConfigurationTargetDetailsConditionsCard
                          index={index}
                          condition={condition}
                          isLoading={isLoadingExperimentTargeting}
                        />
                      </Col>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {hasRights([ Permissions.MIXINS_EXPERIMENTS.CREATE_TARGET ]) && canEditExperiment &&
                    !isEmpty(mixinData?.config) && (
                    <Button
                      style={{ margin: '10px 0' }}
                      className='hovered-btn'
                    >
                      <Link
                        to={urlPageCreateMixinsExperimentMixinTargeting({
                          testId: experimentDetails.id,
                          testName: experimentDetails.name,
                          configId: experimentDetails?.config?.data?.id,
                          configName: experimentDetails?.config?.data?.name,
                          mixinId: experimentDetails?.mixin?.data?.id,
                        })}
                      >
                        + Create target
                      </Link>
                    </Button>
                  )}
                </>
              )}
            </Col>

            <Col xs={24} md={24} lg={16} style={{ padding: '0 0 0 5px' }}>
              {![ EXPERIMENT_STATUSES_MAP.OBSOLETE, EXPERIMENT_STATUSES_MAP.ARCHIVED ].includes(experimentStatus) && (
                <Card className='card-has-table'>
                  <Tabs defaultActiveKey="experiment_details_content">
                    <TabPane tab='Mixin config' key="mixin_config">
                      <Card className='card-has-table' loading={isLoadingExperimentDetails}>
                        <ReactJson
                          src={mixinData?.config}
                          theme="summerfruit:inverted"
                        />
                      </Card>
                    </TabPane>
                    <TabPane tab='History' key="experiment_details_history">
                      <MixinsExperimentHistoryCard
                        isLoading={isLoadingExperimentDetails}
                        data={experimentDetails?.history?.data}
                      />
                    </TabPane>
                  </Tabs>
                </Card>
              )}
            </Col>
          </Row>
        </Content>
      </Spin>
    </HasRights>
  );
};

export default PageMixinsExperimentDetails;
