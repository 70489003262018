import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../services/Api/Api';
import AbTestsService from '../services/abTestsService';
import { setUrlParams } from '../../../lib/setUrlParams';
import { mkNotification } from '../../../lib/mkNotification';



const initialState = {
  data: [],
  errors: {},
  pagination: {},
  options: {},
  loading: false,
};

const abTestsService = new AbTestsService(Api);

export const getAbTestsListData = createAsyncThunk(
  'abTests/getAbTestsList',
  async (values, { rejectWithValue }) => {
    try {
      const response = await abTestsService.getAbTestsList(values);

      if (!isEmpty(values)) {
        setUrlParams(values);
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteAbTest = createAsyncThunk(
  'abTests/deleteAbTest',
  async (abTestId) => {
    const response = await abTestsService.deleteAbTest(abTestId);

    return response.data;
  },
);


export const abTestsSlice = createSlice({
  name: 'abTests',
  initialState,
  reducers: {
    resetAbTestsData: (state) => {
      state.data = [];
      state.pagination = {};
      state.options = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAbTestsListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAbTestsListData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.pagination = action.payload.meta.pagination;
        state.options = action.payload.meta.custom;
      })
      .addCase(getAbTestsListData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
      });

    builder
      .addCase(deleteAbTest.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAbTest.fulfilled, (state) => {
        state.loading = false;
        mkNotification('success', 'Ab-test is successfully deleted.');
      })
      .addCase(deleteAbTest.rejected, (state) => {
        state.loading = false;
      });

  },
});

// actions
export const { resetAbTestsData } = abTestsSlice.actions;

// selectors
export const selectAbTestsLoading = (state) => state.abTests.loading;
export const selectAbTestsData = (state) => state.abTests.data;
export const selectAbTestsOptions = (state) => state.abTests.options;
export const selectAbTestsPagination = (state) => state.abTests.pagination;
export const selectAbTestsErrors = (state) => state.abTests.errors;

// reducer
export default abTestsSlice.reducer;
