import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Layout, Popconfirm,
  Row, Switch,
  Table,
  Tabs,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useApp } from '../../../../app/context/AppContext';
import { AB_TEST_STATUS_MAP } from '../PageAbTests/PageAbTests.const';
import { GROUPS_TABLE_COLUMNS, USERS_BY_DATE_TABLE_COLUMNS, prepareDateStatisticsData } from './PageAbTestDetails.const';
import { BTN_SIDE_MARGIN } from '../../../../const/system';
import urlPageAbTests from '../../../../urls/urlPageAbTests';
import urlPageEditAbTest from '../../../../urls/urlPageEditAbTest';
import PollingNotificationModal from '../../../../components/PollingNotificationModal';
import AbTestDetailsConditionsCard from '../../components/AbTestDetailsConditionsCard';
import AbTestDetailsCard from '../../components/AbTestDetailsCard';
import AbTestDetailsGroupsCard from '../../components/AbTestDetailsGroupsCard';
import AbTestDetailsDateStatisticsChart from '../../components/AbTestDetailsDateStatisticsChart';
import AbTestDetailsGroupsStatisticsChart from '../../components/AbTestDetailsGroupsStatisticsChart';
import HasRights from '../../../../components/HasRights';
import { hasRights, Permissions } from '../../../../const/permissions';
import {
  getAbTestDetails,
  getStatisticsData,
  resetStatisticsData,
  selectData,
  selectLoading,
  selectStatisticsData,
  selectStatisticsLoading,
  selectStatisticsPolling,
  selectIsLoadedInitialStatistics,
  selectPublishAbTestLoading,
  publishAbTest,
  resetAbTestDetails,
} from '../../feature/abTestDetailsSlice';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const { Content } = Layout;
const { TabPane } = Tabs;

const PageAbTestDetails = () => {
  const dispatch = useDispatch();
  const { abTestName } = useParams();
  const { projectSettings } = useApp();
  const abTestDetails = useSelector(selectData);
  const isLoading = useSelector(selectLoading);
  const getStatisticPolling = useSelector(selectStatisticsPolling);
  const statisticData = useSelector(selectStatisticsData);
  const isStatisticLoading = useSelector(selectStatisticsLoading);
  const isLoadedInitialStatistics = useSelector(selectIsLoadedInitialStatistics);
  const isPublishLoading = useSelector(selectPublishAbTestLoading);

  const [ isActiveNotificationModal, setToggleNotificationModal ] = useState(false);
  const [ isGroupsStatisticsTableView, setGroupsStatisticsTableView ] = useState(true);
  const [ isDateStatisticsTableView, setDateStatisticsTableView ] = useState(true);
  const chartsDateStatisticData = !isEmpty(statisticData) ? prepareDateStatisticsData(statisticData.usersByDate) : [];
  const allowPublish = abTestDetails?.status !== AB_TEST_STATUS_MAP.DRAFT;


  useEffect(() => {
    if (abTestName !== ':name') {
      dispatch(getAbTestDetails(abTestName));
    }
    return () => {
      dispatch(resetAbTestDetails());
      dispatch(resetStatisticsData());
    };
  }, [ abTestName ]);

  const pollingInterval = 30000; //30sec

  useEffect(() => {
    const abTestStatisticIntervalId = setInterval(() => {
      return getStatisticPolling ? dispatch(getStatisticsData(abTestName)) : null;
    }, pollingInterval);

    return () => {
      clearInterval(abTestStatisticIntervalId);
    };
  }, [ getStatisticPolling ]);

  useEffect(() => {
    if (!isEmpty(statisticData) || !getStatisticPolling) {
      setToggleNotificationModal(false);
    }
  }, [ statisticData ]);

  const handleGetStatistics = () => {
    dispatch(getStatisticsData(abTestName));
    setToggleNotificationModal(true);
  };

  const handlePublishAbTest = ({ abTestId }) => {
    dispatch(publishAbTest({ abTestId }));
  };


  return (
    <HasRights allowedPermissions={[ Permissions.AB_TESTS.VIEW_DETAILS ]}>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={urlPageAbTests()}>
                Ab-tests list
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Details</Breadcrumb.Item>
          </Breadcrumb>

          <Col>
            {hasRights([ Permissions.AB_TESTS.GET_STATISTIC ]) && (
              <Button
                className='hovered-btn'
                disabled={isLoading}
                loading={getStatisticPolling || isStatisticLoading}
                onClick={handleGetStatistics}
              >
                {!isLoadedInitialStatistics ? 'Get statistics' : 'Refresh statistics'}
              </Button>
            )}

            {hasRights([ Permissions.AB_TESTS.PUBLISH_TEST ]) && allowPublish && (
              <Popconfirm
                title='Sure to publish this ab-test?'
                onConfirm={() => {
                  handlePublishAbTest({ abTestId: abTestDetails.id });
                }}
              >
                <Button
                  style={BTN_SIDE_MARGIN}
                  danger
                  disabled={isLoading || isPublishLoading}
                  loading={isPublishLoading}
                >
                  Publish AB test
                </Button>
              </Popconfirm>
            )}


            {hasRights([ Permissions.AB_TESTS.UPDATE ]) && (
              <Button
                style={BTN_SIDE_MARGIN}
                disabled={isLoading}
                size='middle'
                type="primary"
              >
                <Link to={urlPageEditAbTest({ abTestName: abTestDetails?.name })}>
                  {abTestDetails.status !== AB_TEST_STATUS_MAP.ARCHIVED ? 'Edit details' : 'View details'}
                </Link>
              </Button>
            )}
          </Col>
        </Row>
      </Col>

      <Content>
        <Row gutter={[ 16, 16 ]}>
          <Col xs={24} md={24} xl={6}>
            <div className='card-table'>
              <Row gutter={[ 8, 8 ]}>
                <Col xs={24} md={14} xl={24}>
                  <AbTestDetailsCard
                    data={abTestDetails}
                    isLoading={isLoading}
                  />
                </Col>
                {!isEmpty(abTestDetails?.data?.conditions) && abTestDetails.data.conditions.map((condition, index) => (
                  <Col xs={24} md={10} xl={24} key={condition[index]}>
                    <AbTestDetailsConditionsCard
                      index={index}
                      condition={condition}
                      isLoading={isLoading}
                    />
                  </Col>
                ))}
                {!isEmpty(abTestDetails?.data?.groups) && abTestDetails.data.groups.map((group, index) => (
                  <Col xs={24} md={10} xl={24} key={group[index]}>
                    <AbTestDetailsGroupsCard
                      index={index}
                      group={group}
                      isLoading={isLoading}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col xs={24} md={24} xl={18}>
            <Card className='card-has-table'>
              <Tabs defaultActiveKey="groups_statistics">
                <TabPane tab="Groups statistic" key="groups_statistics">
                  <Row className='da-mb-16 content-right'>
                    <div>
                      Show as:
                      <Switch
                        style={{ marginLeft: 8 }}
                        onChange={(value) => {
                          setGroupsStatisticsTableView(value);
                        }}
                        checkedChildren="Table"
                        unCheckedChildren="Chart"
                        defaultChecked
                      />
                    </div>
                  </Row>
                  {isGroupsStatisticsTableView ? (
                    <Table
                      sticky
                      loading={isLoading}
                      rowKey="uniq_users"
                      columns={GROUPS_TABLE_COLUMNS}
                      dataSource={!isEmpty(statisticData) ? statisticData.groups : []}
                      pagination={{
                        position: [ 'bottomCenter' ],
                        pageSizeOptions: [ '10', '25', '50' ],
                        size: 'small',
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                      }}
                    />
                  ) : (
                    <AbTestDetailsGroupsStatisticsChart data={!isEmpty(statisticData) ? statisticData.groups : []} />
                  )}
                </TabPane>
                <TabPane tab="Statistics by date" key="date_statistics">
                  <Row className='da-mb-16 content-right'>
                    <div>
                      Show as:
                      <Switch
                        style={{ marginLeft: 8 }}
                        onChange={(value) => {
                          setDateStatisticsTableView(value);
                        }}
                        checkedChildren="Table"
                        unCheckedChildren="Chart"
                        defaultChecked
                      />
                    </div>
                  </Row>
                  {isDateStatisticsTableView ? (
                    <Table
                      sticky
                      loading={isLoading}
                      rowKey="index"
                      columns={USERS_BY_DATE_TABLE_COLUMNS}
                      dataSource={!isEmpty(statisticData) ? statisticData.usersByDate : []}
                      pagination={{
                        position: [ 'bottomCenter' ],
                        pageSizeOptions: [ '10', '25', '50' ],
                        size: 'small',
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                      }}
                    />
                  ) : (
                    <AbTestDetailsDateStatisticsChart data={chartsDateStatisticData} />
                  )}
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Content>

      <PollingNotificationModal
        title='Ab-test statistics notification'
        isActiveModal={isActiveNotificationModal}
        toggleModal={setToggleNotificationModal}
      />
    </HasRights>
  );
};

export default PageAbTestDetails;
