import React from 'react';
import { Form, DatePicker, SubmitButton, FormItem, Select, Input } from 'formik-antd';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Col, Row, Tooltip } from 'antd';
import { RiInformationLine } from 'react-icons/ri';
import capitalize from 'lodash/capitalize';
import Yup from '../../../../../vendor/yup';
import { mkClearFormTableParams } from '../../../../../lib/mkClearFormTableParams';
import { getNotEmptyObjectValues } from '../../../../../lib/getNotEmptyObjectValues';
import { TFormDateRangeDivider } from '../../../../AbTests/forms/FormCreateEditAbTest/FormCreateEditAbTest';



const { Option } = Select;


const validationSchema  = Yup.object().shape({
  space_id: Yup.string().nullable(),
  project_id: Yup.string().nullable(),
  user_id: Yup.string().nullable(),
  action: Yup.string().nullable(),
  category: Yup.string().nullable(),
  type: Yup.string().nullable(),
  message: Yup.string().max(191, 'Max 191 symbols required'),
});

const FormLogsFilter = ({
  onSubmit,
  initialValues,
  isGlobalAdmin,
  currentSpaceKey,
  isSubmitting,
  formErrors,
  optionsData,
  spacesData,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(getNotEmptyObjectValues(values));
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setFieldValue, setValues } = props;

        return (
          <Form layout="vertical">
            {isGlobalAdmin && (
              <Col>
                <FormItem
                  className='ant-form-item-col'
                  name="space_id"
                  label="Space:"
                >
                  <Select
                    allowClear
                    showSearch
                    name="space_id"
                    value={values?.space_id}
                    placeholder="Select space"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('space_id', value);
                      }
                      setFieldValue('project_id', null);
                    }}
                  >
                    {spacesData.map((item) => {
                      return (
                        <Option key={item.id} value={String(item.id)}>{item.name}</Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            )}

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="project_id"
                label="Project:"
              >
                <Select
                  allowClear
                  showSearch
                  name="project_id"
                  value={values?.project_id}
                  placeholder="Select project"
                  optionFilterProp="children"
                  disabled={isGlobalAdmin ? !values?.space_id : false}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                  onChange={(value) => {
                    if (!isEmpty(value)) {
                      setFieldValue('project_id', value);
                    }
                  }}
                >
                  {!isEmpty(spacesData) ? spacesData.filter((space) => isGlobalAdmin ? (space.id === Number(values?.space_id)) : space.key === currentSpaceKey)[0]?.projects?.data.map((item) => {
                    return (
                      <Option key={item.id} value={String(item.id)}>{item.name}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="user_id"
                label="User:"
              >
                <Select
                  allowClear
                  showSearch
                  name="user_id"
                  value={values?.user_id}
                  placeholder="Select user"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(optionsData?.users) ? optionsData.users.map((item) => {
                    return (
                      <Option key={item.id} value={String(item.id)}>{item.name}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="category"
                label="Category:"
              >
                <Select
                  allowClear
                  name="category"
                  value={values.category}
                  placeholder="Select category"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(optionsData?.categories) ? optionsData.categories.map((item) => {
                    return (
                      <Option key={item} value={String(item)}>{capitalize(item)}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="action"
                label="Action:"
              >
                <Select
                  allowClear
                  name="action"
                  value={values.action}
                  placeholder="Select action"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(optionsData?.action) ? optionsData.action.map((item) => {
                    return (
                      <Option key={item} value={String(item)}>{capitalize(item)}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="type"
                label="Type:"
              >
                <Select
                  allowClear
                  name="type"
                  value={values.type}
                  placeholder="Select type"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(optionsData?.types) ? optionsData.types.map((item) => {
                    return (
                      <Option key={item} value={String(item)}>{capitalize(item)}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="message"
                label="Message:"
              >
                <Input.TextArea
                  name='message'
                  placeholder='Specify message'
                  rows={4}
                  showCount
                  maxLength={191}
                />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                className='ant-form-item-col'
                name='created_at'
                label={
                  <div className='content-right'>
                    Created at:
                    <Tooltip placement="top" title="Time zone - UTC +0">
                      <RiInformationLine size={18} />
                    </Tooltip>
                  </div>
                }
              >
                <Row>
                  <Col span={11}>
                    <FormItem
                      className='ant-form-item-col disable-ant-form-margin'
                      name='created_at.from'
                    >
                      <DatePicker
                        showTime
                        format='YYYY-MM-DD HH:mm'
                        placeholder='Select min date'
                        name='created_at.from'
                        disabledDate={(date) => !isEmpty(values?.created_at?.to) ? date.isAfter(moment(values.created_at.to).utc(false)) : date.isAfter(moment(new Date()).utc(false))}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('created_at.from', moment(value).format('YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </FormItem>
                  </Col>

                  <Col span={2}>
                    <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                  </Col>
                  <Col span={11}>
                    <FormItem
                      className='ant-form-item-col'
                      name='created_at.to'
                    >
                      <DatePicker
                        showTime
                        format='YYYY-MM-DD HH:mm'
                        placeholder='Select max date'
                        name='created_at.to'
                        disabledDate={(date) => !isEmpty(values?.created_at?.from) ? date.isBefore(moment(values.created_at.from).utc(false)) || date.isAfter(moment(new Date()).utc(false)) : date.isAfter(moment(new Date()).utc(false))}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('created_at.to', moment(value).format('YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>

            <Row style={{ justifyContent: 'center', padding: '10px 0' }} gutter={[ 16, 16 ]}>
              <Col>
                <Button
                  type='secondary'
                  disabled={isEmpty(mkClearFormTableParams(values))}
                  onClick={() => {
                    setValues({});
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || (isEqual(initialValues, values))}
                >
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormLogsFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  isGlobalAdmin: PropTypes.bool,
  currentSpaceKey: PropTypes.string,
  optionsData: PropTypes.object,
  spacesData: PropTypes.array,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    space_id: PropTypes.string,
    project_id: PropTypes.string,
    user_id: PropTypes.string,
    action: PropTypes.string,
    category: PropTypes.string,
    type: PropTypes.string,
    message: PropTypes.string,
    created_at: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
  isSubmitting: PropTypes.bool.isRequired,
};

FormLogsFilter.defaultProps = {
  isGlobalAdmin: false,
  currentSpaceKey: null,
  optionsData: {},
  formErrors: {},
  spacesData: [],
};

export default FormLogsFilter;
