export const checkPathType = (obj, path) => {
  const splittedPath = path.split('.');

  let current = obj;

  let index;

  // eslint-disable-next-line fp/no-loops
  for (index = 0; index < splittedPath.length; ++index) {
    if (current[splittedPath[index]] === undefined) {
      return undefined;
    }
    current = current[splittedPath[index]];
  }

  return typeof current;
};


export const checkScalar = (obj, path) => {
  const pathArr = path.split('.');
  const bannedTypes = [ 'string', 'number' ];

  let objClone = { ...obj };

  let hasScalar = false;

  // eslint-disable-next-line fp/no-loops
  while (pathArr.length && !hasScalar) {
    const piece = pathArr.shift();

    if (objClone[piece] === undefined) break;
    if (!bannedTypes.includes(typeof (objClone[piece]))) {
      objClone = objClone[piece];
    } else {
      hasScalar = true;
    }
  }

  return hasScalar;
};

export const checkObjPathNesting = (obj, path) => {
  const pathArr = path.split('.');

  let objClone = { ...obj };

  let similarPath = false;

  // eslint-disable-next-line fp/no-loops
  while (pathArr.length && !similarPath) {
    const piece = pathArr.shift();

    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    if (typeof objClone[piece] === 'undefined') {
      similarPath = true;
    } else {
      objClone = objClone[piece];
    }
  }
  return !similarPath;
};

