import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Button, Card, Col, Row, Spin } from 'antd';
import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import ReactJson from 'react-json-view';
import urlPageConfigVisualize from '../../../../urls/urlPageConfigVisualize';
import { LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import { FormCreateConfigVisualizeButtonWr } from '../FormCreateConfigVisualize/FormCreateConfigVisualize';
import { Permissions, hasRights } from '../../../../const/permissions';



const { Option } = Select;



const FormCreateConfigVisualizeStep3 = ({
  initialValues,
  onCancel,
  isLoading,
  handleGetServerConfig,
  serverConfigData,
}) => {
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      isSubmitting={false}
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      {(props) => {
        const { values, setFieldValue } = props;

        return (
          <Form layout="horizontal">
            <Spin spinning={isLoading} tip={LOADING_TIP_PLACEHOLDER}>
              <div className="centered-item">
                <Col xs={20} sm={12} md={8} lg={4}>
                  <FormItem name="server" label="Server:">
                    <Select
                      name="server"
                      placeholder="Choose server"
                      value={values.server}
                      disabled={!hasRights([ Permissions.CONFIGS.CONFIG_VISUALIZE.VIEW_CORE_DETAILS ])}
                      onChange={(value) => {
                        setFieldValue('server', value);
                        handleGetServerConfig(value);
                      }}
                    >
                      <Option value={null}>Empty</Option>
                      <Option value="production">Production</Option>
                      <Option value="sandbox">Sandbox</Option>
                    </Select>
                  </FormItem>
                </Col>
              </div>

              {(!isEmpty(serverConfigData) && values.server !== null) && (
                <Card className="da-mt-24">
                  <ReactJson
                    src={serverConfigData}
                    theme="summerfruit:inverted"
                  />
                </Card>
              )}

              <Row gutter={[ 16, 16 ]}>
                <div className="centered-item">
                  <FormCreateConfigVisualizeButtonWr>
                    <Button onClick={onCancel}>
                      Go back
                    </Button>
                  </FormCreateConfigVisualizeButtonWr>
                  <FormCreateConfigVisualizeButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      onClick={() => {
                        navigate(urlPageConfigVisualize());
                      }}
                    >
                      Finish
                    </SubmitButton>
                  </FormCreateConfigVisualizeButtonWr>
                </div>
              </Row>
            </Spin>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateConfigVisualizeStep3.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleGetServerConfig: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    server: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  serverConfigData: PropTypes.object,
};

FormCreateConfigVisualizeStep3.defaultProps = {
  setFieldValue: () => {},
  serverConfigData: {},
  values: {
    server: null,
  },
};

export default FormCreateConfigVisualizeStep3;
