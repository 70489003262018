import { Tooltip } from 'antd';
import { RiInformationLine } from 'react-icons/ri';
import React from 'react';



const ChartsTooltip = () => {
  return (
    <div className='content-right'>
      <Tooltip placement="topRight" title="Time zone - UTC +0">
        <RiInformationLine size={18} />
      </Tooltip>
    </div>
  );
};

export default ChartsTooltip;
